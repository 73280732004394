import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, json, useNavigate } from "react-router-dom";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactSwitch from "react-switch";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { async } from "q";
import { TablePagination } from "@material-ui/core";
import DataProcess from "../../../common/Helper";
import SpreadApi from "../../../services/API/myspreadApi";
import GroupeadApi from "../../../services/API/groupspreadApi";
import AuthService from "../../../services/auth.service";
import { toasterError, toasterSuccess } from "../../../components/ToasterMessages/toastify.helper";
import { AutoLogout } from "../../Logout/Logout";

const GroupSpread = () => {
  const navigate = useNavigate();

  const form = useRef();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataid, setDataId] = useState("");
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowPerPage, setCurrentRowPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  let errorMessage;

  const handleDelete = (id) => {
    handleOpen();

    setDataId(id);
  };

  const handleConfirmDelete = async (id) => {
    await SpreadApi.deleteSpread(id).then(() => {});

    handleOff();
    setDataId(id);
    notify();

    getAllSpread();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllSpread = async () => {
    try {
      const response = await GroupeadApi.GetAllSpread(
        page,
        rowsPerPage,
        sortBy,
        sortOrder
      );
      let result = JSON.parse(DataProcess.decryptData(response.data.result));
      setData(result.data);

      setTotalItems(result.count);
    } catch (error) {
      if(error.response.status == "401"){
        AutoLogout()
      }else{
        let msgError = DataProcess.decryptData(
          error.response.data.resError
        );
        msgError = JSON.parse(msgError);
        toasterError(msgError.message);
      }

    }
  };

  useEffect(() => {
    getAllSpread();
  }, [page, rowsPerPage]);

  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">This field is required!</div>
      );
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const vusername = (value) => {
    if (!emailRegex.test(value)) {
      return (
        <div className="invalid-feedback d-block">
          The username must be Email formate.
        </div>
      );
    }
  };

  const onChangeFName = (e) => {
    const fName = e.target.value;
    setFName(fName);
  };
  const handleCancelClick = () => {
    handleClose();
  };
  const handleClick = () => {
    navigate("/newspread");
  };

  const onChangeLName = (e) => {
    const lName = e.target.value;
    setLName(lName);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const notify = () => {};

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    getAllSpread();
  };

  const handleUpdate = async (id, data) => {
    let islocked;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };

    if (data == "Lock") {
      islocked = true;
    } else if (data == "Continue") {
      islocked = false;
    }

    await fetch(
      process.env.REACT_APP_SERVER_ENV_API +"/spread/update/" + id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(JSON.stringify({ islocked })),
        }),
      },

      toasterSuccess("Update spread successfully!")
    );
    getAllSpread();
  };
  const handleChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const value = selectedOption.value;
    const id = selectedOption.getAttribute("data-id");
    setSelectedValue(value);
    if (value == "Continue") {
      handleUpdate(id, "Continue");
    } else if (value == "Lock") {
      handleUpdate(id, "Lock");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "";
    }
    date.setHours(date.getHours() + 5);

    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };
  return (
    <>
      <div className="container mb-4">
        <div className="container mb-5 d-flex">
          <h3>Group Spread Management</h3>
        </div>

        <div className="container mt-3">
          <div></div>
          <Table striped bordered hover style={{textAlign: "center"}}>
            <thead>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <th onClick={() => handleSort("firstname")}>
                  Customer Name
                  {sortBy === "firstname" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("name")}>
                  Spread Name
                  {sortBy === "name" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("year")}>
                  Year
                  {sortBy === "year" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("notes")}>
                  Spread Detail
                  {sortBy === "notes" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("created_at")}>
                  Date
                  {sortBy === "created_at" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>
                    {item.firstname} {item.lastname}{" "}
                  </td>
                  <td>
                    {" "}
                    {item.islocked ? (
                      item.name
                    ) : (
                      <Link to={`/myspread/${item.id}`}>{item.name}</Link>
                    )}
                  </td>
                  <td>{item.year}</td>
                  <td>{item.notes}</td>
                  <td>{item.created_at ? formatDate(item.created_at) : ""}</td>

                  <td>
                    <Dropdown>
                      <select
                        defaultValue={item.islocked ? "Lock" : "Continue"}
                        onChange={handleChange}
                        disabled={item.islocked}
                      >
                        <option value="Continue" data-id={item.id}>
                          Continue{" "}
                        </option>
                        <option value="Lock" data-id={item.id}>
                          Lock
                        </option>
                      </select>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Modal show={open} onHide={handleOff}>
            <Modal.Header>
              <Modal.Title>Are you sure you want to delete?</Modal.Title>
              <IoClose
                size={24}
                color="red"
                style={{ cursor: "pointer" }}
                onClick={handleCancelDelete}
              />
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="primary"
                style={{
                  marginTop: "15px",
                  marginRight: "5px",
                  backgroundColor: "#c00000",
                }}
                type="submit"
                onClick={() => handleConfirmDelete(dataid)}
              >
                Delete
              </Button>
              <Button
                variant="primary"
                style={{ marginTop: "15px" }}
                type="submit"
                onClick={handleCancelDelete}
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default GroupSpread;
