import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";
import DataProcess from "../../common/Helper";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">This field is required!</div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="invalid-feedback d-block">This is not a valid email.</div>
    );
  }
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const vusername = (value) => {
  if (!emailRegex.test(value)) {
    return (
      <div className="invalid-feedback d-block">
        You have entered invalid email address.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="invalid-feedback d-block">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = (props, { onSetSignup }) => {
  const form = useRef();
  const checkBtn = useRef();

  const navigate = useNavigate();

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || !emailRegex.test(value)) {

      setIsValidEmail(false)
      return (
        <div className="invalid-feedback d-block">
          Please enter a valid email address.
        </div>
      );
    } else {
      setIsValidEmail(true)
    }
  };


  const onChangeFName = (e) => {
    const fName = e.target.value;
    setFName(fName);
  };

  const onChangeLName = (e) => {
    const lName = e.target.value;
    setLName(lName);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const notifySuccess = () => {
    toasterSuccess("User registerd successfully!");
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(fName, lName, username, password).then(
        (response) => {
          if (response.status) {
            let responseData = DataProcess.decryptData(response.result);
            responseData = JSON.parse(responseData);
            if (responseData.success) {
              notifySuccess();
            }
            navigate("/login");
          } else {
            let responseData = DataProcess.decryptData(response.error);
            responseData = JSON.parse(responseData);
            toasterError(responseData.message);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          let msgError = DataProcess.decryptData(error.response.data.resError);

          msgError = JSON.parse(msgError);
          setMessage(msgError.message);

          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div
      className="col-md-12"
      style={{
        backgroundColor: "#343a40",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="card card-container"
        style={{ border: "1px solid black", borderRadius: "15px" }}
      >
        <img
          src="Robospreads-logo.png"
          alt="profile-img"
          style={{ marginBottom: "30px" }}
        />
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">First Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fname"
                  value={fName}
                  onChange={onChangeFName}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Last Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="lname"
                  value={lName}
                  onChange={onChangeLName}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="email"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, validateEmail, vusername]}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                  required
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={!fName || !lName || !username || !password || !isValidEmail}
                  style={{
                    backgroundColor:
                      !fName || !lName || !username || !password || !isValidEmail
                        ? "gray"
                        : "blue",
                  }}
                >
                  Sign Up
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
          <div>
            <p>
              if you already have an account, <Link to="/login">Login</Link>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Register;
