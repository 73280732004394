import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
import DataProcess from "../../common/Helper";
import { toasterError, toasterSuccess } from "../../components/ToasterMessages/toastify.helper";
import UserApi from "../../services/API/UserApi";
import { AutoLogout } from "../Logout/Logout";

const EditUser = (props) => {
  const { id } = useParams();

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
  });

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);


  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "username") {
      // Reset isValidEmail only when the email input changes
      validateEmail(e.target.value);
    }
    if (e.target.name === "password") {
      validatePassword(e.target.value);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    let user;
    UserApi.getUser(id).then((response) => {
      user = response.data.result;

      let userData = DataProcess.decryptData(user);
      userData = JSON.parse(userData);
      setData(userData.data);
    }, (error) => {
      if (error.response.status == "401") {
        AutoLogout()
      } else {
        let msgError = DataProcess.decryptData(
          error.response.data.resError
        );
        msgError = JSON.parse(msgError);
        toasterError(msgError.message);
      }
    });
  }, []);

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(!value || emailRegex.test(value));
  };


  const handleUpdate = async (userData) => {

    if (!isValidEmail || !validPassword) {
      // You might want to display an error message or handle it accordingly
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };

    await fetch(process.env.REACT_APP_SERVER_ENV_API + "/user/update-user/" + userData.id, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ reqJson: DataProcess.encryptData(JSON.stringify(userData)) }),

    },
      toasterSuccess("Update user successfully!"),
      navigate("/user")
    );

  };

  const validatePassword = (password) => {
    const passwordRegex = /^.{6,40}$/;
    setValidPassword(passwordRegex.test(password));
  };



  return (
    <div className="col-md-12">
      <div>
        <h3>Edit User</h3>
      </div>
      <Form onSubmit={() => handleUpdate(data)}>
        <div>
          <div className="form-group">
            <label htmlFor="username">First Name</label>
            <Input
              type="text"
              className="form-control"
              name="firstname"
              value={data.firstname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Last Name</label>
            <Input
              type="text"
              className="form-control"
              name="lastname"
              value={data.lastname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              className={`form-control ${isValidEmail ? "" : "is-invalid"}`}
              name="username"
              value={data.username}
              onChange={handleInputChange}
              required
            />

            {!isValidEmail && (
              <div className="invalid-feedback d-block">
                Please enter a valid email address.
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              className={`form-control ${validPassword ? "" : "is-invalid"}`}
              name="password"
              value={data.password}
              onChange={handleInputChange}
            />
            {!validPassword && (
              <div className="invalid-feedback d-block">
                Password must be between 6 and 40 characters.
              </div>
            )}
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary btn-block"
              disabled={!isValidEmail || !validPassword}
            >Update User</button>
          </div>
        </div>

        <CheckButton style={{ display: "none" }} />
      </Form>
    </div>
  );
};

export default EditUser;
