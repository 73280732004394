import { Input } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../SpreadConfigration/ImportFile.css";
import DataProcess from "../../common/Helper";
import YearApi from "../../services/API/YearApi";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import AuthService from "../../services/auth.service";
import { json, useNavigate } from "react-router-dom";
import SpreadConfiguration from "./SpreadConfigration";
import SpreadConfigurationApi from "../../services/API/spreadConfigurationApi";
import Loader from "../../components/Loader/Loader";
import ReactSwitch from "react-switch";
import { IoClose } from "react-icons/io5";
import Createtableselact from "react-select/creatable";
import { input } from "react-validation/build/input";

const ImportFile = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const overlayRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [prevStartX, setPrevStartX] = useState(0);
  const [prevStartY, setPrevStartY] = useState(0);
  const [prevWidth, setPrevWidth] = useState(0);
  const [prevHeight, setPrevHeight] = useState(0);
  const [imageShow, setImageShow] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [years, setYears] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isCashOn, setIsCashOn] = useState(false);
  const [formSections, setFormSections] = useState([]);
  const [createtableselactOptions, setCreatetableselactOptions] = useState([]);
  const [spreads, setSpreads] = useState([]);
  const [formInput, setFormInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectType, setselectType] = useState("");
  const [label, setLabel] = useState("");

  const [inputValue, setinputValue] = useState({
    year: "",
    fieldName: "",
    form_type: "",
    field_input: "",
    field_label: "",
    pageNo: "",
    ismultiple: false,
    isCash: false,
    isNewConfigration: false,
  });

  const data = [
    {
      form_type: "1040",
    },
    {
      form_type: "1040_sch_1",
    },
    {
      form_type: "1041",
    },
    {
      form_type: "1084_cash_flow",
    },
    {
      form_type: "1040_sch_A",
    },
    {
      form_type: "1040_sch_B",
    },
    {
      form_type: "1040_sch_C",
    },
    {
      form_type: "1040_sch_D",
    },
    {
      form_type: "1040_sch_E",
    },
    {
      form_type: "1040_sch_F",
    },
    {
      form_type: "1120",
    },
    {
      form_type: "1120S",
    },
    {
      form_type: "1120S_K1",
    },
    {
      form_type: "1065C",
    },
    {
      form_type: "1065_K1",
    },
    {
      form_type: "2106",
    },
    {
      form_type: "8606",
    },
    {
      form_type: "8865_K1",
    },
    {
      form_type: "4835",
    },
    {
      form_type: "6252",
    },
    {
      form_type: "8825",
    },
    {
      form_type: "4797",
    },
    {
      form_type: "1084_cash_flow",
    },
    {
      form_type: "incomeStatement",
    },
    {
      form_type: "Balancesheet",
    },
    {
      form_type: "Debt-payment",
    },
    {
      form_type: "Adjustments",
    },
  ];

  const filterFormType = ["1065C", "1120", "1120S"];

  const sidebarWidth = 221;
  const topbarHeight = 200;

  useEffect(() => {
    SearchYears();
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (
      imageShow &&
      typeof imageShow.images === "object" &&
      imageShow.images.length > 0
    ) {
      const currentImage = new Image();
      currentImage.src = imageShow.images[currentImageIndex];
      currentImage.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(currentImage, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [imageShow, currentImageIndex]);

  const fetchCreatetableselactOptions = async () => {
    if (inputValue.selectedOption && selectType !== inputValue.selectedOption) {
      setselectType(inputValue.selectedOption);
      const response = await SpreadConfigurationApi.getSearchConfigration(
        inputValue.selectedOption,
        ""
      );

      if (response) {
        let data =
          response.data && response.data.data ? response.data.data : [];

        const options = data.map((item) => ({
          value: item.field_input,
          label: item.field_input,
          fieldname: item.fieldname,
          field_label: item.field_label,
        }));

        const inputData = data.map((item) => ({
          field_input: item.field_input,
          fieldname: item.fieldname,
          field_label: item.field_label,
        }));

        setFormInput(inputData);

        setCreatetableselactOptions(options);
      } else {
      }
    }
  };

  fetchCreatetableselactOptions();

  const SearchYears = async () => {
    const response = await YearApi.SearchYear();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setYears(result.data);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    inputValue.year = selectedOption;
  };

  const handleInputChange = (value, fieldName) => {
    const matchingObject = createtableselactOptions.find(
      (item) => item.value === value.value
    );

    // Set common values
    let updatedInputValues = {
      isNewConfigration: true,
    };

    // Handle field-specific updates
    switch (fieldName) {
      case "fieldName":
        updatedInputValues = {
          ...updatedInputValues,
          fieldName: value,
          isNewConfigration: true,
        };
        break;
      case "field_input":
        if (matchingObject) {
          setLabel(matchingObject.label);
          updatedInputValues = {
            ...updatedInputValues,
            fieldName: matchingObject.fieldname,
            field_label: matchingObject.field_label,
            field_input: matchingObject.value,
            isNewConfigration: false,
          };
        } else {
          updatedInputValues = {
            ...updatedInputValues,
            field_input: value.label,
            isNewConfigration: true,
          };
        }
        break;
      case "field_label":
        updatedInputValues = {
          ...updatedInputValues,
          field_label: value,
          isNewConfigration: true,
        };
        break;
      default:
        break;
    }

    // Update state
    setinputValue((prevInputValues) => ({
      ...prevInputValues,
      ...updatedInputValues,
    }));

    // Update options
    setCreatetableselactOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.value === value.value
          ? { ...option, value: value.value }
          : option
      )
    );
  };

  const handleDropdownChange = async (e) => {
    const selectedOption = e.target.value;

    setinputValue((prevInputValues) => ({
      ...prevInputValues,
      selectedOption: selectedOption,
      fieldName: "",
      field_label: "",
      field_input: "",
    }));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const overlay = overlayRef.current;
    const rect = overlay.getBoundingClientRect();

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    setIsDrawing(true);
    setStartX(mouseX);
    setStartY(mouseY);
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    setIsDrawing(false);

    const overlay = overlayRef.current;
    const ctxo = overlay.getContext("2d");
    ctxo.strokeStyle = "blue";
    ctxo.lineWidth = 3;

    ctxo.strokeRect(prevStartX, prevStartY, prevWidth, prevHeight);

    setFormSections([
      ...formSections,
      {
        height: parseInt(prevHeight),
        width: parseInt(prevWidth),
        x: parseInt(prevStartX),
        y: parseInt(prevStartY),
        pageno: 1,
      },
    ]);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDrawing) {
      return;
    }

    const overlay = overlayRef.current;
    const rect = overlay.getBoundingClientRect();

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const width = Math.abs(mouseX - startX);
    const height = Math.abs(mouseY - startY);

    const prevX = Math.min(startX, mouseX);
    const prevY = Math.min(startY, mouseY);

    const ctxo = overlay.getContext("2d");
    ctxo.clearRect(0, 0, overlay.width, overlay.height);
    ctxo.strokeStyle = "blue";
    ctxo.lineWidth = 3;

    ctxo.strokeRect(prevX, prevY, width, height);

    setPrevStartX(prevX);
    setPrevStartY(prevY);
    setPrevWidth(width);
    setPrevHeight(height);
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("pdfData", file);

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ENV_API + "/spread/get-image",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          let image = DataProcess.decryptData(data.result);

          setImageShow(JSON.parse(image));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
    }
  };
  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < imageShow.images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const addSpreadConfig = async (e) => {
    e.preventDefault();

    // Ensure fieldName is set in inputValue or provide a default value if needed
    const fieldNameValue = inputValue.fieldName || "";
    const fieldLabelValue = inputValue.field_label || "";

    const reqJson = {
      year: inputValue.year,
      form_type: inputValue.selectedOption,
      fieldName: fieldNameValue,
      field_input: String(inputValue.field_input), // Convert to string
      field_label: fieldLabelValue,
      height: parseInt(prevHeight),
      width: parseInt(prevWidth),
      x: parseInt(prevStartX),
      y: parseInt(prevStartY),
      pageNo: currentPage,
      ismultiple: inputValue.ismultiple,
      isNewConfigration: inputValue.isNewConfigration,
      multipleConfig: formSections,
      isCash: inputValue.isCash,
    };

    // console.log("process.env.REACT_APP_SERVER_ENV_API ",process.env.REACT_APP_SERVER_ENV_API )
    try {
      const stringifiedReqJson = JSON.stringify(reqJson, (key, value) => {
        // Exclude React-specific properties or elements
        if (key.startsWith("__react") || key === "stateNode") {
          return undefined;
        }
        return value;
      });
      console.log(stringifiedReqJson);
      const response = await fetch(
        process.env.REACT_APP_SERVER_ENV_API + "/spread_configuration/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({
            reqJson: DataProcess.encryptData(stringifiedReqJson),
          }),
        }
      );

      if (response.ok) {
        const newYear = await response.json();
        setSpreads([...spreads, newYear]);
        // Clear input values after successful submission
        console.log("INPURVAUE409", inputValue);
        setinputValue((prevInputValue) => ({
          ...prevInputValue,
          pageNo: "",
          ismultiple: false,
          isNewConfigration: false,
          isCash: false,
        }));

        setPrevHeight(0);
        setPrevWidth(0);
        setPrevStartX(0);
        setPrevStartY(0);
        setIsSwitchOn(false);

        toasterSuccess("Spread Configuration added successfully");
      } else {
        const error = await response.json();
        console.log(
          "errorerror",
          JSON.parse(DataProcess.decryptData(error.resError))
        );
        console.log(
          "error",
          JSON.parse(DataProcess.decryptData(error.resError)).error
        );
        console.log("error", typeof error);

        if (
          error &&
          error.resError &&
          JSON.parse(DataProcess.decryptData(error.resError)) &&
          JSON.parse(DataProcess.decryptData(error.resError)).message
        ) {
          const errorMessages = JSON.parse(
            DataProcess.decryptData(error.resError)
          ).message;
          toasterError(errorMessages);
        } else {
          const errorMessage =
            error && error.resError
              ? JSON.parse(DataProcess.decryptData(error.resError)).error
                  .message
              : "An error occurred";
          toasterError(errorMessage);
        }
      }
    } catch (error) {
      toasterError(error.message || "An error occurred");
    }
  };

  const handleSwitchChange = async (newValue, id) => {
    setFormSections([]);
    setSwitchValue(newValue);
    setIsSwitchOn(newValue);

    // Clear fields regardless of the switch state
    setPrevHeight(0);
    setPrevWidth(0);
    setPrevStartX(0);
    setPrevStartY(0);
    setCurrentPage(1);

    // Update input values
    setinputValue((prevInputValue) => ({
      ...prevInputValue,
      ismultiple: newValue,
      height: "",
      width: "",
      x: "",
      y: "",
      pageNo: "",
    }));
  };

  const handleFormTypeCaseChange = (newValue) => {
    setIsCashOn(newValue);

    setinputValue((prevInputValue) => ({
      ...prevInputValue,
      isCash: newValue,
    }));
  };

  const handleAddFormSection = () => {
    setFormSections([
      ...formSections,
      { height: "", width: "", x: 0, y: 0, pageno: currentPage },
    ]);

    // Clear previous values
    setPrevHeight("");
    setPrevWidth("");
    setPrevStartX("");
    setPrevStartY("");
    setCurrentPage("");

    // Update input values
    setinputValue((prevInputValue) => ({
      ...prevInputValue,
      ismultiple: isSwitchOn,
      height: "",
      width: "",
      x: "",
      y: "",
      pageNo: "",
    }));
  };

  const handleCloseFormSection = (sectionIndex) => {
    const updatedFormSections = [...formSections];
    updatedFormSections.splice(sectionIndex, 1);

    const updatedData = {};
    updatedData.height = updatedFormSections
      .map((section) => section.height)
      .join(",");
    updatedData.width = updatedFormSections
      .map((section) => section.width)
      .join(",");
    updatedData.x = updatedFormSections.map((section) => section.x).join(",");
    updatedData.y = updatedFormSections.map((section) => section.y).join(",");
    updatedData.pageNo = updatedFormSections
      .map((section) => section.pageNo)
      .join(",");

    setFormSections(updatedFormSections);
    setinputValue((prevInputValue) => ({
      ...prevInputValue,
      ...updatedData,
    }));
  };

  const handleSectionInputChange = (e, sectionIndex, fieldName) => {
    const updatedFormSections = [...formSections];
    updatedFormSections[sectionIndex][fieldName] = e.target.value;
    setFormSections(updatedFormSections);
  };

  return (
    <>
      <div className="config-form">
        <div className="page-button">
          <label className="upload-btn" for="upload">
            Select a File
          </label>
          <input
            type="file"
            id="upload"
            className=""
            accept="application/pdf"
            onChange={handleFileUpload}
          />
          <button
            className="pagesNumber btn-primary"
            style={{ marginLeft: "275px" }}
            disabled={imageShow.length == 0}
            onClick={handlePreviousImage}
          >
            Previous
          </button>
          <button
            className="pagesNumber btn-primary"
            disabled={imageShow.length == 0}
            onClick={handleNextImage}
          >
            Next
          </button>
        </div>
      </div>
      <div className="form_row field">
        <div className="form">
          <label htmlFor="spread">Year </label>
          <select
            className="form-control"
            name="year"
            onChange={handleOptionChange}
          >
            <option>select</option>
            {years.map((option) => (
              <option key={option.id} value={option.id}>
                {option.year}
              </option>
            ))}
          </select>
          <label htmlFor="username">Form Type</label>
          <select
            className="form-control"
            as="select"
            value={inputValue.selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="">Select a form type</option>
            {data.map((item) => (
              <option key={item.form_type} value={item.form_type}>
                {item.form_type}
              </option>
            ))}
          </select>
          <label htmlFor="username">Field Name</label>
          <Input
            className="form-control"
            type="text"
            placeholder="Enter Field Name"
            value={inputValue.fieldName}
            onChange={(e) => handleInputChange(e.target.value, "fieldName")}
          />
          <label htmlFor="username">Field Input</label>

          <Createtableselact
            className="form-control"
            placeholder="Enter"
            value={
              createtableselactOptions.find(
                (option) => option.value === inputValue.field_input
              ) || {
                label: inputValue.field_input,
                value: inputValue.field_input,
              }
            }
            onChange={(selectedValue) =>
              handleInputChange(selectedValue, "field_input")
            }
            options={createtableselactOptions}
          />
          <label htmlFor="username">Field Label</label>
          <Input
            className="form-control"
            type="text"
            placeholder="Enter Field Label"
            value={inputValue.field_label}
            onChange={(e) => handleInputChange(e.target.value, "field_label")}
          />
          {!isSwitchOn && (
            <div className="config-date">
              <label htmlFor="username">Height</label>
              <Input
                type="text"
                className="form-control"
                name="height"
                value={prevHeight}
                onChange={(e) => setPrevHeight(e.target.value)}
              />
            </div>
          )}

          {!isSwitchOn && (
            <div className="config-date">
              <label htmlFor="username">Width</label>
              <Input
                type="text"
                className="form-control"
                name="width"
                value={prevWidth}
                onChange={(e) => setPrevWidth(e.target.value)}
              />
            </div>
          )}

          {!isSwitchOn && (
            <div className="config-date">
              <label htmlFor="username">X</label>
              <Input
                type="text"
                className="form-control"
                name="x"
                value={prevStartX === "" ? "" : parseInt(prevStartX)}
              />
            </div>
          )}

          {!isSwitchOn && (
            <div className="config-date">
              <label htmlFor="username">Y</label>
              <Input
                type="text"
                className="form-control"
                name="y"
                value={prevStartY === "" ? "" : parseInt(prevStartY)}
              />
            </div>
          )}

          {!isSwitchOn && (
            <div>
              <label htmlFor="username">PageNo</label>
              <Input
                type="text"
                className="form-control"
                name="pageno"
                value={currentPage}
              />
            </div>
          )}

          {isSwitchOn && <p>Add Multiple Values</p>}

          {/* is MUltiple ADd */}

          {formSections.map((section, index) => (
            <div key={index}>
              {isSwitchOn && (
                <IoClose
                  size={24}
                  color="red"
                  style={{ cursor: "pointer", marginLeft: "95%" }}
                  onClick={() => handleCloseFormSection(index)} // Pass the index to the function
                />
              )}
              {isSwitchOn && (
                <div className="config-date">
                  <label htmlFor="username">Height</label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`height-${index}`}
                    value={section.height}
                    onChange={(e) =>
                      handleSectionInputChange(e, index, "height")
                    }
                  />
                </div>
              )}

              {isSwitchOn && (
                <div className="config-date">
                  <label htmlFor="username">Width</label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`width-${index}`}
                    value={section.width}
                    onChange={(e) =>
                      handleSectionInputChange(e, index, "width")
                    }
                  />
                </div>
              )}

              {isSwitchOn && (
                <div className="config-date">
                  <label htmlFor="username">X</label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`x-${index}`}
                    value={section.x}
                    onChange={(e) => handleSectionInputChange(e, index, "x")}
                  />
                </div>
              )}

              {isSwitchOn && (
                <div className="config-date">
                  <label htmlFor="username">Y</label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`y-${index}`}
                    value={section.y}
                    onChange={(e) => handleSectionInputChange(e, index, "y")}
                  />
                </div>
              )}

              {isSwitchOn && (
                <div>
                  <label htmlFor="username">PageNo</label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`pageno-${index}`}
                    value={currentPage}
                    // onChange={(e) =>
                    //   handleSectionInputChange(e, index, "pageno")
                    // }
                  />
                </div>
              )}
            </div>
          ))}

          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <div>
              <label htmlFor="username">IsMultiple</label>
              <div className="switch-container">
                <ReactSwitch
                  checked={isSwitchOn}
                  onChange={(newValue) =>
                    handleSwitchChange(newValue, data.ismultiple)
                  }
                />

                {isSwitchOn && (
                  <button
                    className="multiple btn-danger"
                    type="button"
                    onClick={handleAddFormSection}
                  >
                    Add Multiple
                  </button>
                )}
              </div>
            </div>
            {filterFormType.includes(inputValue.selectedOption) && (
              <div>
                <label htmlFor="isCash">IsCash</label>
                <div className="switch-container">
                  <ReactSwitch
                    checked={isCashOn}
                    onChange={(newValue) => handleFormTypeCaseChange(newValue)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="form-group input-field">
            <button
              className="btn btn-primary btn-block"
              onClick={addSpreadConfig}
            >
              Add SpreadConfigration
            </button>
          </div>
        </div>

        {loading && <Loader />}

        <div className="form_canvas">
          <canvas
            className="image-canvas"
            ref={canvasRef}
            width={2771 - sidebarWidth}
            height={3500 - topbarHeight}
            style={{
              position: "absolute",
              border: "1px solid black",
              scale: "scroll",
            }}
          />
          <canvas
            className="image-canvas"
            ref={overlayRef}
            width={2771 - sidebarWidth}
            height={3500 - topbarHeight}
            style={{ position: "absolute" }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          />
        </div>
      </div>
    </>
  );
};

export default ImportFile;
