import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import { upload } from "@testing-library/user-event/dist/upload";

const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/spread/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const GetAllSpread = (skip, limit, sortBy, sortOrder) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  return axios.get(API_URL + `get?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`, {
    headers: headers,
  });
};

const getSpread = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  let Data = DataProcess.encryptData(JSON.stringify({}));
  return axios.get(API_URL + "get/" + id, {
    headers: headers,
  });
};

const getSpreadForm = (id, form_type, upload_id,key) => {
  console.log("KEY",key);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  let URL = ""
  if(key){
    URL = API_URL + "get-spread-form/" + id + "?form_type=" + form_type + "&upload_Id=" + upload_id + "&key=" + key 
  }else {
    URL = API_URL + "get-spread-form/" + id + "?form_type=" + form_type + "&upload_Id=" + upload_id
  }
  return axios.get(URL, {
    headers: headers,
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const deleteSpread = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  try {
    const response = await fetch(API_URL + "delete/" + id, {
      method: "DELETE",
      headers: headers,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const updateUser = async (updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  const response = await fetch(API_URL + "update/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
  const data = await response.json();

  return data;
};

const getTaxAnalysis = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  return axios.get(API_URL + "get-tax-analysis/" + id, {
    headers: headers,
  });
}
const MySpreadApi = {
  GetAllSpread,
  updateUser,
  deleteSpread,
  getSpread,
  getCurrentUser,
  getSpreadForm,
  getTaxAnalysis,
};

export default MySpreadApi;
