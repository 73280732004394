import { TablePagination } from '@material-ui/core'
import React, { useState } from 'react'

const Pagination = () => {

  return (
    <div>
      <TablePagination
        
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={15}
        rowsPerPage={5}
        page={1}
      />
    </div>
  )
}

export default Pagination
