import axios from "axios";
import DataProcess from "../common/Helper";
import Cookies from "js-cookie";
import { async } from "q";


const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/user/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};



const register = (fName = "", lName = "", username, password) => {
  let data = DataProcess.encryptData(
    JSON.stringify({
      firstName: fName,
      lastName: lName,
      username: username,
      password: password,
    })
  );

  return axios
    .post(API_URL + "signup", {
      reqJson: data,
    })
    .then((response) => {
      if (response.data.result) {
        localStorage.setItem("user", JSON.stringify(response.data.result));
      }

      return {
        status: true,
        result: response.data.result,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: false,
          error: error.response.data.resError,
        };
      }
    });
};

const login = (username, password, otp) => {
  let data = DataProcess.encryptData(
    JSON.stringify({
      username: username,
      password: password,
      otp: otp
    })
  );
  return axios
    .post(API_URL + "login", {
      reqJson: data,
    })
    .then((response) => {
      if (response.data.result) {

        let isadmin = JSON.parse(DataProcess.decryptData(response.data.result))

        Cookies.set("isadmin", isadmin.isAdmin);
        localStorage.setItem("user", JSON.stringify(response.data.result));
      }

      return {
        status: true,
        result: response.data.result,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: false,
          error: error.response.data.resError,
        };
      }
    });
};

const forgetPassword = (username) => {
  let data = DataProcess.encryptData(
    JSON.stringify({
      username: username,
    })
  );
  return axios
    .post(API_URL + "forgot-password", {
      reqJson: data,
    })
    .then((response) => {
      if (response.data.result) {
        localStorage.setItem("user", JSON.stringify(response.data.result));
      }

      return {
        status: true,
        result: response.data.result,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: false,
          error: error.response.data.resError,
        };
      }
    });
};

const changepassword = (username, oldPassword, newPassword, cpassword) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  let data = DataProcess.encryptData(
    JSON.stringify({
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
  );

  return axios
    .post(
      API_URL + "change-password",
      {
        reqJson: data,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      if (response.data.result) {
        localStorage.setItem("user", JSON.stringify(response.data.result));
      }

      return {
        status: true,
        result: response.data.result,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: false,
          error: error.response.data.resError,
        };
      }
    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};

const liveUser = (skip, limit, sortBy, sortOrder) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  return axios.get(API_URL + `get-all-users?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`, {
    headers: headers,
  });
};

const getUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  return axios.get(API_URL + "get-user/" + id, {
    headers: headers,
  });
};

const userProfile = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  return axios.get(API_URL + "profile", {
    headers: headers,
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const deleteUser = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  try {
    const response = await fetch(API_URL + "delete-user/" + id, {
      method: "DELETE",
      headers: headers,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const updateUser = async (updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  const response = await fetch(API_URL + "update-user/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
  const data = await response.json();
  return data;
};

const profile = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  let response = await axios.get(API_URL + "profile", {
    headers: headers
  });

  return JSON.parse(DataProcess.decryptData(response.data.result))

};

const AuthService = {
  register,
  login,
  forgetPassword,
  changepassword,
  logout,
  liveUser,
  getUser,
  userProfile,
  getCurrentUser,
  deleteUser,
  updateUser,
  validToken,
  profile
};

export default AuthService;
