import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import AuthService from "../auth.service";
import { AutoLogout } from "../../Pages/Logout/Logout";
import { toasterError } from "../../components/ToasterMessages/toastify.helper";

const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/user/";

const GetAllUser = (skip, limit) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  return axios.get(API_URL + `get-all-users?page=${skip}&limit=${limit}`, {
    headers: headers,
  });
};

const getUser = (id) => {
  try {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };
    let Data = DataProcess.encryptData(JSON.stringify({}));
    return axios.get(API_URL + "get-user/" + id, {
      headers: headers,
    });
  } catch (error) {
    if(error.response.status == "401"){
      AutoLogout()
    }else{
      let msgError = DataProcess.decryptData(
        error.response.data.resError
      );
      msgError = JSON.parse(msgError);
      toasterError(msgError.message);
    }
  }
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const deleteUser = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  try {
    const response = await fetch(API_URL + "delete-user/" + id, {
      method: "DELETE",
      headers: headers,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const updateUser = async (updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  const response = await fetch(API_URL + "update-user/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
  const data = await response.json();

  return data;
};
const UserApi = {
  GetAllUser,
  updateUser,
  deleteUser,
  getUser,
  getCurrentUser,
};

export default UserApi;
