import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/spread/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const GetAllSpread = (skip, limit, sortBy, sortOrder) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  return axios.get(API_URL + `get-group-spread?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`, {
    headers: headers,
  });
};

const getUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  let Data = DataProcess.encryptData(JSON.stringify({}));
  return axios.get(API_URL + "get/" + id, {
    headers: headers,
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const deleteSpread = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };
  try {
    const response = await fetch(API_URL + "delete/" + id, {
      method: "DELETE",
      headers: headers,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const updateUser = async (updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + validToken(),
  };

  const response = await fetch(API_URL + "update/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
  const data = await response.json();

  return data;
};
const GroupeadApi = {
  GetAllSpread,
  updateUser,
  deleteSpread,
  getUser,
  getCurrentUser,
};

export default GroupeadApi;
