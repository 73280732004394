import Cookies from "js-cookie";

export const Logout = () => {
    Cookies.remove("token");
};

export const AutoLogout = () => {
    Cookies.remove("token");
    setTimeout(() => {
        window.location.href = '/login';
    }, 0);
};
