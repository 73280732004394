import { encrypt, decrypt } from "crypto-js/aes";
import { enc } from "crypto-js";
import dotenv from "dotenv";
dotenv.config();


const Key = process.env.REACT_APP_SECRET_KEY;

function encryptData(plainText) {
  let encData = encrypt(plainText, Key).toString();
  return encData;
}

function decryptData(decryptText) {
  if (decryptText) {
    let dec = decrypt(decryptText, Key).toString(enc.Utf8);
    return dec;
  } else {

    setTimeout(() => {
      window.location.href = '/login';
    }, 0);
    throw new Error('NOT LOGIN'); // Throw an error to be caught by an error boundary

  }
}

const DataProcess = {
  encryptData,
  decryptData
}


export default DataProcess