import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import DataProcess from "../../common/Helper";
import { IoClose } from "react-icons/io5";
import Cookies from "js-cookie";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import { TablePagination } from "@material-ui/core";
import AuthService from "../../services/auth.service";
import { AutoLogout } from "../Logout/Logout";

const Year = () => {
  const [years, setYears] = useState([]);
  const [show, setShow] = useState(false);
  const [inputYear, setInputYear] = useState("");
  const [open, setOpen] = useState(false);
  const [dataid, setDataId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    allYear();
  }, [page, rowsPerPage]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleCancelClick = () => {
    handleClose();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleInputChange = (e) => {
    setInputYear(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const allYear = async () => {
    let year;
    try {

      const response = await YearApi.GetAllYear(
        page,
        rowsPerPage,
        sortBy,
        sortOrder
      );
      let result = JSON.parse(DataProcess.decryptData(response.data.result));
      setYears(result.data);
      setTotalItems(result.count);
    } catch (error) {
      if(error.response.status == "401"){
        AutoLogout()
      }else{
        let msgError = DataProcess.decryptData(
          error.response.data.resError
        );

        msgError = JSON.parse(msgError);
        msgError = msgError && msgError.error && msgError.error.message ? msgError.error.message : msgError && msgError.message ? msgError.message : "";
        console.log("msgError", msgError);
        toasterError(msgError);

        // toasterError(msgError.message);
      }
    }
  };

  const addNewYear = async (e) => {
    e.preventDefault();
    if (inputYear.trim() !== "") {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_ENV_API + "/year/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({ year: inputYear }),
        });

        if (response.ok) {
          const newYear = await response.json();
          setYears([...years, newYear]);
          setInputYear("");
          handleClose();
          toasterSuccess("Year added successfully");
        } else {
          response.json().then((error) => {
            let errorData = JSON.parse(DataProcess.decryptData(error.resError));
            let errorMessage = errorData.message;
            toasterError(errorMessage);
          });
        }
      } catch (error) {
        toasterError(error);
      }
      allYear();
    }
  };

  const handleConfirmDelete = async (id) => {
    try {
      const res = await YearApi.DeleteYear(id);
      if (res && res.data && res.data.result) {
        handleOff();
        setDataId(id);
        toasterSuccess("Year deleted successfully");
        allYear();
      } else {
        const error = await res.json();
        let errorData = JSON.parse(DataProcess.decryptData(error.resError));
        let errorMessage = errorData.message;
        toasterError(errorMessage)
        handleClose();
        allYear();
      }
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error("API Error:", error);
      toasterError("Configuration found for this year so you can't delete this year.");
      // You can display a generic error message here if needed.
    }
  };


  const handleDelete = (id) => {
    handleOpen();
    setDataId(id);
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    allYear();
  };
  let isAdmin = Cookies.get("isadmin");

  return (
    <>
      {(isAdmin === "true" || isAdmin === true) && (
      <div className="container mb-4">
        <div className="container mb-5 d-flex">
          <h3>Year Management</h3>
          <span className="btnclass">
            <Button
              style={{
                backgroundColor: "#c00000",
                color: "white",
                width: "100px",
                marginLeft: "180%",
              }}
              onClick={handleShow}
            >
              Add Year
            </Button>
          </span>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Add Year</Modal.Title>
            <IoClose
              size={24}
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleCancelClick}
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={addNewYear}>
              <Form.Group controlId="formFName">
                <Form.Label>Add New Year</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter New Year"
                  value={inputYear}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                style={{
                  marginTop: "15px",
                  backgroundColor: !inputYear ? "gray" : "#c00000",
                }}
                type="submit"
                disabled={!inputYear}
              >
                Create Year
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <div className="container mt-3">
          <Table
            striped
            bordered
            hover
            style={{ width: "50%", marginLeft: "25%", textAlign: "center" }}
          >
            <thead>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td onClick={() => handleSort("year")} >
                 <b>Year</b> 
                  {sortBy === "year" && (
                    <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </td>
                <td><b>Action</b> </td>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {years.map((i) => (
                <tr key={i.id}>
                  <td>{i.year}</td>
                  <td>
                    <Link to={`/year/${i.id}`}>
                      <FaEdit />
                    </Link>{" "}
                    /
                    <Link onClick={(id) => handleDelete(i.id)}  style={{ textDecoration: 'none' }}>
                      {" "}
                      <FaTrash />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
     )}
      <Modal show={open} onHide={handleOff}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
          <IoClose
            size={24}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={handleCancelDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="primary"
            style={{
              marginTop: "15px",
              marginRight: "5px",
              backgroundColor: "#c00000",
            }}
            type="submit"
            onClick={() => handleConfirmDelete(dataid)}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            style={{ marginTop: "15px" }}
            type="submit"
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Year;