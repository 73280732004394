import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import AuthService from "../../services/auth.service";
import DataProcess from "../../common/Helper";
import { Link, json, useNavigate } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactSwitch from "react-switch";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import { async } from "q";
import { TablePagination } from "@material-ui/core";
import Pagination from "../../components/Paginate/Pagination";
import { AutoLogout } from "../Logout/Logout";
import Cookies from "js-cookie";

const User = () => {
  const form = useRef();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [dataid, setDataId] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [switchValue, setSwitchValue] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (value) => {
    console.log("-===============CALLED");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || !emailRegex.test(value)) {
      console.log("IF");
      setIsValidEmail(false);
    } else {
      console.log("IFELSE");
      setIsValidEmail(true);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFName("");
    setLName("");
    setUsername("");
    setPassword("");
    setSuccessful(false);
    setMessage("");
    setPasswordError("")
    setShow(true);
  };
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  let errorMessage;
  const handleRegister = async (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    if (!validatePassword(password)) {
      setValidPassword(false);
      setPasswordError("Password must be between 6 and 40 characters");
      return;
    } else {
      setValidPassword(true);
      setPasswordError("");
    }
    try {
      let data = DataProcess.encryptData(
        JSON.stringify({
          firstName: fName,
          lastName: lName,
          username: username,
          password: password,
        })
      );

      let response = await fetch(process.env.REACT_APP_SERVER_ENV_API + "/user/add-user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + AuthService.validToken(),
        },
        body: JSON.stringify({ reqJson: data }),
      });

      if (!response.ok) {
        const error = await response.json();
        let errorData = JSON.parse(DataProcess.decryptData(error.resError));
        errorMessage = errorData.message;
        toasterError(errorMessage);

        handleClose();
        getAllUser();
      } else {
        handleClose();
        toasterSuccess("User registered successfully!");

        getAllUser();
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const handleDelete = (id) => {
    handleOpen();

    setDataId(id);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^.{6,40}$/;
    return passwordRegex.test(password);
  };


  const handleConfirmDelete = async (id) => {
    await AuthService.deleteUser(id).then(() => { });

    handleOff();
    setDataId(id);
    notify();

    getAllUser();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllUser = async () => {
    try {
      const response = await AuthService.liveUser(
        page,
        rowsPerPage,
        sortBy,
        sortOrder
      );
      let result = JSON.parse(DataProcess.decryptData(response.data.result));
      setData(result.data);
      setTotalItems(result.count);
    } catch (error) {
      if (error.response.status == "401") {
        AutoLogout()
      } else {
        let msgError = DataProcess.decryptData(
          error.response.data.resError
        );
        msgError = JSON.parse(msgError);
        msgError = msgError && msgError.error && msgError.error.message ? msgError.error.message : msgError && msgError.message ? msgError.message : "";
        console.log("msgError", msgError);
        toasterError(msgError);

        //   const _content =
        //   error.response &&
        //     error.response.data &&
        //     error.response.data.resError ? error.response.data.resError : "";
        // let message = JSON.parse(DataProcess.decryptData(_content)).error.message
        // console.log("_content", (JSON.parse(DataProcess.decryptData(error.response.data.resError))).error.message);
        // toasterError(message)

      }
    }
  };

  useEffect(() => {
    getAllUser();
  }, [page, rowsPerPage]);

  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">This field is required!</div>
      );
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const vusername = (value) => {
    if (!emailRegex.test(value)) {
      return (
        <div className="invalid-feedback d-block">
          The username must be Email formate.
        </div>
      );
    }
  };

  const onChangeFName = (e) => {
    const fName = e.target.value;
    setFName(fName);
  };
  const handleCancelClick = () => {
    handleClose();
  };

  const onChangeLName = (e) => {
    const lName = e.target.value;
    setLName(lName);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const newPassword = e.target.value;

    // Validate password
    if (!validatePassword(newPassword)) {
      setValidPassword(false);
      setPasswordError("Password must be between 6 and 40 characters");
    } else {
      setValidPassword(true);
      setPasswordError("");
    }

    // Update password state
    setPassword(newPassword);
  };

  const notify = () => {
    toasterSuccess("User deleted successfully!");
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = async (newValue, id) => {
    setSwitchValue(newValue);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };

    await fetch(
      process.env.REACT_APP_SERVER_ENV_API + "/user/update-user/" + id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(
            JSON.stringify({ isadmin: newValue })
          ),
        }),
      },
      toasterSuccess("Update user successfully!")
    );
    getAllUser();
  };

  const handleSwitchChangeApprove = async (newValue, id) => {
    setSwitchValue(newValue);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };

    await fetch(
      process.env.REACT_APP_SERVER_ENV_API + "/user/update-user/" + id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(
            JSON.stringify({ isapproved: newValue })
          ),
        }),
      },
      toasterSuccess("Update user successfully!")
    );
    getAllUser();
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    getAllUser();
  };
  let isAdmin = Cookies.get("isadmin");

  return (
    <>
      {(isAdmin === "true" || isAdmin === true) && (
        <div className="container mb-4">
          <div className="container mb-5 d-flex">
            <h3>User Management</h3>
            <span className="btnclass">
              <Button
                style={{
                  backgroundColor: "#c00000",
                  color: "white",
                  width: "100px",
                  marginLeft: "180%"
                }}
                onClick={handleShow}
              >
                Add User
              </Button>
            </span>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Add User</Modal.Title>
              <IoClose
                size={24}
                color="red"
                style={{ cursor: "pointer" }}
                onClick={handleCancelClick}
              />
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleRegister}>
                <Form.Group controlId="formFName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    name="fname"
                    value={fName}
                    onChange={onChangeFName}
                  />
                </Form.Group>
                <Form.Group controlId="formLName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    name="lname"
                    value={lName}
                    onChange={onChangeLName}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Username"
                    name="username"
                    value={username}
                    onChange={(e) => {
                      onChangeUsername(e);
                      validateEmail(e.target.value); // Call validateEmail when the username changes
                    }}
                    required
                  />
                  {!isValidEmail && (
                    <div className="invalid-feedback d-block">
                      Please enter a valid email address.
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    required
                  />
                  {!validPassword && (
                    <div className="invalid-feedback d-block">{passwordError}</div>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  style={{
                    marginTop: "15px",
                    backgroundColor:
                      !fName || !lName || !username || !password || !validPassword || !isValidEmail
                        ? "gray"
                        : "#c00000",
                  }}
                  type="submit"
                  disabled={!fName || !lName || !username || !password || !validPassword || !isValidEmail}
                >
                  Create User
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <div className="container mt-3">
            <div></div>
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                  <th onClick={() => handleSort('firstname')}>First Name
                    {sortBy === 'firstname' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                  </th>
                  <th onClick={() => handleSort('lastname')}>Last Name
                    {sortBy === 'lastname' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                  </th>
                  <th onClick={() => handleSort('username')}>User Name
                    {sortBy === 'username' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                  </th>
                  <th>Action</th>
                  <th>Admin</th>
                  <th>Approved</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: "center" }}>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.firstname}</td>
                    <td>{item.lastname}</td>
                    <td>{item.username}</td>
                    <td>
                      <Link to={`/user/${item.id}`}>
                        <FaEdit />
                      </Link>{" "}
                      /
                      <Link onClick={(id) => handleDelete(item.id)} style={{ textDecoration: 'none' }}>
                        {" "}
                        <FaTrash />
                      </Link>
                    </td>
                    <td>

                      <ReactSwitch
                        checked={item.isadmin}
                        onChange={(newValue) =>
                          handleSwitchChange(newValue, item.id)
                        }
                      />
                    </td>
                    <td>

                      <ReactSwitch
                        checked={item.isapproved}
                        onChange={(newValue) =>
                          handleSwitchChangeApprove(newValue, item.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>


            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />


            <Modal show={open} onHide={handleOff}>
              <Modal.Header>
                <Modal.Title>Are you sure you want to delete?</Modal.Title>
                <IoClose
                  size={24}
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={handleCancelDelete}
                />
              </Modal.Header>
              <Modal.Body>
                <Button
                  variant="primary"
                  style={{
                    marginTop: "15px",
                    marginRight: "5px",
                    backgroundColor: "#c00000",
                  }}
                  type="submit"
                  onClick={() => handleConfirmDelete(dataid)}
                >
                  Delete
                </Button>
                <Button
                  variant="primary"
                  style={{ marginTop: "15px" }}
                  type="submit"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </Button>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default User;
