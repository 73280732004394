import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import importImage from "../../../src/assets/image/import.png";
import exportImage from "../../../src/assets/image/export.png";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import DataProcess from "../../common/Helper";
import { IoClose } from "react-icons/io5";
import ReactSwitch from "react-switch";
import Cookies from "js-cookie";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import { Input, TablePagination } from "@material-ui/core";
import AuthService from "../../services/auth.service";
import SpreadApi from "../../services/API/SpreadApi";
import SpreadDataApi from "../../services/API/SpreadData";
import SpreadConfigurationApi from "../../services/API/spreadConfigurationApi";
import axios from "axios";
import { eventWrapper } from "@testing-library/user-event/dist/utils";
import "../SpreadConfigration/SpreadConfigration.css";
import { AutoLogout } from "../Logout/Logout";
import { useRef } from "react";
import Loader from "../../components/Loader/Loader";

const SpreadConfiguration = () => {
  const fileInputRef = useRef(null);


  const [spreads, setSpreads] = useState([]);
  const [years, setYears] = useState([]);
  const [spreadsData, setSpreadsData] = useState([]);
  const [configs, setConfig] = useState([]);
  const [config, setConfigData] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spreadconfigurations, setSpreadconfigurations] = useState([]);
  const [inputValue, setinputValue] = useState({
    year: "",
    fieldName: "",
    form_type: "",
    field_input: "",
    field_label: "",
    height: "",
    width: "",
    x: "",
    y: "",
    pageNo: "",
    ismultiple: false,
  });

  const [open, setOpen] = useState(false);
  const [dataid, setDataId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [year, setYear] = useState(null);
  const [spread, setSpread] = useState("");
  const [formType, setformType] = useState([]);
  const [fieldname, setfieldname] = useState("");
  const [forms, setForms] = useState(null);
  const [switchValue, setSwitchValue] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [on, setOn] = useState(false);
  const [numFormSections, setNumFormSections] = useState(0);
  const [formSections, setFormSections] = useState([]);
  const [formTypeFilter, setFormTypeFilter] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  let csvData = []
  useEffect(() => {
    searchSpreads();
    searchconfig();
    getAllSpreads(formSubmitted ? year : "", formSubmitted ? formTypeFilter : "");
    SearchYears();
    SearchFormType();
  }, [page, rowsPerPage]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleShowForm = () => setOn(true);
  const handleCloseForm = () => setOn(false);

  const handleCancelClick = () => {
    handleClose();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleAddFormSection = () => {
    // setNumFormSections(numFormSections + 1);
    setFormSections([
      ...formSections,
      { height: "", width: "", x: "", y: "", pageno: "" },
    ]);

    // Update the data object to reflect the changes
    const updatedData = { ...inputValue };
    updatedData.height = "";
    updatedData.width = "";
    updatedData.x = "";
    updatedData.y = "";
    updatedData.pageno = "";
    setinputValue(updatedData);
  };

  const handleImportClick = () => {
    // Add your logic for the up arrow button here
    console.log("Imports arrow button clicked");

    console.log("CLICK", fileInputRef.current.click());

    // const handleFileUpload = (event) => {
    //   const file = event.target.files[0];

    //   Papa.parse(file, {
    //     complete: (result) => {
    //       // The parsed CSV data is available in result.data
    //       setCsvData(result.data);
    //     },
    //     header: true, // If your CSV file has a header row
    //   });
    // };

  };

  const handleExportClick = async () => {
    // Add your logic for the down arrow button here
    console.log("Export arrow button clicked", year, forms);

    const response = await SpreadConfigurationApi.exportSpreadConfigData(year, forms);
    console.log("response", response.data.result);
    let result = DataProcess.decryptData(response.data.result);
    result = JSON.parse(result)
    console.log("result", result.data);
    setSpreadconfigurations(result);

    let data = [];
    let configData = result.data

    console.log("configData", configData);
    const headerRow = [
      "fieldName",
      "field_input",
      "field_label",
      "form_type",
      "x",
      "y",
      "height",
      "width",
      "ismultiple",
      "isnewconfigration",
      "year",
      "multiple_spread_configurations",
      "pageno"
    ];
    data.push(headerRow);

    for (const iterator of configData) {

      let dataConfig = [
        iterator.fieldname,
        iterator.field_input,
        iterator.field_label,
        iterator.form_type,
        iterator.x,
        iterator.y,
        iterator.height,
        iterator.width,
        iterator.ismultiple,
        iterator.isnewconfigration,
        iterator.years,
        iterator && iterator.multiple_spread_configurations ? JSON.stringify(iterator.multiple_spread_configurations) : [],
        iterator.pageNo ? iterator.pageNo : iterator.pageno ,
      ];
      data.push(dataConfig);
    }

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `configration_export.csv`;
    link.click();

  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setLoading(true);
    Papa.parse(file, {
      complete: async (result) => {
        // Handle the parsed data
        console.log('Parsed CSV data:', result.data);
        csvData = result.data

        let encryptData = DataProcess.encryptData(JSON.stringify(result.data))

        const response = await fetch(
          process.env.REACT_APP_SERVER_ENV_API + "/spread_configuration/import",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + AuthService.validToken(),
            },
            body: JSON.stringify({
              reqJson: encryptData,
            }),
          }
        );

        window.location.reload()
        // toasterSuccess("Configration Imported Successfully.")
        // navigate("/spreadconfiguration")
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // console.log("response", response);

      },
      header: true,
      dynamicTyping: true,
    });


  };


  const handleSectionInputChange = (e, sectionIndex, fieldName) => {
    const updatedFormSections = [...formSections];
    updatedFormSections[sectionIndex][fieldName] = e.target.value;
    setFormSections(updatedFormSections);
  };

  const handleCloseFormSection = (sectionIndex) => {
    const updatedFormSections = [...formSections];
    updatedFormSections.splice(sectionIndex, 1);
    setFormSections(updatedFormSections);

    // Update the data object to reflect the changes
    const updatedData = { ...inputValue };
    updatedData.height = updatedFormSections
      .map((section) => section.height)
      .join(",");
    updatedData.width = updatedFormSections
      .map((section) => section.width)
      .join(",");
    updatedData.x = updatedFormSections.map((section) => section.x).join(",");
    updatedData.y = updatedFormSections.map((section) => section.y).join(",");
    updatedData.pageno = updatedFormSections
      .map((section) => section.pageno)
      .join(",");
    setinputValue(updatedData);
  };

  // const handleSwitchChange = (newValue) => {
  //   setIsSwitchOn(newValue);
  // };

  const handleSwitchChange = async (newValue, id) => {
    console.log("newValue", newValue);
    setSwitchValue(newValue);
    setIsSwitchOn(newValue);
    setinputValue((prevInputValue) => ({
      ...prevInputValue,
      ismultiple: newValue,
    }));

    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + AuthService.validToken(),
    // };

    // await fetch(
    //   "http://localhost:3001/api/user/update-user/" + id,
    //   {
    //     method: "PUT",
    //     headers: headers,
    //     body: JSON.stringify({
    //       reqJson: DataProcess.encryptData(
    //         JSON.stringify({ isadmin: newValue })
    //       ),
    //     }),
    //   },
    //   toasterSuccess("Update user successfully!")
    // );
    getAllSpreads(year, forms);
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setinputValue((prevInputValues) => ({
      ...prevInputValues,
      [fieldName]: value,
    }));

    console.log("fieldName", fieldName);
    console.log("value", value);
  };

  const handleDropdownChange = (e) => {
    const selectedOption = e.target.value;
    setinputValue((prevInputValues) => ({
      ...prevInputValues,
      selectedOption: selectedOption,
    }));
  };

  const data = [
    {
      form_type: "1040",
    },
    {
      form_type: "1040_sch_1",
    },
    {
      form_type: "1041",
    },
    {
      form_type: "1040_sch_A",
    },
    {
      form_type: "1040_sch_B",
    },
    {
      form_type: "1040_sch_C",
    },
    {
      form_type: "1040_sch_D",
    },
    {
      form_type: "1040_sch_E",
    },
    {
      form_type: "1040_sch_F",
    },
    {
      form_type: "1120",
    },
    {
      form_type: "1120S",
    },
    {
      form_type: "1120S_K1",
    },
    {
      form_type: "1065C",
    },
    {
      form_type: "1065_K1",
    },
    {
      form_type: "2106",
    },
    {
      form_type: "8606",
    },
    {
      form_type: "8865_K1",
    },
    {
      form_type: "4835",
    },
    {
      form_type: "6252",
    },
    {
      form_type: "8825",
    },
    {
      form_type: "4797",
    },
    {
      form_type: "1084_cash_flow",
    },
    {
      form_type: "incomeStatement",
    },
    {
      form_type: "Balancesheet",
    },
  ];

  // const handleFormTypeChange = (e) => {
  //   setformType(e.target.value);
  // };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (!formSubmitted) {
      setFormTypeFilter("");
      setYear(null);
    }
    setFormTypeFilter(formTypeFilter);
    setYear(year);
  };

  const getAllSpreads = async (year, forms) => {
    console.log("FOME8288", year, forms);

    try {
      const response = await SpreadConfigurationApi.GetAllSpreadConfig(
        page,
        rowsPerPage,
        sortBy,
        sortOrder,
        forms,
        year
      );
      let result = JSON.parse(DataProcess.decryptData(response.data.result));
      setSpreadsData(result.data);
      setTotalItems(result.count);

    } catch (error) {
      let msgError = DataProcess.decryptData(
        error.response.data.resError
      );
      msgError = JSON.parse(msgError);
      msgError = msgError && msgError.error && msgError.error.message ? msgError.error.message : msgError && msgError.message ? msgError.message : "";
      console.log("msgError", msgError);
      toasterError(msgError);

    }
  };

  const searchSpreads = async () => {
    const response = await SpreadApi.searchSpread(
      page,
      rowsPerPage,
      sortBy,
      sortOrder
    );
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setSpreads(result.data);
  };

  const searchconfig = async () => {
    const response = await SpreadConfigurationApi.SearchSpreadConfig();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setConfig(result.data);
  };

  const SearchYears = async () => {
    const response = await YearApi.SearchYear();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setYears(result.data);
  };

  const SearchFormType = async (year) => {
    const response = await SpreadConfigurationApi.SpreadConfigFormType(year);
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setformType(result.data);
  };

  const addNewYear = async (e) => {
    e.preventDefault();

    console.log("inputValue", inputValue);

    let reqJson = {
      year: inputValue.year, // Add year
      form_type: inputValue.selectedOption, // Add form_type
      fieldName: inputValue.fieldName,
      field_input: inputValue.field_input,
      field_label: inputValue.field_label,
      height: inputValue.height,
      width: inputValue.width,
      x: inputValue.x,
      y: inputValue.y,
      pageNo: inputValue.pageNo,
      ismultiple: inputValue.ismultiple,
      multipleConfig: formSections,
    };

    console.log("reqJson", reqJson);
    reqJson = DataProcess.encryptData(JSON.stringify(reqJson));

    // if (inputValue.trim() !== "") {
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_ENV_API + "/spread_configuration/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({ reqJson: reqJson }),
        }
      );

      if (response.ok) {
        const newYear = await response.json();
        setSpreads([...spreads, newYear]);
        // setinputValue("");

        handleClose();
        toasterSuccess("Spread Configration added successfully");
      } else {
        response.json().then((error) => {
          let errorData = JSON.parse(DataProcess.decryptData(error.resError));
          let errorMessage;
          if (errorData && errorData.error && errorData.error.message) {
            errorMessage = errorData.error.message;
          } else {
            errorMessage = errorData.message;
          }
          toasterError(errorMessage);
        });
      }
    } catch (error) {
      toasterError(error);
    }
    console.log("384");
    getAllSpreads(year, forms);
  };

  const handleConfirmDelete = async (id) => {
    await SpreadConfigurationApi.DeleteSpreadConfig(id).then((res) => {
      if (res && res.data && res.data.result) {
        handleOff();
        setDataId(id);
        toasterSuccess("Spread Configuration deleted successfully");
        console.log("394");
        getAllSpreads(year, forms);
      } else {
        res.json().then((error) => {
          let errorData = JSON.parse(DataProcess.decryptData(error.resError));
          let errorMessage = errorData.message;
          toasterError(errorMessage);

          handleClose();
          console.log("403");
          getAllSpreads(year, forms);
        });
      }
    }, (error) => {
      if (error.response.status == "401") {
        AutoLogout()
      } else {
        let msgError = DataProcess.decryptData(
          error.response.data.resError
        );
        msgError = JSON.parse(msgError);
        toasterError(msgError.message);
      }
    });
  };

  const handleDelete = (id) => {
    handleOpen();
    setDataId(id);
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    console.log("433");
    getAllSpreads(year, forms);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    inputValue.year = selectedOption;
  };

  // const handleSwitchChange = async (newValue, id) => {
  //   setSwitchValue(newValue);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + AuthService.validToken(),
  //   };

  //   await fetch(
  //     "http://localhost:3001/api/user/update-user/" + id,
  //     {
  //       method: "PUT",
  //       headers: headers,
  //       body: JSON.stringify({
  //         reqJson: DataProcess.encryptData(
  //           JSON.stringify({ isadmin: newValue })
  //         ),
  //       }),
  //     },
  //     toasterSuccess("Update user successfully!")
  //   );
  //   // getAllUser();
  //   getAllSpreads();
  // };

  const handleConfigOptionChange = (event) => {
    const selectedOption = event.target.value;
    setConfigData(selectedOption);
  };

  const handleInputYearChange = (e) => {
    // setinputValue({ ...inputValue, [e.target.name]: e.target.value });
    setYear(e.target.value);
    SearchFormType(e.target.value);
    setFormSubmitted(false);

  };

  const handleInputFormTypeChange = (e) => {
    // setinputValue({ ...inputValue, [e.target.value] : e.target.value})
    setForms(e.target.value);
    setFormTypeFilter(e.target.value)
    setFormSubmitted(false);
  };

  const handleSearch = () => {
    setFormSubmitted(true);
    getAllSpreads(year, forms);
  };
  let isAdmin = Cookies.get("isadmin");

  return (
    <>
      {(isAdmin === "true" || isAdmin === true) && (

        <div className="container mb-4 spread-config-wrapper">
          <div className="container mb-9 d-flex">
            <h5>Spread Configuration Management</h5>
            {loading && <Loader />}
            <div className="spread-config-btn">
              <span>
                <Dropdown>
                  <select
                    className="form-control"
                    defaultValue={"select"}
                    onChange={handleInputYearChange}
                    value={year}
                  >
                    <option disabled>select</option>
                    {years.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.year}
                      </option>
                    ))}
                  </select>
                </Dropdown>
              </span>
              <span className="spread-config-form">
                <Dropdown>
                  <select
                    className="form-control"
                    defaultValue={"select"}
                    onChange={handleInputFormTypeChange}
                    value={formTypeFilter}
                  >
                    <option>select</option>
                    {formType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.form_type}
                      </option>
                    ))}
                  </select>
                </Dropdown>
              </span>
              <span>
                <Button onClick={handleSearch}>
                  Submit
                </Button>
              </span>
            </div>

            <span className="btnclass">
              <Button
                style={{
                  backgroundColor: "#c00000",
                  color: "white",
                  width: "250px",
                }}
                onClick={handleShow}
              >
                Add Spread Configuration
              </Button>
              <Link to="/spreadconfiguration/importfile">
                <Button>Import File</Button>
              </Link>
            </span>
            <div className="arrow-button" onClick={handleImportClick} title="Import Configuration ">
              <img src={importImage} alt="" />
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </div>
            <div className="arrow-button" onClick={handleExportClick} title="Export Configuration">
              <img src={exportImage} alt="" />
            </div>

          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Add Spread Configuration</Modal.Title>
              <IoClose
                size={24}
                color="red"
                style={{ cursor: "pointer" }}
                onClick={handleCancelClick}
              />
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={addNewYear}>
                <Form.Group controlId="formFName">
                  <Form.Label>Year</Form.Label>

                  <Form.Control as="select" onChange={handleOptionChange}>
                    <option disabled selected>
                      select
                    </option>
                    {years.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.year}
                      </option>
                    ))}
                  </Form.Control>

                  {/* <Form.Label>Form Type</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter Form Type"
                  value={inputValue.form_type}
                  onChange={(e) => handleInputChange(e, "form_type")}
                /> */}

                  <Form.Label>Select Form Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={inputValue.selectedOption}
                    onChange={handleDropdownChange}
                  >
                    <option value="">Select a form type</option>
                    {data.map((item) => (
                      <option key={item.form_type} value={item.form_type}>
                        {item.form_type}
                      </option>
                    ))}
                  </Form.Control>


                  <Form.Label>Field Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Field Name"
                    value={inputValue.fieldName}
                    onChange={(e) => handleInputChange(e, "fieldName")}
                  />

                  <Form.Label>Field Input</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Field Input"
                    value={inputValue.field_input}
                    onChange={(e) => handleInputChange(e, "field_input")}
                  />

                  <Form.Label>Field Label</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Field Label"
                    value={inputValue.field_label}
                    onChange={(e) => handleInputChange(e, "field_label")}
                  />

                  {!isSwitchOn && (
                    <div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="username">Height</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="height"
                              value={inputValue.height}
                              onChange={(e) => handleInputChange(e, "height")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="username">Width</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="width"
                              value={inputValue.width}
                              onChange={(e) => handleInputChange(e, "width")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="username">X</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="x"
                              value={inputValue.x}
                              onChange={(e) => handleInputChange(e, "x")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="username">Y</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="y"
                              value={inputValue.y}
                              onChange={(e) => handleInputChange(e, "y")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="username">PageNo</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="pageno"
                          value={inputValue.pageNo}
                          onChange={(e) => handleInputChange(e, "pageNo")}
                        />
                      </div>
                    </div>
                  )}

                  {isSwitchOn && <p>Add Multiple Values</p>}

                  {formSections.map((section, index) => (
                    <div key={index}>
                      <IoClose
                        size={24}
                        color="red"
                        style={{ cursor: "pointer", marginLeft: "95%" }}
                        onClick={() => handleCloseFormSection(index)} // Pass the index to the function
                      />

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor={`height-${index}`}>Height</label>
                            <Input
                              type="text"
                              className="form-control"
                              name={`height-${index}`}
                              value={section.height}
                              onChange={(e) =>
                                handleSectionInputChange(e, index, "height")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor={`width-${index}`}>Width</label>
                            <Input
                              type="text"
                              className="form-control"
                              name={`width-${index}`}
                              value={section.width}
                              onChange={(e) =>
                                handleSectionInputChange(e, index, "width")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor={`x-${index}`}>X</label>
                            <Input
                              type="text"
                              className="form-control"
                              name={`x-${index}`}
                              value={section.x}
                              onChange={(e) =>
                                handleSectionInputChange(e, index, "x")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor={`y-${index}`}>Y</label>
                            <Input
                              type="text"
                              className="form-control"
                              name={`y-${index}`}
                              value={section.y}
                              onChange={(e) =>
                                handleSectionInputChange(e, index, "y")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor={`pagenos-${index}`}>PageNo</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`pageno-${index}`}
                          value={section.pageno}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "pageno")
                          }
                        />
                      </div>
                    </div>
                  ))}
                </Form.Group>

                <Form.Group>
                  <Form.Label>IsMultiple</Form.Label>
                  <div className="switch-container">
                    <ReactSwitch
                      checked={isSwitchOn}
                      onChange={(newValue) =>
                        handleSwitchChange(newValue, inputValue.ismultiple)
                      }
                    />
                    {isSwitchOn && (
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#c00000",
                          color: "white",
                          width: "250px",
                          marginLeft: "10px",
                        }}
                        onClick={handleAddFormSection}
                      >
                        Add Multiple
                      </button>
                    )}
                  </div>
                </Form.Group>

                <Button
                  variant="primary"
                  style={{ marginTop: "15px", backgroundColor: "#c00000" }}
                  type="submit"
                >
                  Create Spread Configuration
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <div className="container mt-3">
            <Table
              striped
              bordered
              hover
              style={{ width: "100%", alignItems: "center" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                  <td onClick={() => handleSort("year")}>
                    year
                    {sortBy === "year" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("fieldname")}>
                    fieldName
                    {sortBy === "fieldname" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("form_type")}>
                    Form type
                    {sortBy === "form_type" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("field_input")}>
                    Field input
                    {sortBy === "field_input" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("field_label")}>
                    Field label
                    {sortBy === "field_label" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("height")}>
                    Height
                    {sortBy === "height" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("width")}>
                    Width
                    {sortBy === "width" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("x")}>
                    X
                    {sortBy === "x" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("y")}>
                    Y
                    {sortBy === "y" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td onClick={() => handleSort("pageno")}>
                    PageNo
                    {sortBy === "pageno" && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </td>
                  <td>Action </td>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {spreadsData.map((i) => (
                  <tr key={i.id}>
                    <td>{i.year}</td>
                    <td>{i.fieldname}</td>
                    <td>{i.form_type}</td>
                    <td>{i.field_input}</td>
                    <td>{i.field_label}</td>
                    <td>{i.height}</td>
                    <td>{i.width}</td>
                    <td>{i.x}</td>
                    <td>{i.y}</td>
                    <td>{i.pageno}</td>
                    <td>
                      <Link to={`/spreadconfiguration/${i.id}`}>
                        <FaEdit />
                      </Link>{" "}
                      /
                      <Link onClick={(id) => handleDelete(i.id)} style={{ textDecoration: 'none' }}>
                        {" "}
                        <FaTrash />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      )}

      <Modal show={open} onHide={handleOff}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
          <IoClose
            size={24}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={handleCancelDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="primary"
            style={{
              marginTop: "15px",
              marginRight: "5px",
              backgroundColor: "#c00000",
            }}
            type="submit"
            onClick={() => handleConfirmDelete(dataid)}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            style={{ marginTop: "15px" }}
            type="submit"
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SpreadConfiguration;
