import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";
import Register from "../SignUp/Register";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthService from "../../services/auth.service";
import Cookies from "js-cookie";
import DataProcess from "../../common/Helper";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">This field is required!</div>
    );
  }
};

const Login = ({ onSetSignup }) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoginFlag, setIsLoginFlag] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeOTP = (e) => {
    const otp = e.target.value;
    setOTP(otp);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || !emailRegex.test(value)) {
      setIsValidEmail(false);
      return (
        <div className="invalid-feedback d-block">
          Please enter a valid email address.
        </div>
      );
    } else {
      setUsername(value.toLowerCase());
      setIsValidEmail(true);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    setIsLoginFlag(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password, otp)
        .then(
          (res) => {
            if (res && res.status) {
              if (!isLoginFlag) {
                notifySuccess(
                  JSON.parse(DataProcess.decryptData(res.result)).message
                );
                navigate("/login");
              } else {
                if (!otp) {
                  navigate("/login");
                } else {
                  Cookies.set("token", res.result);
                  setLoading(true);
                  notifySuccess();
                  navigate("/");
                }
              }
            } else {
              setOTP("");
              setIsLoginFlag(false);
              setLoading(false);
              notifyError(res.error);
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.resError) ||
              error.message ||
              error.toString();
            let msgError = DataProcess.decryptData(
              error.response.data.resError
            );
            msgError = JSON.parse(msgError);
            setLoading(msgError.status);
            setMessage(msgError.message);
          }
        )
        .catch((error) => {});
    } else {
      setLoading(false);
    }
  };

  const notifySuccess = (message) => {
    if (message) toasterSuccess(message);
    else toasterSuccess("Login Successfully Completed!");
  };

  const notifyError = (error) => {
    toasterError(JSON.parse(DataProcess.decryptData(error)).message);
  };

  return (
    <>
      <div
        className="col-md-12"
        style={{
          backgroundColor: "#343a40",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="card card-container"
          style={{ border: "1px solid black", borderRadius: "15px" }}
        >
          <img
            src="Robospreads-logo.png"
            alt="profile-img"
            style={{ marginBottom: "30px" }}
          />

          <Form onSubmit={handleLogin} ref={form}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Input
                type="email"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required, validateEmail]}
                disabled={isLoginFlag}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                disabled={isLoginFlag}
                required
              />
            </div>

            {isLoginFlag && (
              <div className="form-group">
                <label htmlFor="password">OTP</label>
                <input
                  type="text"
                  className="form-control"
                  name="otp"
                  value={otp}
                  onChange={onChangeOTP}
                  disabled={!isLoginFlag}
                />
              </div>
            )}

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={!username || !password || !isValidEmail}
                style={{
                  backgroundColor:
                    !username || !password || !isValidEmail ? "gray" : "blue",
                  cursor: "pointer", // Always set to "pointer" since it's a button
                }}
              >
                {loading && <span className="spinner-border-sm"></span>}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />

            <div>
              <p>
                <Link to="/forgetpassword">Forgot Password</Link>
              </p>
            </div>

            <div>
              <p>
                Don't have an account? <Link to="/register">Sign up</Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
