import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Input from "react-validation/build/input";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataProcess from "../../common/Helper";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeOldpassword = (e) => {
    const oldPassword = e.target.value;
    setOldPassword(oldPassword);
  };

  const onChangeNewpassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const onChangeCpassword = (e) => {
    const cpassword = e.target.value;
    setCPassword(cpassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e) {
      if (newpassword === cpassword) {
        AuthService.changepassword(
          username,
          oldpassword,
          newpassword,
          cpassword
        ).then((res) => {
          if (res && res.status) {
            toasterSuccess("Password change successfully!");
            navigate("/newspread");
          } else {
            let errorMessage = DataProcess.decryptData(res.error);
            errorMessage = JSON.parse(errorMessage);
            toasterError(errorMessage.message);
          }
        });
      } else {
        toasterError("New password and compare password are not matched");
      }
    }
  };

  return (
    <>
      <div className="com-md-12">
        <div style={{ textAlign: "center" }}>
          <h1>Change Password</h1>
        </div>
        <div className="card card-container">
          <Form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="email"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Old Password">Old Password</label>
              <input
                type="password"
                className="form-control"
                name="oldpassword"
                value={oldpassword}
                onChange={onChangeOldpassword}
              />
            </div>
            <div className="form-group">
              <label htmlFor="New Password">New Password</label>
              <input
                type="password"
                className="form-control"
                name="newpassword"
                value={newpassword}
                onChange={onChangeNewpassword}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Confirm Password">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                name="cpassword"
                value={cpassword}
                onChange={onChangeCpassword}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={
                  !username || !oldpassword || !newpassword || !cpassword
                }
                style={{
                  backgroundColor:
                    !username || !oldpassword || !newpassword || !cpassword
                      ? "gray"
                      : "blue",
                }}
              >
                {loading && <span className="spinner-border-sm"></span>}
                <span>Change Password</span>
              </button>
            </div>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
