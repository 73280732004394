import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { useNavigate, useParams } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import DataProcess from "../../common/Helper";
import { toasterSuccess } from "../../components/ToasterMessages/toastify.helper";
import AuthService from "../../services/auth.service";
import axios from "axios";
import { Button } from "react-bootstrap";

const EditYear = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    year: "",
  });

  useEffect(() => {
    let year;
    YearApi.GetYear(id).then((response) => {
      year = response.data.result;

      let userData = DataProcess.decryptData(year);
      userData = JSON.parse(userData);
      setData(userData.data);
    });
  }, []);

  const handleUpdate = async (userData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };
    await fetch(
      process.env.REACT_APP_SERVER_ENV_API + "/year/update/" + userData.id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(JSON.stringify(userData)),
        }),
      },
      toasterSuccess("Update year successfully!"),
      navigate("/year")
    );
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="col-md-12">
        <h3>Edit Year</h3>

        <Form onSubmit={() => handleUpdate(data)}>
          <div>
            <div className="form-group">
              <label htmlFor="username">Year</label>
              <Input
                type="number"
                className="form-control"
                name="year"
                value={data.year}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                style={{ width: "20%" }}
                disabled = {!data.year}
              >
                Update Year
              </button>
            </div>
          </div>

          <CheckButton style={{ display: "none" }} />
        </Form>
      </div>
    </>
  );
};

export default EditYear;
