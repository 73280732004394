import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.css";
import { useState } from "react";
import Cookies from "js-cookie";

const Sidebar = () => {
  const [admin, setAdmin] = useState(false);
  const location = useLocation();

  const isActiveLink = (path) => {
    return location.pathname.startsWith(path);
  };

  const login = () => {
    let isAdmin = Cookies.get("isadmin");
    if (isAdmin === "true") {
      return (
        <>
          <div className="sidebar-menu">
            <ul>
              <li>
                <NavLink to="/myspread" activeClassName="active">My Spreads</NavLink>
              </li>
              <li>
                <NavLink to="/groupspread" activeClassName="active">Group Spreads</NavLink>
              </li>
              <li>
                <NavLink to="/newspread" activeClassName="active">Start New Spread</NavLink>
              </li>
              <li>
                <NavLink to="/spreadconfiguration" activeClassName="active">Spread Configuration</NavLink>
              </li>
              <li>
                <NavLink to="/user" activeClassName="active">Users</NavLink>
              </li>
              <li>
                <NavLink to="/year" activeClassName="active">Year</NavLink>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="sidebar-menu">
            <ul>
              <li>
                <NavLink exact to="/myspread" activeClassName="active">My Spreads</NavLink>
              </li>
              <li>
                <NavLink to="/groupspread" activeClassName="active">Group Spreads</NavLink>
              </li>
              <li>
                <NavLink to="/newspread" activeClassName="active">Start New Spread</NavLink>
              </li>
            </ul>
          </div>
        </>
      );
    }
  };
  return login();
};

export default Sidebar;
