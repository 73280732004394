import React, { useEffect, useRef, useState } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { useNavigate, useParams } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import ReactSwitch from "react-switch";
import DataProcess from "../../common/Helper";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import AuthService from "../../services/auth.service";
import axios from "axios";
import SpreadDataApi from "../../services/API/SpreadData";
import SpreadApi from "../../services/API/SpreadApi";
import SpreadConfigurationApi from "../../services/API/spreadConfigurationApi";
import { Button, Modal } from "react-bootstrap";
import "../SpreadConfigration/ImportFile.css";
import { IoClose } from "react-icons/io5";
import Iframe from "react-iframe";
import { AutoLogout } from "../Logout/Logout";
import Loader from "../../components/Loader/Loader";
import Createtableselact from "react-select/creatable";

const EditSpreadConfiguration = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const overlayRef = useRef(null);
  const sidebarWidth = 221;
  const topbarHeight = 200;
  const [data, setData] = useState({
    value: "",
  });
  const [spreads, setSpreads] = useState([]);
  const [configs, setConfig] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [years, setYears] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [show, setShow] = useState(false);
  const [formSections, setFormSections] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [imageShow, setImageShow] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [prevStartX, setPrevStartX] = useState(0);
  const [prevStartY, setPrevStartY] = useState(0);
  const [prevWidth, setPrevWidth] = useState(0);
  const [prevHeight, setPrevHeight] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectType, setselectType] = useState("");
  const [formInput, setFormInput] = useState([]);
  const [createtableselactOptions, setCreatetableselactOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(data.pageno || 1);
  const [isCashOn, setIsCashOn] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancelClick = () => {
    handleClose();
  };

  const formDatas = [
    {
      form_type: "1040",
    },
    {
      form_type: "1040_sch_1",
    },
    {
      form_type: "1041",
    },
    {
      form_type: "1040_sch_A",
    },
    {
      form_type: "1040_sch_B",
    },
    {
      form_type: "1040_sch_C",
    },
    {
      form_type: "1040_sch_D",
    },
    {
      form_type: "1040_sch_E",
    },
    {
      form_type: "1040_sch_F",
    },
    {
      form_type: "1120",
    },
    {
      form_type: "1120S",
    },
    {
      form_type: "1120S_K1",
    },
    {
      form_type: "1065C",
    },
    {
      form_type: "1065_K1",
    },
    {
      form_type: "2106",
    },
    {
      form_type: "8606",
    },
    {
      form_type: "8865_K1",
    },
    {
      form_type: "4835",
    },
    {
      form_type: "6252",
    },
    {
      form_type: "8825",
    },
    {
      form_type: "4797",
    },
    {
      form_type: "1084_cash_flow",
    },
    {
      form_type: "incomeStatement",
    },
    {
      form_type: "Balancesheet",
    },
  ];

  const filterFormType = ["1065C", "1120", "1120S"];

  const handleAddFormSection = () => {
    setFormSections([
      ...formSections,
      { height: "", width: "", x: "", y: "", pageno: "" },
    ]);

    const updatedData = { ...data };
    updatedData.height = "";
    updatedData.width = "";
    updatedData.x = "";
    updatedData.y = "";
    updatedData.pageno = "";
    setData(updatedData);
  };

  const handleSectionInputChange = (e, sectionIndex, fieldName) => {
    const updatedFormSections = [...formSections];
    updatedFormSections[sectionIndex][fieldName] = e.target.value;
    setFormSections(updatedFormSections);
  };

  const searchSpreads = async () => {
    const response = await SpreadApi.searchSpread();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setSpreads(result.data);
    setTotalItems(result.count);
  };

  const searchconfig = async () => {
    const response = await SpreadConfigurationApi.SearchSpreadConfig();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setConfig(result.data);
    setTotalItems(result.count);
  };

  useEffect(() => {
    searchSpreads();
    searchconfig();
    SearchYears();
    let spreadData;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    SpreadConfigurationApi.GetSpreadConfig(id).then(
      (response) => {
        spreadData = response.data.result;

        let userData = DataProcess.decryptData(spreadData);
        userData = JSON.parse(userData);

        setData(userData.data);
        if (
          userData &&
          userData.data &&
          userData.data.multiple_spread_configuration &&
          userData.data.multiple_spread_configuration.length > 0 &&
          userData.data.multiple_spread_configuration[0] != null
        ) {
          setFormSections(userData.data.multiple_spread_configuration);
        }

        setIsSwitchOn(userData.data.ismultiple);
      },
      (error) => {
        if (error.response.status == "401") {
          AutoLogout();
        } else {
          let msgError = DataProcess.decryptData(error.response.data.resError);
          msgError = JSON.parse(msgError);
          toasterError(msgError.message);
        }
      }
    );

    if (
      imageShow &&
      typeof imageShow.images === "object" &&
      imageShow.images.length > 0
    ) {
      const currentImage = new Image();
      currentImage.src = imageShow.images[currentImageIndex];
      currentImage.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(currentImage, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [imageShow, currentImageIndex]);

  const SearchYears = async () => {
    const response = await YearApi.SearchYear();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setYears(result.data);
  };

  const handleUpdate = async (userData) => {
    delete userData.configname;
    delete userData.name;
    userData = { ...userData, multipleConfig: formSections };
    console.log(userData, "userData");

    if (!isSwitchOn) {
      console.log("LAD", prevStartX, prevStartY, prevWidth, prevHeight);

      userData.height = parseInt(prevHeight)
        ? parseInt(prevHeight)
        : data.height;
      userData.width = parseInt(prevWidth) ? parseInt(prevWidth) : data.width;
      userData.x = parseInt(prevStartX) ? parseInt(prevStartX) : data.x;
      userData.y = parseInt(prevStartY) ? parseInt(prevStartY) : data.y;
      userData.pageno = currentPage ? currentPage : data.pageno;

      userData.multipleConfig = [];
    }

    delete userData.selectedOption;
    delete userData.isnewconfigration;
    delete userData.fieldName;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    };
    await fetch(
      process.env.REACT_APP_SERVER_ENV_API +
        "/spread_configuration/update/" +
        userData.id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(JSON.stringify(userData)),
        }),
      },
      toasterSuccess("Update SpreadData successfully!"),
      navigate("/spreadconfiguration")
    );
  };

  const handleInputChange = (value, fieldName) => {
    const matchingObject = createtableselactOptions.find(
      (item) => item.value === value.value
    );

    let updatedInputValues = {
      isNewConfigration: true,
    };

    switch (fieldName) {
      case "fieldName":
        updatedInputValues = {
          ...updatedInputValues,
          fieldName: value,
          isNewConfigration: true,
        };
        break;
      case "field_input":
        if (matchingObject) {
          updatedInputValues = {
            ...updatedInputValues,
            fieldName: matchingObject.fieldname,
            field_label: matchingObject.field_label,
            field_input: matchingObject.value,
            isNewConfigration: false,
          };
        } else {
          updatedInputValues = {
            ...updatedInputValues,
            field_input: value.label,
            isNewConfigration: true,
          };
        }
        break;
      case "field_label":
        updatedInputValues = {
          ...updatedInputValues,
          field_label: value,
          isNewConfigration: true,
        };
        break;
      default:
        break;
    }

    updatedInputValues.fieldname = value.fieldname;

    setData((prevInputValues) => ({
      ...prevInputValues,
      ...updatedInputValues,
    }));

    setCreatetableselactOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.value === value.value
          ? { ...option, value: value.value }
          : option
      )
    );
  };

  useEffect(() => {
    if (data.form_type) {
      handleDropdownChange(data.form_type);
    }
  }, [data.form_type]);

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setCurrentPage(currentPage - 1);
      if (!isSwitchOn) {
        setCurrentPage(currentPage - 1);
      }
      if (formSections.length > 0) {
        const updatedFormSections = [...formSections];
        const lastIndex = updatedFormSections.length - 1;

        if (updatedFormSections[lastIndex].hasOwnProperty("pageno")) {
          updatedFormSections[lastIndex].pageno = currentPage - 1;
          setFormSections(updatedFormSections);
        }
      }
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < imageShow.images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setCurrentPage(currentPage + 1);
      if (!isSwitchOn) {
        setCurrentPage(currentPage + 1);
      }
      if (formSections.length > 0) {
        const updatedFormSections = [...formSections];
        const lastIndex = updatedFormSections.length - 1;

        if (updatedFormSections[lastIndex].hasOwnProperty("pageno")) {
          updatedFormSections[lastIndex].pageno = currentPage + 1;
          setFormSections(updatedFormSections);
        }
      }
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const overlay = overlayRef.current;
    const rect = overlay.getBoundingClientRect();

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    setIsDrawing(true);
    setStartX(mouseX);
    setStartY(mouseY);
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    setIsDrawing(false);

    const overlay = overlayRef.current;
    const ctxo = overlay.getContext("2d");
    ctxo.strokeStyle = "blue";
    ctxo.lineWidth = 3;

    ctxo.strokeRect(prevStartX, prevStartY, prevWidth, prevHeight);

    setFormSections([
      ...formSections,
      {
        height: parseInt(prevHeight),
        width: parseInt(prevWidth),
        x: parseInt(prevStartX),
        y: parseInt(prevStartY),
        pageno: 1,
      },
    ]);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDrawing) {
      return;
    }

    const overlay = overlayRef.current;
    const rect = overlay.getBoundingClientRect();

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const width = Math.abs(mouseX - startX);
    const height = Math.abs(mouseY - startY);

    const prevX = Math.min(startX, mouseX);
    const prevY = Math.min(startY, mouseY);

    const ctxo = overlay.getContext("2d");
    ctxo.clearRect(0, 0, overlay.width, overlay.height);
    ctxo.strokeStyle = "blue";
    ctxo.lineWidth = 3;

    ctxo.strokeRect(prevX, prevY, width, height);

    setPrevStartX(prevX);
    setPrevStartY(prevY);
    setPrevWidth(width);
    setPrevHeight(height);
  };

  const handleCloseFormSection = async (sectionIndex) => {
    const updatedFormSections = [...formSections];
    const removedSection = updatedFormSections.splice(sectionIndex, 1)[0];
    const removedSectionId = removedSection.id;
    if (removedSectionId) {
      await SpreadConfigurationApi.DeleteMultipleSpreadConfig(removedSectionId);
      // window.location.reload();
    }
    updatedFormSections.splice(sectionIndex, 1);
    setFormSections(updatedFormSections);

    const updatedData = { ...data };

    updatedData.height = updatedFormSections
      .map((section) => section.height)
      .join(",");
    updatedData.width = updatedFormSections
      .map((section) => section.width)
      .join(",");
    updatedData.x = updatedFormSections.map((section) => section.x).join(",");
    updatedData.y = updatedFormSections.map((section) => section.y).join(",");
    updatedData.pageno = updatedFormSections
      .map((section) => section.pageno)
      .join(",");

    setData(updatedData);
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("pdfData", file);

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ENV_API + "/spread/get-image",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          let image = DataProcess.decryptData(data.result);

          setImageShow(JSON.parse(image));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          console.error("API call error:", response.statusText);
        }
      } catch (error) {
        console.error("API call error:", error);
        setLoading(false);
      }
    } else {
    }
  };

  const handleDropdownChange = async (e) => {
    const selectedOption = e;

    setData((prevInputValues) => ({
      ...prevInputValues,
      selectedOption: selectedOption,
    }));
  };

  const handleFormTypeCaseChange = (newValue) => {
    setIsCashOn(newValue);

    setData((prevInputValue) => ({
      ...prevInputValue,
      iscash: newValue,
    }));
  };

  const handleSwitchChange = async (newValue, id) => {
    setSwitchValue(newValue);
    setIsSwitchOn(newValue);

    setData((prevInputValue) => ({
      ...prevInputValue,
      ismultiple: newValue,
    }));
  };

  const fetchCreatetableselactOptions = async () => {
    if (data.selectedOption && selectType !== data.selectedOption) {
      setselectType(data.selectedOption);
      const response = await SpreadConfigurationApi.getSearchConfigration(
        data.selectedOption,
        ""
      );

      if (response) {
        let data =
          response.data && response.data.data ? response.data.data : [];

        const options = data.map((item) => ({
          value: item.field_input,
          label: item.field_input,
          fieldname: item.fieldname,
          field_label: item.field_label,
          form_type: item.form_type,
        }));

        const inputData = data.map((item) => ({
          field_input: item.field_input,
          fieldName: item.fieldname,
          field_label: item.field_label,
          form_type: item.form_type,
        }));

        setFormInput(inputData);

        setCreatetableselactOptions(options);
      } else {
      }
    }
  };

  const handleSetPage = (value) => {
    setData((prevData) => ({
      ...prevData,
      pageno: value,
    }));
    setCurrentPage(value);
  };

  fetchCreatetableselactOptions();

  return (
    <>
      <div>
        <h3>Edit SpreadData</h3>
        <Form>
          <div>
            <div className="config-form field">
              <div className="page-button">
                <label className="upload-btn" for="upload">
                  Select a File
                </label>
                <input
                  type="file"
                  id="upload"
                  className=""
                  accept="application/pdf"
                  onChange={handleFileUpload}
                />
                <button
                  className="pagesNumber btn-primary"
                  style={{ marginLeft: "275px" }}
                  disabled={imageShow.length === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePreviousImage();
                  }}
                >
                  Previous
                </button>
                <button
                  className="pagesNumber btn-primary"
                  disabled={imageShow.length === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNextImage();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="form_row field">
              <div className="form">
                <label htmlFor="spread">Year </label>
                <select
                  className="form-control"
                  name="year"
                  value={data.year}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    select
                  </option>
                  {years.map((option) => (
                    <option key={option.id} value={option.id} disabled>
                      {option.year}
                    </option>
                  ))}
                </select>
                <label htmlFor="username">Form Type</label>
                <select
                  className="form-control"
                  as="select"
                  value={data.form_type}
                  onChange={handleDropdownChange}
                >
                  <option value="">Select a form type</option>
                  {formDatas.map((item) => (
                    <option key={item.form_type} value={item.form_type}>
                      {item.form_type}
                    </option>
                  ))}
                </select>
                <label htmlFor="username">Field Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fieldname"
                  value={data.fieldname}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "fieldName")
                  }
                />
                <label htmlFor="username">Field Input</label>
                <Createtableselact
                  className="form-control"
                  name="field_input"
                  value={
                    createtableselactOptions.find(
                      (option) => option.value === data.field_input
                    ) || { label: data.field_input, value: data.field_input }
                  }
                  onChange={(selectedValue) =>
                    handleInputChange(selectedValue, "field_input")
                  }
                  options={createtableselactOptions}
                />
                <label htmlFor="username">Field Label</label>
                <Input
                  type="text"
                  className="form-control"
                  name="field_label"
                  value={data.field_label}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "field_label")
                  }
                />
                {!isSwitchOn && (
                  <div className="config-date">
                    <label htmlFor="username">Height</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="height"
                      value={prevHeight ? prevHeight : data.height}
                    />
                  </div>
                )}

                {!isSwitchOn && (
                  <div className="config-date">
                    <label htmlFor="username">Width</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="width"
                      value={prevWidth ? prevWidth : data.width}
                    />
                  </div>
                )}

                {!isSwitchOn && (
                  <div className="config-date">
                    <label htmlFor="username">X</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="x"
                      value={prevStartX ? prevStartX : data.x}
                    />
                  </div>
                )}

                {!isSwitchOn && (
                  <div className="config-date">
                    <label htmlFor="username">Y</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="y"
                      value={prevStartY ? prevStartY : data.y}
                    />
                  </div>
                )}

                {!isSwitchOn && (
                  <div>
                    <label htmlFor="username">PageNo</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="pageno"
                      value={currentPage}
                      onChange={(e) => handleSetPage(e.target.value)}
                    />
                  </div>
                )}

                {isSwitchOn && <p>Add Multiple Values</p>}

                {/* is MUltiple ADd */}

                {formSections.map((section, index) => (
                  <div key={index}>
                    {isSwitchOn && (
                      <IoClose
                        size={24}
                        color="red"
                        style={{ cursor: "pointer", marginLeft: "95%" }}
                        onClick={() => handleCloseFormSection(index)}
                      />
                    )}

                    {isSwitchOn && (
                      <div className="config-date">
                        <label htmlFor="username">Height</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`height-${index}`}
                          value={section.height}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "height")
                          }
                        />
                      </div>
                    )}

                    {isSwitchOn && (
                      <div className="config-date">
                        <label htmlFor="username">Width</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`width-${index}`}
                          value={section.width}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "width")
                          }
                        />
                      </div>
                    )}

                    {isSwitchOn && (
                      <div className="config-date">
                        <label htmlFor="username">X</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`x-${index}`}
                          value={section.x}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "x")
                          }
                        />
                      </div>
                    )}

                    {isSwitchOn && (
                      <div className="config-date">
                        <label htmlFor="username">Y</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`y-${index}`}
                          value={section.y}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "y")
                          }
                        />
                      </div>
                    )}

                    {isSwitchOn && (
                      <div>
                        <label htmlFor="username">PageNo</label>
                        <Input
                          type="text"
                          className="form-control"
                          name={`pageno-${index}`}
                          value={currentPage}
                          onChange={(e) =>
                            handleSectionInputChange(e, index, "pageno")
                          }
                        />
                      </div>
                    )}
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <div>
                    <label htmlFor="username">IsMultiple</label>
                    <div className="switch-container">
                      <ReactSwitch
                        checked={isSwitchOn}
                        onChange={(newValue) =>
                          handleSwitchChange(newValue, data.ismultiple)
                        }
                      />

                      {isSwitchOn && (
                        <button
                          className="multiple btn-danger"
                          type="button"
                          onClick={handleAddFormSection}
                        >
                          Add Multiple
                        </button>
                      )}
                    </div>
                  </div>
                  {filterFormType.includes(data.form_type) && (
                    <div>
                      <label htmlFor="formTypeCase">IsCash</label>
                      <div className="switch-container">
                        <ReactSwitch
                          id="formTypeCase"
                          checked={isCashOn}
                          onChange={(newValue) =>
                            handleFormTypeCaseChange(newValue, data.iscash)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* is MUltiple ADd */}

                <div className="form-group input-field">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => handleUpdate(data)}
                  >
                    Update SpreadData
                  </button>
                </div>
              </div>
              {loading && <Loader />}
              <div className="form_canvas">
                <canvas
                  className="image-canvas"
                  ref={canvasRef}
                  width={2771 - sidebarWidth}
                  height={3500 - topbarHeight}
                  style={{
                    position: "absolute",
                    border: "1px solid black",
                    scale: "scroll",
                  }}
                />
                <canvas
                  className="image-canvas"
                  ref={overlayRef}
                  width={2771 - sidebarWidth}
                  height={3500 - topbarHeight}
                  style={{ position: "absolute" }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditSpreadConfiguration;
