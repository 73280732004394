import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MySpread from "./Pages/SpreadPages/MySpread/Myspread";
import NewSpread from "./Pages/SpreadPages/StartNewSpread/NewSpread";
import Login from "./Pages/Login/Login";
import Layout from "./components/Layout";
import Register from "./Pages/SignUp/Register";
import ForgetPassword from "./Pages/Forgetpassword/ForgetPassword";
import ChangePassword from "./Pages/changepassword/ChangePassword";
import User from "./Pages/User/User";
import EditUser from "./Pages/User/EditUser";
import GroupSpread from "./Pages/SpreadPages/GroupSpread/GroupSpread";
import Year from "./Pages/Year/Year";
import EditYear from "./Pages/Year/EditYear";
import EditSpread from "./Pages/SpreadPages/MySpread/EditSpread.js";
import SpreadData from "./Pages/SpreadData/SpreadData";
import EditSpreadData from "./Pages/SpreadData/EditSpreadData";
import SpreadConfigration from "./Pages/SpreadConfigration/SpreadConfigration";
import EditSpreadConfigration from "./Pages/SpreadConfigration/EditSpreadConfigration";
import TaxAnaysis from "./Pages/TaxAalysis/TaxAnalysis";
import ImportFile from "./Pages/SpreadConfigration/ImportFile";
import SendGridKey from "./Pages/SendGrid/sendGridKey.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          { path: "/myspread", element: <MySpread /> },
          { path: "/newspread", element: <NewSpread /> },
          { path: "/changepassword", element: <ChangePassword /> },
          { path: "/user", element: <User /> },
          { path: "/user/:id", element: <EditUser /> },
          { path: "/groupspread", element: <GroupSpread /> },
          { path: "/year", element: <Year /> },
          { path: "/year/:id", element: <EditYear /> },
          { path: "/myspread/:id", element: <EditSpread /> },
          { path: "/spreaddata", element: <SpreadData /> },
          { path: "/editspreaddata/:id", element: <EditSpreadData /> },
          { path: "/spreadconfiguration", element: <SpreadConfigration /> },
          { path: "/taxanalysis/:id", element: <TaxAnaysis /> },
          {
            path: "/spreadconfiguration/:id",
            element: <EditSpreadConfigration />,
          },
          { path: "/spreadconfiguration/importfile", element: <ImportFile /> },
          { path: "/sendgrid", element: <SendGridKey /> },

        ],
      },
      { path: "/login", element: <Login /> },
      { path: "/register", element: <Register /> },
      { path: "/forgetpassword", element: <ForgetPassword /> },
    ],
  },
]);

ReactDOM.render(
  <>
    <RouterProvider router={router} />
  </>,
  document.getElementById("root")
);

reportWebVitals();
