import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { useNavigate, useParams } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import DataProcess from "../../common/Helper";
import { toasterSuccess } from "../../components/ToasterMessages/toastify.helper";
import AuthService from "../../services/auth.service";
import axios from "axios";
import SpreadDataApi from "../../services/API/SpreadData";
import SpreadApi from "../../services/API/SpreadApi";
import SpreadConfigurationApi from "../../services/API/spreadConfigurationApi";

const EditSpreadData = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    value: "",
  });
  const [spreads, setSpreads] = useState([]);
  const [configs, setConfig] = useState([]);
  const [totalItems, setTotalItems] = useState(0);


  const searchSpreads = async () => {
    const response = await SpreadApi.searchSpread();
    let result = JSON.parse(DataProcess.decryptData(response.data.result))
    setSpreads(result.data);
    setTotalItems(result.count);
  };

  const searchconfig = async () => {
    const response = await SpreadConfigurationApi.SearchSpreadConfig();
    let result = JSON.parse(DataProcess.decryptData(response.data.result))
    setConfig(result.data);
    setTotalItems(result.count);
  };

  useEffect(() => {
    searchSpreads();
    searchconfig();
    let spreadData;
    SpreadDataApi.GetSpreadData(id).then((response) => {
      spreadData = response.data.result;

      let userData = DataProcess.decryptData(spreadData);
      userData = JSON.parse(userData);
      setData(userData.data);
      
    });
  }, []);

  const handleUpdate = async (userData) => {
    delete userData.configname
    delete userData.name
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + AuthService.validToken(),
    }
    await fetch(
      process.env.REACT_APP_SERVER_ENV_API +"/spread_data/update/" + userData.id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          reqJson: DataProcess.encryptData(JSON.stringify(userData)),
        }),
      },
      toasterSuccess("Update SpreadData successfully!"),
      navigate("/spreaddata")
    );
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="col-md-12">
        <h3>Edit SpreadData</h3>
        <Form onSubmit={() => handleUpdate(data)}>
          <div>
         
            <div className="form-group">
              <label htmlFor="spread">Spread </label>
              <select
                className="form-control"
                name="spread_id"
                value={data.spread_id}
                onChange={handleInputChange}
              >
                <option value="" disabled>select</option>
                {spreads.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="spread_config">Spread Configuration</label>
              <select
                className="form-control"
                name="config_id"
                value={data.config_id}
                onChange={handleInputChange}
              >
                <option value="" disabled>select</option>
                {configs.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.fieldname}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="username">Value</label>
              <Input
                type="text"
                className="form-control"
                name="value"
                value={data.value}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">Update SpreadData</button>
            </div>
          </div>

          <CheckButton style={{ display: "none" }} />
        </Form>
      </div>
    </>
  );
};

export default EditSpreadData;
