import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import AuthService from "../auth.service";

const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/year/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const CreateYear = () => {
  return axios.post(API_URL + "create", {});
};

const GetYear = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  let Data = DataProcess.encryptData(JSON.stringify({}));
  return axios.get(API_URL + "get/" + id, {headers:headers});
};

const DeleteYear = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  return axios.delete(API_URL + "delete/" + id, { 
    headers: headers
   });
};

const GetAllYear = (skip, limit, sortBy, sortOrder) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  return axios.get(API_URL + `get?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`, {
    headers: headers
  });
};

const SearchYear = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  return axios.get(API_URL + `search`, {
    headers: headers
  });
};

const UpdateYear = async(updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  const response = await fetch(API_URL + "update/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
};

const YearApi = {
  CreateYear,
  GetYear,
  DeleteYear,
  GetAllYear,
  UpdateYear,
  SearchYear
};

export default YearApi;
