import React from "react";
import { Spinner } from "react-bootstrap";
// import GifLoader from "../../../public/spinner.gif"
import gLoader from "../../../src/assets/image/Loading_icon.gif"
import { Audio } from "react-loader-spinner";
import CSS from "../Loader/Loader.css";

const Loader = () => {
  return (
    <div className="loader-content">
      {/* <Spinner
        height="200"
        width="200"
        style={{alignItems: "center"}}
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      /> */}
      <img src={gLoader} alt="Loading..." className="loader-gif" />
    </div>
  );
};

export default Loader;
