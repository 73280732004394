import React from "react";
import Navbar from "../NavBar/Navbar";
import Sidebar from "../SideBar/Sidebar";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Navbar />
      <div className="app-mainpage">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="pages" style={{width: "95%"}}>
          <div className="container mt-3">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
