import React from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import MySpreadApi from "../../services/API/myspreadApi";
import DataProcess from "../../common/Helper";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ListItem } from "@material-ui/core";
import moment from "moment/moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import XlsxPopulate from "xlsx-populate";
import { blue } from "@material-ui/core/colors";
import CSS from "../TaxAalysis/TaxAnalysis.css";
import Loader from "../../components/Loader/Loader";

const addBackgroundColum = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
];

const keysToCheck = [
  "QUICK RATIO",
  "CURRENT RATIO",
  "GROSS PROFIT MARGIN %",
  "PRE-TAX PROFIT MARGIN %",
  "NET PROFIT AFTER TAXES %",
  "RETURN ON EQUITY (ROE) %",
  "RETURN ON ASSETS (ROA) %",
];

const TaxAnaysis = (props) => {
  const { id } = useParams();

  const [taxFormData, setTaxFormData] = useState({});
  const [taxYear, setTaxYear] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const [taxValue, setTaxValue] = useState([]);
  const [name, setName] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const [date, setDate] = useState([]);
  const [taxAverage, setTaxAverage] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the loader after 1 second
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    // Clear the timeout if the component unmounts before the timeout
    return () => clearTimeout(timeoutId);
  }, []);

  const formatValue = (value) => {
    if (!value) {
      return "$      0";
    }
    if (value.includes("%")) {
      return value.trim();
    } else {
      let cleanedString = value.replace(/[$,]/g, "").trim();
      cleanedString = Number(cleanedString);
      const formattedValue = new Intl.NumberFormat("en-US", {
        style: "decimal",
      }).format(cleanedString);
      return "$ " + formattedValue;
    }
  };

  const getTaxAnalysis = async () => {
    try {
      const response = await MySpreadApi.getTaxAnalysis(id);
      const decryptedData = DataProcess.decryptData(response.data.result);
      const parsedData = JSON.parse(decryptedData);
      const dataValue = Object.entries(parsedData.forms_data);

      const formattedEntries = Object.entries(dataValue[0][1]).map(
        ([key, value]) => {
          // Format the value as needed.
          const formattedValue = formatValue(value);
          return [key, formattedValue];
        }
      );
      const formattedObj = Object.fromEntries(formattedEntries);

      dataValue[0][1] = formattedObj;

      setTaxFormData(Object.fromEntries(dataValue));
      setTaxYear(parsedData.years);
      setTaxValue(parsedData.resultWithTotal);
      setAdditionalData(parsedData.additionData);
      setTaxAverage(parsedData.resultWithTotal.averages);
      setName(parsedData.otherDetails.name);
      setCustomerName(parsedData.otherDetails.customerName);
      setDate(parsedData.otherDetails.date);
    } catch (error) {}
  };

  const getElementValue = (year, form_type) => {
    let value = 0;

    if (taxValue && taxValue.length > 0) {
      const i = taxYear.indexOf(year);
      const element = taxValue[i];

      if (
        element.year === year &&
        element.total &&
        element.total[form_type] &&
        form_type !== "Adjustments"
      ) {
        value = element.total[form_type];
        return formatValue(value);
      } else {
        if (form_type === "Adjustments") {
          form_type = "Sum Adjustments";
        }
        value = taxFormData[year][form_type] || 0;
        if (value === 0) return value;
        return formatValue(value);
      }
    }
    return value;
  };
  const getElementFormData = (year, form_type) => {
    let value = 0;

    if (taxFormData && taxFormData.length > 0) {
      for (let i = 0; i < taxFormData.length; i++) {
        const element = taxFormData[i];

        if (element.year == year && element.total && element.total[form_type]) {
          value = element.total[form_type];

          return value;
        }
      }
    }
    return value;
  };
  const getElementAvrage = (form_type) => {
    let value = 0;
    if (taxValue.length > 0) {
      if (taxValue[0]["averages"][form_type]) {
        value = taxValue[0]["averages"][form_type];
        return formatValue(value);
      } else {
        return value;
      }
    }
    return value;
  };

  function getData(additionData, formType, year) {
    if (additionData[formType] && additionData[formType].configurations[year]) {
      return additionData[formType].configurations[year].data;
    } else {
      return []; // Return an empty array if formType or year is not found
    }
  }

  // Example usage:
  // const data1040_2021 = getData(additionData, "1040", "2021");
  // console.log(data1040_2021);

  const getCurrentAndPriorChanges = (form_type) => {
    let value = 0;
    if (taxValue.length > 0) {
      if (taxValue[0]["currentAndPriorChange"][form_type]) {
        value = taxValue[0]["currentAndPriorChange"][form_type];
        value = "$  " + Number(value.replace("$", "")).toFixed(2);
        return formatValue(value);
      } else {
        return value;
      }
    }
  };

  function getAdditionalFielsInput(formType) {
    const allFieldInputs = [];

    if (additionalData[formType]) {
      for (const year in additionalData[formType].configurations) {
        const yearData = additionalData[formType].configurations[year];

        for (const dataObj of yearData.data) {
          if (dataObj.field_input) {
            allFieldInputs.push(
              dataObj.fieldName + ":==:" + dataObj.field_input
            );
          }
        }
      }
    }
    return allFieldInputs;
  }

  const getAdditionalFielsInput1040 = getAdditionalFielsInput("1040");
  const getAdditionalFielsInputSchA = getAdditionalFielsInput("1040_sch_A");
  const getAdditionalFielsInputSchB = getAdditionalFielsInput("1040_sch_B");
  const getAdditionalFielsInputSchC = getAdditionalFielsInput("1040_sch_C");
  const getAdditionalFielsInputSchD = getAdditionalFielsInput("1040_sch_D");
  const getAdditionalFielsInputSchE = getAdditionalFielsInput("1040_sch_E");
  const getAdditionalFielsInputSchF = getAdditionalFielsInput("1040_sch_F");
  const getAdditionalFielsInput2106 = getAdditionalFielsInput("2106");
  const getAdditionalFielsInput4797 = getAdditionalFielsInput("4797");
  const getAdditionalFielsInput4835 = getAdditionalFielsInput("4835");
  const getAdditionalFielsInput6252 = getAdditionalFielsInput("6252");
  const getAdditionalFielsInput8825 = getAdditionalFielsInput("8825");
  const getAdditionalFielsInput8606 = getAdditionalFielsInput("8606");
  const getAdditionalFielsInput065K = getAdditionalFielsInput("1065K");
  const getAdditionalFielsInputS_K1 = getAdditionalFielsInput("1120S_K1");
  const getAdditionalFielsInput1041 = getAdditionalFielsInput("1041");
  const getAdditionalFielsInput5_K1 = getAdditionalFielsInput("8865_K1");
  const getAdditionalFielsInput065C = getAdditionalFielsInput("1065C");
  const getAdditionalFielsInput120S = getAdditionalFielsInput("1120S");

  console.log("getAdditionalFielsInput1040", getAdditionalFielsInput1040);

  // Iterate through form types
  function getAdditionalData(formType) {
    const allFieldInputs = [];

    if (additionalData[formType]) {
      for (const year in additionalData[formType].configurations) {
        const yearData = additionalData[formType].configurations[year];

        for (const dataObj of yearData.data) {
          if (dataObj.field_input) {
            allFieldInputs.push(dataObj.field_input);
          }
        }
      }
    }

    const additionalDataResult = [
      allFieldInputs.map((fieldName) => {
        console.log("fieldName", fieldName);
        return [
          fieldName,
          ...taxYear.map(
            (item) => getElementValue(item, fieldName) || "$      0"
          ),
          getElementAvrage(fieldName) || "$      0",
          getCurrentAndPriorChanges(fieldName) || "$      0",
        ];
      }),
    ];

    return additionalDataResult;
  }

  const additionalData1040 = getAdditionalData("1040");
  const additionalDataSchA = getAdditionalData("1040_sch_A");
  const additionalDataSchB = getAdditionalData("1040_sch_B");
  const additionalDataSchC = getAdditionalData("1040_sch_C");
  const additionalDataSchD = getAdditionalData("1040_sch_D");
  const additionalDataSchE = getAdditionalData("1040_sch_E");
  const additionalDataSchF = getAdditionalData("1040_sch_F");
  const additionalData2106 = getAdditionalData("2106");
  const additionalData4797 = getAdditionalData("4797");
  const additionalData4835 = getAdditionalData("4835");
  const additionalData6252 = getAdditionalData("6252");
  const additionalData8825 = getAdditionalData("8825");
  const additionalData8606 = getAdditionalData("8606");
  const additionalData1065K = getAdditionalData("1065K");
  const additionalData1120S_K1 = getAdditionalData("1120S_K1");
  const additionalData1041 = getAdditionalData("1041");
  const additionalData8865_K1 = getAdditionalData("8865_K1");
  const additionalData1065C = getAdditionalData("1065C");
  const additionalData1120S = getAdditionalData("1120S");

  const generatePDF = () => {
    const pdf = new jsPDF("p", "pt", "letter");

    const columns = [
      "Cash Flow Recap",
      ...taxYear,
      "Average",
      "Current to last Year",
    ];
    const rows = [
      [
        "Form 1040",
        ...taxYear.map(
          (item) => getElementValue(item, "Form 1040") || "$      0"
        ),
        getElementAvrage("Form 1040") || "$      0",
        getCurrentAndPriorChanges("Form 1040") || "$      0",
      ],
      [
        "Schedule A",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule A") || "$      0"
        ),
        getElementAvrage("Schedule A") || "$      0",
        getCurrentAndPriorChanges("Schedule A") || "$      0",
      ],
      [
        "Schedule B",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule B") || "$      0"
        ),
        getElementAvrage("Scedule B") || "$      0",
        getCurrentAndPriorChanges("Scedule B") || "$      0",
      ],
      [
        "Schedule C",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule C") || "$      0"
        ),
        getElementAvrage("Scedule C") || "$      0",
        getCurrentAndPriorChanges("Scedule C") || "$      0",
      ],
      [
        "Schedule D",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule D") || "$      0"
        ),
        getElementAvrage("Scedule D") || "$      0",
        getCurrentAndPriorChanges("Scedule D") || "$      0",
      ],
      [
        "Schedule E",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule E") || "$      0"
        ),
        getElementAvrage("Scedule E") || "$      0",
        getCurrentAndPriorChanges("Scedule E") || "$      0",
      ],
      [
        "Schedule F",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule F") || "$      0"
        ),
        getElementAvrage("Scedule F") || "$      0",
        getCurrentAndPriorChanges("Scedule F") || "$      0",
      ],
      [
        "Form 2106",
        ...taxYear.map(
          (item) => getElementValue(item, "ME 2106") || "$      0"
        ),
        getElementAvrage("ME 2106") || "$      0",
        getCurrentAndPriorChanges("ME 2106") || "$      0",
      ],
      [
        "Form 4797",
        ...taxYear.map(
          (item) => getElementValue(item, "Form 4797") || "$      0"
        ),
        getElementAvrage("Form 4797") || "$      0",
        getCurrentAndPriorChanges("Form 4797") || "$      0",
      ],
      [
        "Form 4835",
        ...taxYear.map(
          (item) => getElementValue(item, "Form 4835") || "$      0"
        ),
        getElementAvrage("Form 4835") || "$      0",
        getCurrentAndPriorChanges("Form 4835") || "$      0",
      ],
      [
        "Form 6252",
        ...taxYear.map(
          (item) => getElementValue(item, "Form 6252") || "$      0"
        ),
        getElementAvrage("Form 6252") || "$      0",
        getCurrentAndPriorChanges("Form 6252") || "$      0",
      ],
      [
        "Form 8825",
        ...taxYear.map(
          (item) => getElementValue(item, "Form 8825") || "$      0"
        ),
        getElementAvrage("Form 8825") || "$      0",
        getCurrentAndPriorChanges("Form 8825") || "$      0",
      ],
      [
        "Schedule K-1, Partnership",
        ...taxYear.map(
          (item) =>
            getElementValue(item, "Schedule K-1, Partnership") || "$      0"
        ),
        getElementAvrage("Schedule K-1, Partnership") || "$      0",
        getCurrentAndPriorChanges("Schedule K-1, Partnership") || "$      0",
      ],
      [
        "Schedule K-1, S-Corporation",
        ...taxYear.map(
          (item) =>
            getElementValue(item, "Schedule K-1, S-Corporation") || "$      0"
        ),
        getElementAvrage("Schedule K-1, S-Corporation") || "$      0",
        getCurrentAndPriorChanges("Schedule K-1, S-Corporation") || "$      0",
      ],
      [
        "Schedule K-1, Trust Income",
        ...taxYear.map(
          (item) =>
            getElementValue(item, "Schedule K-1, Trust Income") || "$      0"
        ),
        getElementAvrage("Schedule K-1, Trust Income") || "$      0",
        getCurrentAndPriorChanges("Schedule K-1, Trust Income") || "$      0",
      ],
      [
        "Schedule K-1 Form 8865",
        ...taxYear.map(
          (item) => getElementValue(item, "8865_K1") || "$      0"
        ),
        getElementAvrage("8865_K1") || "$      0",
        getCurrentAndPriorChanges("8865_K1") || "$      0",
      ],
      [
        "Schedule 1065",
        ...taxYear.map((item) => getElementValue(item, "1065C") || "$      0"),
        getElementAvrage("1065C") || "$      0",
        getCurrentAndPriorChanges("1065C") || "$      0",
      ],
      [
        "Schedule 1120",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule 1120") || "$      0"
        ),
        getElementAvrage("Schedule 1120") || "$      0",
        getCurrentAndPriorChanges("Schedule 1120") || "$      0",
      ],
      [
        "Schedule 1120S",
        ...taxYear.map(
          (item) => getElementValue(item, "Schedule 1120S") || "$      0"
        ),
        getElementAvrage("Schedule 1120S") || "$      0",
        getCurrentAndPriorChanges("Schedule 1120S") || "$      0",
      ],
      [
        "Cash Flow From Business Financials",
        ...taxYear.map((item) => getElementValue(item, "Total") || "$      0"),
        getElementAvrage("Total") || "$      0",
        getCurrentAndPriorChanges("Total") || "$      0",
      ],
      [
        "Annual Cash Available To Service Debt",
        ...taxYear.map(
          (item) =>
            getElementValue(item, "Annual Cash Available To Service Debt") ||
            "$      0"
        ),
        getElementAvrage("Annual Cash Available To Service Debt") || "$      0",
        getCurrentAndPriorChanges("Annual Cash Available To Service Debt") ||
          "$      0",
      ],
      [
        "Deductions, 1040, Sch. A, Form 8606",
        ...taxYear.map(
          (item) => taxFormData[item]["Deductions, 1040, Sch. A, Form 8606"]
        ),
        getElementAvrage("Deductions, 1040, Sch. A, Form 8606") || "$      0",
        getCurrentAndPriorChanges("Deductions, 1040, Sch. A, Form 8606") ||
          "$      0",
      ],
      [
        "Tax Payments",
        ...taxYear.map(
          (item) => taxFormData[item]["Tax Payments"] || "$      0"
        ),
        getElementAvrage("Tax Payments") || "$      0",
        getCurrentAndPriorChanges("Tax Payments") || "$      0",
      ],
      [
        "Total Deductions From Cash Flow",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Deductions From Cash Flow"]
        ),
        getElementAvrage("Total Deductions From Cash Flow") || "$      0",
        getCurrentAndPriorChanges("Total Deductions From Cash Flow") ||
          "$      0",
      ],
      [
        "Adjustments",
        ...taxYear.map(
          (item) => taxFormData[item]["Adjustments"] || "$      0"
        ),
        getElementAvrage("Adjustments") || "$      0",
        getCurrentAndPriorChanges("Adjustments") || "$      0",
      ],
      [
        "Net Cash Available",
        ...taxYear.map(
          (item) => taxFormData[item]["Net Cash Available"] || "$      0"
        ),
        getElementAvrage("Net Cash Available") || "$      0",
        getCurrentAndPriorChanges("Net Cash Available") || "$      0",
      ],
      [],
      ["Adjustments"],
      [
        "Auto Expenses Adjustments",
        ...taxYear.map((item) => taxFormData[item]["ADJ 1"] || "$      0"),
        getElementAvrage("ADJ 1") || "$      0",
        getCurrentAndPriorChanges("ADJ 1") || "$      0",
      ],
      [
        "Amortization",
        ...taxYear.map((item) => taxFormData[item]["ADJ 4"] || "$      0"),
        getElementAvrage("ADJ 4") || "$      0",
        getCurrentAndPriorChanges("ADJ 4") || "$      0",
      ],
      [
        "Other Adjustments",
        ...taxYear.map((item) => taxFormData[item]["ADJ 5"] || "$      0"),
        getElementAvrage("ADJ 5") || "$      0",
        getCurrentAndPriorChanges("ADJ 5") || "$      0",
      ],
      [
        "Sum Adjustments",
        ...taxYear.map(
          (item) => taxFormData[item]["Sum Adjustments"] || "$      0"
        ),
        getElementAvrage("Sum Adjustments") || "$      0",
        getCurrentAndPriorChanges("Sum Adjustments") || "$      0",
      ],
      ["Annual Debt Payments"],
      [
        "Projected Payment on Loan Request",
        ...taxYear.map((item) => taxFormData[item]["Debt 1"] || "$      0"),
        getElementAvrage("Debt 1") || "$      0",
        getCurrentAndPriorChanges("Debt 1") || "$      0",
      ],
      [
        "Home Mortgage/Rent",
        ...taxYear.map((item) => taxFormData[item]["Debt 2"] || "$      0"),
        getElementAvrage("Debt 2") || "$      0",
        getCurrentAndPriorChanges("Debt 2") || "$      0",
      ],
      [
        "Revolving Totals",
        ...taxYear.map((item) => taxFormData[item]["Debt 3"] || "$      0"),
        getElementAvrage("Debt 3") || "$      0",
        getCurrentAndPriorChanges("Debt 3") || "$      0",
      ],
      [
        "Installment Totals",
        ...taxYear.map((item) => taxFormData[item]["Debt 4"] || "$      0"),
        getElementAvrage("Debt 4") || "$      0",
        getCurrentAndPriorChanges("Debt 4") || "$      0",
      ],
      [
        "Rental Totals",
        ...taxYear.map((item) => taxFormData[item]["Debt 5"] || "$      0"),
        getElementAvrage("Debt 5") || "$      0",
        getCurrentAndPriorChanges("Debt 5") || "$      0",
      ],
      [
        "Other",
        ...taxYear.map((item) => taxFormData[item]["Debt 6"] || "$      0"),
        getElementAvrage("Debt 6") || "$      0",
        getCurrentAndPriorChanges("Debt 6") || "$      0",
      ],
      [
        "Personal Living Expenses-est.",
        ...taxYear.map((item) => taxFormData[item]["Debt 7"] || "$      0"),
        getElementAvrage("Debt 7") || "$      0",
        getCurrentAndPriorChanges("Debt 7") || "$      0",
      ],
      [
        "Total Annual Debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Annual Debt"] || "$      0"
        ),
        getElementAvrage("Total Annual Debt") || "$      0",
        getCurrentAndPriorChanges("Total Annual Debt") || "$      0",
      ],
      [
        "Net Cashflow After Debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Net Cashflow After Debt"] || "$      0"
        ),
        getElementAvrage("Net Cashflow After Debt") || "$      0",
        getCurrentAndPriorChanges("Net Cashflow After Debt") || "$      0",
      ],
      [
        "Debt Ratio: (cash flow/debt)",
        ...taxYear.map(
          (item) => taxFormData[item]["Debt Ratio: (cash flow/debt)"]
        ),
        getElementAvrage("Debt Ratio: (cash flow/debt)") || "$      0",
        getCurrentAndPriorChanges("Debt Ratio: (cash flow/debt)") || "$      0",
      ],
      [
        "Debt Ratio: (debt/cash flow)",
        ...taxYear.map(
          (item) => taxFormData[item]["Debt Ratio: (debt/cash flow)"]
        ),
        getElementAvrage("Debt Ratio: (debt/cash flow)") || "$      0",
        getCurrentAndPriorChanges("Debt Ratio: (debt/cash flow)") || "$      0",
      ],
      [
        "Net Cashflow After Debt with Adj.",
        ...taxYear.map(
          (item) => taxFormData[item]["Net Cashflow After Debt with Adj."]
        ),
        getElementAvrage("Net Cashflow After Debt with Adj.") || "$      0",
        getCurrentAndPriorChanges("Net Cashflow After Debt with Adj.") ||
          "$      0",
      ],
      [
        "Debt Ratio Adjusted: (cash flow/debt)",
        ...taxYear.map(
          (item) => taxFormData[item]["Debt Ratio Adjusted: (cash flow/debt)"]
        ),
        getElementAvrage("Debt Ratio Adjusted: (cash flow/debt)") || "$      0",
        getCurrentAndPriorChanges("Debt Ratio Adjusted: (cash flow/debt)") ||
          "$      0",
      ],
      [
        "Debt Ratio Adjusted: (debt/cash flow)",
        ...taxYear.map(
          (item) => taxFormData[item]["Debt Ratio Adjusted: (debt/cash flow))"]
        ),
        getElementAvrage("Debt Ratio Adjusted: (debt/cash flow))") ||
          "$      0",
        getCurrentAndPriorChanges("Debt Ratio Adjusted: (debt/cash flow))") ||
          "$      0",
      ],
      ["Form 1040"],
      [
        "Wages, salaries",
        ...taxYear.map((item) => taxFormData[item]["w1"] || "$      0"),
        getElementAvrage("w1") || "$      0",
        getCurrentAndPriorChanges("w1") || "$      0",
      ],
      [
        "Taxable interest income",
        ...taxYear.map((item) => taxFormData[item]["TI 1"] || "$      0"),
        getElementAvrage("TI 1") || "$      0",
        getCurrentAndPriorChanges("TI 1") || "$      0",
      ],
      [
        "Nontaxable interest income",
        ...taxYear.map((item) => taxFormData[item]["NT 1"] || "$      0"),
        getElementAvrage("NT 1") || "$      0",
        getCurrentAndPriorChanges("NT 1") || "$      0",
      ],
      [
        "Dividend income",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["D1"].replace("$", "")) +
              Number(taxFormData[item]["D2"].replace("$", "")))
        ),
        "$    " +
          (parseFloat(getElementAvrage("D1").replace("$", "")) +
            parseFloat(getElementAvrage("D2").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("D1").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("D2").replace("$", ""))),
      ],
      [
        "Taxable refunds",
        ...taxYear.map((item) => taxFormData[item]["TR1"] || "$      0"),
        getElementAvrage("TR1") || "$      0",
        getCurrentAndPriorChanges("TR1") || "$      0",
      ],
      [
        "Alimony Received",
        ...taxYear.map((item) => taxFormData[item]["AL1"] || "$      0"),
        getElementAvrage("AL1") || "$      0",
        getCurrentAndPriorChanges("AL1") || "$      0",
      ],
      [
        "Business income or loss",
        ...taxYear.map((item) => taxFormData[item]["BI1"] || "$      0"),
        getElementAvrage("BI1") || "$      0",
        getCurrentAndPriorChanges("BI1") || "$      0",
      ],
      [
        "Total IRA Dist.",
        ...taxYear.map((item) => taxFormData[item]["IRA1"] || "$      0"),
        getElementAvrage("IRA1") || "$      0",
        getCurrentAndPriorChanges("IRA1") || "$      0",
      ],
      [
        "Total Pensions",
        ...taxYear.map((item) => taxFormData[item]["PEN1"] || "$      0"),
        getElementAvrage("PEN1") || "$      0",
        getCurrentAndPriorChanges("PEN1") || "$      0",
      ],
      [
        "Farm Income",
        ...taxYear.map((item) => taxFormData[item]["FARM1"] || "$      0"),
        getElementAvrage("FARM1") || "$      0",
        getCurrentAndPriorChanges("FARM1") || "$      0",
      ],
      [
        "Unemp. comp. insurance",
        ...taxYear.map((item) => taxFormData[item]["UNC1"] || "$      0"),
        getElementAvrage("UNC1") || "$      0",
        getCurrentAndPriorChanges("UNC1") || "$      0",
      ],
      [
        "Social security benefits",
        ...taxYear.map((item) => taxFormData[item]["SSI1"] || "$      0"),
        getElementAvrage("SSI1") || "$      0",
        getCurrentAndPriorChanges("SSI1") || "$      0",
      ],
      [
        "Child Support",
        ...taxYear.map((item) => taxFormData[item]["CS1"] || "$      0"),
        getElementAvrage("CS1") || "$      0",
        getCurrentAndPriorChanges("CS1") || "$      0",
      ],
      [
        "Other income - CASH",
        ...taxYear.map((item) => taxFormData[item]["OTI1"] || "$      0"),
        getElementAvrage("OTI1") || "$      0",
        getCurrentAndPriorChanges("OTI1") || "$      0",
      ],
      [
        "Total 1040 Cash Inflows",
        ...taxYear.map(
          (item) => taxFormData[item]["Total 1040 Cash Inflows"] || "$      0"
        ),
        getElementAvrage("Total 1040 Cash Inflows") || "$      0",
        getCurrentAndPriorChanges("Total 1040 Cash Inflows") || "$      0",
      ],
      [
        "Educator Expenses",
        ...taxYear.map((item) => taxFormData[item]["Ex1"] || "$      0"),
        getElementAvrage("Ex1") || "$      0",
        getCurrentAndPriorChanges("Ex1") || "$      0",
      ],
      [
        "Certain Business Expenses",
        ...taxYear.map((item) => taxFormData[item]["BEX1"] || "$      0"),
        getElementAvrage("BEX1") || "$      0",
        getCurrentAndPriorChanges("BEX1") || "$      0",
      ],
      [
        "IRA Deductions",
        ...taxYear.map((item) => taxFormData[item]["IRAD1"] || "$      0"),
        getElementAvrage("IRAD1") || "$      0",
        getCurrentAndPriorChanges("IRAD1") || "$      0",
      ],
      [
        "Medical Savings",
        ...taxYear.map((item) => taxFormData[item]["MedS1"] || "$      0"),
        getElementAvrage("MedS1") || "$      0",
        getCurrentAndPriorChanges("MedS1") || "$      0",
      ],
      [
        "Moving Expenses",
        ...taxYear.map((item) => taxFormData[item]["MEXP1"] || "$      0"),
        getElementAvrage("MEXP1") || "$      0",
        getCurrentAndPriorChanges("MEXP1") || "$      0",
      ],
      [
        "Self-Employed Insurance",
        ...taxYear.map((item) => taxFormData[item]["SEPI1"] || "$      0"),
        getElementAvrage("SEPI1") || "$      0",
        getCurrentAndPriorChanges("SEPI1") || "$      0",
      ],
      ["Keogh and SEP Contributions"],
      [
        "Early Withdrawal Penalty",
        ...taxYear.map((item) => taxFormData[item]["EWDP1"] || "$      0"),
        getElementAvrage("EWDP1") || "$      0",
        getCurrentAndPriorChanges("EWDP1") || "$      0",
      ],
      [
        "Alimony Paid",
        ...taxYear.map((item) => taxFormData[item]["AP1"] || "$      0"),
        getElementAvrage("AP1") || "$      0",
        getCurrentAndPriorChanges("AP1") || "$      0",
      ],
      [
        "Student Loan Interest Deduction",
        ...taxYear.map((item) => taxFormData[item]["SLI1"] || "$      0"),
        getElementAvrage("SLI1") || "$      0",
        getCurrentAndPriorChanges("SLI1") || "$      0",
      ],
      ["Child Support"],
      [
        "Total 1040 Cash Deductions",
        ...taxYear.map(
          (item) => taxFormData[item]["Total 1040 Cash Deductions"]
        ),
        getElementAvrage("Total 1040 Cash Deductions") || "$      0",
        getCurrentAndPriorChanges("Total 1040 Cash Deductions") || "$      0",
      ],
      [
        "Total Federal Tax Payments",
        ...taxYear.map((item) => taxFormData[item]["FICA1"] || "$      0"),
        getElementAvrage("FICA1") || "$      0",
        getCurrentAndPriorChanges("FICA1") || "$      0",
      ],
      [
        "Refunded (from previous year)",
        ...taxYear.map((item) => taxFormData[item]["PYR1"] || "$      0"),
        getElementAvrage("PYR1") || "$      0",
        getCurrentAndPriorChanges("PYR1") || "$      0",
      ],
      [
        "Owed (from previous year)",
        ...taxYear.map((item) => taxFormData[item]["OPY1"] || "$      0"),
        getElementAvrage("OPY1") || "$      0",
        getCurrentAndPriorChanges("OPY1") || "$      0",
      ],
      [
        "FICA tax (From W-2) or.0765 x wages subject to Inc. limits",
        ...taxYear.map(
          (item) =>
            taxFormData[item][
              "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
            ] || "$      0"
        ),
        getElementAvrage(
          "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
        ),
        getCurrentAndPriorChanges(
          "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
        ),
      ],
      [
        "Cash Tax Reconciliation",
        ...taxYear.map(
          (item) => taxFormData[item]["Cash Tax Reconciliation"] || "$      0"
        ),
        getElementAvrage("Cash Tax Reconciliation") || "$      0",
        getCurrentAndPriorChanges("Cash Tax Reconciliation") || "$      0",
      ],
      ...additionalData1040[0],
      [
        "Total  Form 1040 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total  Form 1040 Items"] || "$      0"
        ),
        getElementAvrage("Total  Form 1040 Items") || "$      0",
        getCurrentAndPriorChanges("Total  Form 1040 Items") || "$      0",
      ],

      ["SCHEDULE A"],
      [
        "Medical Expenses",
        ...taxYear.map((item) => taxFormData[item]["MEDEX1"] || "$      0"),
        getElementAvrage("MEDEX1") || "$      0",
        getCurrentAndPriorChanges("MEDEX1") || "$      0",
      ],
      [
        "Taxes Paid",
        ...taxYear.map((item) => taxFormData[item]["TPA1"] || "$      0"),
        getElementAvrage("TPA1") || "$      0",
        getCurrentAndPriorChanges("TPA1") || "$      0",
      ],
      [
        "Cash Charitable Expenses",
        ...taxYear.map((item) => taxFormData[item]["CEX1"] || "$      0"),
        getElementAvrage("CEX1") || "$      0",
        getCurrentAndPriorChanges("CEX1") || "$      0",
      ],
      [
        "Miscellaneous Deductions",
        ...taxYear.map((item) => taxFormData[item]["MISCD1"] || "$      0"),
        getElementAvrage("MISCD1") || "$      0",
        getCurrentAndPriorChanges("MISCD1") || "$      0",
      ],
      [
        "Other deductions (if cash)",
        ...taxYear.map((item) => taxFormData[item]["OCD1"] || "$      0"),
        getElementAvrage("OCD1") || "$      0",
        getCurrentAndPriorChanges("OCD1") || "$      0",
      ],
      ...additionalDataSchA[0],
      [
        "Total Schedule A Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule A Items"] || "$      0"
        ),
        getElementAvrage("Total Schedule A Items") || "$      0",
        getCurrentAndPriorChanges("Total Schedule A Items") || "$      0",
      ],
      ["Schedule B (need new schedule as a TAB)"],
      [
        "Taxable interest income",
        ...taxYear.map((item) => taxFormData[item]["TI 1"] || "$      0"),
        getElementAvrage("TI 1") || "$      0",
        getCurrentAndPriorChanges("TI 1") || "$      0",
      ],
      [
        "Dividend income",
        ...taxYear.map((item) => taxFormData[item]["D2"] || "$      0"),
        getElementAvrage("D2") || "$      0",
        getCurrentAndPriorChanges("D2") || "$      0",
      ],
      ...additionalDataSchB[0],
      [
        "Total",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule B"] || "$      0"
        ),
        getElementAvrage("Total Schedule B") || "$      0",
        getCurrentAndPriorChanges("Total Schedule B") || "$      0",
      ],
      ["SCHEDULE C"],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["Sch C1"] || "$      0"),
        getElementAvrage("Sch C1") || "$      0",
        getCurrentAndPriorChanges("Sch C1") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["Sch C2"] || "$      0"),
        getElementAvrage("Sch C2") || "$      0",
        getCurrentAndPriorChanges("Sch C2") || "$      0",
      ],
      [
        "Interest",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["Sch C3"].replace("$", "") || 0) +
              Number(taxFormData[item]["Sch C4"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("Sch C3").replace("$", "")) +
            parseFloat(getElementAvrage("Sch C4").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("Sch C3").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("Sch C4").replace("$", ""))),
      ],
      [
        "50% of Meals and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["Sch C5"] || "$      0"),
        getElementAvrage("Sch C5") || "$      0",
        getCurrentAndPriorChanges("Sch C5") || "$      0",
      ],
      [
        "Business Use of Home",
        ...taxYear.map((item) => taxFormData[item]["Sch C6"] || "$      0"),
        getElementAvrage("Sch C6") || "$      0",
        getCurrentAndPriorChanges("Sch C6") || "$      0",
      ],
      [
        "Beginning Inventory",
        ...taxYear.map((item) => taxFormData[item]["Sch C7"] || "$      0"),
        getElementAvrage("Sch C7") || "$      0",
        getCurrentAndPriorChanges("Sch C7") || "$      0",
      ],
      [
        "Ending Inventory",
        ...taxYear.map((item) => taxFormData[item]["Sch C8"] || "$      0"),
        getElementAvrage("Sch C8") || "$      0",
        getCurrentAndPriorChanges("Sch C8") || "$      0",
      ],
      ...additionalDataSchC[0],
      [
        "Total Schedule C Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule C Items"] || "$      0"
        ),
        getElementAvrage("Total Schedule C Items") || "$      0",
        getCurrentAndPriorChanges("Total Schedule C Items") || "$      0",
      ],
      ["SCHEDULE D"],
      [
        "Net proceeds from the sale of stocks, bonds, other securities, land, and other nondepreciable property",
        ...taxYear.map((item) => taxFormData[item]["Sch D1"] || "$      0"),
        getElementAvrage("Sch D1") || "$      0",
        getCurrentAndPriorChanges("Total Schedule D Items") || "$      0",
      ],
      ...additionalDataSchD[0],
      [
        "Total Schedule D Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule D Items"] || "$      0"
        ),
        getElementAvrage("Total Schedule D Items") || "$      0",
        getCurrentAndPriorChanges("Total Schedule D Items") || "$      0",
      ],
      ["SCHEDULE E"],
      [
        "Interest Paid",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["Sch E1"].replace("$", "") || 0) +
              Number(taxFormData[item]["Sch E2"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("Sch E1").replace("$", "")) +
            parseFloat(getElementAvrage("Sch E2").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("Sch E1").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("Sch E2").replace("$", ""))),
      ],
      [
        "Depreciation & Amortization",
        ...taxYear.map((item) => taxFormData[item]["Sch E3"] || "$      0"),
        getElementAvrage("Sch E3") || "$      0",
        getCurrentAndPriorChanges("Sch E3") || "$      0",
      ],
      [
        "Income From Rentals or Royalties",
        ...taxYear.map((item) => taxFormData[item]["Sch E4"] || "$      0"),
        getElementAvrage("Sch E4") || "$      0",
        getCurrentAndPriorChanges("Sch E4") || "$      0",
      ],
      ...additionalDataSchE[0],
      [
        "Total Schedule E Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule E Items"] || "$      0"
        ),
        getElementAvrage("Total Schedule E Items") || "$      0",
        getCurrentAndPriorChanges("Total Schedule E Items") || "$      0",
      ],
      ["SCHEDULE F"],
      [
        "Program Payments",
        ...taxYear.map((item) => taxFormData[item]["Sch F1"] || "$      0"),
        getElementAvrage("Sch F1") || "$      0",
        getCurrentAndPriorChanges("Sch F1") || "$      0",
      ],
      [
        "Program Payments, Taxable",
        ...taxYear.map((item) => taxFormData[item]["Sch F2"] || "$      0"),
        getElementAvrage("Sch F2") || "$      0",
        getCurrentAndPriorChanges("Sch F2") || "$      0",
      ],
      [
        "CCC Loans Forfeited",
        ...taxYear.map((item) => taxFormData[item]["Sch F3"] || "$      0"),
        getElementAvrage("Sch F3") || "$      0",
        getCurrentAndPriorChanges("Sch F3") || "$      0",
      ],
      [
        "Crop Insurance (current year)",
        ...taxYear.map((item) => taxFormData[item]["Sch F4"] || "$      0"),
        getElementAvrage("Sch F4") || "$      0",
        getCurrentAndPriorChanges("Sch F4") || "$      0",
      ],
      [
        "Crop Insurance Taxable/Deferred",
        ...taxYear.map((item) => taxFormData[item]["Sch F5"] || "$      0"),
        getElementAvrage("Sch F5") || "$      0",
        getCurrentAndPriorChanges("Sch F5") || "$      0",
      ],
      [
        "Def Prior Year",
        ...taxYear.map((item) => taxFormData[item]["Sch F6"] || "$      0"),
        getElementAvrage("Sch F6") || "$      0",
        getCurrentAndPriorChanges("Sch F6") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["Sch F7"] || "$      0"),
        getElementAvrage("Sch F7") || "$      0",
        getCurrentAndPriorChanges("Sch F7") || "$      0",
      ],
      [
        "Interest",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["Sch F8"].replace("$", "") || 0) +
              Number(taxFormData[item]["Sch F9"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("Sch F8").replace("$", "")) +
            parseFloat(getElementAvrage("Sch F9").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("Sch F8").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("Sch F9").replace("$", ""))),
      ],
      [
        "Program Payments",
        ...taxYear.map((item) => taxFormData[item]["Sch F10"] || "$      0"),
        getElementAvrage("Sch F10") || "$      0",
        getCurrentAndPriorChanges("Sch F10") || "$      0",
      ],
      [
        "Program Payments, Taxable",
        ...taxYear.map((item) => taxFormData[item]["Sch F11"] || "$      0"),
        getElementAvrage("Sch F11") || "$      0",
        getCurrentAndPriorChanges("Sch F11") || "$      0",
      ],
      [
        "CCC Loans Forfeited",
        ...taxYear.map((item) => taxFormData[item]["Sch F12"] || "$      0"),
        getElementAvrage("Sch F12") || "$      0",
        getCurrentAndPriorChanges("Sch F12") || "$      0",
      ],
      [
        "Beginning Inventory",
        ...taxYear.map((item) => taxFormData[item]["Sch F13"] || "$      0"),
        getElementAvrage("Sch F13") || "$      0",
        getCurrentAndPriorChanges("Sch F13") || "$      0",
      ],
      [
        "Ending Inventory",
        ...taxYear.map((item) => taxFormData[item]["Sch F14"] || "$      0"),
        getElementAvrage("Sch F14") || "$      0",
        getCurrentAndPriorChanges("Sch F14") || "$      0",
      ],
      [
        "Net New Investments",
        ...taxYear.map((item) => taxFormData[item]["Sch F15"] || "$      0"),
        getElementAvrage("Sch F15") || "$      0",
        getCurrentAndPriorChanges("Sch F15") || "$      0",
      ],
      ...additionalDataSchF[0],
      [
        "Total Schedule F Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Schedule F Items"] || "$      0"
        ),
        getElementAvrage("Total Schedule F Items") || "$      0",
        getCurrentAndPriorChanges("Total Schedule F Items") || "$      0",
      ],
      ["FORM 2106"],
      [
        "Meals & Entertainment",
        ...taxYear.map((item) => taxFormData[item]["ME 2106"] || "$      0"),
        getElementAvrage("ME 2106") || "$      0",
        getCurrentAndPriorChanges("ME 2106") || "$      0",
      ],
      ...additionalData2106[0],
      [
        "Total Form 2106 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 2106 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 2106 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 2106 Items") || "$      0",
      ],
      ["FORM 4797"],
      [
        "Gross Sales Price",
        ...taxYear.map((item) => taxFormData[item]["F4797 1"] || "$      0"),
        getElementAvrage("F4797 1") || "$      0",
        getCurrentAndPriorChanges("F4797 1") || "$      0",
      ],
      [
        "Gross Sales Price",
        ...taxYear.map((item) => taxFormData[item]["F4797 2"] || "$      0"),
        getElementAvrage("F4797 2") || "$      0",
        getCurrentAndPriorChanges("F4797 2") || "$      0",
      ],
      [
        "Total Gain Page 2",
        ...taxYear.map((item) => taxFormData[item]["F4797 3"] || "$      0"),
        getElementAvrage("F4797 3") || "$      0",
        getCurrentAndPriorChanges("F4797 3") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["F4797 4"] || "$      0"),
        getElementAvrage("F4797 4") || "$      0",
        getCurrentAndPriorChanges("F4797 4") || "$      0",
      ],
      ...additionalData4797[0],
      [
        "Total Form 4797 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 4797 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 4797 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 4797 Items") || "$      0",
      ],
      ["FORM 4835"],
      [
        "Income From Production",
        ...taxYear.map((item) => taxFormData[item]["f4835 1"] || "$      0"),
        getElementAvrage("f4835 1") || "$      0",
        getCurrentAndPriorChanges("f4835 1") || "$      0",
      ],
      [
        "Total Co-op Dist. (Taxable)",
        ...taxYear.map((item) => taxFormData[item]["f4835 2"] || "$      0"),
        getElementAvrage("f4835 2") || "$      0",
        getCurrentAndPriorChanges("f4835 2") || "$      0",
      ],
      [
        "Agricultural Program Payments",
        ...taxYear.map((item) => taxFormData[item]["f4835 3"] || "$      0"),
        getElementAvrage("f4835 3") || "$      0",
        getCurrentAndPriorChanges("f4835 3") || "$      0",
      ],
      [
        "CCC Loans",
        ...taxYear.map((item) => taxFormData[item]["f4835 4"] || "$      0"),
        getElementAvrage("f4835 4") || "$      0",
        getCurrentAndPriorChanges("f4835 4") || "$      0",
      ],
      [
        "Crop Insurance (Current Year)",
        ...taxYear.map((item) => taxFormData[item]["f4835 5"] || "$      0"),
        getElementAvrage("f4835 5") || "$      0",
        getCurrentAndPriorChanges("f4835 5") || "$      0",
      ],
      [
        "Other Income",
        ...taxYear.map((item) => taxFormData[item]["f4835 6"] || "$      0"),
        getElementAvrage("f4835 6") || "$      0",
        getCurrentAndPriorChanges("f4835 6") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["f4835 7"] || "$      0"),
        getElementAvrage("f4835 7") || "$      0",
        getCurrentAndPriorChanges("f4835 7") || "$      0",
      ],
      [
        "Interest",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["f4835 8"].replace("$", "") || 0) +
              Number(taxFormData[item]["f4835 9"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("f4835 8").replace("$", "")) +
            parseFloat(getElementAvrage("f4835 9").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("f4835 8").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("f4835 9").replace("$", ""))),
      ],
      [
        "Total Expenses",
        ...taxYear.map((item) => taxFormData[item]["f4835 10"] || "$      0"),
        getElementAvrage("f4835 10") || "$      0",
        getCurrentAndPriorChanges("f4835 10") || "$      0",
      ],
      [
        "Net New Investments",
        ...taxYear.map((item) => taxFormData[item]["f4835 11"] || "$      0"),
        getElementAvrage("f4835 11") || "$      0",
        getCurrentAndPriorChanges("f4835 11") || "$      0",
      ],
      ...additionalData4835[0],
      [
        "Total Form 4835 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 4835 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 4835 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 4835 Items") || "$      0",
      ],
      ["Form 6252"],
      [
        "Commissions & Other Expenses",
        ...taxYear.map((item) => taxFormData[item]["F6252 1"] || "$      0"),
        getElementAvrage("F6252 1") || "$      0",
        getCurrentAndPriorChanges("F6252 1") || "$      0",
      ],
      [
        "Payments Received",
        ...taxYear.map((item) => {
          const value = getElementValue(item, "F6252 2");
          return value ? value : "$      0";
        }),
        getElementAvrage("F6252 2") || "$      0",
        getCurrentAndPriorChanges("F6252 2") || "$      0",
      ],
      ...additionalData6252[0],
      [
        "Total Form 6252 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 6252 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 6252 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 6252 Items") || "$      0",
      ],
      ["Form 8825"],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["F8825 1"] || "$      0"),
        getElementAvrage("F8825 1") || "$      0",
        getCurrentAndPriorChanges("F8825 1") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["F8825 2"] || "$      0"),
        getElementAvrage("F8825 2") || "$      0",
        getCurrentAndPriorChanges("F8825 2") || "$      0",
      ],
      [
        "Amortization",
        ...taxYear.map((item) => taxFormData[item]["F8825 3"] || "$      0"),
        getElementAvrage("F8825 3") || "$      0",
        getCurrentAndPriorChanges("F8825 3") || "$      0",
      ],
      ...additionalData8825[0],
      [
        "Total form 8825",
        ...taxYear.map(
          (item) => taxFormData[item]["Total form 8825"] || "$      0"
        ),
        getElementAvrage("Total form 8825") || "$      0",
        getCurrentAndPriorChanges("Total form 8825") || "$      0",
      ],
      ["Form 8606"],
      [
        "Nondeductible Contributions",
        ...taxYear.map((item) => taxFormData[item]["F8606 1"] || "$      0"),
        getElementAvrage("F8606 1") || "$      0",
        getCurrentAndPriorChanges("F8606 1") || "$      0",
      ],
      [
        "Contr. 1/1 to 4/15 Next Year",
        ...taxYear.map((item) => taxFormData[item]["F8606 2"] || "$      0"),
        getElementAvrage("F8606 2") || "$      0",
        getCurrentAndPriorChanges("F8606 2") || "$      0",
      ],
      ...additionalData8606[0],
      [
        "Total Form 8606 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 8606 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 8606 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 8606 Items") || "$      0",
      ],
      ["SCHEDULE K-1"],
      [
        "Ordinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["1065K 1"] || "$      0"),
        getElementAvrage("1065K 1") || "$      0",
        getCurrentAndPriorChanges("1065K 1") || "$      0",
      ],
      [
        "Net Rental Real Estate Income",
        ...taxYear.map((item) => taxFormData[item]["1065K 2"] || "$      0"),
        getElementAvrage("1065K 2") || "$      0",
        getCurrentAndPriorChanges("1065K 2") || "$      0",
      ],
      [
        "Other Rental Inc.",
        ...taxYear.map((item) => taxFormData[item]["1065K 3"] || "$      0"),
        getElementAvrage("1065K 3") || "$      0",
        getCurrentAndPriorChanges("1065K 3") || "$      0",
      ],
      [
        "Guaranteed Payments to Partner",
        ...taxYear.map((item) => taxFormData[item]["1065K 4"] || "$      0"),
        getElementAvrage("1065K 4") || "$      0",
        getCurrentAndPriorChanges("1065K 4") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1065K 5"] || "$      0"),
        getElementAvrage("1065K 5") || "$      0",
        getCurrentAndPriorChanges("1065K 5") || "$      0",
      ],
      [
        "Dividends",
        ...taxYear.map((item) => taxFormData[item]["1065K 6"] || "$      0"),
        getElementAvrage("1065K 6") || "$      0",
        getCurrentAndPriorChanges("1065K 6") || "$      0",
      ],
      [
        "Royalties",
        ...taxYear.map((item) => taxFormData[item]["1065K 7"] || "$      0"),
        getElementAvrage("1065K 7") || "$      0",
        getCurrentAndPriorChanges("1065K 7") || "$      0",
      ],
      [
        "Distributions of Cash",
        ...taxYear.map((item) => taxFormData[item]["1065K 8"] || "$      0"),
        getElementAvrage("1065K 8") || "$      0",
        getCurrentAndPriorChanges("1065K 8") || "$      0",
      ],
      ...additionalData1065K[0],
      [
        "Total Form 1065 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1065 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 1065 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 1065 Items") || "$      0",
      ],
      ["SCHEDULE K-1 / (Form 1120S) S-Corporation"],
      [
        "Ordinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["1120SK 1"] || "$      0"),
        getElementAvrage("1120SK 1") || "$      0",
        getCurrentAndPriorChanges("1120SK 1") || "$      0",
      ],
      [
        "Net Rental Real Estate Income",
        ...taxYear.map((item) => taxFormData[item]["1120SK 2"] || "$      0"),
        getElementAvrage("1120SK 2") || "$      0",
        getCurrentAndPriorChanges("1120SK 2") || "$      0",
      ],
      [
        "Other Rental Inc.",
        ...taxYear.map((item) => taxFormData[item]["1120SK 3"] || "$      0"),
        getElementAvrage("1120SK 3") || "$      0",
        getCurrentAndPriorChanges("1120SK 3") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1120SK 4"] || "$      0"),
        getElementAvrage("1120SK 4") || "$      0",
        getCurrentAndPriorChanges("1120SK 4") || "$      0",
      ],
      [
        "Ordinary Dividends",
        ...taxYear.map((item) => taxFormData[item]["1120SK 5"] || "$      0"),
        getElementAvrage("1120SK 5") || "$      0",
        getCurrentAndPriorChanges("1120SK 5") || "$      0",
      ],
      [
        "Royalties",
        ...taxYear.map((item) => taxFormData[item]["1120SK 6"] || "$      0"),
        getElementAvrage("1120SK 6") || "$      0",
        getCurrentAndPriorChanges("1120SK 6") || "$      0",
      ],
      [
        "Distributions of Cash",
        ...taxYear.map((item) => taxFormData[item]["1120SK 7"] || "$      0"),
        getElementAvrage("1120SK 7") || "$      0",
        getCurrentAndPriorChanges("1120SK 7") || "$      0",
      ],
      ...additionalData1120S_K1[0],
      [
        "Total Form 1120S Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120S Items"] || "$      0"
        ),
        getElementAvrage("Total Form 1120S Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 1120S Items") || "$      0",
      ],
      ["SCHEDULE K-1 / (Form 1041) Trust Income"],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1041K 1"] || "$      0"),
        getElementAvrage("1041K 1") || "$      0",
        getCurrentAndPriorChanges("1041K 1") || "$      0",
      ],
      [
        "Dividends",
        ...taxYear.map((item) => taxFormData[item]["1041K 2"] || "$      0"),
        getElementAvrage("1041K 2") || "$      0",
        getCurrentAndPriorChanges("1041K 2") || "$      0",
      ],
      [
        "Ordinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["1041K 3"] || "$      0"),
        getElementAvrage("1041K 3") || "$      0",
        getCurrentAndPriorChanges("1041K 3") || "$      0",
      ],
      [
        "Net Rental Real Estate Income",
        ...taxYear.map((item) => taxFormData[item]["1041K 4"] || "$      0"),
        getElementAvrage("1041K 4") || "$      0",
        getCurrentAndPriorChanges("1041K 4") || "$      0",
      ],
      [
        "Other Rental Income",
        ...taxYear.map((item) => taxFormData[item]["1041K 5"] || "$      0"),
        getElementAvrage("1041K 5") || "$      0",
        getCurrentAndPriorChanges("1041K 5") || "$      0",
      ],
      [
        "Distributions From Trust",
        ...taxYear.map((item) => taxFormData[item]["1041K 6"] || "$      0"),
        getElementAvrage("1041K 6") || "$      0",
        getCurrentAndPriorChanges("1041K 6") || "$      0",
      ],
      ...additionalData1041[0],
      [
        "Total Form 1041 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1041 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 1041 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 1041 Items") || "$      0",
      ],
      ["SCHEDULE K-1 / (Form 8865)"],
      [
        "Oridinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["8865K 1"] || "$      0"),
        getElementAvrage("8865K 1") || "$      0",
        getCurrentAndPriorChanges("8865K 1") || "$      0",
      ],
      [
        "Net Rental Real Estate Income",
        ...taxYear.map((item) => taxFormData[item]["8865K 2"] || "$      0"),
        getElementAvrage("8865K 2") || "$      0",
        getCurrentAndPriorChanges("8865K 2") || "$      0",
      ],
      [
        "Other Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["8865K 3"] || "$      0"),
        getElementAvrage("8865K 3") || "$      0",
        getCurrentAndPriorChanges("8865K 3") || "$      0",
      ],
      [
        "Guaranteed Payments",
        ...taxYear.map((item) => taxFormData[item]["8865K 4"] || "$      0"),
        getElementAvrage("8865K 4") || "$      0",
        getCurrentAndPriorChanges("8865K 4") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["8865K 5"] || "$      0"),
        getElementAvrage("8865K 5") || "$      0",
        getCurrentAndPriorChanges("8865K 5") || "$      0",
      ],
      [
        "Ordinary Dividends",
        ...taxYear.map((item) => taxFormData[item]["8865K 6"] || "$      0"),
        getElementAvrage("8865K 6") || "$      0",
        getCurrentAndPriorChanges("8865K 6") || "$      0",
      ],
      [
        "Royalties",
        ...taxYear.map((item) => taxFormData[item]["8865K 7"] || "$      0"),
        getElementAvrage("8865K 7") || "$      0",
        getCurrentAndPriorChanges("8865K 7") || "$      0",
      ],
      [
        "Other Income or Loss",
        ...taxYear.map((item) => taxFormData[item]["8865K 8"] || "$      0"),
        getElementAvrage("8865K 8") || "$      0",
        getCurrentAndPriorChanges("8865K 8") || "$      0",
      ],
      [
        "Distributions of Cash",
        ...taxYear.map((item) => taxFormData[item]["8865K 9"] || "$      0"),
        getElementAvrage("8865K 9") || "$      0",
        getCurrentAndPriorChanges("8865K 9") || "$      0",
      ],
      ...additionalData8865_K1[0],
      [
        "Total Form 8865 Items",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 8865 Items"] || "$      0"
        ),
        getElementAvrage("Total Form 8865 Items") || "$      0",
        getCurrentAndPriorChanges("Total Form 8865 Items") || "$      0",
      ],
      ["Form 1065 (Cash Basis) / Return of Partnership Income (Cash Basis)"],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1065C1"] || "$      0"),
        getElementAvrage("1065C1") || "$      0",
        getCurrentAndPriorChanges("1065C1") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1065C2"] || "$      0"),
        getElementAvrage("1065C2") || "$      0",
        getCurrentAndPriorChanges("1065C2") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1065C3"] || "$      0"),
        getElementAvrage("1065C3") || "$      0",
        getCurrentAndPriorChanges("1065C3") || "$      0",
      ],
      [
        "Ordinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["1065C4"] || "$      0"),
        getElementAvrage("1065C4") || "$      0",
        getCurrentAndPriorChanges("1065C4") || "$      0",
      ],
      ["Form 1065 - Schedule K (Cash)"],
      [
        "Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1065C5"] || "$      0"),
        getElementAvrage("1065C5") || "$      0",
        getCurrentAndPriorChanges("1065C5") || "$      0",
      ],
      [
        "Other Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1065C6"] || "$      0"),
        getElementAvrage("1065C6") || "$      0",
        getCurrentAndPriorChanges("1065C6") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1065C7"] || "$      0"),
        getElementAvrage("1065C7") || "$      0",
        getCurrentAndPriorChanges("1065C7") || "$      0",
      ],
      [
        "Qualified Dividends",
        ...taxYear.map((item) => taxFormData[item]["1065C8"] || "$      0"),
        getElementAvrage("1065C8") || "$      0",
        getCurrentAndPriorChanges("1065C8") || "$      0",
      ],
      [
        "Royalties",
        ...taxYear.map((item) => taxFormData[item]["1065C9"] || "$      0"),
        getElementAvrage("1065C9") || "$      0",
        getCurrentAndPriorChanges("1065C9") || "$      0",
      ],
      [
        "Contributions",
        ...taxYear.map((item) => taxFormData[item]["1065C10"] || "$      0"),
        getElementAvrage("1065C10") || "$      0",
        getCurrentAndPriorChanges("1065C10") || "$      0",
      ],
      [
        "Tax Exempt Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1065C11"] || "$      0"),
        getElementAvrage("1065C11") || "$      0",
        getCurrentAndPriorChanges("1065C11") || "$      0",
      ],
      [
        "Other Tax Exempt Income",
        ...taxYear.map((item) => taxFormData[item]["1065C12"] || "$      0"),
        getElementAvrage("1065C12") || "$      0",
        getCurrentAndPriorChanges("1065C12") || "$      0",
      ],
      [
        "Nondeductible Expenses",
        ...taxYear.map((item) => taxFormData[item]["1065C13"] || "$      0"),
        getElementAvrage("1065C13") || "$      0",
        getCurrentAndPriorChanges("1065C13") || "$      0",
      ],
      ["Form 1065 - Schedule M1 (Cash Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1065C14"] || "$      0"),
        getElementAvrage("1065C14") || "$      0",
        getCurrentAndPriorChanges("1065C14") || "$      0",
      ],
      [
        "Tax Exempt Interest ",
        ...taxYear.map((item) => taxFormData[item]["1065C15"] || "$      0"),
        getElementAvrage("1065C15") || "$      0",
        getCurrentAndPriorChanges("1065C15") || "$      0",
      ],
      ...additionalData1065C[0],
      [
        "Total Form 1065 Items Cash Basis",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1065 Items Cash Basis"]
        ),
        getElementAvrage("Total Form 1065 Items Cash Basis") || "$      0",
        getCurrentAndPriorChanges("Total Form 1065 Items Cash Basis") ||
          "$      0",
      ],
      [
        "Form 1065 (Accrual Basis) / Return of Partnership Income (Accrual Basis)",
      ],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1065A1"] || "$      0"),
        getElementAvrage("1065A1") || "$      0",
        getCurrentAndPriorChanges("1065A1") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1065A2"] || "$      0"),
        getElementAvrage("1065A2") || "$      0",
        getCurrentAndPriorChanges("1065A2") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1065A3"] || "$      0"),
        getElementAvrage("1065A3") || "$      0",
        getCurrentAndPriorChanges("1065A3") || "$      0",
      ],
      [
        "Ordinary Business Income",
        ...taxYear.map((item) => taxFormData[item]["1065A4"] || "$      0"),
        getElementAvrage("1065A4") || "$      0",
        getCurrentAndPriorChanges("1065A4") || "$      0",
      ],
      ["Form 1065 - Schedule K (Accrual)"],
      [
        "Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1065A5"] || "$      0"),
        getElementAvrage("1065A5") || "$      0",
        getCurrentAndPriorChanges("1065A5") || "$      0",
      ],
      [
        "Other Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1065A6"] || "$      0"),
        getElementAvrage("1065A6") || "$      0",
        getCurrentAndPriorChanges("1065A6") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1065A7"] || "$      0"),
        getElementAvrage("1065A7") || "$      0",
        getCurrentAndPriorChanges("1065A7") || "$      0",
      ],
      [
        "Qualified Dividends",
        ...taxYear.map((item) => taxFormData[item]["1065A8"] || "$      0"),
        getElementAvrage("1065A8") || "$      0",
        getCurrentAndPriorChanges("1065A8") || "$      0",
      ],
      [
        "Royalties",
        ...taxYear.map((item) => taxFormData[item]["1065A9"] || "$      0"),
        getElementAvrage("1065A9") || "$      0",
        getCurrentAndPriorChanges("1065A9") || "$      0",
      ],
      [
        "Contributions",
        ...taxYear.map((item) => taxFormData[item]["1065A10"] || "$      0"),
        getElementAvrage("1065A10") || "$      0",
        getCurrentAndPriorChanges("1065A10") || "$      0",
      ],
      [
        "Tax Exempt Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1065A11"] || "$      0"),
        getElementAvrage("1065A11") || "$      0",
        getCurrentAndPriorChanges("1065A11") || "$      0",
      ],
      [
        "Other Tax Exempt Income",
        ...taxYear.map((item) => taxFormData[item]["1065A12"] || "$      0"),
        getElementAvrage("1065A12") || "$      0",
        getCurrentAndPriorChanges("1065A12") || "$      0",
      ],
      [
        "Nondeductible Expenses",
        ...taxYear.map((item) => taxFormData[item]["1065A13"] || "$      0"),
        getElementAvrage("1065A13") || "$      0",
        getCurrentAndPriorChanges("1065A13") || "$      0",
      ],
      ["Form 1065 - Schedule L (Accrual Basis)"],
      [
        "Trade Notes and Accounts Recievable (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A14"] || "$      0"),
        getElementAvrage("1065A14") || "$      0",
        getCurrentAndPriorChanges("1065A14") || "$      0",
      ],
      [
        "Trade Notes and Accounts Recievable (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A15"] || "$      0"),
        getElementAvrage("1065A15") || "$      0",
        getCurrentAndPriorChanges("1065A15") || "$      0",
      ],
      [
        "Less Allowance Bad Debts (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A16"] || "$      0"),
        getElementAvrage("1065A16") || "$      0",
        getCurrentAndPriorChanges("1065A16") || "$      0",
      ],
      [
        "Less Allowance Bad Debts (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A17"] || "$      0"),
        getElementAvrage("1065A17") || "$      0",
        getCurrentAndPriorChanges("1065A17") || "$      0",
      ],
      [
        "Inventories (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A18"] || "$      0"),
        getElementAvrage("1065A18") || "$      0",
        getCurrentAndPriorChanges("1065A18") || "$      0",
      ],
      [
        "Inventories (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A19"] || "$      0"),
        getElementAvrage("1065A19") || "$      0",
        getCurrentAndPriorChanges("1065A19") || "$      0",
      ],
      [
        "Other Current Assets (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A20"] || "$      0"),
        getElementAvrage("1065A20") || "$      0",
        getCurrentAndPriorChanges("1065A20") || "$      0",
      ],
      [
        "Other Current Assets (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A21"] || "$      0"),
        getElementAvrage("1065A21") || "$      0",
        getCurrentAndPriorChanges("1065A21") || "$      0",
      ],
      [
        "Accounts Payable (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A22"] || "$      0"),
        getElementAvrage("1065A22") || "$      0",
        getCurrentAndPriorChanges("1065A22") || "$      0",
      ],
      [
        "Accounts Payable (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A23"] || "$      0"),
        getElementAvrage("1065A23") || "$      0",
        getCurrentAndPriorChanges("1065A23") || "$      0",
      ],
      [
        "MTG, notes, bonds payable <1 year Beg",
        ...taxYear.map((item) => taxFormData[item]["1065A24"] || "$      0"),
        getElementAvrage("1065A24") || "$      0",
        getCurrentAndPriorChanges("1065A24") || "$      0",
      ],
      [
        "MTG, notes, bonds payable <1 year End",
        ...taxYear.map((item) => taxFormData[item]["1065A25"] || "$      0"),
        getElementAvrage("1065A25") || "$      0",
        getCurrentAndPriorChanges("1065A25") || "$      0",
      ],
      [
        "Other Current Liabilities (Start)",
        ...taxYear.map((item) => taxFormData[item]["1065A26"] || "$      0"),
        getElementAvrage("1065A26") || "$      0",
        getCurrentAndPriorChanges("1065A26") || "$      0",
      ],
      [
        "Other Current Liabilities (End)",
        ...taxYear.map((item) => taxFormData[item]["1065A27"] || "$      0"),
        getElementAvrage("1065A27") || "$      0",
        getCurrentAndPriorChanges("1065A27") || "$      0",
      ],
      ["Form 1065 - Schedule M1 (Accrual Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1065A28"] || "$      0"),
        getElementAvrage("1065A28") || "$      0",
        getCurrentAndPriorChanges("1065A28") || "$      0",
      ],
      [
        "Tax Exempt Interest ",
        ...taxYear.map((item) => taxFormData[item]["1065A29"] || "$      0"),
        getElementAvrage("1065A29") || "$      0",
        getCurrentAndPriorChanges("1065A29") || "$      0",
      ],
      [
        "Total Form 1065 Items (Accrual Basis)",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1065 Items (Accrual Basis)"]
        ),
        getElementAvrage("Total Form 1065 Items (Accrual Basis)") || "$      0",
        getCurrentAndPriorChanges("Total Form 1065 Items (Accrual Basis)") ||
          "$      0",
      ],
      [
        "Form 1120 (Cash Basis) / US Corporation Income Tax Return (Cash Basis)",
      ],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1120C1"] || "$      0"),
        getElementAvrage("1120C1") || "$      0",
        getCurrentAndPriorChanges("1120C1") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1120C2"] || "$      0"),
        getElementAvrage("1120C2") || "$      0",
        getCurrentAndPriorChanges("1120C2") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1120C3"] || "$      0"),
        getElementAvrage("1120C3") || "$      0",
        getCurrentAndPriorChanges("1120C3") || "$      0",
      ],
      [
        "Taxable Income",
        ...taxYear.map((item) => taxFormData[item]["1120C4"] || "$      0"),
        getElementAvrage("1120C4") || "$      0",
        getCurrentAndPriorChanges("1120C4") || "$      0",
      ],
      ["Form 1120 - Schedule J (Cash)"],
      [
        "Estimated Tax Payments",
        ...taxYear.map((item) => taxFormData[item]["1120C5"] || "$      0"),
        getElementAvrage("1120C5") || "$      0",
        getCurrentAndPriorChanges("1120C5") || "$      0",
      ],
      ["Form 1120 - Schedule M1 (Cash Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1120C6"] || "$      0"),
        getElementAvrage("1120C6") || "$      0",
        getCurrentAndPriorChanges("1120C6") || "$      0",
      ],
      [
        "Charitable Contributions",
        ...taxYear.map((item) => taxFormData[item]["1120C7"] || "$      0"),
        getElementAvrage("1120C7") || "$      0",
        getCurrentAndPriorChanges("1120C7") || "$      0",
      ],
      [
        "Tax-Exempt Interest",
        ...taxYear.map((item) => taxFormData[item]["1120C8"] || "$      0"),
        getElementAvrage("1120C8") || "$      0",
        getCurrentAndPriorChanges("1120C8") || "$      0",
      ],
      [
        "Total Form 1120 Items Cash Basis",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120 Items Cash Basis"]
        ),
        getElementAvrage("Total Form 1120 Items Cash Basis") || "$      0",
        getCurrentAndPriorChanges("Total Form 1120 Items Cash Basis") ||
          "$      0",
      ],
      [
        "Form 1120 (Accrual Basis) / US Corporation Income Tax Return (Accrual Basis)",
      ],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1120A1"] || "$      0"),
        getElementAvrage("1120A1") || "$      0",
        getCurrentAndPriorChanges("1120A1") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1120A2"] || "$      0"),
        getElementAvrage("1120A2") || "$      0",
        getCurrentAndPriorChanges("1120A2") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1120A3"] || "$      0"),
        getElementAvrage("1120A3") || "$      0",
        getCurrentAndPriorChanges("1120A3") || "$      0",
      ],
      [
        "Business Income",
        ...taxYear.map((item) => taxFormData[item]["1120A4"] || "$      0"),
        getElementAvrage("1120A4") || "$      0",
        getCurrentAndPriorChanges("1120A4") || "$      0",
      ],
      ["Form 1120 - Schedule J (Cash)"],
      [
        "Estimated Tax Payments",
        ...taxYear.map((item) => taxFormData[item]["1120A5"] || "$      0"),
        getElementAvrage("1120A5") || "$      0",
        getCurrentAndPriorChanges("1120A5") || "$      0",
      ],
      ["Form 1120 - Schedule L (Accrual Basis)"],
      [
        "Less Allowance Bad Debts (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120A6"] || "$      0"),
        getElementAvrage("1120A6") || "$      0",
        getCurrentAndPriorChanges("1120A6") || "$      0",
      ],
      [
        "Less Allowance Bad Debts (End)",
        ...taxYear.map((item) => taxFormData[item]["1120A7"] || "$      0"),
        getElementAvrage("1120A7") || "$      0",
        getCurrentAndPriorChanges("1120A7") || "$      0",
      ],
      [
        "Inventories (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120A8"] || "$      0"),
        getElementAvrage("1120A8") || "$      0",
        getCurrentAndPriorChanges("1120A8") || "$      0",
      ],
      [
        "Inventories (End)",
        ...taxYear.map((item) => taxFormData[item]["1120A9"] || "$      0"),
        getElementAvrage("1120A9") || "$      0",
        getCurrentAndPriorChanges("1120A9") || "$      0",
      ],
      [
        "Other Current Assets (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120A10"] || "$      0"),
        getElementAvrage("1120A10") || "$      0",
        getCurrentAndPriorChanges("1120A10") || "$      0",
      ],
      [
        "Other Current Assets (End)",
        ...taxYear.map((item) => taxFormData[item]["1120A11"] || "$      0"),
        getElementAvrage("1120A11") || "$      0",
        getCurrentAndPriorChanges("1120A11") || "$      0",
      ],
      [
        "Accounts Payable (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120A12"] || "$      0"),
        getElementAvrage("1120A12") || "$      0",
        getCurrentAndPriorChanges("1120A12") || "$      0",
      ],
      [
        "Accounts Payable (End)",
        ...taxYear.map((item) => taxFormData[item]["1120A13"] || "$      0"),
        getElementAvrage("1120A13") || "$      0",
        getCurrentAndPriorChanges("1120A13") || "$      0",
      ],
      [
        "MTG, notes, bonds payable <1 year Beg",
        ...taxYear.map((item) => taxFormData[item]["1120A14"] || "$      0"),
        getElementAvrage("1120A14") || "$      0",
        getCurrentAndPriorChanges("1120A14") || "$      0",
      ],
      [
        "MTG, notes, bonds payable <1 year End",
        ...taxYear.map((item) => taxFormData[item]["1120A15"] || "$      0"),
        getElementAvrage("1120A15") || "$      0",
        getCurrentAndPriorChanges("1120A15") || "$      0",
      ],
      [
        "Other Current Liabilities (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120A16"] || "$      0"),
        getElementAvrage("1120A16") || "$      0",
        getCurrentAndPriorChanges("1120A16") || "$      0",
      ],
      [
        "Other Current Liabilities (End)",
        ...taxYear.map((item) => taxFormData[item]["1120A17"] || "$      0"),
        getElementAvrage("1120A17") || "$      0",
        getCurrentAndPriorChanges("1120A17") || "$      0",
      ],
      ["Form 1120 - Schedule M1 (Accrual Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1120A18"] || "$      0"),
        getElementAvrage("1120A18") || "$      0",
        getCurrentAndPriorChanges("1120A18") || "$      0",
      ],
      [
        "Charitable Contributions",
        ...taxYear.map((item) => taxFormData[item]["1120A19"] || "$      0"),
        getElementAvrage("1120A19") || "$      0",
        getCurrentAndPriorChanges("1120A19") || "$      0",
      ],
      [
        "Tax-Exempt Interest",
        ...taxYear.map((item) => taxFormData[item]["1120A20"] || "$      0"),
        getElementAvrage("1120A20") || "$      0",
        getCurrentAndPriorChanges("1120A20") || "$      0",
      ],
      [
        "Total Form 1120 Items (Accrual Basis)",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120 Items (Accrual Basis)"]
        ),
        getElementAvrage("Total Form 1120 Items (Accrual Basis)") || "$      0",
        getCurrentAndPriorChanges("Total Form 1120 Items (Accrual Basis)") ||
          "$      0",
      ],
      [
        "Form 1120S (Cash Basis) / U.S. Income Tax Return for an S Corporation (Cash Basis)",
      ],
      [
        "Business Income",
        ...taxYear.map((item) => taxFormData[item]["1120S C1"] || "$      0"),
        getElementAvrage("1120S C1") || "$      0",
        getCurrentAndPriorChanges("1120S C1") || "$      0",
      ],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1120S C2"] || "$      0"),
        getElementAvrage("1120S C2") || "$      0",
        getCurrentAndPriorChanges("1120S C2") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1120S C3"] || "$      0"),
        getElementAvrage("1120S C3") || "$      0",
        getCurrentAndPriorChanges("1120S C3") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1120S C4"] || "$      0"),
        getElementAvrage("1120S C4") || "$      0",
        getCurrentAndPriorChanges("1120S C4") || "$      0",
      ],
      [
        "Estimated Tax Payments",
        ...taxYear.map((item) => taxFormData[item]["1120S C5"] || "$      0"),
        getElementAvrage("1120S C5") || "$      0",
        getCurrentAndPriorChanges("1120S C5") || "$      0",
      ],
      ["Form 1120S - Schedule K (Cash)"],
      [
        "Net Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1120S C6"] || "$      0"),
        getElementAvrage("1120S C6") || "$      0",
        getCurrentAndPriorChanges("1120S C6") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1120S C7"] || "$      0"),
        getElementAvrage("1120S C7") || "$      0",
        getCurrentAndPriorChanges("1120S C7") || "$      0",
      ],
      [
        "Tax Exempt Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1120S C8"] || "$      0"),
        getElementAvrage("1120S C8") || "$      0",
        getCurrentAndPriorChanges("1120S C8") || "$      0",
      ],
      [
        "Other Tax Exempt Income",
        ...taxYear.map((item) => taxFormData[item]["1120S C9"] || "$      0"),
        getElementAvrage("1120S C9") || "$      0",
        getCurrentAndPriorChanges("1120S C9") || "$      0",
      ],
      [
        "Nondeductible Expenses",
        ...taxYear.map((item) => taxFormData[item]["1120S C10"] || "$      0"),
        getElementAvrage("1120S C10") || "$      0",
        getCurrentAndPriorChanges("1120S C10") || "$      0",
      ],
      ["Form 1120S - Schedule M1 (Cash Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1120S C11"] || "$      0"),
        getElementAvrage("1120S C11") || "$      0",
        getCurrentAndPriorChanges("1120S C11") || "$      0",
      ],
      [
        "Tax-Exempt Interest",
        ...taxYear.map((item) => taxFormData[item]["1120S C12"] || "$      0"),
        getElementAvrage("1120S C12") || "$      0",
        getCurrentAndPriorChanges("1120S C12") || "$      0",
      ],
      ...additionalData1120S[0],
      [
        "Total Form 1120S Items Cash Basis",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120S Items Cash Basis"]
        ),
        getElementAvrage("Total Form 1120S Items Cash Basis") || "$      0",
        getCurrentAndPriorChanges("Total Form 1120S Items Cash Basis") ||
          "$      0",
      ],
      [
        "Form 1120S (Accrual Basis) / U.S. Income Tax Return for an S Corporation (Accrual Basis)",
      ],
      [
        "Interest",
        ...taxYear.map((item) => taxFormData[item]["1120S A1"] || "$      0"),
        getElementAvrage("1120S A1") || "$      0",
        getCurrentAndPriorChanges("1120S A1") || "$      0",
      ],
      [
        "Less Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1120S A2"] || "$      0"),
        getElementAvrage("1120S A2") || "$      0",
        getCurrentAndPriorChanges("1120S A2") || "$      0",
      ],
      [
        "charitable Contributions",
        ...taxYear.map((item) => taxFormData[item]["1120S A3"] || "$      0"),
        getElementAvrage("1120S A3") || "$      0",
        getCurrentAndPriorChanges("1120S A3") || "$      0",
      ],
      [
        "Business Income",
        ...taxYear.map((item) => taxFormData[item]["1120S A4"] || "$      0"),
        getElementAvrage("1120S A4") || "$      0",
        getCurrentAndPriorChanges("1120S A4") || "$      0",
      ],
      [
        "Est. Tax Pmt.",
        ...taxYear.map((item) => taxFormData[item]["1120S A5"] || "$      0"),
        getElementAvrage("1120S A5") || "$      0",
        getCurrentAndPriorChanges("1120S A5") || "$      0",
      ],
      ["Form 1120S - Schedule K (Accrual)"],
      [
        "Net Rental Net Income",
        ...taxYear.map((item) => taxFormData[item]["1120S A6"] || "$      0"),
        getElementAvrage("1120S A6") || "$      0",
        getCurrentAndPriorChanges("1120S A6") || "$      0",
      ],
      [
        "Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1120S A7"] || "$      0"),
        getElementAvrage("1120S A7") || "$      0",
        getCurrentAndPriorChanges("1120S A7") || "$      0",
      ],
      [
        "Tax Exempt Interest Income",
        ...taxYear.map((item) => taxFormData[item]["1120S A8"] || "$      0"),
        getElementAvrage("1120S A8") || "$      0",
        getCurrentAndPriorChanges("1120S A8") || "$      0",
      ],
      [
        "Other Tax Exempt Income",
        ...taxYear.map((item) => taxFormData[item]["1120S A9"] || "$      0"),
        getElementAvrage("1120S A9") || "$      0",
        getCurrentAndPriorChanges("1120S A9") || "$      0",
      ],
      [
        "Nondeductible Expenses",
        ...taxYear.map((item) => taxFormData[item]["1120S A10"] || "$      0"),
        getElementAvrage("1120S A10") || "$      0",
        getCurrentAndPriorChanges("1120S A10") || "$      0",
      ],
      ["Form 1120S - Schedule L (Accrual Basis)"],
      [
        "Less Allowance Bad Debts (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120S A11"] || "$      0"),
        getElementAvrage("1120S A11") || "$      0",
        getCurrentAndPriorChanges("1120S A11") || "$      0",
      ],
      [
        "Less Allowance Bad Debts (End)",
        ...taxYear.map((item) => taxFormData[item]["1120S A12"] || "$      0"),
        getElementAvrage("1120S A12") || "$      0",
        getCurrentAndPriorChanges("1120S A12") || "$      0",
      ],
      [
        "Inventories (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120S A13"] || "$      0"),
        getElementAvrage("1120S A13") || "$      0",
        getCurrentAndPriorChanges("1120S A13") || "$      0",
      ],
      [
        "Inventories (End)",
        ...taxYear.map((item) => taxFormData[item]["1120S A14"] || "$      0"),
        getElementAvrage("1120S A14") || "$      0",
        getCurrentAndPriorChanges("1120S A14") || "$      0",
      ],
      [
        "Other Current Assets (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120S A15"] || "$      0"),
        getElementAvrage("1120S A15") || "$      0",
        getCurrentAndPriorChanges("1120S A15") || "$      0",
      ],
      [
        "Other Current Assets (End)",
        ...taxYear.map((item) => taxFormData[item]["1120S A16"] || "$      0"),
        getElementAvrage("1120S A16") || "$      0",
        getCurrentAndPriorChanges("1120S A16") || "$      0",
      ],
      [
        "Accounts Payable (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120S A17"] || "$      0"),
        getElementAvrage("1120S A17") || "$      0",
        getCurrentAndPriorChanges("1120S A17") || "$      0",
      ],
      [
        "Accounts Payable (End)",
        ...taxYear.map((item) => taxFormData[item]["1120S A18"] || "$      0"),
        getElementAvrage("1120S A18") || "$      0",
        getCurrentAndPriorChanges("1120S A18") || "$      0",
      ],
      [
        "MTG, notes, bonds Pay<1 Yr Beg",
        ...taxYear.map((item) => taxFormData[item]["1120S A19"] || "$      0"),
        getElementAvrage("1120S A19") || "$      0",
        getCurrentAndPriorChanges("1120S A19") || "$      0",
      ],
      [
        "MTG, notes, bonds Pay<1 Yr End",
        ...taxYear.map((item) => taxFormData[item]["1120S A20"] || "$      0"),
        getElementAvrage("1120S A20") || "$      0",
        getCurrentAndPriorChanges("1120S A20") || "$      0",
      ],
      [
        "Other Current Liabilities (Start)",
        ...taxYear.map((item) => taxFormData[item]["1120S A21"] || "$      0"),
        getElementAvrage("1120S A21") || "$      0",
        getCurrentAndPriorChanges("1120S A21") || "$      0",
      ],
      [
        "Other Current Liabilities (End)",
        ...taxYear.map((item) => taxFormData[item]["1120S A22"] || "$      0"),
        getElementAvrage("1120S A22") || "$      0",
        getCurrentAndPriorChanges("1120S A22") || "$      0",
      ],
      ["Form 1120S - Schedule M1 (Accrual Basis)"],
      [
        "Travel and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1120S A23"] || "$      0"),
        getElementAvrage("1120S A23") || "$      0",
        getCurrentAndPriorChanges("1120S A23") || "$      0",
      ],
      [
        "Tax-Exempt Interest",
        ...taxYear.map((item) => taxFormData[item]["1120S A24"] || "$      0"),
        getElementAvrage("1120S A24") || "$      0",
        getCurrentAndPriorChanges("1120S A24") || "$      0",
      ],
      [
        "Total Form 1120S Items (Accrual Basis)",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120S Items (Accrual Basis)"]
        ),
        getElementAvrage("Total Form 1120S Items (Accrual Basis)") ||
          "$      0",
        getCurrentAndPriorChanges("Total Form 1120S Items (Accrual Basis)") ||
          "$      0",
      ],
      ["FORM Cash Flow Anlaysis 1084 / IRS 1040-Individual Income Tax Return"],
      [
        "Wages, salaries",
        ...taxYear.map((item) => taxFormData[item]["w1"] || "$      0"),
        getElementAvrage("w1") || "$      0",
        getCurrentAndPriorChanges("w1") || "$      0",
      ],
      ["Schedule B Interest and Ordinary Dividend"],
      [
        "Interest Income from Self Employment",
        ...taxYear.map((item) => taxFormData[item]["TI 1"] || "$      0"),
        getElementAvrage("TI 1") || "$      0",
        getCurrentAndPriorChanges("TI 1") || "$      0",
      ],
      [
        "Dividends from Self-Employment",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["D1"].replace("$", "") || 0) +
              Number(taxFormData[item]["D2"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("D1").replace("$", "")) +
            parseFloat(getElementAvrage("D2").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("D1").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("D2").replace("$", ""))),
      ],
      ["Schedule C-Profit or Loss From Business"],
      [
        "Net Profit",
        ...taxYear.map((item) => taxFormData[item]["BI1"] || "$      0"),
        getElementAvrage("BI1") || "$      0",
        getCurrentAndPriorChanges("BI1") || "$      0",
      ],
      [
        "Nonrecurring Other Income/LossExpense",
        ...taxYear.map((item) => taxFormData[item]["1084C 1"] || "$      0"),
        getElementAvrage("1084C 1") || "$      0",
        getCurrentAndPriorChanges("1084C 1") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["Sch C1"] || "$      0"),
        getElementAvrage("Sch C1") || "$      0",
        getCurrentAndPriorChanges("Sch C1") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["Sch C2"] || "$      0"),
        getElementAvrage("Sch C2") || "$      0",
        getCurrentAndPriorChanges("Sch C2") || "$      0",
      ],
      [
        "Non-Deductible Meals and and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["Sch C5"] || "$      0"),
        getElementAvrage("Sch C5") || "$      0",
        getCurrentAndPriorChanges("Sch C5") || "$      0",
      ],
      [
        "Business Use of Home",
        ...taxYear.map((item) => taxFormData[item]["Sch C6"] || "$      0"),
        getElementAvrage("Sch C6") || "$      0",
        getCurrentAndPriorChanges("Sch C6") || "$      0",
      ],
      [
        "Amortization/Casualty Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 1AM"] || "$      0"),
        getElementAvrage("1084C 1AM") || "$      0",
        getCurrentAndPriorChanges("1084C 1AM") || "$      0",
      ],
      [
        "Schedule D-Recurring Gains",
        ...taxYear.map((item) => taxFormData[item]["1084C 2"] || "$      0"),
        getElementAvrage("1084C 2") || "$      0",
        getCurrentAndPriorChanges("1084C 2") || "$      0",
      ],
      [
        "Recurring Capital Gains",
        ...taxYear.map((item) => taxFormData[item]["1084C 3"] || "$      0"),
        getElementAvrage("1084C 3") || "$      0",
        getCurrentAndPriorChanges("1084C 3") || "$      0",
      ],
      ["Schedule E Income or Loss"],
      [
        "Royalties Received",
        ...taxYear.map((item) => taxFormData[item]["Sch E4"] || "$      0"),
        getElementAvrage("Sch E4") || "$      0",
        getCurrentAndPriorChanges("Sch E4") || "$      0",
      ],
      [
        "Total Expenses",
        ...taxYear.map((item) => taxFormData[item]["1084C 4"] || "$      0"),
        getElementAvrage("1084C 4") || "$      0",
        getCurrentAndPriorChanges("1084C 4") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1084C 5"] || "$      0"),
        getElementAvrage("1084C 5") || "$      0",
        getCurrentAndPriorChanges("1084C 5") || "$      0",
      ],
      ["Schedule F Profit or Loss"],
      [
        "Net Farm Profit or Loss",
        ...taxYear.map((item) => taxFormData[item]["FARM1"] || "$      0"),
        getElementAvrage("FARM1") || "$      0",
        getCurrentAndPriorChanges("FARM1") || "$      0",
      ],
      [
        "Non-Tax Portion Ongoing Coop CC Payments",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["Sch F1"].replace("$", "") || 0) +
              Number(taxFormData[item]["Sch F4"].replace("$", "") || 0))
        ),
        "$    " +
          (parseFloat(getElementAvrage("Sch F1").replace("$", "")) +
            parseFloat(getElementAvrage("Sch F4").replace("$", ""))),
        "$    " +
          (parseFloat(getCurrentAndPriorChanges("Sch F1").replace("$", "")) +
            parseFloat(getCurrentAndPriorChanges("Sch F4").replace("$", ""))),
      ],
      [
        "Nonrecurring Other Income Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 6"] || "$      0"),
        getElementAvrage("1084C 6") || "$      0",
        getCurrentAndPriorChanges("1084C 6") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["Sch F7"] || "$      0"),
        getElementAvrage("Sch F7") || "$      0",
        getCurrentAndPriorChanges("Sch F7") || "$      0",
      ],
      [
        "Amortization/Casualty Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 7AM"] || "$      0"),
        getElementAvrage("1084C 7AM") || "$      0",
        getCurrentAndPriorChanges("1084C 7AM") || "$      0",
      ],
      [
        "Business Use of Home",
        ...taxYear.map((item) => taxFormData[item]["1084C 7"] || "$      0"),
        getElementAvrage("1084C 7") || "$      0",
        getCurrentAndPriorChanges("1084C 7") || "$      0",
      ],
      ["Partnership or S Corporation"],
      ["Schedule K-1 Form 1065 Partners Share of Income/Loss"],
      [
        "Ordinary Income",
        ...taxYear.map((item) => taxFormData[item]["1065K 1"] || "$      0"),
        getElementAvrage("1065K 1") || "$      0",
        getCurrentAndPriorChanges("1065K 1") || "$      0",
      ],
      [
        "Net Rental Real Estate/Other Net Income/Loss",
        ...taxYear.map(
          (item) =>
            "$    " +
            (Number(taxFormData[item]["1065K 1"].replace("$", "") || 0) +
              Number(taxFormData[item]["1065K 2"].replace("$", "") || 0))
        ),
        "$    " +
          (
            (getElementAvrage("1065K 1")
              ? parseFloat(getElementAvrage("1065K 1").replace("$", ""))
              : 0) +
            (getElementAvrage("1065K 2")
              ? parseFloat(getElementAvrage("1065K 2").replace("$", ""))
              : 0)
          ).toFixed(2),
        "$    " +
          (
            (getCurrentAndPriorChanges("1065K 1")
              ? parseFloat(
                  getCurrentAndPriorChanges("1065K 1").replace("$", "")
                )
              : 0) +
            (getCurrentAndPriorChanges("1065K 2")
              ? parseFloat(
                  getCurrentAndPriorChanges("1065K 2").replace("$", "")
                )
              : 0)
          ).toFixed(2),
      ],
      [
        "Guaranteed Payments to Partner",
        ...taxYear.map((item) => taxFormData[item]["1065K 4"] || "$      0"),
        getElementAvrage("1065K 4") || "$      0",
        getCurrentAndPriorChanges("1065K 4") || "$      0",
      ],
      ["Form 1065 Adjustments to Business Cash Flow"],
      [
        "Ordinary Income Loss From Other Partnerships",
        ...taxYear.map((item) => taxFormData[item]["1084C 8"] || "$      0"),
        getElementAvrage("1084C 8") || "$      0",
        getCurrentAndPriorChanges("1084C 8") || "$      0",
      ],
      [
        "Non Recurring Other",
        ...taxYear.map((item) => taxFormData[item]["1084C 9"] || "$      0"),
        getElementAvrage("1084C 9") || "$      0",
        getCurrentAndPriorChanges("1084C 9") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1084C 10"] || "$      0"),
        getElementAvrage("1084C 10") || "$      0",
        getCurrentAndPriorChanges("1084C 10") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1084C 11"] || "$      0"),
        getElementAvrage("1084C 11") || "$      0",
        getCurrentAndPriorChanges("1084C 11") || "$      0",
      ],
      [
        "Amortization/Casualty Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 12"] || "$      0"),
        getElementAvrage("1084C 12") || "$      0",
        getCurrentAndPriorChanges("1084C 12") || "$      0",
      ],
      [
        "Mortgages Or Notes Payable Less than 1 Year",
        ...taxYear.map((item) => taxFormData[item]["1084C 13"] || "$      0"),
        getElementAvrage("1084C 13") || "$      0",
        getCurrentAndPriorChanges("1084C 13") || "$      0",
      ],
      [
        "Non-Deductible Travel and and Entertainment",
        ...taxYear.map((item) => taxFormData[item]["1084C 14"] || "$      0"),
        getElementAvrage("1084C 14") || "$      0",
        getCurrentAndPriorChanges("1084C 14") || "$      0",
      ],
      [
        "Total",
        ...taxYear.map((item) => taxFormData[item]["Total"] || "$      0"),
        getElementAvrage("Total") || "$      0",
        getCurrentAndPriorChanges("Total") || "$      0",
      ],
      [
        "Total % Percentage Owned",
        ...taxYear.map((item) => taxFormData[item]["1084C 15"] || "$      0"),
        getElementAvrage("1084C 15") || "$      0",
        getCurrentAndPriorChanges("1084C 15") || "$      0",
      ],
      ["Schedule K-1 Form 1120S"],
      [
        "Ordinary Income",
        ...taxYear.map((item) => taxFormData[item]["1084C 16"] || "$      0"),
        getElementAvrage("1084C 16") || "$      0",
        getCurrentAndPriorChanges("1084C 16") || "$      0",
      ],
      [
        "Net Rental Real Estate/Other Net Income/Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 17"] || "$      0"),
        getElementAvrage("1084C 17") || "$      0",
        getCurrentAndPriorChanges("1084C 17") || "$      0",
      ],
      ["Form 1120S - Adjustments to Business Cash Flow"],
      [
        "Nonrecurring Other (Income) Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 18"] || "$      0"),
        getElementAvrage("1084C 18") || "$      0",
        getCurrentAndPriorChanges("1084C 18") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1084C 19"] || "$      0"),
        getElementAvrage("1084C 19") || "$      0",
        getCurrentAndPriorChanges("1084C 19") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1084C 20"] || "$      0"),
        getElementAvrage("1084C 20") || "$      0",
        getCurrentAndPriorChanges("1084C 20") || "$      0",
      ],
      [
        "Amortization/Casualty Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 21"] || "$      0"),
        getElementAvrage("1084C 21") || "$      0",
        getCurrentAndPriorChanges("1084C 21") || "$      0",
      ],
      [
        "Mortgages or Notes Payable in Less than 1 Year",
        ...taxYear.map((item) => taxFormData[item]["1084C 22"] || "$      0"),
        getElementAvrage("1084C 22") || "$      0",
        getCurrentAndPriorChanges("1084C 22") || "$      0",
      ],
      [
        "Non-deductible Travel and Entertainment Expenses",
        ...taxYear.map((item) => taxFormData[item]["1084C 23"] || "$      0"),
        getElementAvrage("1084C 23") || "$      0",
        getCurrentAndPriorChanges("1084C 23") || "$      0",
      ],
      [
        "Total",
        ...taxYear.map((item) => taxFormData[item]["total"] || "$      0"),
        getElementAvrage("total") || "$      0",
        getCurrentAndPriorChanges("total") || "$      0",
      ],
      [
        "Total % Percentage Owned",
        ...taxYear.map((item) => taxFormData[item]["1084C 24"] || "$      0"),
        getElementAvrage("1084C 24") || "$      0",
        getCurrentAndPriorChanges("1084C 24") || "$      0",
      ],
      ["Form 1120 – Regular Corporation"],
      [
        "Taxable Income",
        ...taxYear.map((item) => taxFormData[item]["1084C 25"] || "$      0"),
        getElementAvrage("1084C 25") || "$      0",
        getCurrentAndPriorChanges("1084C 25") || "$      0",
      ],
      [
        "Total Tax",
        ...taxYear.map((item) => taxFormData[item]["1084C 26"] || "$      0"),
        getElementAvrage("1084C 26") || "$      0",
        getCurrentAndPriorChanges("1084C 26") || "$      0",
      ],
      [
        "Nonrecurring (Gains) Losses",
        ...taxYear.map((item) => taxFormData[item]["1084C 27"] || "$      0"),
        getElementAvrage("1084C 27") || "$      0",
        getCurrentAndPriorChanges("1084C 27") || "$      0",
      ],
      [
        "Nonrecurring Other (Income) Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 28"] || "$      0"),
        getElementAvrage("1084C 28") || "$      0",
        getCurrentAndPriorChanges("1084C 28") || "$      0",
      ],
      [
        "Depreciation",
        ...taxYear.map((item) => taxFormData[item]["1084C 29"] || "$      0"),
        getElementAvrage("1084C 29") || "$      0",
        getCurrentAndPriorChanges("1084C 29") || "$      0",
      ],
      [
        "Depletion",
        ...taxYear.map((item) => taxFormData[item]["1084C 30"] || "$      0"),
        getElementAvrage("1084C 30") || "$      0",
        getCurrentAndPriorChanges("1084C 30") || "$      0",
      ],
      [
        "Amortization/Casualty Loss",
        ...taxYear.map((item) => taxFormData[item]["1084C 31"] || "$      0"),
        getElementAvrage("1084C 31") || "$      0",
        getCurrentAndPriorChanges("1084C 31") || "$      0",
      ],
      [
        "Net Operating Loss and Special Deductions",
        ...taxYear.map((item) => taxFormData[item]["1084C 32"] || "$      0"),
        getElementAvrage("1084C 32") || "$      0",
        getCurrentAndPriorChanges("1084C 32") || "$      0",
      ],
      [
        "Mortgages or Notes Payable in Less than 1 Year",
        ...taxYear.map((item) => taxFormData[item]["1084C 33"] || "$      0"),
        getElementAvrage("1084C 33") || "$      0",
        getCurrentAndPriorChanges("1084C 33") || "$      0",
      ],
      [
        "Non-deductible Travel and Entertainment Expenses",
        ...taxYear.map((item) => taxFormData[item]["1084C 34"] || "$      0"),
        getElementAvrage("1084C 34") || "$      0",
        getCurrentAndPriorChanges("1084C 34") || "$      0",
      ],
      [
        "Subtotal",
        ...taxYear.map((item) => taxFormData[item]["Subtotal"] || "$      0"),
        getElementAvrage("Subtotal") || "$      0",
        getCurrentAndPriorChanges("Subtotal") || "$      0",
      ],
      [
        "Less: Dividends Paid to Borrower",
        ...taxYear.map((item) => taxFormData[item]["1084C 35"] || "$      0"),
        getElementAvrage("1084C 35") || "$      0",
        getCurrentAndPriorChanges("1084C 35") || "$      0",
      ],
      [
        "Total Form 1120",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Form 1120"] || "$      0"
        ),
        getElementAvrage("Total Form 1120") || "$      0",
        getCurrentAndPriorChanges("Total Form 1120") || "$      0",
      ],
      [
        "End Cashflow Analysis",
        ...taxYear.map(
          (item) => taxFormData[item]["End Cashflow Analysis"] || "$      0"
        ),
        getElementAvrage("End Cashflow Analysis") || "$      0",
        getCurrentAndPriorChanges("End Cashflow Analysis") || "$      0",
      ],
      ["Business Manual Financials Cash Flow Section"],
      ["B A L A N C E   S H E E T "],
      [
        "Cash",
        ...taxYear.map((item) => taxFormData[item]["BS1"] || "$      0"),
        getElementAvrage("BS1") || "$      0",
        getCurrentAndPriorChanges("BS1") || "$      0",
      ],
      [
        "Certificates of Deposits",
        ...taxYear.map((item) => taxFormData[item]["BS2"] || "$      0"),
        getElementAvrage("BS2") || "$      0",
        getCurrentAndPriorChanges("BS2") || "$      0",
      ],
      [
        "A/R trade",
        ...taxYear.map((item) => taxFormData[item]["BS3"] || "$      0"),
        getElementAvrage("BS3") || "$      0",
        getCurrentAndPriorChanges("BS3") || "$      0",
      ],
      [
        "Inventory",
        ...taxYear.map((item) => taxFormData[item]["BS4"] || "$      0"),
        getElementAvrage("BS4") || "$      0",
        getCurrentAndPriorChanges("BS4") || "$      0",
      ],
      [
        "Other current assets",
        ...taxYear.map((item) => taxFormData[item]["BS5"] || "$      0"),
        getElementAvrage("BS5") || "$      0",
        getCurrentAndPriorChanges("BS5") || "$      0",
      ],
      [
        "Total Current Assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Current Assets"] || "$      0"
        ),
        getElementAvrage("Total Current Assets") || "$      0",
        getCurrentAndPriorChanges("Total Current Assets") || "$      0",
      ],
      [
        "Land & Buildings",
        ...taxYear.map((item) => taxFormData[item]["BS6"] || "$      0"),
        getElementAvrage("BS6") || "$      0",
        getCurrentAndPriorChanges("BS6") || "$      0",
      ],
      [
        "Leasehold Improvements",
        ...taxYear.map((item) => taxFormData[item]["BS7"] || "$      0"),
        getElementAvrage("BS7") || "$      0",
        getCurrentAndPriorChanges("BS7") || "$      0",
      ],
      [
        "Equipment",
        ...taxYear.map((item) => taxFormData[item]["BS8"] || "$      0"),
        getElementAvrage("BS8") || "$      0",
        getCurrentAndPriorChanges("BS8") || "$      0",
      ],
      [
        "Fixtures & Furn.",
        ...taxYear.map((item) => taxFormData[item]["BS9"] || "$      0"),
        getElementAvrage("BS9") || "$      0",
        getCurrentAndPriorChanges("BS9") || "$      0",
      ],
      [
        "Gross Fixed assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Gross Fixed assets"] || "$      0"
        ),
        getElementAvrage("Gross Fixed assets") || "$      0",
        getCurrentAndPriorChanges("Gross Fixed assets") || "$      0",
      ],
      [
        "Acc. Depreciation",
        ...taxYear.map((item) => taxFormData[item]["BS10"] || "$      0"),
        getElementAvrage("BS10") || "$      0",
        getCurrentAndPriorChanges("BS10") || "$      0",
      ],
      [
        "Net Fixed Assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Net Fixed Assets"] || "$      0"
        ),
        getElementAvrage("Net Fixed Assets") || "$      0",
        getCurrentAndPriorChanges("Net Fixed Assets") || "$      0",
      ],
      [
        "Prepaids",
        ...taxYear.map((item) => taxFormData[item]["BS11"] || "$      0"),
        getElementAvrage("BS11") || "$      0",
        getCurrentAndPriorChanges("BS11") || "$      0",
      ],
      [
        "N/R - Noncurrent",
        ...taxYear.map((item) => taxFormData[item]["BS12"] || "$      0"),
        getElementAvrage("BS12") || "$      0",
        getCurrentAndPriorChanges("BS12") || "$      0",
      ],
      [
        "L/T Investments",
        ...taxYear.map((item) => taxFormData[item]["BS13"] || "$      0"),
        getElementAvrage("BS13") || "$      0",
        getCurrentAndPriorChanges("BS13") || "$      0",
      ],
      [
        "Due from Affl.,Off.,Stkhld.",
        ...taxYear.map((item) => taxFormData[item]["BS14"] || "$      0"),
        getElementAvrage("BS14") || "$      0",
        getCurrentAndPriorChanges("BS14") || "$      0",
      ],
      [
        "Other Assets",
        ...taxYear.map((item) => taxFormData[item]["BS15"] || "$      0"),
        getElementAvrage("BS15") || "$      0",
        getCurrentAndPriorChanges("BS15") || "$      0",
      ],
      [
        "Intangibles",
        ...taxYear.map((item) => taxFormData[item]["BS16"] || "$      0"),
        getElementAvrage("BS16") || "$      0",
        getCurrentAndPriorChanges("BS16") || "$      0",
      ],
      [
        "TOTAL ASSETS",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL ASSETS"] || "$      0"
        ),
        getElementAvrage("TOTAL ASSETS") || "$      0",
        getCurrentAndPriorChanges("TOTAL ASSETS") || "$      0",
      ],
      [
        "Overdrafts",
        ...taxYear.map((item) => taxFormData[item]["BS17"] || "$      0"),
        getElementAvrage("BS17") || "$      0",
        getCurrentAndPriorChanges("BS17") || "$      0",
      ],
      [
        "Accounts Payable",
        ...taxYear.map((item) => taxFormData[item]["BS18"] || "$      0"),
        getElementAvrage("BS18") || "$      0",
        getCurrentAndPriorChanges("BS18") || "$      0",
      ],
      [
        "Notes Payable",
        ...taxYear.map((item) => taxFormData[item]["BS19"] || "$      0"),
        getElementAvrage("BS19") || "$      0",
        getCurrentAndPriorChanges("BS19") || "$      0",
      ],
      [
        "CPLTD",
        ...taxYear.map((item) => taxFormData[item]["BS20"] || "$      0"),
        getElementAvrage("BS20") || "$      0",
        getCurrentAndPriorChanges("BS20") || "$      0",
      ],
      [
        "Accrued Expenses",
        ...taxYear.map((item) => taxFormData[item]["BS21"] || "$      0"),
        getElementAvrage("BS21") || "$      0",
        getCurrentAndPriorChanges("BS21") || "$      0",
      ],
      [
        "Taxes Payable",
        ...taxYear.map((item) => taxFormData[item]["BS22"] || "$      0"),
        getElementAvrage("BS22") || "$      0",
        getCurrentAndPriorChanges("BS22") || "$      0",
      ],
      [
        "Income Taxes",
        ...taxYear.map((item) => taxFormData[item]["BS23"] || "$      0"),
        getElementAvrage("BS23") || "$      0",
        getCurrentAndPriorChanges("BS23") || "$      0",
      ],
      [
        "Other Current Liabilities",
        ...taxYear.map(
          (item) => taxFormData[item]["Other Current Liabilities"]
        ),
        getElementAvrage("Other Current Liabilities") || "$      0",
        getCurrentAndPriorChanges("Other Current Liabilities") || "$      0",
      ],
      [
        "CURRENT LIABILITIES",
        ...taxYear.map(
          (item) => taxFormData[item]["CURRENT LIABILITIES"] || "$      0"
        ),
        getElementAvrage("CURRENT LIABILITIES") || "$      0",
        getCurrentAndPriorChanges("CURRENT LIABILITIES") || "$      0",
      ],
      [
        "Long Term Debt",
        ...taxYear.map((item) => taxFormData[item]["BS25"] || "$      0"),
        getElementAvrage("BS25") || "$      0",
        getCurrentAndPriorChanges("BS25") || "$      0",
      ],
      [
        "Debt to Stockholders",
        ...taxYear.map((item) => taxFormData[item]["BS26"] || "$      0"),
        getElementAvrage("BS26") || "$      0",
        getCurrentAndPriorChanges("BS26") || "$      0",
      ],
      [
        "Deferred Taxes",
        ...taxYear.map((item) => taxFormData[item]["BS27"] || "$      0"),
        getElementAvrage("BS27") || "$      0",
        getCurrentAndPriorChanges("BS27") || "$      0",
      ],
      [
        "Other Liabilities",
        ...taxYear.map((item) => taxFormData[item]["BS28"] || "$      0"),
        getElementAvrage("BS28") || "$      0",
        getCurrentAndPriorChanges("BS28") || "$      0",
      ],
      [
        "TOTAL LIABILITIES",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL LIABILITIES"] || "$      0"
        ),
        getElementAvrage("TOTAL LIABILITIES") || "$      0",
        getCurrentAndPriorChanges("TOTAL LIABILITIES") || "$      0",
      ],
      [
        "Preferred Stock",
        ...taxYear.map((item) => taxFormData[item]["BS29"] || "$      0"),
        getElementAvrage("BS29") || "$      0",
        getCurrentAndPriorChanges("BS29") || "$      0",
      ],
      [
        "Common Stock",
        ...taxYear.map((item) => taxFormData[item]["BS30"] || "$      0"),
        getElementAvrage("BS30") || "$      0",
        getCurrentAndPriorChanges("BS30") || "$      0",
      ],
      [
        "Paid In Capital",
        ...taxYear.map((item) => taxFormData[item]["BS31"] || "$      0"),
        getElementAvrage("BS31") || "$      0",
        getCurrentAndPriorChanges("BS31") || "$      0",
      ],
      [
        "Treasury Stock",
        ...taxYear.map((item) => taxFormData[item]["BS32"] || "$      0"),
        getElementAvrage("BS32") || "$      0",
        getCurrentAndPriorChanges("BS32") || "$      0",
      ],
      [
        "Retained Earnings",
        ...taxYear.map((item) => taxFormData[item]["BS33"] || "$      0"),
        getElementAvrage("BS33") || "$      0",
        getCurrentAndPriorChanges("BS33") || "$      0",
      ],
      [
        "NET WORTH",
        ...taxYear.map((item) => taxFormData[item]["NET WORTH"] || "$      0"),
        getElementAvrage("NET WORTH") || "$      0",
        getCurrentAndPriorChanges("NET WORTH") || "$      0",
      ],
      [
        "TOTAL LIAB. & NET WORTH",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL LIAB. & NET WORTH"] || "$      0"
        ),
        getElementAvrage("TOTAL LIAB. & NET WORTH") || "$      0",
        getCurrentAndPriorChanges("TOTAL LIAB. & NET WORTH") || "$      0",
      ],
      ["Balancing Line"],
      ["# OF MONTHS"],
      ["I N C O M E   S T A T E M E N T  "],
      [
        "Gross Revenues",
        ...taxYear.map((item) => taxFormData[item]["IS 1"] || "$      0"),
        getElementAvrage("IS 1") || "$      0",
        getCurrentAndPriorChanges("IS 1") || "$      0",
      ],
      [
        "Other Income",
        ...taxYear.map((item) => taxFormData[item]["IS 2"] || "$      0"),
        getElementAvrage("IS 2") || "$      0",
        getCurrentAndPriorChanges("IS 2") || "$      0",
      ],
      [
        "TOTAL REVENUES",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL REVENUES"] || "$      0"
        ),
        getElementAvrage("TOTAL REVENUES") || "$      0",
        getCurrentAndPriorChanges("TOTAL REVENUES") || "$      0",
      ],
      [
        "Direct",
        ...taxYear.map((item) => taxFormData[item]["IS 3"] || "$      0"),
        getElementAvrage("IS 3") || "$      0",
        getCurrentAndPriorChanges("IS 3") || "$      0",
      ],
      [
        "Materials",
        ...taxYear.map((item) => taxFormData[item]["IS 4"] || "$      0"),
        getElementAvrage("IS 4") || "$      0",
        getCurrentAndPriorChanges("IS 4") || "$      0",
      ],
      [
        "COGS - Depreciation",
        ...taxYear.map((item) => taxFormData[item]["IS 5"] || "$      0"),
        getElementAvrage("IS 5") || "$      0",
        getCurrentAndPriorChanges("IS 5") || "$      0",
      ],
      [
        "COGS - Other",
        ...taxYear.map((item) => taxFormData[item]["IS 6"] || "$      0"),
        getElementAvrage("IS 6") || "$      0",
        getCurrentAndPriorChanges("IS 6") || "$      0",
      ],
      [
        "Total Direct Costs",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Direct Costs"] || "$      0"
        ),
        getElementAvrage("Total Direct Costs") || "$      0",
        getCurrentAndPriorChanges("Total Direct Costs") || "$      0",
      ],
      [
        "GROSS PROFIT",
        ...taxYear.map(
          (item) => taxFormData[item]["GROSS PROFIT"] || "$      0"
        ),
        getElementAvrage("GROSS PROFIT") || "$      0",
        getCurrentAndPriorChanges("GROSS PROFIT") || "$      0",
      ],
      [
        "Depreciation Expense",
        ...taxYear.map((item) => taxFormData[item]["IS 7"] || "$      0"),
        getElementAvrage("IS 7") || "$      0",
        getCurrentAndPriorChanges("IS 7") || "$      0",
      ],
      [
        "Rent",
        ...taxYear.map((item) => taxFormData[item]["IS 8"] || "$      0"),
        getElementAvrage("IS 8") || "$      0",
        getCurrentAndPriorChanges("IS 8") || "$      0",
      ],
      [
        "Other Salaries",
        ...taxYear.map((item) => taxFormData[item]["IS 9"] || "$      0"),
        getElementAvrage("IS 9") || "$      0",
        getCurrentAndPriorChanges("IS 9") || "$      0",
      ],
      [
        "Officer's Comp",
        ...taxYear.map((item) => taxFormData[item]["IS 10"] || "$      0"),
        getElementAvrage("IS 10") || "$      0",
        getCurrentAndPriorChanges("IS 10") || "$      0",
      ],
      [
        "Repairs",
        ...taxYear.map((item) => taxFormData[item]["IS 11"] || "$      0"),
        getElementAvrage("IS 11") || "$      0",
        getCurrentAndPriorChanges("IS 11") || "$      0",
      ],
      [
        "Taxes",
        ...taxYear.map((item) => taxFormData[item]["IS 12"] || "$      0"),
        getElementAvrage("IS 12") || "$      0",
        getCurrentAndPriorChanges("IS 12") || "$      0",
      ],
      [
        "Employee benefits",
        ...taxYear.map((item) => taxFormData[item]["IS 13"] || "$      0"),
        getElementAvrage("IS 13") || "$      0",
        getCurrentAndPriorChanges("IS 13") || "$      0",
      ],
      [
        "Advertisin",
        ...taxYear.map((item) => taxFormData[item]["IS14"] || "$      0"),
        getElementAvrage("IS14") || "$      0",
        getCurrentAndPriorChanges("IS14") || "$      0",
      ],
      [
        "Insurance Expense",
        ...taxYear.map((item) => taxFormData[item]["IS15"] || "$      0"),
        getElementAvrage("IS15") || "$      0",
        getCurrentAndPriorChanges("IS15") || "$      0",
      ],
      [
        "Utilities",
        ...taxYear.map((item) => taxFormData[item]["IS16"] || "$      0"),
        getElementAvrage("IS16") || "$      0",
        getCurrentAndPriorChanges("IS16") || "$      0",
      ],
      [
        "Operating Expenses",
        ...taxYear.map((item) => taxFormData[item]["IS17"] || "$      0"),
        getElementAvrage("IS17") || "$      0",
        getCurrentAndPriorChanges("IS17") || "$      0",
      ],
      [
        "Total Operating Expenses",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Operating Expenses"] || "$      0"
        ),
        getElementAvrage("Total Operating Expenses") || "$      0",
        getCurrentAndPriorChanges("Total Operating Expenses") || "$      0",
      ],
      [
        "OPERATING NET INCOME",
        ...taxYear.map(
          (item) => taxFormData[item]["OPERATING NET INCOME"] || "$      0"
        ),
        getElementAvrage("OPERATING NET INCOME") || "$      0",
        getCurrentAndPriorChanges("OPERATING NET INCOME") || "$      0",
      ],
      [
        "Interest Expense",
        ...taxYear.map((item) => taxFormData[item]["IS18"] || "$      0"),
        getElementAvrage("IS18") || "$      0",
        getCurrentAndPriorChanges("IS18") || "$      0",
      ],
      [
        "Other Income",
        ...taxYear.map((item) => taxFormData[item]["IS19"] || "$      0"),
        getElementAvrage("IS19") || "$      0",
        getCurrentAndPriorChanges("IS19") || "$      0",
      ],
      [
        "Other Expense",
        ...taxYear.map((item) => taxFormData[item]["IS20"] || "$      0"),
        getElementAvrage("IS20") || "$      0",
        getCurrentAndPriorChanges("IS20") || "$      0",
      ],
      [
        "Unconsolidated Subsidiary",
        ...taxYear.map((item) => taxFormData[item]["IS21"] || "$      0"),
        getElementAvrage("IS21") || "$      0",
        getCurrentAndPriorChanges("IS21") || "$      0",
      ],
      [
        "INCOME BEFORE TAXES",
        ...taxYear.map(
          (item) => taxFormData[item]["INCOME BEFORE TAXES"] || "$      0"
        ),
        getElementAvrage("INCOME BEFORE TAXES") || "$      0",
        getCurrentAndPriorChanges("INCOME BEFORE TAXES") || "$      0",
      ],
      [
        "Current Taxes",
        ...taxYear.map((item) => taxFormData[item]["IS22"] || "$      0"),
        getElementAvrage("IS22") || "$      0",
        getCurrentAndPriorChanges("IS22") || "$      0",
      ],
      [
        "Deferred Taxes",
        ...taxYear.map((item) => taxFormData[item]["IS23"] || "$      0"),
        getElementAvrage("IS23") || "$      0",
        getCurrentAndPriorChanges("IS23") || "$      0",
      ],
      [
        "Cumu. Effect of Chng in Acct. Prin.",
        ...taxYear.map((item) => taxFormData[item]["IS24"] || "$      0"),
        getElementAvrage("IS24") || "$      0",
        getCurrentAndPriorChanges("IS24") || "$      0",
      ],
      [
        "NET INCOME",
        ...taxYear.map((item) => taxFormData[item]["NET INCOME"] || "$      0"),
        getElementAvrage("NET INCOME") || "$      0",
        getCurrentAndPriorChanges("NET INCOME") || "$      0",
      ],
      ["RECONCILIATION OF NET WORTH "],
      [
        "Beginning Net Worth",
        ...taxYear.map((item) => taxFormData[item]["IS25"] || "$      0"),
        getElementAvrage("IS25") || "$      0",
        getCurrentAndPriorChanges("IS25") || "$      0",
      ],
      [
        "Profitability/(loss)",
        ...taxYear.map(
          (item) => taxFormData[item]["Profitability/(loss)"] || "$      0"
        ),
        getElementAvrage("Profitability/(loss)") || "$      0",
        getCurrentAndPriorChanges("Profitability/(loss)") || "$      0",
      ],
      [
        "Dividends (disbursements)",
        ...taxYear.map((item) => taxFormData[item]["IS26"] || "$      0"),
        getElementAvrage("IS26") || "$      0",
        getCurrentAndPriorChanges("IS26") || "$      0",
      ],
      [
        "Other",
        ...taxYear.map((item) => taxFormData[item]["IS27"] || "$      0"),
        getElementAvrage("IS27") || "$      0",
        getCurrentAndPriorChanges("IS27") || "$      0",
      ],
      [
        "Adjustment Required ",
        ...taxYear.map((item) => taxFormData[item]["IS28"] || "$      0"),
        getElementAvrage("IS28") || "$      0",
        getCurrentAndPriorChanges("IS28") || "$      0",
      ],
      [
        "Ending Net Worth",
        ...taxYear.map(
          (item) => taxFormData[item]["Ending Net Worth"] || "$      0"
        ),
        getElementAvrage("Ending Net Worth") || "$      0",
        getCurrentAndPriorChanges("Ending Net Worth") || "$      0",
      ],
      ["Balancing line"],
      [" C A S H F L O W   A N A L Y S I S /  Sources/(Uses)  "],
      [
        "Sales - Net",
        ...taxYear.map(
          (item) => taxFormData[item]["Sales - Net"] || "$      0"
        ),
        getElementAvrage("Sales - Net") || "$      0",
        getCurrentAndPriorChanges("Sales - Net") || "$      0",
      ],
      [
        "Change in A/R",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in A/R"] || "$      0"
        ),
        getElementAvrage("Change in A/R") || "$      0",
        getCurrentAndPriorChanges("Change in A/R") || "$      0",
      ],
      [
        "CASH FROM SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH FROM SALES"] || "$      0"
        ),
        getElementAvrage("CASH FROM SALES") || "$      0",
        getCurrentAndPriorChanges("CASH FROM SALES") || "$      0",
      ],
      [
        "Cost of Goods Sold",
        ...taxYear.map(
          (item) => taxFormData[item]["Cost of Goods Sold"] || "$      0"
        ),
        getElementAvrage("Cost of Goods Sold") || "$      0",
        getCurrentAndPriorChanges("Cost of Goods Sold") || "$      0",
      ],
      [
        "Change in inventories",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in inventories"] || "$      0"
        ),
        getElementAvrage("Change in inventories") || "$      0",
        getCurrentAndPriorChanges("Change in inventories") || "$      0",
      ],
      [
        "Change in Payables",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in Payables"] || "$      0"
        ),
        getElementAvrage("Change in Payables") || "$      0",
        getCurrentAndPriorChanges("Change in Payables") || "$      0",
      ],
      [
        "CASH PRODUCTION COST",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH PRODUCTION COST"] || "$      0"
        ),
        getElementAvrage("CASH PRODUCTION COST") || "$      0",
        getCurrentAndPriorChanges("CASH PRODUCTION COST") || "$      0",
      ],
      [
        "GROSS CASH MARGIN",
        ...taxYear.map(
          (item) => taxFormData[item]["GROSS CASH MARGIN"] || "$      0"
        ),
        getElementAvrage("GROSS CASH MARGIN") || "$      0",
        getCurrentAndPriorChanges("GROSS CASH MARGIN") || "$      0",
      ],
      [
        "SG & A Expenses",
        ...taxYear.map(
          (item) => taxFormData[item]["SG & A Expenses"] || "$      0"
        ),
        getElementAvrage("SG & A Expenses") || "$      0",
        getCurrentAndPriorChanges("SG & A Expenses") || "$      0",
      ],
      [
        "Change In Prepaids",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Prepaids"] || "$      0"
        ),
        getElementAvrage("Change In Prepaids") || "$      0",
        getCurrentAndPriorChanges("Change In Prepaids") || "$      0",
      ],
      [
        "Change In Other Taxes",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Other Taxes"] || "$      0"
        ),
        getElementAvrage("Change In Other Taxes") || "$      0",
        getCurrentAndPriorChanges("Change In Other Taxes") || "$      0",
      ],
      [
        "Change In Accruals",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Accruals"] || "$      0"
        ),
        getElementAvrage("Change In Accruals") || "$      0",
        getCurrentAndPriorChanges("Change In Accruals") || "$      0",
      ],
      [
        "CASH OPERATING EXPENSE",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH OPERATING EXPENSE"] || "$      0"
        ),
        getElementAvrage("CASH OPERATING EXPENSE") || "$      0",
        getCurrentAndPriorChanges("CASH OPERATING EXPENSE") || "$      0",
      ],
      [
        "CASH AFTER OPERATIONS",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH AFTER OPERATIONS"] || "$      0"
        ),
        getElementAvrage("CASH AFTER OPERATIONS") || "$      0",
        getCurrentAndPriorChanges("CASH AFTER OPERATIONS") || "$      0",
      ],
      [
        "Other Income",
        ...taxYear.map(
          (item) => taxFormData[item]["Other Income"] || "$      0"
        ),
        getElementAvrage("Other Income") || "$      0",
        getCurrentAndPriorChanges("Other Income") || "$      0",
      ],
      [
        "Other Expenses",
        ...taxYear.map(
          (item) => taxFormData[item]["Other Expenses"] || "$      0"
        ),
        getElementAvrage("Other Expenses") || "$      0",
        getCurrentAndPriorChanges("Other Expenses") || "$      0",
      ],
      [
        "Income Taxes Paid",
        ...taxYear.map(
          (item) => taxFormData[item]["Income Taxes Paid"] || "$      0"
        ),
        getElementAvrage("Income Taxes Paid") || "$      0",
        getCurrentAndPriorChanges("Income Taxes Paid") || "$      0",
      ],
      [
        "Change in N/R",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in N/R"] || "$      0"
        ),
        getElementAvrage("Change in N/R") || "$      0",
        getCurrentAndPriorChanges("Change in N/R") || "$      0",
      ],
      [
        "Change in Misc. Assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in Misc. Assets"] || "$      0"
        ),
        getElementAvrage("Change in Misc. Assets") || "$      0",
        getCurrentAndPriorChanges("Change in Misc. Assets") || "$      0",
      ],
      [
        "Change in Misc. Liabilities",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in Misc. Liabilities"]
        ),
        getElementAvrage("Change in Misc. Liabilities") || "$      0",
        getCurrentAndPriorChanges("Change in Misc. Liabilities") || "$      0",
      ],
      [
        "NET CASH AFTER OPERATIONS",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH AFTER OPERATIONS"]
        ),
        getElementAvrage("NET CASH AFTER OPERATIONS") || "$      0",
        getCurrentAndPriorChanges("NET CASH AFTER OPERATIONS") || "$      0",
      ],
      [
        "CPLTD",
        ...taxYear.map((item) => taxFormData[item]["CPLTD"] || "$      0"),
        getElementAvrage("CPLTD") || "$      0",
        getCurrentAndPriorChanges("CPLTD") || "$      0",
      ],
      [
        "Interest Expense",
        ...taxYear.map(
          (item) => taxFormData[item]["Interest Expense"] || "$      0"
        ),
        getElementAvrage("Interest Expense") || "$      0",
        getCurrentAndPriorChanges("Interest Expense") || "$      0",
      ],
      [
        "Dividends Paid",
        ...taxYear.map(
          (item) => taxFormData[item]["Dividends Paid"] || "$      0"
        ),
        getElementAvrage("Dividends Paid") || "$      0",
        getCurrentAndPriorChanges("Dividends Paid") || "$      0",
      ],
      [
        "DEBT AMORTIZATION & FIN. COSTS",
        ...taxYear.map(
          (item) => taxFormData[item]["DEBT AMORTIZATION & FIN. COSTS"]
        ),
        getElementAvrage("DEBT AMORTIZATION & FIN. COSTS") || "$      0",
        getCurrentAndPriorChanges("DEBT AMORTIZATION & FIN. COSTS") ||
          "$      0",
      ],
      [
        "NET CASH INCOME AFTER FIN. COST",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH INCOME AFTER FIN. COST"]
        ),
        getElementAvrage("NET CASH INCOME AFTER FIN. COST") || "$      0",
        getCurrentAndPriorChanges("NET CASH INCOME AFTER FIN. COST") ||
          "$      0",
      ],
      [
        "Capital Expenditures",
        ...taxYear.map(
          (item) => taxFormData[item]["Capital Expenditures"] || "$      0"
        ),
        getElementAvrage("Capital Expenditures") || "$      0",
        getCurrentAndPriorChanges("Capital Expenditures") || "$      0",
      ],
      [
        "Change in L/T Investments",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in L/T Investments"]
        ),
        getElementAvrage("Change in L/T Investments") || "$      0",
        getCurrentAndPriorChanges("Change in L/T Investments") || "$      0",
      ],
      [
        "FINANCING SURPLUS/",
        ...taxYear.map(
          (item) => taxFormData[item]["FINANCING SURPLUS/"] || "$      0"
        ),
        getElementAvrage("FINANCING SURPLUS/") || "$      0",
        getCurrentAndPriorChanges("FINANCING SURPLUS/") || "$      0",
      ],
      [
        "REQUIREMENTS",
        ...taxYear.map(
          (item) => taxFormData[item]["REQUIREMENTS"] || "$      0"
        ),
        getElementAvrage("REQUIREMENTS") || "$      0",
        getCurrentAndPriorChanges("REQUIREMENTS") || "$      0",
      ],
      [
        "Change In Overdraft",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Overdraft"] || "$      0"
        ),
        getElementAvrage("Change In Overdraft") || "$      0",
        getCurrentAndPriorChanges("Change In Overdraft") || "$      0",
      ],
      [
        "Change In Short-term Debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Short-term Debt"]
        ),
        getElementAvrage("Change In Short-term Debt") || "$      0",
        getCurrentAndPriorChanges("Change In Short-term Debt") || "$      0",
      ],
      [
        "Change in Long-Term debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in Long-Term debt"] || "$      0"
        ),
        getElementAvrage("Change in Long-Term debt") || "$      0",
        getCurrentAndPriorChanges("Change in Long-Term debt") || "$      0",
      ],
      [
        "Change In Equity",
        ...taxYear.map(
          (item) => taxFormData[item]["Change In Equity"] || "$      0"
        ),
        getElementAvrage("Change In Equity") || "$      0",
        getCurrentAndPriorChanges("Change In Equity") || "$      0",
      ],
      [
        "TOTAL EXTERNAL FINANCING",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL EXTERNAL FINANCING"] || "$      0"
        ),
        getElementAvrage("TOTAL EXTERNAL FINANCING") || "$      0",
        getCurrentAndPriorChanges("TOTAL EXTERNAL FINANCING") || "$      0",
      ],
      [
        "CASH AFTER FINANCING",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH AFTER FINANCING"] || "$      0"
        ),
        getElementAvrage("CASH AFTER FINANCING") || "$      0",
        getCurrentAndPriorChanges("CASH AFTER FINANCING") || "$      0",
      ],
      [
        "ACTUAL CHANGE IN CASH",
        ...taxYear.map(
          (item) => taxFormData[item]["ACTUAL CHANGE IN CASH"] || "$      0"
        ),
        getElementAvrage("ACTUAL CHANGE IN CASH") || "$      0",
        getCurrentAndPriorChanges("ACTUAL CHANGE IN CASH") || "$      0",
      ],
      [
        "TRADITIONAL CASH FLOW",
        ...taxYear.map(
          (item) => taxFormData[item]["TRADITIONAL CASH FLOW"] || "$      0"
        ),
        getElementAvrage("TRADITIONAL CASH FLOW") || "$      0",
        getCurrentAndPriorChanges("TRADITIONAL CASH FLOW") || "$      0",
      ],
      ["STATEMENT DATE"],
      ["R A T I O S"],
      [
        "ANNUALIZING FACTOR",
        ...taxYear.map(
          (item) => taxFormData[item]["ANNUALIZING FACTOR"] || "$      0"
        ),
        getElementAvrage("ANNUALIZING FACTOR") || "$      0",
        getCurrentAndPriorChanges("ANNUALIZING FACTOR") || "$      0",
      ],
      [
        "ANNUALIZED SALES GROWTH",
        ...taxYear.map(
          (item) => taxFormData[item]["ANNUALIZED SALES GROWTH"] || "$      0"
        ),
        getElementAvrage("ANNUALIZED SALES GROWTH") || "$      0",
        getCurrentAndPriorChanges("ANNUALIZED SALES GROWTH") || "$      0",
      ],
      ["LIQUIDITY:============================"],
      [
        "WORKING CAPITAL",
        ...taxYear.map(
          (item) => taxFormData[item]["WORKING CAPITAL"] || "$      0"
        ),
        getElementAvrage("WORKING CAPITAL") || "$      0",
        getCurrentAndPriorChanges("WORKING CAPITAL") || "$      0",
      ],
      [
        "CURRENT RATIO",
        ...taxYear.map((item) =>
          (taxFormData[item]["CURRENT RATIO"] || "$      0").replace("$", "")
        ),
        (getElementAvrage("CURRENT RATIO") || "$      0").replace("$", ""),
        (getCurrentAndPriorChanges("CURRENT RATIO") || "$      0").replace(
          "$",
          ""
        ),
      ],
      [
        "QUICK RATIO",
        ...taxYear.map((item) =>
          (taxFormData[item]["QUICK RATIO"] || "$      0").replace("$", "")
        ),
        (getElementAvrage("QUICK RATIO") || "$      0").replace("$", ""),
        (getCurrentAndPriorChanges("QUICK RATIO") || "$      0").replace(
          "$",
          ""
        ),
      ],
      [
        "AGE OF RECEIVABLES - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["AGE OF RECEIVABLES - DAYS"]
        ),
        getElementAvrage("AGE OF RECEIVABLES - DAYS") || "$      0",
        getCurrentAndPriorChanges("AGE OF RECEIVABLES - DAYS") || "$      0",
      ],
      [
        "INVENTORY TURNOVER - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["INVENTORY TURNOVER - DAYS"]
        ),
        getElementAvrage("INVENTORY TURNOVER - DAYS") || "$      0",
        getCurrentAndPriorChanges("INVENTORY TURNOVER - DAYS") || "$      0",
      ],
      [
        "AGE OF PAYABLES - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["AGE OF PAYABLES - DAYS"] || "$      0"
        ),
        getElementAvrage("AGE OF PAYABLES - DAYS") || "$      0",
        getCurrentAndPriorChanges("AGE OF PAYABLES - DAYS") || "$      0",
      ],
      ["COVERAGE:==========================="],
      [
        "INTEREST COVERAGE",
        ...taxYear.map(
          (item) => taxFormData[item]["INTEREST COVERAGE"] || "$      0"
        ),
        getElementAvrage("INTEREST COVERAGE") || "$      0",
        getCurrentAndPriorChanges("INTEREST COVERAGE") || "$      0",
      ],
      [
        "NET CASH AFTER OPER./CPLTD",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH AFTER OPER./CPLTD"]
        ),
        getElementAvrage("NET CASH AFTER OPER./CPLTD") || "$      0",
        getCurrentAndPriorChanges("NET CASH AFTER OPER./CPLTD") || "$      0",
      ],
      [
        "TRADITIONAL CASH FLOW/CPLTD",
        ...taxYear.map(
          (item) => taxFormData[item]["TRADITIONAL CASH FLOW/CPLTD"]
        ),
        getElementAvrage("TRADITIONAL CASH FLOW/CPLTD") || "$      0",
        getCurrentAndPriorChanges("TRADITIONAL CASH FLOW/CPLTD") || "$      0",
      ],
      ["LEVERAGE:============================="],
      [
        "DEBT/TNW",
        ...taxYear.map((item) => taxFormData[item]["DEBT/TNW"] || "$      0"),
        getElementAvrage("DEBT/TNW") || "$      0",
        getCurrentAndPriorChanges("DEBT/TNW") || "$      0",
      ],
      [
        "CREDITORS EQUITY/TOTAL ASSETS",
        ...taxYear.map(
          (item) => taxFormData[item]["CREDITORS EQUITY/TOTAL ASSETS"]
        ),
        getElementAvrage("CREDITORS EQUITY/TOTAL ASSETS") || "$      0",
        getCurrentAndPriorChanges("CREDITORS EQUITY/TOTAL ASSETS") ||
          "$      0",
      ],
      [
        "OWNERS EQUITY/TOTAL ASSETS",
        ...taxYear.map(
          (item) => taxFormData[item]["OWNERS EQUITY/TOTAL ASSETS"]
        ),
        getElementAvrage("OWNERS EQUITY/TOTAL ASSETS") || "$      0",
        getCurrentAndPriorChanges("OWNERS EQUITY/TOTAL ASSETS") || "$      0",
      ],
      [
        "NET FIXED ASSETS/LTD",
        ...taxYear.map(
          (item) => taxFormData[item]["NET FIXED ASSETS/LTD"] || "$      0"
        ),
        getElementAvrage("NET FIXED ASSETS/LTD") || "$      0",
        getCurrentAndPriorChanges("NET FIXED ASSETS/LTD") || "$      0",
      ],
      ["PROFITABILITY:======================="],
      [
        "GROSS PROFIT MARGIN %",
        ...taxYear.map((item) =>
          (taxFormData[item]["GROSS PROFIT MARGIN %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("GROSS PROFIT MARGIN %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("GROSS PROFIT MARGIN %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "PRE-TAX PROFIT MARGIN %",
        ...taxYear.map((item) =>
          (taxFormData[item]["PRE-TAX PROFIT MARGIN %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("PRE-TAX PROFIT MARGIN %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("PRE-TAX PROFIT MARGIN %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "NET PROFIT AFTER TAXES %",
        ...taxYear.map((item) =>
          (taxFormData[item]["NET PROFIT AFTER TAXES %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("NET PROFIT AFTER TAXES %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("NET PROFIT AFTER TAXES %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "RETURN ON EQUITY (ROE) %",
        ...taxYear.map((item) =>
          (taxFormData[item]["RETURN ON EQUITY (ROE) %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("RETURN ON EQUITY (ROE) %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("RETURN ON EQUITY (ROE) %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "RETURN ON ASSETS (ROA) %",
        ...taxYear.map((item) =>
          (taxFormData[item]["RETURN ON ASSETS (ROA) %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("RETURN ON ASSETS (ROA) %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("RETURN ON ASSETS (ROA) %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "BANKRUPTCY RATIO: Z SCORE",
        ...taxYear.map(
          (item) => taxFormData[item]["BANKRUPTCY RATIO: Z SCORE"]
        ),
        getElementAvrage("Adjustments") || "$      0",
        getCurrentAndPriorChanges("Adjustments") || "$      0",
      ],
      [
        "Z < 1.80 WEAK COMPANY",
        ...taxYear.map(
          (item) => taxFormData[item]["Z < 1.80 WEAK COMPANY"] || "$      0"
        ),
        getElementAvrage("Z < 1.80 WEAK COMPANY") || "$      0",
        getCurrentAndPriorChanges("Z < 1.80 WEAK COMPANY") || "$      0",
      ],
      [
        "Z > 2.70 STRONG COMPANY",
        ...taxYear.map(
          (item) => taxFormData[item]["Z > 2.70 STRONG COMPANY"] || "$      0"
        ),
        getElementAvrage("Z > 2.70 STRONG COMPANY") || "$      0",
        getCurrentAndPriorChanges("Z > 2.70 STRONG COMPANY") || "$      0",
      ],
      ["OPERATING CAPITAL REQUIREMENTS======="],
      [
        "CASH / AVG DAYS SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH / AVG DAYS SALES"] || "$      0"
        ),
        getElementAvrage("CASH / AVG DAYS SALES") || "$      0",
        getCurrentAndPriorChanges("CASH / AVG DAYS SALES") || "$      0",
      ],
      [
        "RECEIVABLES / AVG DAYS SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["RECEIVABLES / AVG DAYS SALES"]
        ),
        getElementAvrage("RECEIVABLES / AVG DAYS SALES") || "$      0",
        getCurrentAndPriorChanges("RECEIVABLES / AVG DAYS SALES") || "$      0",
      ],
      [
        "INVENTORY / AVG DAYS SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["INVENTORY / AVG DAYS SALES"]
        ),
        getElementAvrage("INVENTORY / AVG DAYS SALES") || "$      0",
        getCurrentAndPriorChanges("INVENTORY / AVG DAYS SALES") || "$      0",
      ],
      [
        "TOTAL TRADING CYCLE",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL TRADING CYCLE"] || "$      0"
        ),
        getElementAvrage("TOTAL TRADING CYCLE") || "$      0",
        getCurrentAndPriorChanges("TOTAL TRADING CYCLE") || "$      0",
      ],
      [
        "PAYABLES / AVG DAYS SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["PAYABLES / AVG DAYS SALES"]
        ),
        getElementAvrage("PAYABLES / AVG DAYS SALES") || "$      0",
        getCurrentAndPriorChanges("PAYABLES / AVG DAYS SALES") || "$      0",
      ],
      [
        "NET CASH CYCLE",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH CYCLE"] || "$      0"
        ),
        getElementAvrage("NET CASH CYCLE") || "$      0",
        getCurrentAndPriorChanges("NET CASH CYCLE") || "$      0",
      ],
      [
        "OPERATING CAPITAL REQUIRED",
        ...taxYear.map(
          (item) => taxFormData[item]["OPERATING CAPITAL REQUIRED"]
        ),
        getElementAvrage("OPERATING CAPITAL REQUIRED") || "$      0",
        getCurrentAndPriorChanges("OPERATING CAPITAL REQUIRED") || "$      0",
      ],
      ["FINANCIAL STATEMENTS ON:"],
      ["TYPE OF REPORT"],
      ["STATEMENT DATE"],
      ["# OF MONTHS"],
      ["H I G H  L I G H T S "],
      ["INCOME STATEMENT:"],
      [
        "ANNUALIZED SALES GROWTH",
        ...taxYear.map(
          (item) =>
            taxFormData[item]["ANNUALIZED SALES GROWTH _743"] || "$      0"
        ),
        getElementAvrage("ANNUALIZED SALES GROWTH _743") || "$      0",
        getCurrentAndPriorChanges("ANNUALIZED SALES GROWTH _743") || "$      0",
      ],
      [
        "Gross Revenues",
        ...taxYear.map(
          (item) => taxFormData[item]["Gross Revenues"] || "$      0"
        ),
        getElementAvrage("Gross Revenues") || "$      0",
        getCurrentAndPriorChanges("Gross Revenues") || "$      0",
      ],
      [
        "GROSS PROFIT",
        ...taxYear.map(
          (item) => taxFormData[item]["GROSS PROFIT"] || "$      0"
        ),
        getElementAvrage("GROSS PROFIT") || "$      0",
        getCurrentAndPriorChanges("GROSS PROFIT") || "$      0",
      ],
      [
        "Total Operating Expenses",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Operating Expenses"] || "$      0"
        ),
        getElementAvrage("Total Operating Expenses") || "$      0",
        getCurrentAndPriorChanges("Total Operating Expenses") || "$      0",
      ],
      [
        "INCOME BEFORE TAXES",
        ...taxYear.map(
          (item) => taxFormData[item]["INCOME BEFORE TAXES"] || "$      0"
        ),
        getElementAvrage("INCOME BEFORE TAXES") || "$      0",
        getCurrentAndPriorChanges("INCOME BEFORE TAXES") || "$      0",
      ],
      [
        "NET INCOME",
        ...taxYear.map((item) => taxFormData[item]["NET INCOME"] || "$      0"),
        getElementAvrage("NET INCOME") || "$      0",
        getCurrentAndPriorChanges("NET INCOME") || "$      0",
      ],
      [
        "CASH DIVIDENDS",
        ...taxYear.map((item) => taxFormData[item]["IS26"] || "$      0"),
        getElementAvrage("IS26") || "$      0",
        getCurrentAndPriorChanges("IS26") || "$      0",
      ],
      [
        "GROSS PROFIT MARGIN %",
        ...taxYear.map((item) =>
          (taxFormData[item]["GROSS PROFIT MARGIN %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("GROSS PROFIT MARGIN %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("GROSS PROFIT MARGIN %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "RETURN ON EQUITY (ROE) %",
        ...taxYear.map((item) =>
          (taxFormData[item]["RETURN ON EQUITY (ROE) %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("RETURN ON EQUITY (ROE) %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("RETURN ON EQUITY (ROE) %") || "$      0"
        ).replace("$", ""),
      ],
      [
        "RETURN ON ASSETS (ROA) %",
        ...taxYear.map((item) =>
          (taxFormData[item]["RETURN ON ASSETS (ROA) %"] || "$      0").replace(
            "$",
            ""
          )
        ),
        (getElementAvrage("RETURN ON ASSETS (ROA) %") || "$      0").replace(
          "$",
          ""
        ),
        (
          getCurrentAndPriorChanges("RETURN ON ASSETS (ROA) %") || "$      0"
        ).replace("$", ""),
      ],
      ["BALANCE SHEET:"],
      [
        "Cash",
        ...taxYear.map((item) => taxFormData[item]["Cash"] || "$      0"),
        getElementAvrage("Cash") || "$      0",
        getCurrentAndPriorChanges("Cash") || "$      0",
      ],
      [
        "Current Assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Current Assets"] || "$      0"
        ),
        getElementAvrage("Current Assets") || "$      0",
        getCurrentAndPriorChanges("Current Assets") || "$      0",
      ],
      [
        "Net Fixed Assets",
        ...taxYear.map(
          (item) => taxFormData[item]["Net Fixed Assets"] || "$      0"
        ),
        getElementAvrage("Net Fixed Assets") || "$      0",
        getCurrentAndPriorChanges("Net Fixed Assets") || "$      0",
      ],
      [
        "TOTAL ASSETS",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL ASSETS"] || "$      0"
        ),
        getElementAvrage("TOTAL ASSETS") || "$      0",
        getCurrentAndPriorChanges("TOTAL ASSETS") || "$      0",
      ],
      [
        "Short-term Debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Short-term Debt"] || "$      0"
        ),
        getElementAvrage("Short-term Debt") || "$      0",
        getCurrentAndPriorChanges("Short-term Debt") || "$      0",
      ],
      [
        "Total Current Liabilities",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Current Liabilities"]
        ),
        getElementAvrage("Total Current Liabilities") || "$      0",
        getCurrentAndPriorChanges("Total Current Liabilities") || "$      0",
      ],
      [
        "Total Long-term Debt",
        ...taxYear.map(
          (item) => taxFormData[item]["Total Long-term Debt"] || "$      0"
        ),
        getElementAvrage("Total Long-term Debt") || "$      0",
        getCurrentAndPriorChanges("Total Long-term Debt") || "$      0",
      ],
      [
        "TOTAL LIABILITIES",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL LIABILITIES"] || "$      0"
        ),
        getElementAvrage("TOTAL LIABILITIES") || "$      0",
        getCurrentAndPriorChanges("TOTAL LIABILITIES") || "$      0",
      ],
      [
        "NET WORTH",
        ...taxYear.map((item) => taxFormData[item]["NET WORTH"] || "$      0"),
        getElementAvrage("NET WORTH") || "$      0",
        getCurrentAndPriorChanges("NET WORTH") || "$      0",
      ],
      ["RATIOS:"],
      [
        "CURRENT RATIO",
        ...taxYear.map((item) =>
          (taxFormData[item]["CURRENT RATIO"] || "$      0").replace("$", "")
        ),
        (getElementAvrage("CURRENT RATIO") || "$      0").replace("$", ""),
        (getCurrentAndPriorChanges("CURRENT RATIO") || "$      0").replace(
          "$",
          ""
        ),
      ],
      [
        "QUICK RATIO",
        ...taxYear.map((item) =>
          (taxFormData[item]["QUICK RATIO"] || "$      0").replace("$", "")
        ),
        (getElementAvrage("QUICK RATIO") || "$      0").replace("$", ""),
        (getCurrentAndPriorChanges("QUICK RATIO") || "$      0").replace(
          "$",
          ""
        ),
      ],
      [
        "WORKING CAPITAL",
        ...taxYear.map(
          (item) => taxFormData[item]["WORKING CAPITAL"] || "$      0"
        ),
        getElementAvrage("WORKING CAPITAL") || "$      0",
        getCurrentAndPriorChanges("WORKING CAPITAL") || "$      0",
      ],
      [
        "AGE OF RECEIVABLES - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["AGE OF RECEIVABLES - DAYS"]
        ),
        getElementAvrage("AGE OF RECEIVABLES - DAYS") || "$      0",
        getCurrentAndPriorChanges("AGE OF RECEIVABLES - DAYS") || "$      0",
      ],
      [
        "INVENTORY TURNOVER - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["INVENTORY TURNOVER - DAYS"]
        ),
        getElementAvrage("INVENTORY TURNOVER - DAYS") || "$      0",
        getCurrentAndPriorChanges("INVENTORY TURNOVER - DAYS") || "$      0",
      ],
      [
        "AGE OF PAYABLES - DAYS",
        ...taxYear.map(
          (item) => taxFormData[item]["AGE OF PAYABLES - DAYS"] || "$      0"
        ),
        getElementAvrage("AGE OF PAYABLES - DAYS") || "$      0",
        getCurrentAndPriorChanges("AGE OF PAYABLES - DAYS") || "$      0",
      ],
      [
        "DEBT/TNW",
        ...taxYear.map((item) => taxFormData[item]["DEBT/TNW"] || "$      0"),
        getElementAvrage("DEBT/TNW") || "$      0",
        getCurrentAndPriorChanges("DEBT/TNW") || "$      0",
      ],
      ["CASH FLOW: Incr. (Decr.) in Cash"],
      [
        "CASH FROM SALES",
        ...taxYear.map(
          (item) => taxFormData[item]["CASH FROM SALES"] || "$      0"
        ),
        getElementAvrage("CASH FROM SALES") || "$      0",
        getCurrentAndPriorChanges("CASH FROM SALES") || "$      0",
      ],
      [
        "GROSS CASH MARGIN",
        ...taxYear.map(
          (item) => taxFormData[item]["GROSS CASH MARGIN"] || "$      0"
        ),
        getElementAvrage("GROSS CASH MARGIN") || "$      0",
        getCurrentAndPriorChanges("GROSS CASH MARGIN") || "$      0",
      ],
      [
        "NET CASH AFTER OPERATIONS",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH AFTER OPERATIONS"]
        ),
        getElementAvrage("NET CASH AFTER OPERATIONS") || "$      0",
        getCurrentAndPriorChanges("NET CASH AFTER OPERATIONS") || "$      0",
      ],
      [
        "NET CASH INCOME AFTER FIN. COST",
        ...taxYear.map(
          (item) => taxFormData[item]["NET CASH INCOME AFTER FIN. COST"]
        ),
        getElementAvrage("NET CASH INCOME AFTER FIN. COST") || "$      0",
        getCurrentAndPriorChanges("NET CASH INCOME AFTER FIN. COST") ||
          "$      0",
      ],
      [
        "Capital Expenditures",
        ...taxYear.map(
          (item) => taxFormData[item]["Capital Expenditures"] || "$      0"
        ),
        getElementAvrage("Capital Expenditures") || "$      0",
        getCurrentAndPriorChanges("Capital Expenditures") || "$      0",
      ],
      [
        "Change in L/T Investments",
        ...taxYear.map(
          (item) => taxFormData[item]["Change in L/T Investments"]
        ),
        getElementAvrage("Change in L/T Investments") || "$      0",
        getCurrentAndPriorChanges("Change in L/T Investments") || "$      0",
      ],
      [
        "FINANCING SURPLUS/",
        ...taxYear.map(
          (item) => taxFormData[item]["FINANCING SURPLUS/"] || "$      0"
        ),
        getElementAvrage("FINANCING SURPLUS/") || "$      0",
        getCurrentAndPriorChanges("FINANCING SURPLUS/") || "$      0",
      ],
      [
        "TOTAL EXTERNAL FINANCING",
        ...taxYear.map(
          (item) => taxFormData[item]["TOTAL EXTERNAL FINANCING"] || "$      0"
        ),
        getElementAvrage("TOTAL EXTERNAL FINANCING") || "$      0",
        getCurrentAndPriorChanges("TOTAL EXTERNAL FINANCING") || "$      0",
      ],
      [
        "ACTUAL CHANGE IN CASH",
        ...taxYear.map(
          (item) => taxFormData[item]["ACTUAL CHANGE IN CASH"] || "$      0"
        ),
        getElementAvrage("ACTUAL CHANGE IN CASH") || "$      0",
        getCurrentAndPriorChanges("ACTUAL CHANGE IN CASH") || "$      0",
      ],
      [
        "TRADITIONAL CASH FLOW",
        ...taxYear.map(
          (item) => taxFormData[item]["TRADITIONAL CASH FLOW"] || "$      0"
        ),
        getElementAvrage("TRADITIONAL CASH FLOW") || "$      0",
        getCurrentAndPriorChanges("TRADITIONAL CASH FLOW") || "$      0",
      ],
    ];

    const columnStyles = {};
    for (let i = 1; i <= taxYear.length + 2; i++) {
      columnStyles[i] = {
        cellWidth: 70,
      };
    }

    for (let i = 25; i < rows.length; i++) {
      let rowData = rows[i][0];
      if (formHeading.includes(rowData)) {
        rows[i][0] = {
          content: rowData,
          styles: {
            fillColor: [255, 0, 0],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        };
        for (let j = 1; j < taxYear.length + 3; j++) {
          rows[i][j] = {
            content: "",
            styles: {
              fillColor: [255, 0, 0],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          };
        }
      }
    }
    pdf.autoTable({
      head: [columns],
      body: rows,
      startY: 60,
      margin: { top: 60 },
      columnStyles: columnStyles,
    });

    pdf.save(`${name}_${moment(date).format("YYYY-MM-DD")}.pdf`);
  };

  const forms = [
    "Form 1040",
    "Schedule A",
    "Schedule B",
    "Schedule C",
    "Schedule D",
    "Schedule E",
    "Schedule F",
    "Form 2106",
    "Form 4797",
    "Form 4835",
    "Form 6252",
    "Form 8825",
    "Schedule K-1, Partnership",
    "Schedule K-1, S-Corporation",
    "Schedule K-1, Trust Income",
    "Schedule K-1 Form 8865:==:8865_K1",
    "Schedule 1065:==:1065C",
    "Schedule 1120",
    "Schedule 1120S",
    "Cash Flow From Business Financials:==:Total",
    "Annual Cash Available To Service Debt",
    "Deductions, 1040, Sch. A, Form 8606",
    "Tax Payments",
    "Total Deductions From Cash Flow",
    "Adjustments",
    "Net Cash Available",
  ];

  const newforms = [
    "",
    "Adjustments",
    "Year of Tax Return",
    "Auto Expenses Adjustments:==:ADJ 1",
    "Amortization:==:ADJ 4",
    "Other Adjustments:==:ADJ 5",
    "Sum Adjustments",
    "",
    "Annual Debt Payments",
    "Year of Tax Return",
    "Projected Payment on Loan Request:==:Debt 1",
    "Home Mortgage/Rent:==:Debt 2",
    "Revolving Totals:==:Debt 3",
    "Installment Totals:==:Debt 4",
    "Rental Totals:==:Debt 5",
    "Other:==:Debt 6",
    "Personal Living Expenses-est.:==:Debt 7",
    "Total Annual Debt",
    "Net Cashflow After Debt",
    "",
    "Debt Ratio: (cash flow/debt)",
    "Debt Ratio: (debt/cash flow)",
    "Net Cashflow After Debt with Adj.",
    "",
    "Debt Ratio Adjusted: (cash flow/debt)",
    "Debt Ratio Adjusted: (debt/cash flow))",
    "Form 1040",
    "Year of Tax Return",
    "Wages, salaries:==:w1",
    "Taxable interest income:==:TI 1",
    "Nontaxable interest income:==:NT 1",
    "Dividend income:==:D1 + D2",
    "Taxable refunds:==:TR1",
    "Alimony Received:==:AL1",
    "Business income or loss:==:BI1",
    "Total IRA Dist.:==:IRA1",
    "Total Pensions:==:PEN1",
    "Farm Income:==:FARM1",
    "Unemp. comp. insurance:==:UNC1",
    "Social security benefits:==:SSI1",
    "Child Support:==:CS1",
    "Other income - CASH:==:OTI1",
    "Total 1040 Cash Inflows",
    "",
    "Educator Expenses:==:Ex1",
    "Certain Business Expenses:==:BEX1",
    "IRA Deductions:==:IRAD1",
    "Medical Savings:==:MedS1",
    "Moving Expenses:==:MEXP1",
    "Self-Employed Insurance:==:SEPI1",
    "Keogh and SEP Contributions",
    "Early Withdrawal Penalty:==:EWDP1",
    "Alimony Paid:==:AP1",
    "Student Loan Interest Deduction:==:SLI1",
    "Child Support",
    "Total 1040 Cash Deductions",
    "Total Federal Tax Payments:==:FICA1",
    "",
    "Refunded (from previous year):==:PYR1",
    "Owed (from previous year):==:OPY1",
    "FICA tax (From W-2) or.0765 x wages subject to Inc. limits",
    "Cash Tax Reconciliation",
    "Total  Form 1040 Items",
    "",
    "",
    "SCHEDULE A",
    "Year of Tax Return",
    "Medical Expenses:==:MEDEX1",
    "Taxes Paid:==:TPA1",
    "Cash Charitable Expenses:==:CEX1",
    "Miscellaneous Deductions:==:MISCD1",
    "Other deductions (if cash):==:OCD1",
    "Total Schedule A Items",
    "",
    "Schedule B (need new schedule as a TAB)",
    "Taxable interest income:==:TI 1",
    "Dividend income:==:D2",
    "Total Schedule B",
    "",
    "SCHEDULE C",
    "Year of Tax Return",
    "Depletion:==:Sch C1",
    "Depreciation:==:Sch C2",
    "Interest:==:Sch C3 + Sch C4",
    "50% of Meals and Entertainment:==:Sch C5",
    "Business Use of Home:==:Sch C6",
    "Beginning Inventory:==:Sch C7",
    "Ending Inventory:==:Sch C8",
    "Total Schedule C Items",
    "",
    "SCHEDULE D",
    "Year of Tax Return",
    "Net proceeds from the sale of stocks, bonds, other securities, land, and other nondepreciable property:==:Sch D1",
    "Total Schedule D Items",
    "",
    "SCHEDULE E",
    "Year of Tax Return",
    "Interest Paid:==:Sch E1 + Sch E2",
    "Depreciation & Amortization:==:Sch E3",
    "Income From Rentals or Royalties:==:Sch E4",
    "Total Schedule E Items",
    "",
    "SCHEDULE F",
    "Year of Tax Return",
    "Program Payments:==:Sch F1",
    "Program Payments, Taxable:==:Sch F2",
    "CCC Loans Forfeited:==:Sch F3",
    "Crop Insurance (current year):==:Sch F4",
    "Crop Insurance Taxable/Deferred:==:Sch F5",
    "Def Prior Year:==:Sch F6",
    "Depreciation:==:Sch F7",
    "Interest:==:Sch F8 + Sch F9",
    "Program Payments:==:Sch F10",
    "Program Payments, Taxable:==:Sch F11",
    "CCC Loans Forfeited:==:Sch F12",
    "Beginning Inventory:==:Sch F13",
    "Ending Inventory:==:Sch F14",
    "Net New Investments:==:Sch F15",
    "Total Schedule F Items",
    "FORM 2106",
    "Employee Business Expenses",
    "Year of Tax Return",
    "Meals & Entertainment:==:ME 2106",
    "Total Form 2106 Items",
    "",
    "FORM 4797",
    "Sales of Business Property",
    "Year of Tax Return",
    "Gross Sales Price:==:F4797 1",
    "Gross Sales Price:==:F4797 2",
    "Total Gain Page 2:==:F4797 3",
    "Depreciation:==:F4797 4",
    "Total Form 4797 Items",
    "",
    "FORM 4835",
    "Farm Rental Income",
    "Year of Tax Return",
    "Income From Production:==:f4835 1",
    "Total Co-op Dist. (Taxable):==:f4835 2",
    "Agricultural Program Payments:==:f4835 3",
    "CCC Loans:==:f4835 4",
    "Crop Insurance (Current Year):==:f4835 5",
    "Other Income:==:f4835 6",
    "Depreciation:==:f4835 7",
    "Interest:==:f4835 8 + f4835 9",
    "Total Expenses:==:f4835 10",
    "Net New Investments:==:f4835 11",
    "Total Form 4835 Items",
    "",
    "Form 6252",
    "Installment Sale Income",
    "Year of Tax Return",
    "Commissions & Other Expenses:==:F6252 1",
    "Payments Received:==:F6252 2",
    "Total Form 6252 Items",
    "",
    "",
    "Form 8825",
    "Interest:==:F8825 1",
    "Depreciation:==:F8825 2",
    "Amortization:==:F8825 3",
    "Total form 8825",
    "",
    "Form 8606",
    "Nondedutible IRAs",
    "Year of Tax Return",
    "Nondeductible Contributions:==:F8606 1",
    "Contr. 1/1 to 4/15 Next Year:==:F8606 2",
    "Total Form 8606 Items",
    "",
    "SCHEDULE K-1",
    "(Form 1065) Partnership",
    "Year of Tax Return",
    "Ordinary Business Income:==:1065K 1",
    "Net Rental Real Estate Income:==:1065K 2",
    "Other Rental Inc.:==:1065K 3",
    "Guaranteed Payments to Partner:==:1065K 4",
    "Interest Income:==:1065K 5",
    "Dividends:==:1065K 6",
    "Royalties:==:1065K 7",
    "Distributions of Cash:==:1065K 8",
    "Total Form 1065 Items",
    "SCHEDULE K-1",
    "(Form 1120S) S-Corporation",
    "Year of Tax Return",
    "Ordinary Business Income:==:1120SK 1",
    "Net Rental Real Estate Income:==:1120SK 2",
    "Other Rental Inc.:==:1120SK 3",
    "Interest Income:==:1120SK 4",
    "Ordinary Dividends:==:1120SK 5",
    "Royalties:==:1120SK 6",
    "Distributions of Cash:==:1120SK 7",
    "Total Form 1120S Items",
    "",
    "SCHEDULE K-1",
    "(Form 1041) Trust Income",
    "Year of Tax Return",
    "Interest:==:1041K 1",
    "Dividends:==:1041K 2",
    "Ordinary Business Income:==:1041K 3",
    "Net Rental Real Estate Income:==:1041K 4",
    "Other Rental Income:==:1041K 5",
    "Distributions From Trust:==:1041K 6",
    "Total Form 1041 Items",
    "",
    "Bug add this section to spread and to forms",
    "SCHEDULE K-1",
    "(Form 8865)",
    "Oridinary Business Income:==:8865K 1",
    "Net Rental Real Estate Income:==:8865K 2",
    "Other Rental Net Income:==:8865K 3",
    "Guaranteed Payments:==:8865K 4",
    "Interest Income:==:8865K 5",
    "Ordinary Dividends:==:8865K 6",
    "Royalties:==:8865K 7",
    "Other Income or Loss:==:8865K 8",
    "Distributions of Cash:==:8865K 9",
    "Total Form 8865 Items",
    "",
    "",
    "Form 1065 (Cash Basis)",
    "Return of Partnership Income (Cash Basis)",
    "Year of Tax Return",
    "Interest:==:1065C1",
    "Less Depreciation:==:1065C2",
    "Depletion:==:1065C3",
    "Ordinary Business Income:==:1065C4",
    "Form 1065 - Schedule K (Cash)",
    "Year of Tax Return",
    "Rental Net Income:==:1065C5",
    "Other Rental Net Income:==:1065C6",
    "Interest Income:==:1065C7",
    "Qualified Dividends:==:1065C8",
    "Royalties:==:1065C9",
    "Contributions:==:1065C10",
    "Tax Exempt Interest Income:==:1065C11",
    "Other Tax Exempt Income:==:1065C12",
    "Nondeductible Expenses:==:1065C13",
    "Form 1065 - Schedule M1 (Cash Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1065C14",
    "Tax Exempt Interest:==:1065C15",
    "",
    "Total Form 1065 Items Cash Basis",
    "",
    "Form 1065 (Accrual Basis)",
    "Return of Partnership Income (Accrual Basis)",
    "Year of Tax Return",
    "Interest:==:1065A1",
    "Less Depreciation:==:1065A2",
    "Depletion:==:1065A3",
    "Ordinary Business Income:==:1065A4",
    "Form 1065 - Schedule K (Accrual)",
    "Year of Tax Return",
    "Rental Net Income:==:1065A5",
    "Other Rental Net Income:==:1065A6",
    "Interest Income:==:1065A7",
    "Qualified Dividends:==:1065A8",
    "Royalties:==:1065A9",
    "Contributions:==:1065A10",
    "Tax Exempt Interest Income:==:1065A11",
    "Other Tax Exempt Income:==:1065A12",
    "Nondeductible Expenses:==:1065A13",
    "Form 1065 - Schedule L (Accrual Basis)",
    "Year of Tax Return",
    "Trade Notes and Accounts Recievable (Start):==:1065A14",
    "Trade Notes and Accounts Recievable (End):==:1065A15",
    "Less Allowance Bad Debts (Start):==:1065A16",
    "Less Allowance Bad Debts (End):==:1065A17",
    "Inventories (Start):==:1065A18",
    "Inventories (End):==:1065A19",
    "Other Current Assets (Start):==:1065A20",
    "Other Current Assets (End):==:1065A21",
    "Accounts Payable (Start):==:1065A22",
    "Accounts Payable (End):==:1065A23",
    "MTG, notes, bonds payable <1 year Beg:==:1065A24",
    "MTG, notes, bonds payable <1 year End:==:1065A25",
    "Other Current Liabilities (Start):==:1065A26",
    "Other Current Liabilities (End):==:1065A27",
    "Form 1065 - Schedule M1 (Accrual Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1065A28",
    "Tax Exempt Interest:==:1065A29",
    "Total Form 1065 Items (Accrual Basis)",
    "",
    "",
    "Form 1120 (Cash Basis)",
    "US Corporation Income Tax Return (Cash Basis)",
    "Year of Tax Return",
    "Interest:==:1120C1",
    "Less Depreciation:==:1120C2",
    "Depletion:==:1120C3",
    "Taxable Income:==:1120C4",
    "Form 1120 - Schedule J (Cash)",
    "Year of Tax Return",
    "Estimated Tax Payments:==:1120C5",
    "Form 1120 - Schedule M1 (Cash Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1120C6",
    "Charitable Contributions:==:1120C7",
    "Tax-Exempt Interest:==:1120C8",
    "Total Form 1120 Items Cash Basis",
    "",
    "Form 1120 (Accrual Basis)",
    "US Corporation Income Tax Return (Accrual Basis)",
    "Year of Tax Return",
    "Interest:==:1120A1",
    "Less Depreciation:==:1120A2",
    "Depletion:==:1120A3",
    "Business Income:==:1120A4",
    "Form 1120 - Schedule J (Cash)",
    "Year of Tax Return",
    "Estimated Tax Payments:==:1120A5",
    "Form 1120 - Schedule L (Accrual Basis)",
    "Year of Tax Return",
    "Less Allowance Bad Debts (Start):==:1120A6",
    "Less Allowance Bad Debts (End):==:1120A7",
    "Inventories (Start):==:1120A8",
    "Inventories (End):==:1120A9",
    "Other Current Assets (Start):==:1120A10",
    "Other Current Assets (End):==:1120A11",
    "Accounts Payable (Start):==:1120A12",
    "Accounts Payable (End):==:1120A13",
    "MTG, notes, bonds payable <1 year Beg:==:1120A14",
    "MTG, notes, bonds payable <1 year End:==:1120A15",
    "Other Current Liabilities (Start):==:1120A16",
    "Other Current Liabilities (End):==:1120A17",
    "Form 1120 - Schedule M1 (Accrual Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1120A18",
    "Charitable Contributions:==:1120A19",
    "Tax-Exempt Interest:==:1120A20",
    "Total Form 1120 Items (Accrual Basis)",
    "",
    "Form 1120S (Cash Basis)",
    "U.S. Income Tax Return for an S Corporation (Cash Basis)",
    "Year of Tax Return",
    "Business Income:==:1120S C1",
    "Interest:==:1120S C2",
    "Less Depreciation:==:1120S C3",
    "Depletion:==:1120S C4",
    "Estimated Tax Payments:==:1120S C5",
    "Form 1120S - Schedule K (Cash)",
    "Year of Tax Return",
    "Net Rental Net Income:==:1120S C6",
    "Interest Income:==:1120S C7",
    "Tax Exempt Interest Income:==:1120S C8",
    "Other Tax Exempt Income:==:1120S C9",
    "Nondeductible Expenses:==:1120S C10",
    "Form 1120S - Schedule M1 (Cash Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1120S C11",
    "Tax-Exempt Interest:==:1120S C12",
    "Total Form 1120S Items Cash Basis",
    "",
    "Form 1120S (Accrual Basis)",
    "U.S. Income Tax Return for an S Corporation (Accrual Basis)",
    "Year of Tax Return",
    "Interest:==:1120S A1",
    "Less Depreciation:==:1120S A2",
    "charitable Contributions:==:1120S A3",
    "Business Income:==:1120S A4",
    "Est. Tax Pmt.:==:1120S A5",
    "Form 1120S - Schedule K (Accrual)",
    "Year of Tax Return",
    "Net Rental Net Income:==:1120S A6",
    "Interest Income:==:1120S A7",
    "Tax Exempt Interest Income:==:1120S A8",
    "Other Tax Exempt Income:==:1120S A9",
    "Nondeductible Expenses:==:1120S A10",
    "Form 1120S - Schedule L (Accrual Basis)",
    "Year of Tax Return",
    "Less Allowance Bad Debts (Start):==:1120S A11",
    "Less Allowance Bad Debts (End):==:1120S A12",
    "Inventories (Start):==:1120S A13",
    "Inventories (End):==:1120S A14",
    "Other Current Assets (Start):==:1120S A15",
    "Other Current Assets (End):==:1120S A16",
    "Accounts Payable (Start):==:1120S A17",
    "Accounts Payable (End):==:1120S A18",
    "MTG, notes, bonds Pay<1 Yr Beg:==:1120S A19",
    "MTG, notes, bonds Pay<1 Yr End:==:1120S A20",
    "Other Current Liabilities (Start):==:1120S A21",
    "Other Current Liabilities (End):==:1120S A22",
    "Form 1120S - Schedule M1 (Accrual Basis)",
    "Year of Tax Return",
    "Travel and Entertainment:==:1120S A23",
    "Tax-Exempt Interest:==:1120S A24",
    "Total Form 1120S Items (Accrual Basis)",
    "",
    "",
    "FORM Cash Flow Anlaysis 1084",
    "IRS 1040-Individual Income Tax Return",
    "Wages, salaries:==:w1",
    "Schedule B Interest and Ordinary Dividend",
    "Interest Income from Self Employment:==:TI 1",
    "Dividends from Self-Employment:==:D1 + D2",
    "",
    "Schedule C-Profit or Loss From Business",
    "Net Profit:==:BI1",
    "Nonrecurring Other Income/LossExpense:==:1084C 1",
    "Depletion:==:Sch C1",
    "Depreciation:==:Sch C2",
    "Non-Deductible Meals and and Entertainment:==:Sch C5",
    "Business Use of Home:==:Sch C6",
    "Amortization/Casualty Loss:==:1084C 1AM",
    "Schedule D-Recurring Gains:==:1084C 2",
    "Recurring Capital Gains:==:1084C 3",
    "Schedule E Income or Loss",
    "Royalties Received:==:Sch E4",
    "Total Expenses:==:1084C 4",
    "Depletion:==:1084C 5",
    "Schedule F Profit or Loss",
    "Net Farm Profit or Loss:==:FARM1",
    "Non-Tax Portion Ongoing Coop CC Payments:==:Sch F1 + Sch F4",
    "Nonrecurring Other Income Loss:==:1084C 6",
    "Depreciation:==:Sch F7",
    "Amortization/Casualty Loss:==:1084C 7AM",
    "Business Use of Home:==:1084C 7",
    "Partnership or S Corporation",
    "Schedule K-1 Form 1065 Partners Share of Income/Loss",
    "Ordinary Income:==:1065K 1",
    "Net Rental Real Estate/Other Net Income/Loss:==:1065K 1 + 1065K 2",
    "Guaranteed Payments to Partner:==:1065K 4",
    "Form 1065 Adjustments to Business Cash Flow",
    "Ordinary Income Loss From Other Partnerships:==:1084C 8",
    "Non Recurring Other:==:1084C 9",
    "Depreciation:==:1084C 10",
    "Depletion:==:1084C 11",
    "Amortization/Casualty Loss:==:1084C 13",
    "Mortgages Or Notes Payable Less than 1 Year:==:1084C 13",
    "Non-Deductible Travel and and Entertainment:==:1084C 14",
    "Total",
    "Total % Percentage Owned:==:1084C 15",
    "Schedule K-1 Form 1120S",
    "Ordinary Income:==:1084C 16",
    "Net Rental Real Estate/Other Net Income/Loss:==:1084C 17",
    "Form 1120S - Adjustments to Business Cash Flow",
    "Nonrecurring Other (Income) Loss:==:1084C 18",
    "Depreciation:==:1084C 19",
    "Depletion:==:1084C 20",
    "Amortization/Casualty Loss:==:1084C 21",
    "Mortgages or Notes Payable in Less than 1 Year:==:1084C 22",
    "Non-deductible Travel and Entertainment Expenses:==:1084C 23",
    "total",
    "Total % Percentage Owned:==:1084C 24",
    "Form 1120 – Regular Corporation",
    "Taxable Income:==:1084C 25",
    "Total Tax:==:1084C 26",
    "Nonrecurring (Gains) Losses:==:1084C 27",
    "Nonrecurring Other (Income) Loss:==:1084C 28",
    "Depreciation:==:1084C 29",
    "Depletion:==:1084C 30",
    "Amortization/Casualty Loss:==:1084C 31",
    "Net Operating Loss and Special Deductions:==:1084C 32",
    "Mortgages or Notes Payable in Less than 1 Year:==:1084C 33",
    "Non-deductible Travel and Entertainment Expenses:==:1084C 34",
    "Subtotal",
    "Less: Dividends Paid to Borrower:==:1084C 35",
    "Total Form 1120",
    "",
    "End Cashflow Analysis",
    "",
    "Business Manual Financials Cash Flow Section",
    "",
    "B A L A N C E   S H E E T ",
    "Cash:==:BS1",
    "Certificates of Deposits:==:BS2",
    "A/R trade:==:BS3",
    "Inventory:==:BS4",
    "Other current assets:==:BS5",
    "Total Current Assets",
    "",
    "Land & Buildings:==:BS6",
    "Leasehold Improvements:==:BS7",
    "Equipment:==:BS8",
    "Fixtures & Furn.:==:BS9",
    "Gross Fixed assets",
    "",
    "Acc. Depreciation:==:BS10",
    "Net Fixed Assets",
    "",
    "Prepaids:==:BS11",
    "N/R - Noncurrent:==:BS12",
    "L/T Investments:==:BS13",
    "Due from Affl.,Off.,Stkhld.:==:BS14",
    "Other Assets:==:BS15",
    "Intangibles:==:BS16",
    "TOTAL ASSETS",
    "",
    "Overdrafts:==:BS17",
    "Accounts Payable:==:BS18",
    "Notes Payable:==:BS19",
    "CPLTD:==:BS20",
    "Accrued Expenses:==:BS21",
    "Taxes Payable:==:BS22",
    "Income Taxes:==:BS23",
    "Other Current Liabilities",
    "",
    "CURRENT LIABILITIES",
    "",
    "Long Term Debt:==:BS25",
    "Debt to Stockholders:==:BS26",
    "Deferred Taxes:==:BS27",
    "Other Liabilities:==:BS28",
    "TOTAL LIABILITIES",
    "",
    "Preferred Stock:==:BS29",
    "Common Stock:==:BS30",
    "Paid In Capital:==:BS31",
    "Treasury Stock:==:BS32",
    "Retained Earnings:==:BS33",
    "",
    "NET WORTH",
    "",
    "TOTAL LIAB. & NET WORTH",
    "",
    "Balancing Line",
    "",
    "# OF MONTHS:==:Manual MO",
    "I N C O M E   S T A T E M E N T  ",
    "Gross Revenues:==:IS 1",
    "Other Income:==:IS 2",
    "TOTAL REVENUES",
    "",
    "Direct:==:IS 3",
    "Materials:==:IS 4",
    "COGS - Depreciation:==:IS 5",
    "COGS - Other:==:IS 6",
    "Total Direct Costs",
    "",
    "GROSS PROFIT",
    "",
    "Depreciation Expense:==:IS 7",
    "Rent:==:IS 8",
    "Other Salaries:==:IS 9",
    "Officer's Comp:==:IS 10",
    "Repairs:==:IS 11",
    "Taxes:==:IS 12",
    "Employee benefits:==:IS 13",
    "Advertisin:==:IS14",
    "Insurance Expense:==:IS15",
    "Utilities:==:IS16",
    "Operating Expenses:==:IS17",
    "Total Operating Expenses",
    "",
    "OPERATING NET INCOME",
    "",
    "Interest Expense:==:IS18",
    "Other Income:==:IS19",
    "Other Expense:==:IS20",
    "Unconsolidated Subsidiary:==:IS21",
    "INCOME BEFORE TAXES",
    "",
    "Current Taxes:==:IS22",
    "Deferred Taxes:==:IS23",
    "Cumu. Effect of Chng in Acct. Prin.:==:IS24",
    "",
    "NET INCOME",
    "",
    "RECONCILIATION OF NET WORTH ",
    "",
    "Beginning Net Worth:==:IS25",
    "",
    "Profitability/(loss)",
    "Dividends (disbursements):==:IS26",
    "Other:==:IS27",
    "",
    "Adjustment Required                      ss179:==:IS28",
    "",
    "Ending Net Worth",
    "",
    "Balancing line",
    "",
    " C A S H F L O W   A N A L Y S I S",
    " Sources/(Uses)  ",
    "",
    "Sales - Net",
    "Change in A/R",
    "",
    "CASH FROM SALES",
    "",
    "Cost of Goods Sold",
    "Change in inventories",
    "Change in Payables",
    "",
    "CASH PRODUCTION COST",
    "",
    "GROSS CASH MARGIN",
    "",
    "SG & A Expenses",
    "Change In Prepaids",
    "Change In Other Taxes",
    "Change In Accruals",
    "",
    "CASH OPERATING EXPENSE",
    "",
    "CASH AFTER OPERATIONS",
    "",
    "Other Income:==:IS19",
    "Other Expenses",
    "Income Taxes Paid",
    "Change in N/R",
    "Change in Misc. Assets",
    "Change in Misc. Liabilities",
    "",
    "NET CASH AFTER OPERATIONS",
    "",
    "CPLTD",
    "Interest Expense:==:IS18",
    "Dividends Paid:==:IS26",
    "",
    "DEBT AMORTIZATION & FIN. COSTS",
    "",
    "NET CASH INCOME AFTER FIN. COST",
    "",
    "Capital Expenditures",
    "Change in L/T Investments",
    "",
    "FINANCING SURPLUS/",
    "REQUIREMENTS",
    "",
    "Change In Overdraft",
    "Change In Short-term Debt",
    "Change in Long-Term debt",
    "Change In Equity",
    "",
    "TOTAL EXTERNAL FINANCING",
    "",
    "CASH AFTER FINANCING",
    "ACTUAL CHANGE IN CASH",
    "",
    "TRADITIONAL CASH FLOW",
    "",
    "STATEMENT DATE",
    "",
    "R A T I O S",
    "",
    "ANNUALIZING FACTOR",
    "ANNUALIZED SALES GROWTH",
    "",
    "LIQUIDITY:============================",
    "",
    "WORKING CAPITAL",
    "CURRENT RATIO",
    "QUICK RATIO",
    "AGE OF RECEIVABLES - DAYS",
    "INVENTORY TURNOVER - DAYS",
    "AGE OF PAYABLES - DAYS",
    "",
    "COVERAGE:===========================",
    "",
    "INTEREST COVERAGE",
    "NET CASH AFTER OPER./CPLTD",
    "TRADITIONAL CASH FLOW/CPLTD",
    "",
    "LEVERAGE:=============================",
    "",
    "DEBT/TNW",
    "CREDITORS EQUITY/TOTAL ASSETS",
    "OWNERS EQUITY/TOTAL ASSETS",
    "NET FIXED ASSETS/LTD",
    "",
    "PROFITABILITY:=======================",
    "",
    "GROSS PROFIT MARGIN %",
    "PRE-TAX PROFIT MARGIN %",
    "NET PROFIT AFTER TAXES %",
    "RETURN ON EQUITY (ROE) %",
    "RETURN ON ASSETS (ROA) %",
    "",
    "BANKRUPTCY RATIO: Z SCORE",
    "Z < 1.80 WEAK COMPANY",
    "Z > 2.70 STRONG COMPANY",
    "",
    "OPERATING CAPITAL REQUIREMENTS=======",
    "",
    "CASH / AVG DAYS SALES",
    "",
    "RECEIVABLES / AVG DAYS SALES",
    "",
    "INVENTORY / AVG DAYS SALES",
    "",
    "TOTAL TRADING CYCLE",
    "",
    "PAYABLES / AVG DAYS SALES",
    "",
    "NET CASH CYCLE",
    "",
    "OPERATING CAPITAL REQUIRED",
    "",
    "FINANCIAL STATEMENTS ON:",
    "",
    "TYPE OF REPORT",
    "STATEMENT DATE",
    "# OF MONTHS:==:Manual MO",
    "",
    "H I G H  L I G H T S ",
    "",
    "INCOME STATEMENT:",
    "",
    "ANNUALIZED SALES GROWTH:==:ANNUALIZED SALES GROWTH _743",
    "Gross Revenues:==:IS 1",
    "GROSS PROFIT",
    "Total Operating Expenses",
    "INCOME BEFORE TAXES",
    "NET INCOME",
    "",
    "CASH DIVIDENDS:==:IS26",
    "",
    "GROSS PROFIT MARGIN %",
    "RETURN ON EQUITY (ROE) %",
    "RETURN ON ASSETS (ROA) %",
    "",
    "BALANCE SHEET:",
    "",
    "",
    "Cash:==:BS1",
    "Current Assets",
    "Net Fixed Assets",
    "TOTAL ASSETS",
    "",
    "Short-term Debt",
    "Total Current Liabilities",
    "Total Long-term Debt",
    "TOTAL LIABILITIES",
    "NET WORTH",
    "",
    "RATIOS:",
    "",
    "CURRENT RATIO",
    "QUICK RATIO",
    "WORKING CAPITAL",
    "",
    "AGE OF RECEIVABLES - DAYS",
    "INVENTORY TURNOVER - DAYS",
    "AGE OF PAYABLES - DAYS",
    "",
    "DEBT/TNW",
    "",
    "CASH FLOW: Incr. (Decr.) in Cash",
    "CASH FROM SALES",
    "GROSS CASH MARGIN",
    "",
    "NET CASH AFTER OPERATIONS",
    "NET CASH INCOME AFTER FIN. COST",
    "",
    "Capital Expenditures",
    "Change in L/T Investments",
    "",
    "FINANCING SURPLUS/",
    "TOTAL EXTERNAL FINANCING",
    "ACTUAL CHANGE IN CASH",
    "",
    "TRADITIONAL CASH FLOW",
  ];

  const formHeading = [
    "Adjustments",
    "Form 1040",
    "Year of Tax Return",
    "Annual Debt Payments",
    "SCHEDULE A",
    "Schedule B (need new schedule as a TAB)",
    "SCHEDULE B",
    "SCHEDULE C",
    "SCHEDULE D",
    "SCHEDULE E",
    "SCHEDULE F",
    "FORM 2106",
    "Employee Business Expenses",
    "FORM 4797",
    "FORM 4835",
    "Farm Rental Income",
    "Form 6252",
    "Installment Sale Income",
    "Form 8825",
    "Form 8606",
    "Nondedutible IRAs",
    "SCHEDULE K-1",
    "(Form 1065) Partnership",
    "(Form 1120S) S-Corporation",
    "(Form 1041) Trust Income",
    "(Form 8865)",
    "Form 1065 (Cash Basis)",
    "Return of Partnership Income (Cash Basis)",
    "Form 1065 - Schedule K (Cash)",
    "Bug add this section to spread and to forms",
    "Form 1065 - Schedule M1 (Cash Basis)",
    "Form 1065 (Accrual Basis)",
    "Return of Partnership Income (Accrual Basis)",
    "Form 1065 - Schedule K (Accrual)",
    "Form 1065 - Schedule L (Accrual Basis)",
    "Form 1065 - Schedule M1 (Accrual Basis)",
    "Form 1120 (Cash Basis)",
    "US Corporation Income Tax Return (Cash Basis)",
    "Form 1120 - Schedule J (Cash)",
    "Form 1120 - Schedule M1 (Cash Basis)",
    "Form 1120 (Accrual Basis)",
    "US Corporation Income Tax Return (Accrual Basis)",
    "Form 1120 - Schedule L (Accrual Basis)",
    "Form 1120 - Schedule M1 (Accrual Basis)",
    "Form 1120S (Cash Basis)",
    "U.S. Income Tax Return for an S Corporation (Cash Basis)",
    "Form 1120S - Schedule K (Cash)",
    "Form 1120S - Schedule M1 (Cash Basis)",
    "Form 1120S (Accrual Basis)",
    "U.S. Income Tax Return for an S Corporation (Accrual Basis)",
    "Form 1120S - Schedule K (Accrual)",
    "Form 1120S - Schedule L (Accrual Basis)",
    "Form 1120S - Schedule M1 (Accrual Basis)",
    "FORM Cash Flow Anlaysis 1084",
    "IRS 1040-Individual Income Tax Return",
    "Schedule B Interest and Ordinary Dividend",
    "Schedule C-Profit or Loss From Business",
    "Schedule E Income or Loss",
    "Schedule F Profit or Loss",
    "Partnership or S Corporation",
    "Schedule K-1 Form 1065 Partners Share of Income/Loss",
    "Form 1065 Adjustments to Business Cash Flow",
    "Schedule K-1 Form 1120S",
    "Form 1120S - Adjustments to Business Cash Flow",
    "Form 1120 - Regular Corporation",
    "Business Manual Financials Cash Flow Section",
    "B A L A N C E   S H E E T ",
    "Balancing Line",
    "# OF MONTHS",
    "I N C O M E   S T A T E M E N T  ",
    "RECONCILIATION OF NET WORTH ",
    "Balancing line",
    " C A S H F L O W   A N A L Y S I S",
    " Sources/(Uses)  ",
    "STATEMENT DATE",
    "R A T I O S",
    "LIQUIDITY:============================",
    "COVERAGE:===========================",
    "LEVERAGE:=============================",
    "PROFITABILITY:=======================",
    "OPERATING CAPITAL REQUIREMENTS=======",
    "H I G H  L I G H T S ",
    "INCOME STATEMENT:",
    "CASH DIVIDENDS",
    "BALANCE SHEET:",
    "RATIOS:",
    "Z < 1.80 WEAK COMPANY",
    "Z > 2.70 STRONG COMPANY",
    "CASH FLOW: Incr. (Decr.) in Cash",
  ];

  // Insert the new values before the specified item
  newforms.splice(
    newforms.indexOf("Total  Form 1040 Items"),
    0,
    ...getAdditionalFielsInput1040
  );
  newforms.splice(
    newforms.indexOf("Total Schedule A Items"),
    0,
    ...getAdditionalFielsInputSchA
  );
  newforms.splice(
    newforms.indexOf("Total Schedule B"),
    0,
    ...getAdditionalFielsInputSchB
  );
  newforms.splice(
    newforms.indexOf("Total Schedule C Items"),
    0,
    ...getAdditionalFielsInputSchC
  );
  newforms.splice(
    newforms.indexOf("Total Schedule D Items"),
    0,
    ...getAdditionalFielsInputSchD
  );
  newforms.splice(
    newforms.indexOf("Total Schedule E Items"),
    0,
    ...getAdditionalFielsInputSchE
  );
  newforms.splice(
    newforms.indexOf("Total Schedule F Items"),
    0,
    ...getAdditionalFielsInputSchF
  );
  newforms.splice(
    newforms.indexOf("Total Form 2106 Items"),
    0,
    ...getAdditionalFielsInput2106
  );
  newforms.splice(
    newforms.indexOf("Total Form 4797 Items"),
    0,
    ...getAdditionalFielsInput4797
  );
  newforms.splice(
    newforms.indexOf("Total Form 4835 Items"),
    0,
    ...getAdditionalFielsInput4835
  );
  newforms.splice(
    newforms.indexOf("Total Form 6252 Items"),
    0,
    ...getAdditionalFielsInput6252
  );
  newforms.splice(
    newforms.indexOf("Total form 8825"),
    0,
    ...getAdditionalFielsInput8825
  );
  newforms.splice(
    newforms.indexOf("Total Form 8606 Items"),
    0,
    ...getAdditionalFielsInput8606
  );
  newforms.splice(
    newforms.indexOf("Total Form 1065 Items"),
    0,
    ...getAdditionalFielsInput065K
  );
  newforms.splice(
    newforms.indexOf("Total Form 1120S Items"),
    0,
    ...getAdditionalFielsInputS_K1
  );
  newforms.splice(
    newforms.indexOf("Total Form 1041 Items"),
    0,
    ...getAdditionalFielsInput1041
  );
  newforms.splice(
    newforms.indexOf("Total Form 8865 Items"),
    0,
    ...getAdditionalFielsInput5_K1
  );
  newforms.splice(
    newforms.indexOf("Total Form 1065 Items Cash Basis"),
    0,
    ...getAdditionalFielsInput065C
  );
  newforms.splice(
    newforms.indexOf("Total Form 1120 Items Cash Basis"),
    0,
    ...getAdditionalFielsInput120S
  );

  const exportCSV = async () => {
    let data = [];

    const headerRow = [
      "Cash Flow Recap",
      ...taxYear,
      "Average",
      "Current to last Year",
    ];
    data.push(headerRow);

    forms.forEach((form) => {
      let newform;
      let splitForm = form.split(":==:");
      let formKey = splitForm[1] ? splitForm[1] : form;
      form = splitForm[0] ? splitForm[0] : form;
      console.log("form", form);
      if (form === "Form 2106") {
        newform = "ME 2106";
      } else {
        newform = form;
      }
      const rowData = [
        form,
        ...taxYear.map((year) => getElementValue(year, formKey) || "$      0"),
        getElementAvrage(formKey) || "$      0",
        getCurrentAndPriorChanges(formKey) || "$      0",
      ];
      data.push(rowData);
    });

    newforms.forEach((form) => {
      let splitForm = form.split(":==:");
      let formKey = splitForm[1] ? splitForm[1] : form;
      form = splitForm[0] ? splitForm[0] : form;
      if (form == "" || formHeading.includes(form)) {
        if (
          formHeading.includes(form) &&
          form != "CASH DIVIDENDS" &&
          form != "# OF MONTHS"
        ) {
          const row = [form, , ,];
          data.push(row);
        } else if (formHeading.includes(form) && form == "CASH DIVIDENDS") {
          const rowData = [
            form,
            ...taxYear.map((year) =>
              taxFormData[year][form]
                ? taxFormData[year][form].toString()
                : "$      0"
            ),
            getElementAvrage(form) || "$      0",
            getCurrentAndPriorChanges(form) || "$      0",
          ];
          data.push(rowData);
        } else if (formHeading.includes(form) && form == "# OF MONTHS") {
          const rowData = [
            form,
            ...taxYear.map((year) =>
              taxFormData[year]["Manual MO"]
                ? taxFormData[year]["Manual MO"].toString()
                : "0"
            ),
            getElementAvrage("Manual MO") || "$      0",
            getCurrentAndPriorChanges("Manual MO") || "$      0",
          ];
          data.push(rowData);
        } else {
          const row = [form, , ,];
          data.push(row);
        }
      } else if (form && form == "Total Schedule B") {
        let newForm = "Total Schedule B";
        const rowData = [
          "Total",
          ...taxYear.map((year) =>
            taxFormData[year][newForm]
              ? taxFormData[year][newForm].toString()
              : "$      0"
          ),
          getElementAvrage(newForm) || "$      0",
          getCurrentAndPriorChanges(newForm) || "$      0",
        ];
        data.push(rowData);
      } else {
        let formMultiple = formKey.split(" + ");
        if (formMultiple.length > 1) {
          const rowData = [
            form,
            ...taxYear.map((year) => {
              const value1 =
                Number(taxFormData[year][formMultiple[0]].replace("$", "")) ||
                0;
              const value2 =
                Number(taxFormData[year][formMultiple[1]].replace("$", "")) ||
                0;
              return `$     ${(value1 + value2).toFixed(2)}`;
            }),
            `$     ${(
              (getElementAvrage(formMultiple[0])
                ? parseFloat(getElementAvrage(formMultiple[0]).replace("$", ""))
                : 0) +
              (getElementAvrage(formMultiple[1])
                ? parseFloat(getElementAvrage(formMultiple[1]).replace("$", ""))
                : 0)
            ).toFixed(2)}`,
            `$     ${(
              (getCurrentAndPriorChanges(formMultiple[0])
                ? parseFloat(
                    getCurrentAndPriorChanges(formMultiple[0]).replace("$", "")
                  )
                : 0) +
              (getCurrentAndPriorChanges(formMultiple[1])
                ? parseFloat(
                    getCurrentAndPriorChanges(formMultiple[1]).replace("$", "")
                  )
                : 0)
            ).toFixed(2)}`,
          ];

          data.push(rowData);
        } else if (keysToCheck.includes(form)) {
          const rowData = [
            form,
            ...taxYear.map((year) =>
              taxFormData[year][formKey]
                ? taxFormData[year][formKey].toString().replace("$", "")
                : "0"
            ),
            getElementAvrage(formKey).replace("$", "") || "0",
            getCurrentAndPriorChanges(formKey).replace("$", "") || "0",
          ];
          data.push(rowData);
        } else {
          const rowData = [
            form,
            ...taxYear.map((year) =>
              taxFormData[year][formKey]
                ? taxFormData[year][formKey].toString()
                : "$      0"
            ),
            getElementAvrage(formKey) || "$      0",
            getCurrentAndPriorChanges(formKey) || "$      0",
          ];
          data.push(rowData);
        }
      }
    });

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${name}_${moment(date).format("YYYY-MM-DD")}.csv`;
    link.click();
  };

  const exportToExcel = async () => {
    try {
      let dataArray = [];

      const headerRow = [
        "Cash Flow Recap",
        ...taxYear,
        "Average",
        "Current to last Year",
      ];
      dataArray.push(headerRow);

      forms.forEach((form) => {
        let formNew;
        if (form == "Adjustments") {
          formNew = "Sum Adjustments";
        } else if (form === "Form 2106") {
          formNew = "ME 2106";
        } else {
          formNew = form;
        }
        const rowData = [
          form,
          ...taxYear.map(
            (year) => getElementValue(year, formNew) || "$      0"
          ),
          getElementAvrage(formNew) || "$      0",
          getCurrentAndPriorChanges(formNew) || "$      0",
        ];
        dataArray.push(rowData);
      });

      newforms.forEach((form) => {
        let splitForm = form.split(":==:");
        let formKey = splitForm[1] ? splitForm[1] : form;
        form = splitForm[0] ? splitForm[0] : form;
        if (form == "" || formHeading.includes(form)) {
          if (
            formHeading.includes(form) &&
            form != "CASH DIVIDENDS" &&
            form != "# OF MONTHS"
          ) {
            const row = [form, , ,];
            dataArray.push(row);
          } else if (formHeading.includes(form) && form == "CASH DIVIDENDS") {
            const rowData = [
              form,
              ...taxYear.map((year) =>
                taxFormData[year][form]
                  ? taxFormData[year][form].toString()
                  : "$      0"
              ),
              getElementAvrage(form) || "$      0",
              getCurrentAndPriorChanges(form) || "$      0",
            ];
            dataArray.push(rowData);
          } else if (formHeading.includes(form) && form == "# OF MONTHS") {
            const rowData = [
              form,
              ...taxYear.map((year) =>
                taxFormData[year]["Manual MO"]
                  ? taxFormData[year]["Manual MO"].toString()
                  : "0"
              ),
              getElementAvrage("Manual MO") || "$      0",
              getCurrentAndPriorChanges("Manual MO") || "$      0",
            ];
            dataArray.push(rowData);
          } else {
            const row = [form, , ,];
            dataArray.push(row);
          }
        } else if (form && form == "Total Schedule B") {
          let newForm = "Total Schedule B";
          const rowData = [
            "Total",
            ...taxYear.map((year) =>
              taxFormData[year][newForm]
                ? taxFormData[year][newForm].toString()
                : "$      0"
            ),
            getElementAvrage(newForm) || "$      0",
            getCurrentAndPriorChanges(newForm) || "$      0",
          ];
          dataArray.push(rowData);
        } else {
          let formMultiple = formKey.split(" + ");
          if (formMultiple.length > 1) {
            const rowData = [
              form,
              ...taxYear.map((year) => {
                const value1 =
                  Number(taxFormData[year][formMultiple[0]].replace("$", "")) ||
                  0;
                const value2 =
                  Number(taxFormData[year][formMultiple[1]].replace("$", "")) ||
                  0;
                return `$     ${(value1 + value2).toFixed(2)}`;
              }),
              `$     ${(
                (getElementAvrage(formMultiple[0])
                  ? parseFloat(
                      getElementAvrage(formMultiple[0]).replace("$", "")
                    )
                  : 0) +
                (getElementAvrage(formMultiple[1])
                  ? parseFloat(
                      getElementAvrage(formMultiple[1]).replace("$", "")
                    )
                  : 0)
              ).toFixed(2)}`,
              `$     ${(
                (getCurrentAndPriorChanges(formMultiple[0])
                  ? parseFloat(
                      getCurrentAndPriorChanges(formMultiple[0]).replace(
                        "$",
                        ""
                      )
                    )
                  : 0) +
                (getCurrentAndPriorChanges(formMultiple[1])
                  ? parseFloat(
                      getCurrentAndPriorChanges(formMultiple[1]).replace(
                        "$",
                        ""
                      )
                    )
                  : 0)
              ).toFixed(2)}`,
            ];

            dataArray.push(rowData);
          } else if (keysToCheck.includes(form)) {
            const rowData = [
              form,
              ...taxYear.map((year) =>
                taxFormData[year][formKey]
                  ? taxFormData[year][formKey].toString().replace("$", "")
                  : "0"
              ),
              getElementAvrage(formKey).replace("$", "") || "0",
              getCurrentAndPriorChanges(formKey).replace("$", "") || "0",
            ];
            dataArray.push(rowData);
          } else {
            const rowData = [
              form,
              ...taxYear.map((year) =>
                taxFormData[year][formKey]
                  ? taxFormData[year][formKey].toString()
                  : "$      0"
              ),
              getElementAvrage(formKey) || "$      0",
              getCurrentAndPriorChanges(formKey) || "$      0",
            ];
            dataArray.push(rowData);
          }
        }
      });

      // Create a new XlsxPopulate workbook
      const wb = await XlsxPopulate.fromBlankAsync();

      // Get the first sheet of the workbook
      const ws = wb.sheet(0);

      // Add data to the worksheet
      ws.cell("A1").value(dataArray);

      ws.row(1).style({ bold: true });

      // Set background color for the header row (e.g., light gray)
      for (let j = 0; j < taxYear.length + 3; j++) {
        let rowIndex = 1;
        ws.cell(`${addBackgroundColum[j]}${rowIndex}`).style({
          fill: {
            type: "solid",
            color: "FF0000",
          },
          fontColor: "FFFFFF",
        });
        // Set the font style for the header row
      }
      const formHeadingColumnIndex = 0;

      for (let i = 1; i <= 28; i++) {
        ws.row(i).style({ bold: false });
      }

      ws.row(1).style({ bold: true });

      ws.column("A").width(45);
      ws.column(1).style({ horizontalAlignment: "left" });
      for (let i = 2; i <= taxYear.length + 3; i++) {
        ws.column(i).width(20).style({ horizontalAlignment: "right" });
      }

      for (let rowIndex = 28; rowIndex < dataArray.length; rowIndex++) {
        const rowData = dataArray[rowIndex];
        // Check if the value in the first column (where form values are) is in formHeading
        if (formHeading.includes(rowData[formHeadingColumnIndex])) {
          // Apply bold font style to the entire row
          ws.row(rowIndex + 1).style({ bold: true });
          ws.cell(`A${rowIndex + 1}`).style({
            fill: {
              type: "solid",
              color: "FF0000",
            },
            fontColor: "FFFFFF",
          });

          for (let j = 1; j < taxYear.length + 3; j++) {
            ws.cell(`${addBackgroundColum[j]}${rowIndex + 1}`).style({
              fill: {
                type: "solid",
                color: "FF0000",
              },
              fontColor: "FFFFFF",
            });
          }
        }
      }

      // Define the filename for the Excel file
      const dateStr = moment(date).format("YYYY-MM-DD");
      const fileName = `${name}_${dateStr}.xlsx`;

      // Save the workbook to a blob
      const blob = await wb.outputAsync();

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  useEffect(() => {
    getTaxAnalysis();
  }, [""]);

  return (
    <>
      {showLoader && <Loader />}
      <div className="col-md-12">
        <div>
          <h3>Tax Analysis</h3>
        </div>
        <div className="form-inline">
          <div className="form-group">
            <h6 htmlFor="" className="d-inline">
              Spreads Report :
            </h6>
            <input
              type="text"
              className="form-control input int"
              id="name"
              name="name"
              value={name}
            />
          </div>
        </div>
        <div className="form-inline">
          <div className="form-group">
            <h6 htmlFor="" className="d-inline">
              Customer Name :
            </h6>
            <input
              type="text"
              className="form-control input int"
              id=""
              name="customerName"
              value={customerName}
            />
          </div>
        </div>
        <div className="form-inline">
          <div className="form-group">
            <h6 htmlFor="" className="d-inline">
              Date Run :
            </h6>
            <input
              type="text"
              className="form-control input int"
              id=""
              name="name"
              value={moment(date).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <button className="btn btn-primary" onClick={generatePDF}>
          Export PDF
        </button>
        <button className="btn btn-primary" onClick={exportCSV}>
          Export CSV
        </button>
        <button className="btn btn-primary" onClick={exportToExcel}>
          {" "}
          Export Excel{" "}
        </button>
        <div className="container mt-3 analysis-table">
          <Table striped bordered hover style={{ width: "100%" }}>
            <thead>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Cash Flow Recap</td>
                {taxYear &&
                  taxYear.map((item, key) => <td key={key}>{item}</td>)}
                <td>Average</td>
                <td>Current to last Year</td>
              </tr>
            </thead>
            <tbody style={{}}>
              <tr>
                <td>Form 1040</td>

                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Form 1040")
                        ? getElementValue(item, "Form 1040")
                        : `$  0`}
                    </td>
                  ))}

                <td>{getElementAvrage("Form 1040") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Form 1040") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule A</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule A") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule A") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule A") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule B</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule B") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule B") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule B") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule C</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule C") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule C") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule C") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule D</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule D") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule D") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule D") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule E</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule E") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule E") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule E") || "$      0"}</td>
              </tr>

              <tr>
                <td>Schedule F</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule F") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule F") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Schedule F") || "$      0"}</td>
              </tr>

              <tr>
                <td>Form 2106</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "ME 2106") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("ME 2106") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("ME 2106") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 4797</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Form 4797") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Form 4797") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Form 4797") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 4835</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Form 4835") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Form 4835") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Form 4835") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 6252</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Form 6252") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Form 6252") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Form 6252") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 8825</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Form 8825") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Form 8825") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Form 8825") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule K-1, Partnership</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule K-1, Partnership") ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Schedule K-1, Partnership") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Schedule K-1, Partnership") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Schedule K-1, S-Corporation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule K-1, S-Corporation") ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Schedule K-1, S-Corporation") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Schedule K-1, S-Corporation") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Schedule K-1, Trust Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule K-1, Trust Income") ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Schedule K-1, Trust Income") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Schedule K-1, Trust Income") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Schedule K-1 Form 8865</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "8865_K1") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("8865_K1") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("8865_K1" || "$      0") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Schedule 1065</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "1065C") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065C") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule 1120</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule 1120") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule 1120") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Schedule 1120") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Schedule 1120S</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Schedule 1120S") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Schedule 1120S") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Schedule 1120S") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Cash Flow From Business Financials</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(item, "Total") || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Total") || "$      0"}</td>
              </tr>
              <tr>
                <td>Annual Cash Available To Service Debt</td>

                {taxYear &&
                  taxYear.map((item, index) => (
                    <td key={index}>
                      {getElementValue(
                        item,
                        "Annual Cash Available To Service Debt"
                      ) || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Annual Cash Available To Service Debt") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Annual Cash Available To Service Debt"
                  ) || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Deductions, 1040, Sch. A, Form 8606</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item][
                        "Deductions, 1040, Sch. A, Form 8606"
                      ] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Deductions, 1040, Sch. A, Form 8606") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Deductions, 1040, Sch. A, Form 8606"
                  ) || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Tax Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Tax Payments"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Tax Payments") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Tax Payments") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Total Deductions From Cash Flow</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Deductions From Cash Flow"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Deductions From Cash Flow") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Deductions From Cash Flow")}
                </td>
              </tr>
              <tr>
                <td>Adjustments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Sum Adjustments"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Sum Adjustments") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Sum Adjustments") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Net Cash Available</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <tr key={index}>
                      <td key={index}>
                        {taxFormData[item]["Net Cash Available"]}
                      </td>
                    </tr>
                  ))}
                <td>{getElementAvrage("Net Cash Available") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Net Cash Available") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Adjustments</td>
              </tr>
              <tr>
                <td>Auto Expenses Adjustments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["ADJ 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("ADJ 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("ADJ 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Amortization</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["ADJ 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("ADJ 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("ADJ 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Adjustments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["ADJ 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("ADJ 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("ADJ 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Sum Adjustments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Sum Adjustments"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Sum Adjustments") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Sum Adjustments") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Annual Debt Payments</td>
              </tr>
              <tr>
                <td>Projected Payment on Loan Request</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 1") || "$      0"}</td>
              </tr>

              <tr>
                <td>Home Mortgage/Rent</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Revolving Totals</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Installment Totals</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Rental Totals</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Personal Living Expenses-est.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Debt 7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Debt 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Debt 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Annual Debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Annual Debt"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total Annual Debt") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Annual Debt") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Net Cashflow After Debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Net Cashflow After Debt"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Net Cashflow After Debt") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Net Cashflow After Debt") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Debt Ratio: (cash flow/debt)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Debt Ratio: (cash flow/debt)"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Debt Ratio: (cash flow/debt)") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Debt Ratio: (cash flow/debt)")}
                </td>
              </tr>
              <tr>
                <td>Debt Ratio: (debt/cash flow)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Debt Ratio: (debt/cash flow)"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Debt Ratio: (debt/cash flow)") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Debt Ratio: (debt/cash flow)")}
                </td>
              </tr>
              <tr>
                <td>Net Cashflow After Debt with Adj.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Net Cashflow After Debt with Adj."]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Net Cashflow After Debt with Adj.") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Net Cashflow After Debt with Adj."
                  )}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Debt Ratio Adjusted: (cash flow/debt)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {
                        taxFormData[item][
                          "Debt Ratio Adjusted: (cash flow/debt)"
                        ]
                      }
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Debt Ratio Adjusted: (cash flow/debt)")}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Debt Ratio Adjusted: (cash flow/debt)"
                  )}
                </td>
              </tr>
              <tr>
                <td>Debt Ratio Adjusted: (debt/cash flow)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {
                        taxFormData[item][
                          "Debt Ratio Adjusted: (debt/cash flow))"
                        ]
                      }
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Debt Ratio Adjusted: (debt/cash flow))")}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Debt Ratio Adjusted: (debt/cash flow))"
                  )}
                </td>
              </tr>
              <br></br>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Form 1040</td>
              </tr>
              <tr>
                <td>Wages, salaries</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["w1"]
                        ? taxFormData[item]["w1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("w1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("w1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Taxable interest income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TI 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TI 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("TI 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Nontaxable interest income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["NT 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("NT 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("NT 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividend income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["D1"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["D2"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("D1")
                      ? parseFloat(getElementAvrage("D1").replace("$", ""))
                      : 0) +
                    (getElementAvrage("D2")
                      ? parseFloat(getElementAvrage("D2").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("D1")
                      ? parseFloat(
                          getCurrentAndPriorChanges("D1").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("D2")
                      ? parseFloat(
                          getCurrentAndPriorChanges("D2").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Taxable refunds</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TR1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TR1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("TR1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Alimony Received</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["AL1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("AL1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("AL1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Business income or loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BI1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total IRA Dist.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["IRA1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("IRA1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IRA1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Pensions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["PEN1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("PEN1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("PEN1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Farm Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["FARM1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("FARM1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("FARM1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Unemp. comp. insurance</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["UNC1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("UNC1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("UNC1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Social security benefits</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["SSI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("SSI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("SSI1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Child Support</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["CS1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("CS1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("CS1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other income - CASH</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["OTI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("OTI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("OTI1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total 1040 Cash Inflows</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total 1040 Cash Inflows"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total 1040 Cash Inflows") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total 1040 Cash Inflows") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Educator Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Ex1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Ex1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Ex1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Certain Business Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BEX1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BEX1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BEX1") || "$      0"}</td>
              </tr>
              <tr>
                <td>IRA Deductions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["IRAD1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("IRAD1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IRAD1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Medical Savings</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["MedS1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("MedS1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("MedS1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Moving Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["MEXP1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("MEXP1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("MEXP1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Self-Employed Insurance</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["SEPI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("SEPI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("SEPI1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Keogh and SEP Contributions</td>
              </tr>
              <tr>
                <td>Early Withdrawal Penalty</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["EWDP1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("EWDP1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("EWDP1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Alimony Paid</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["AP1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("AP1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("AP1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Student Loan Interest Deduction</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["SLI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("SLI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("SLI1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Child Support</td>
              </tr>
              <tr>
                <td>Total 1040 Cash Deductions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total 1040 Cash Deductions"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total 1040 Cash Deductions") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total 1040 Cash Deductions")}
                </td>
              </tr>
              <tr>
                <td>Total Federal Tax Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["FICA1"]
                        ? taxFormData[item]["FICA1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("FICA1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("FICA1") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>Refunded (from previous year)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["PYR1"]
                        ? taxFormData[item]["PYR1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("PYR1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("PYR1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Owed (from previous year)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["OPY1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("OPY1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("OPY1") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  FICA tax (From W-2) or.0765 x wages subject to Inc. limits
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {
                        taxFormData[item][
                          "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
                        ]
                      }
                    </td>
                  ))}
                <td>
                  {getElementAvrage(
                    "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
                  )}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "FICA tax (From W-2) or.0765 x wages subject to Inc. limits"
                  )}
                </td>
              </tr>
              <tr>
                <td>Cash Tax Reconciliation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Cash Tax Reconciliation"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Cash Tax Reconciliation") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Cash Tax Reconciliation") ||
                    "$      0"}
                </td>
              </tr>

              {console.log(getData(additionalData, "1040", "2021"))}

              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040", year).length > 0 &&
                    getData(additionalData, "1040", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}

              <tr>
                <td>Total Form 1040 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total  Form 1040 Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total  Form 1040 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total  Form 1040 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>SCHEDULE A</td>
              </tr>
              <tr>
                <td>Medical Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["MEDEX1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("MEDEX1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("MEDEX1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Taxes Paid</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TPA1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TPA1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("TPA1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Cash Charitable Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["CEX1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("CEX1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("CEX1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Miscellaneous Deductions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["MISCD1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("MISCD1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("MISCD1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other deductions (if cash)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["OCD1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("OCD1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("OCD1") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_A", year).length > 0 &&
                    getData(additionalData, "1040_sch_A", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}

              <tr>
                <td>Total Schedule A Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule A Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Schedule A Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule A Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Schedule B (need new schedule as a TAB)</td>
              </tr>
              <tr>
                <td>Taxable interest income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TI 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TI 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("TI 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividend income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["D2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("D2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("D2") || "$      0"}</td>
              </tr>

              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_B", year).length > 0 &&
                    getData(additionalData, "1040_sch_B", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule B"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total Schedule B") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule B") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>SCHEDULE C</td>
              </tr>
              <tr>
                <td>Depletion </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["Sch C3"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["Sch C4"].replace("$", "") || 0
                        )}
                    </td>
                  ))}

                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("Sch C3")
                      ? parseFloat(getElementAvrage("Sch C3").replace("$", ""))
                      : 0) +
                    (getElementAvrage("Sch C4")
                      ? parseFloat(getElementAvrage("Sch C4").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("Sch C3")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch C3").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("Sch C4")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch C4").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>50% of Meals and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Business Use of Home</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Beginning Inventory</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ending Inventory</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C8") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_C", year).length > 0 &&
                    getData(additionalData, "1040_sch_C", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Schedule C Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule C Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Schedule C Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule C Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>SCHEDULE D</td>
              </tr>
              <tr>
                <td>
                  Net proceeds from the sale of stocks, bonds, other securities,
                  land, and other nondepreciable property
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Sch D1"]
                        ? taxFormData[item]["Sch D1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Sch D1") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule D Items") ||
                    "$      0"}
                </td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_D", year).length > 0 &&
                    getData(additionalData, "1040_sch_D", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Schedule D Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule D Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Schedule D Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule D Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>SCHEDULE E</td>
              </tr>
              <tr>
                <td>Interest Paid</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["Sch E1"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["Sch E2"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("Sch E1")
                      ? parseFloat(getElementAvrage("Sch E1").replace("$", ""))
                      : 0) +
                    (getElementAvrage("Sch E2")
                      ? parseFloat(getElementAvrage("Sch E2").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("Sch E1")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch E1").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("Sch E2")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch E2").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Depreciation & Amortization</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch E3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch E3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch E3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Income From Rentals or Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch E4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch E4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch E4") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_E", year).length > 0 &&
                    getData(additionalData, "1040_sch_E", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Schedule E Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule E Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Schedule E Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule E Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>SCHEDULE F</td>
              </tr>
              <tr>
                <td>Program Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Program Payments, Taxable</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F2") || "$      0"}</td>
              </tr>
              <tr>
                <td>CCC Loans Forfeited</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Crop Insurance (current year)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Crop Insurance Taxable/Deferred</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Def Prior Year</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["Sch F8"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["Sch F9"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("Sch F8")
                      ? parseFloat(getElementAvrage("Sch F8").replace("$", ""))
                      : 0) +
                    (getElementAvrage("Sch F9")
                      ? parseFloat(getElementAvrage("Sch F9").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("Sch F8")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch F8").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("Sch F9")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch F9").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Program Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Program Payments, Taxable</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F11") || "$      0"}</td>
              </tr>
              <tr>
                <td>CCC Loans Forfeited</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Beginning Inventory</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ending Inventory</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net New Investments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch F15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch F15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F15") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1040_sch_F", year).length > 0 &&
                    getData(additionalData, "1040_sch_F", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Schedule F Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Schedule F Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Schedule F Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Schedule F Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  FORM 2106<br></br>Employee Business Expenses
                </td>
              </tr>
              <tr>
                <td>Meals & Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ME 2106"]
                        ? taxFormData[item]["ME 2106"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("ME 2106") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 2106 Items") ||
                    "$      0"}
                </td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "2106", year).length > 0 &&
                    getData(additionalData, "2106", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 2106 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 2106 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 2106 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 2106 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  FORM 4797<br></br>Sales of Business Property
                </td>
              </tr>
              <tr>
                <td>Gross Sales Price</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["F4797 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("F4797 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F4797 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Gross Sales Price</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["F4797 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("F4797 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F4797 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Gain Page 2</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["F4797 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("F4797 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F4797 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["F4797 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("F4797 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F4797 4") || "$      0"}</td>
              </tr>

              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "4797", year).length > 0 &&
                    getData(additionalData, "4797", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 4797 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 4797 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 4797 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 4797 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  FORM 4835<br></br>Farm Rental Income
                </td>
              </tr>
              <tr>
                <td>Income From Production</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 1"]
                        ? taxFormData[item]["f4835 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Co-op Dist. (Taxable)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 2"]
                        ? taxFormData[item]["f4835 2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Agricultural Program Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 3"]
                        ? taxFormData[item]["f4835 3"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>CCC Loans</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 4"]
                        ? taxFormData[item]["f4835 4"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Crop Insurance (Current Year)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 5"]
                        ? taxFormData[item]["f4835 5"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 6"]
                        ? taxFormData[item]["f4835 6"]
                        : "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("f4835 6")
                    ? getElementAvrage("f4835 6")
                    : "$      0"}
                </td>
                <td>{getCurrentAndPriorChanges("f4835 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 7"]
                        ? taxFormData[item]["f4835 7"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["f4835 8"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["f4835 9"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("f4835 8")
                      ? parseFloat(getElementAvrage("f4835 8").replace("$", ""))
                      : 0) +
                    (getElementAvrage("f4835 9")
                      ? parseFloat(getElementAvrage("f4835 9").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("f4835 8")
                      ? parseFloat(
                          getCurrentAndPriorChanges("f4835 8").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("f4835 9")
                      ? parseFloat(
                          getCurrentAndPriorChanges("f4835 9").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Total Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 10"]
                        ? taxFormData[item]["f4835 10"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net New Investments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["f4835 11"]
                        ? taxFormData[item]["f4835 11"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("f4835 11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("f4835 11") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "4835", year).length > 0 &&
                    getData(additionalData, "4835", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 4835 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 4835 Items"]
                        ? taxFormData[item]["Total Form 4835 Items"]
                        : "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 4835 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 4835 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 6252<br></br>Installment Sale Income
                </td>
              </tr>
              <tr>
                <td>Commissions & Other Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F6252 1"]
                        ? taxFormData[item]["F6252 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F6252 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F6252 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Payments Received</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F6252 2"]
                        ? taxFormData[item]["F6252 2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F6252 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F6252 2") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "6252", year).length > 0 &&
                    getData(additionalData, "6252", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 6252 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 6252 Items"]
                        ? taxFormData[item]["Total Form 6252 Items"]
                        : "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 6252 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 6252 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Form 8825</td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F8825 1"]
                        ? taxFormData[item]["F8825 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F8825 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F8825 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F8825 2"]
                        ? taxFormData[item]["F8825 2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F8825 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F8825 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Amortization</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F8825 3"]
                        ? taxFormData[item]["F8825 3"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F8825 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F8825 3") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "8825", year).length > 0 &&
                    getData(additionalData, "8825", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total form 8825</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total form 8825"]
                        ? taxFormData[item]["Total form 8825"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total form 8825") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total form 8825") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 8606 <br></br>Nondedutible IRAs
                </td>
              </tr>
              <tr>
                <td>Nondeductible Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F8606 1"]
                        ? taxFormData[item]["F8606 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F8606 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F8606 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Contr. 1/1 to 4/15 Next Year</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["F8606 2"]
                        ? taxFormData[item]["F8606 2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("F8606 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("F8606 2") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "8606", year).length > 0 &&
                    getData(additionalData, "8506", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 8606 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 8606 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 8606 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 8606 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  SCHEDULE K-1<br></br>(Form 1065) Partnership
                </td>
              </tr>
              <tr>
                <td>Ordinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net Rental Real Estate Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Inc.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Guaranteed Payments to Partner</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Distributions of Cash</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065K 8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065K 8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 8") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1065K", year).length > 0 &&
                    getData(additionalData, "1065K", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Form 1065 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1065 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1065 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 1065 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  SCHEDULE K-1<br></br>(Form 1120S) S-Corporation
                </td>
              </tr>
              <tr>
                <td>Ordinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net Rental Real Estate Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Inc.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Distributions of Cash</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120SK 7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120SK 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120SK 7") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1120S_K1", year).length > 0 &&
                    getData(additionalData, "1120S_K1", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Form 1120S Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1120S Items"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1120S Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 1120S Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  SCHEDULE K-1<br></br>(Form 1041) Trust Income
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ordinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net Rental Real Estate Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Distributions From Trust</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1041K 6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1041K 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1041K 6") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1041", year).length > 0 &&
                    getData(additionalData, "1041", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>Total Form 1041 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1041 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1041 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 1041 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Bug add this section to spread and to forms</td>
              </tr>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  SCHEDULE K-1<br></br>(Form 8865)
                </td>
              </tr>
              <tr>
                <td>Oridinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net Rental Real Estate Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Guaranteed Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ordinary Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Income or Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Distributions of Cash</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["8865K 9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("8865K 9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("8865K 9") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "8865_K1", year).length > 0 &&
                    getData(additionalData, "8865_K1", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Form 8865 Items</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 8865 Items"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 8865 Items") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 8865 Items") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1065 (Cash Basis)<br></br>Return of Partnership Income
                  (Cash Basis)
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ordinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1065 - Schedule K (Cash)</td>
              </tr>
              <tr>
                <td>Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Qualified Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Tax Exempt Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Nondeductible Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1065 - Schedule M1 (Cash Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065C15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065C15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065C15") || "$      0"}</td>
              </tr>
              <br></br>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1065C", year).length > 0 &&
                    getData(additionalData, "1065C", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}

              <tr>
                <td>Total Form 1065 Items Cash Basis</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1065 Items Cash Basis"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1065 Items Cash Basis") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1065 Items Cash Basis"
                  )}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1065 (Accrual Basis)<br></br>Return of Partnership Income
                  (Accrual Basis)
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Ordinary Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1065 - Schedule K (Accrual)</td>
              </tr>
              <tr>
                <td>Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Qualified Dividends</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Royalties</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Tax Exempt Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Nondeductible Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1065 - Schedule L (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Trade Notes and Accounts Recievable (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Trade Notes and Accounts Recievable (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A16"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A16") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A17"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A17") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A18"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A18") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A19"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A19") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A20"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A21"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A21") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A21") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A22"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A22") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A22") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A23"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A23") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A23") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds payable {"<"} 1 year Beg</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A24"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A24") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A24") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds payable {"<"} 1 year End</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1065A25"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1065A25") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A25") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065A26"]
                        ? taxFormData[item]["1065A26"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065A26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A26") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065A27"]
                        ? taxFormData[item]["1065A27"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065A27") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A27") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1065 - Schedule M1 (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065A28"]
                        ? taxFormData[item]["1065A28"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065A28") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A28") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065A29"]
                        ? taxFormData[item]["1065A29"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065A29") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065A29") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Form 1065 Items (Accrual Basis)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item][
                        "Total Form 1065 Items (Accrual Basis)"
                      ]
                        ? taxFormData[item][
                            "Total Form 1065 Items (Accrual Basis)"
                          ]
                        : "$      0"}
                    </td>
                  ))}

                <td>
                  {getElementAvrage("Total Form 1065 Items (Accrual Basis)") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1065 Items (Accrual Basis)"
                  ) || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1120 (Cash Basis)<br></br>US Corporation Income Tax
                  Return (Cash Basis)
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1120C1"]
                        ? taxFormData[item]["1120C1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1120C1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1120C2"]
                        ? taxFormData[item]["1120C2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1120C2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1120C3"]
                        ? taxFormData[item]["1120C3"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1120C3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Taxable Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120C4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120C4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 - Schedule J (Cash)</td>
              </tr>
              <tr>
                <td>Estimated Tax Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120C5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120C5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 - Schedule M1 (Cash Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120C6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120C6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Charitable Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120C7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120C7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax-Exempt Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120C8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120C8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120C8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Form 1120 Items Cash Basis</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1120 Items Cash Basis"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1120 Items Cash Basis") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1120 Items Cash Basis"
                  )}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1120 (Accrual Basis)<br></br>US Corporation Income Tax
                  Return (Accrual Basis)
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 - Schedule J (Cash)</td>
              </tr>
              <tr>
                <td>Estimated Tax Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 - Schedule L (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A13") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds payable {"<"} 1 year Beg</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A14") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds payable {"<"} 1 year End</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A16"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A16") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A17"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A17") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 - Schedule M1 (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A18"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A18") || "$      0"}</td>
              </tr>
              <tr>
                <td>Charitable Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A19"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A19") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax-Exempt Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120A20"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120A20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120A20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Form 1120 Items (Accrual Basis)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {
                        taxFormData[item][
                          "Total Form 1120 Items (Accrual Basis)"
                        ]
                      }
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1120 Items (Accrual Basis)")}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1120 Items (Accrual Basis)"
                  )}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1120S (Cash Basis)<br></br>U.S. Income Tax Return for an
                  S Corporation (Cash Basis)
                </td>
              </tr>
              <tr>
                <td>Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Estimated Tax Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Schedule K (Cash)</td>
              </tr>
              <tr style={{}}>
                <td>Net Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Tax Exempt Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Nondeductible Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Schedule M1 (Cash Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax-Exempt Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S C12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S C12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S C12") || "$      0"}</td>
              </tr>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1120S", year).length > 0 &&
                    getData(additionalData, "1120S", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>Total Form 1120S Items Cash Basis</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1120S Items Cash Basis"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1120S Items Cash Basis") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1120S Items Cash Basis"
                  )}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  Form 1120S (Accrual Basis)<br></br>U.S. Income Tax Return for
                  an S Corporation (Accrual Basis)
                </td>
              </tr>
              <tr>
                <td>Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A2") || "$      0"}</td>
              </tr>
              <tr>
                <td>charitable Contributions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Business Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Est. Tax Pmt.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Schedule K (Accrual)</td>
              </tr>
              <tr style={{}}>
                <td>Net Rental Net Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax Exempt Interest Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Tax Exempt Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Nondeductible Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Schedule L (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Less Allowance Bad Debts (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventories (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Assets (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A16"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A16") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A17"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A17") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A18"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A18") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds Pay {"<"} 1 Yr Beg</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A19"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A19") || "$      0"}</td>
              </tr>
              <tr>
                <td>MTG, notes, bonds Pay {"<"} 1 Yr End</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A20"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (Start)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A21"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A21") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A21") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Current Liabilities (End)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A22"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A22") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A22") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Schedule M1 (Accrual Basis)</td>
              </tr>
              <tr>
                <td>Travel and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A23"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A23") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A23") || "$      0"}</td>
              </tr>
              <tr>
                <td>Tax-Exempt Interest</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1120S A24"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1120S A24") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1120S A24") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total Form 1120S Items (Accrual Basis)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {
                        taxFormData[item][
                          "Total Form 1120S Items (Accrual Basis)"
                        ]
                      }
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Form 1120S Items (Accrual Basis)")}
                </td>
                <td>
                  {getCurrentAndPriorChanges(
                    "Total Form 1120S Items (Accrual Basis)"
                  )}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  FORM Cash Flow Anlaysis 1084<br></br>IRS 1040-Individual
                  Income Tax Return
                </td>
              </tr>
              <tr>
                <td>Wages, salaries</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["w1"]
                        ? taxFormData[item]["w1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("w1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("w1") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Schedule B Interest and Ordinary Dividend</b>
                </td>
              </tr>
              <tr>
                <td>Interest Income from Self Employment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TI 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TI 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("TI 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividends from Self-Employment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    // <td>{taxFormData[item]["D1"] + taxFormData[item]["D2"] || "$      0"}</td>
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["D1"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["D2"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("D1")
                      ? parseFloat(getElementAvrage("D1").replace("$", ""))
                      : 0) +
                    (getElementAvrage("D2")
                      ? parseFloat(getElementAvrage("D2").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("D1")
                      ? parseFloat(
                          getCurrentAndPriorChanges("D1").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("D2")
                      ? parseFloat(
                          getCurrentAndPriorChanges("D2").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Schedule C-Profit or Loss From Business</b>
                </td>
              </tr>
              <tr>
                <td>Net Profit</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BI1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BI1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BI1") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Nonrecurring Other Income/LossExpense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C2") || "$      0"}</td>
              </tr>
              <tr>
                <td>Non-Deductible Meals and and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C5") || "$      0"}</td>
              </tr>
              <tr>
                <td>Business Use of Home</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch C6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch C6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch C6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Amortization/Casualty Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 1AM"]
                        ? taxFormData[item]["1084C 1AM"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 1AM") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 1AM") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Schedule D-Recurring Gains</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 2") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Recurring Capital Gains</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Schedule E Income or Loss</b>
                </td>
              </tr>
              <tr>
                <td>Royalties Received</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sch E4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sch E4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch E4") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 4") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Schedule F Profit or Loss</b>
                </td>
              </tr>
              <tr>
                <td>Net Farm Profit or Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["FARM1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("FARM1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("FARM1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Non-Tax Portion Ongoing Coop CC Payments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    // <td>
                    //   {Number(taxFormData[item]["Sch F1"]) +
                    //     Number(taxFormData[item]["Sch F4"])}
                    // </td>
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["Sch F1"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["Sch F4"].replace("$", "") || 0
                        )}
                    </td>
                  ))}
                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("Sch F1")
                      ? parseFloat(getElementAvrage("Sch F1").replace("$", ""))
                      : 0) +
                    (getElementAvrage("Sch F4")
                      ? parseFloat(getElementAvrage("Sch F4").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("Sch F1")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch F1").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("Sch F4")
                      ? parseFloat(
                          getCurrentAndPriorChanges("Sch F4").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>
                {/* <td>
                  {Number(getElementAvrage("Sch F1")) +
                    Number(getElementAvrage("Sch F4"))}
                </td>
                <td>
                  {Number(getCurrentAndPriorChanges("Sch F1")) +
                    Number(getCurrentAndPriorChanges("Sch F4"))}
                </td> */}
              </tr>
              <tr style={{}}>
                <td>Nonrecurring Other Income Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 6"]
                        ? taxFormData[item]["1084C 6"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Sch F7"]
                        ? taxFormData[item]["Sch F7"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Sch F7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Sch F7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Amortization/Casualty Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 7AM"]
                        ? taxFormData[item]["1084C 7AM"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 7AM") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 7AM") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Business Use of Home</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 7"]
                        ? taxFormData[item]["1084C 7"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Partnership or S Corporation</b>
                </td>
              </tr>
              <tr>
                <td>Schedule K-1 Form 1065 Partners Share of Income/Loss</td>
              </tr>
              <tr>
                <td>Ordinary Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065K 1"]
                        ? taxFormData[item]["1065K 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065K 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Net Rental Real Estate/Other Net Income/Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    // <td>
                    //   {taxFormData[item]["1065K 1"]
                    //     ? taxFormData[item]["1065K 1"]
                    //     : 0 + taxFormData[item]["1065K 2"]
                    //       ? taxFormData[item]["1065K 2"]
                    //       : "$      0"}
                    // </td>
                    <td className="dollar-value-cell">
                      {parseFloat(
                        taxFormData[item]["1065K 1"].replace("$", "") || 0
                      ) +
                        parseFloat(
                          taxFormData[item]["1065K 2"].replace("$", "") || 0
                        )}
                    </td>
                  ))}

                <td className="dollar-value-cell">
                  {(
                    (getElementAvrage("1065K 1")
                      ? parseFloat(getElementAvrage("1065K 1").replace("$", ""))
                      : 0) +
                    (getElementAvrage("1065K 2")
                      ? parseFloat(getElementAvrage("1065K 2").replace("$", ""))
                      : 0)
                  ).toFixed(2)}
                </td>
                <td className="dollar-value-cell">
                  {(
                    (getCurrentAndPriorChanges("1065K 1")
                      ? parseFloat(
                          getCurrentAndPriorChanges("1065K 1").replace("$", "")
                        )
                      : 0) +
                    (getCurrentAndPriorChanges("1065K 2")
                      ? parseFloat(
                          getCurrentAndPriorChanges("1065K 2").replace("$", "")
                        )
                      : 0)
                  ).toFixed(2)}
                </td>

                {/* <td>
                  {getElementAvrage("1065K 1") + getElementAvrage("1065K 2")}
                </td>
                <td>
                  {getCurrentAndPriorChanges("1065K 1") +
                    getCurrentAndPriorChanges("1065K 2")}
                </td> */}
              </tr>
              <tr>
                <td>Guaranteed Payments to Partner</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1065K 4"]
                        ? taxFormData[item]["1065K 4"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1065K 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1065K 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Form 1065 Adjustments to Business Cash Flow</b>
                </td>
              </tr>
              <tr style={{}}>
                <td>Ordinary Income Loss From Other Partnerships</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 8"]
                        ? taxFormData[item]["1084C 8"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 8") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Non Recurring Other</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 9"]
                        ? taxFormData[item]["1084C 9"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 9") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 10"]
                        ? taxFormData[item]["1084C 10"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 10") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 11"]
                        ? taxFormData[item]["1084C 11"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 11") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Amortization/Casualty Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 12"]
                        ? taxFormData[item]["1084C 12"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 12") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Mortgages Or Notes Payable Less than 1 Year</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 13") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Non-Deductible Travel and and Entertainment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Total</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Total"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Total") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Total") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total % Percentage Owned</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 15"]
                        ? taxFormData[item]["1084C 15"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Schedule K-1 Form 1120S</td>
              </tr>
              <tr style={{}}>
                <td>Ordinary Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 16"]
                        ? taxFormData[item]["1084C 16"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 16") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Net Rental Real Estate/Other Net Income/Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 17"]
                        ? taxFormData[item]["1084C 17"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 17") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120S - Adjustments to Business Cash Flow</td>
              </tr>
              <tr style={{}}>
                <td>Nonrecurring Other (Income) Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 18"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 18") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 19"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 19") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 20"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 20") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Amortization/Casualty Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 21"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 21") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 21") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Mortgages or Notes Payable in Less than 1 Year</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 22"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 22") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 22") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Non-deductible Travel and Entertainment Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 23"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 23") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 23") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["total"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("total") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("total") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total % Percentage Owned</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 24"]
                        ? taxFormData[item]["1084C 24"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 24") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 24") || "$      0"}</td>
              </tr>
              <tr>
                <td>Form 1120 – Regular Corporation</td>
              </tr>
              <tr style={{}}>
                <td>Taxable Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 25"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 25") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 25") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total Tax</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 26"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 26") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Nonrecurring (Gains) Losses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 27"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 27") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 27") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Nonrecurring Other (Income) Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 28"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 28") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 28") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 29"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 29") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 29") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Depletion</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 30"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 30") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 30") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Amortization/Casualty Loss</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 31"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 31") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 31") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Net Operating Loss and Special Deductions</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 32"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 32") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 32") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Mortgages or Notes Payable in Less than 1 Year</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 33"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 33") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 33") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Non-deductible Travel and Entertainment Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["1084C 34"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("1084C 34") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 34") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Subtotal</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Subtotal"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Subtotal") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Subtotal") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Less: Dividends Paid to Borrower</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["1084C 35"]
                        ? taxFormData[item]["1084C 35"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("1084C 35") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("1084C 35") || "$      0"}</td>
              </tr>
              <tr style={{}}>
                <td>Total Form 1120</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Form 1120"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total Form 1120") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Form 1120") || "$      0"}
                </td>
              </tr>
              <br></br>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "1084", year).length > 0 &&
                    getData(additionalData, "1084", year).map((data, index) => (
                      <tr key={`${yearIndex}-${index}`}>
                        <td>{data.fieldName}</td>
                        {taxYear.map((item, index) => (
                          <td key={index}>
                            {taxFormData[item][data.field_input] || "$ 0"}
                          </td>
                        ))}
                        <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                        <td>
                          {getCurrentAndPriorChanges(data.field_input) || "$ 0"}
                        </td>
                      </tr>
                    ))
                )}
              <tr>
                <td>End Cashflow Analysis</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["End Cashflow Analysis"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("End Cashflow Analysis") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("End Cashflow Analysis") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Business Manual Financials Cash Flow Section</td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>B A L A N C E S H E E T </td>
              </tr>
              <tr>
                <td>Cash</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Certificates of Deposits</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS2"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS2") || "$      0"}</td>
              </tr>
              <tr>
                <td>A/R trade</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS3"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Inventory</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS4"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS4") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other current assets</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS5"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS5") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Current Assets</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Current Assets"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Current Assets") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Current Assets") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Land & Buildings</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS6"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS6") || "$      0"}</td>
              </tr>
              <tr>
                <td>Leasehold Improvements</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS7"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Equipment</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS8"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Fixtures & Furn.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS9"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS9") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Gross Fixed assets</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Gross Fixed assets"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Gross Fixed assets") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Gross Fixed assets") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Acc. Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS10"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS10") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Net Fixed Assets</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Net Fixed Assets"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Net Fixed Assets") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Net Fixed Assets") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Prepaids</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS11"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS11") || "$      0"}</td>
              </tr>
              <tr>
                <td>N/R - Noncurrent</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS12"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS12") || "$      0"}</td>
              </tr>
              <tr>
                <td>L/T Investments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS13"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Due from Affl.,Off.,Stkhld.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS14"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Assets</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS15"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Intangibles</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS16"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS16") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>TOTAL ASSETS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TOTAL ASSETS"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TOTAL ASSETS") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL ASSETS") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Overdrafts</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS17"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS17") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accounts Payable</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS18"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS18") || "$      0"}</td>
              </tr>
              <tr>
                <td>Notes Payable</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS19"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS19") || "$      0"}</td>
              </tr>
              <tr>
                <td>CPLTD</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS20"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Accrued Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS21"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS21") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS21") || "$      0"}</td>
              </tr>
              <tr>
                <td>Taxes Payable</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS22"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS22") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS22") || "$      0"}</td>
              </tr>
              <tr>
                <td>Income Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS23"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS23") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS23") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Other Current Liabilities</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Other Current Liabilities"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Other Current Liabilities") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Other Current Liabilities")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CURRENT LIABILITIES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CURRENT LIABILITIES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("CURRENT LIABILITIES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("CURRENT LIABILITIES") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Long Term Debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS25"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS25") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS25") || "$      0"}</td>
              </tr>
              <tr>
                <td>Debt to Stockholders</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS26"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS26") || "$      0"}</td>
              </tr>
              <tr>
                <td>Deferred Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS27"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS27") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS27") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Liabilities</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS28"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS28") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS28") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>TOTAL LIABILITIES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL LIABILITIES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("TOTAL LIABILITIES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL LIABILITIES") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Preferred Stock</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS29"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS29") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS29") || "$      0"}</td>
              </tr>
              <tr>
                <td>Common Stock</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS30"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS30") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS30") || "$      0"}</td>
              </tr>
              <tr>
                <td>Paid In Capital</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS31"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS31") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS31") || "$      0"}</td>
              </tr>
              <tr>
                <td>Treasury Stock</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS32"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS32") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS32") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Retained Earnings</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS33"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS33") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS33") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>NET WORTH</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["NET WORTH"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("NET WORTH") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("NET WORTH") || "$      0"}</td>
              </tr>
              <br></br>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "Balancesheet", year).length > 0 &&
                    getData(additionalData, "Balancesheet", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>
                  <b>TOTAL LIAB. & NET WORTH</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL LIAB. & NET WORTH"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TOTAL LIAB. & NET WORTH") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL LIAB. & NET WORTH") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>Balancing Line</td>
              </tr>
              <br></br>
              <tr>
                <td># OF MONTHS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Manual MO"]
                        ? taxFormData[item]["Manual MO"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Manual MO") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("Manual MO") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>I N C O M E S T A T E M E N T </b>
                </td>
              </tr>
              <tr>
                <td>Gross Revenues</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 1"]
                        ? taxFormData[item]["IS 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 2"]
                        ? taxFormData[item]["IS 2"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 2") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 2") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>TOTAL REVENUES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TOTAL REVENUES"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TOTAL REVENUES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL REVENUES") || "$      0"}
                </td>
              </tr>
              <br></br>

              <tr>
                <td>Direct</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 3"]
                        ? taxFormData[item]["IS 3"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 3") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 3") || "$      0"}</td>
              </tr>
              <tr>
                <td>Materials</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 4"]
                        ? taxFormData[item]["IS 4"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 4") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 4") || "$      0"}</td>
              </tr>
              <tr>
                <td>COGS - Depreciation</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 5"]
                        ? taxFormData[item]["IS 5"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 5") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 5") || "$      0"}</td>
              </tr>
              <tr>
                <td>COGS - Other</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 6"]
                        ? taxFormData[item]["IS 6"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 6") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 6") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Direct Costs</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Direct Costs"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Total Direct Costs") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Total Direct Costs") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>GROSS PROFIT</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["GROSS PROFIT"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("GROSS PROFIT") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("GROSS PROFIT") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Depreciation Expense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {Number(taxFormData[item]["IS 7"])
                        ? Number(taxFormData[item]["IS 7"])
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 7") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 7") || "$      0"}</td>
              </tr>
              <tr>
                <td>Rent</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 8"]
                        ? taxFormData[item]["IS 8"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 8") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 8") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Salaries</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 9"]
                        ? taxFormData[item]["IS 9"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 9") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 9") || "$      0"}</td>
              </tr>
              <tr>
                <td>Officer's Comp</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 10"]
                        ? taxFormData[item]["IS 10"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 10") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 10") || "$      0"}</td>
              </tr>
              <tr>
                <td>Repairs</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 11"]
                        ? taxFormData[item]["IS 11"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 11") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 11") || "$      0"}</td>
              </tr>
              <tr>
                <td>Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 12"]
                        ? taxFormData[item]["IS 12"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 12") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 12") || "$      0"}</td>
              </tr>
              <tr>
                <td>Employee benefits</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 13"]
                        ? taxFormData[item]["IS 13"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 13") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 13") || "$      0"}</td>
              </tr>
              <tr>
                <td>Advertisin</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS14"]
                        ? taxFormData[item]["IS14"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS14") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS14") || "$      0"}</td>
              </tr>
              <tr>
                <td>Insurance Expense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS15"]
                        ? taxFormData[item]["IS15"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS15") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS15") || "$      0"}</td>
              </tr>
              <tr>
                <td>Utilities</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS16"]
                        ? taxFormData[item]["IS16"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS16") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS16") || "$      0"}</td>
              </tr>
              <tr>
                <td>Operating Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS17"]
                        ? taxFormData[item]["IS17"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS17") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS17") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Operating Expenses</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Operating Expenses"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Operating Expenses") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Operating Expenses") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>OPERATING NET INCOME</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["OPERATING NET INCOME"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("OPERATING NET INCOME") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("OPERATING NET INCOME") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Interest Expense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS18"]
                        ? taxFormData[item]["IS18"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS18") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS19"]
                        ? taxFormData[item]["IS19"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS19") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Expense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS20"]
                        ? taxFormData[item]["IS20"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Unconsolidated Subsidiary</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS21"]
                        ? taxFormData[item]["IS21"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS21") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS21") || "$      0"}</td>
              </tr>
              <tr>
                <td>
                  <b>INCOME BEFORE TAXES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INCOME BEFORE TAXES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("INCOME BEFORE TAXES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("INCOME BEFORE TAXES") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Current Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS22"]
                        ? taxFormData[item]["IS22"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS22") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS22") || "$      0"}</td>
              </tr>
              <tr>
                <td>Deferred Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS23"]
                        ? taxFormData[item]["IS23"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS23") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS23") || "$      0"}</td>
              </tr>
              <tr>
                <td>Cumu. Effect of Chng in Acct. Prin.</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS24"]
                        ? taxFormData[item]["IS24"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS24") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS24") || "$      0"}</td>
              </tr>
              <br></br>
              {Array.isArray(taxYear) &&
                taxYear.map(
                  (year, yearIndex) =>
                    getData(additionalData, "incomeStatement", year).length >
                      0 &&
                    getData(additionalData, "incomeStatement", year).map(
                      (data, index) => (
                        <tr key={`${yearIndex}-${index}`}>
                          <td>{data.fieldName}</td>
                          {taxYear.map((item, index) => (
                            <td key={index}>
                              {taxFormData[item][data.field_input] || "$ 0"}
                            </td>
                          ))}
                          <td>{getElementAvrage(data.field_input) || "$ 0"}</td>
                          <td>
                            {getCurrentAndPriorChanges(data.field_input) ||
                              "$ 0"}
                          </td>
                        </tr>
                      )
                    )
                )}
              <tr>
                <td>
                  <b>NET INCOME</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET INCOME"]
                        ? taxFormData[item]["NET INCOME"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("NET INCOME") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("NET INCOME") || "$      0"}</td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>RECONCILIATION OF NET WORTH </td>
              </tr>
              <br></br>
              <tr>
                <td>Beginning Net Worth</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS25"]
                        ? taxFormData[item]["IS25"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS25") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS25") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>Profitability/(loss)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Profitability/(loss)"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Profitability/(loss)") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Profitability/(loss)") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Dividends (disbursements)</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS26"]
                        ? taxFormData[item]["IS26"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS26") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS27"]
                        ? taxFormData[item]["IS27"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS27") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS27") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>Adjustment Required ss179</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS28"]
                        ? taxFormData[item]["IS28"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS28") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS28") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Ending Net Worth</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Ending Net Worth"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Ending Net Worth") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Ending Net Worth") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Balancing line</b>
                </td>
              </tr>
              <br></br>

              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>
                  C A S H F L O W A N A L Y S I S <br></br> Sources/(Uses)
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Sales - Net</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Sales - Net"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Sales - Net") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Sales - Net") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in A/R</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Change in A/R"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Change in A/R") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change in A/R") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>CASH FROM SALES</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH FROM SALES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("CASH FROM SALES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("CASH FROM SALES") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Cost of Goods Sold</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Cost of Goods Sold"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Cost of Goods Sold") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Cost of Goods Sold") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in inventories</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in inventories"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in inventories") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in inventories") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in Payables</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in Payables"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Change in Payables") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change in Payables") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CASH PRODUCTION COST</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH PRODUCTION COST"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CASH PRODUCTION COST") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CASH PRODUCTION COST") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>GROSS CASH MARGIN</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["GROSS CASH MARGIN"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("GROSS CASH MARGIN") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("GROSS CASH MARGIN") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>SG & A Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["SG & A Expenses"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("SG & A Expenses") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("SG & A Expenses") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change In Prepaids</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Prepaids"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Change In Prepaids") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change In Prepaids") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change In Other Taxes</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Other Taxes"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change In Other Taxes") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change In Other Taxes") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change In Accruals</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Accruals"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Change In Accruals") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change In Accruals") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CASH OPERATING EXPENSE</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH OPERATING EXPENSE"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CASH OPERATING EXPENSE") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CASH OPERATING EXPENSE") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{}}>
                <td>CASH AFTER OPERATIONS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH AFTER OPERATIONS"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CASH AFTER OPERATIONS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CASH AFTER OPERATIONS") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Other Income</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS19"]
                        ? taxFormData[item]["IS19"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS19") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS19") || "$      0"}</td>
              </tr>
              <tr>
                <td>Other Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Other Expenses"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Other Expenses") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Other Expenses") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Income Taxes Paid</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Income Taxes Paid"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Income Taxes Paid") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Income Taxes Paid") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in N/R</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Change in N/R"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Change in N/R") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change in N/R") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in Misc. Assets</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in Misc. Assets"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in Misc. Assets") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in Misc. Assets") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in Misc. Liabilities</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in Misc. Liabilities"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in Misc. Liabilities") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in Misc. Liabilities")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> NET CASH AFTER OPERATIONS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET CASH AFTER OPERATIONS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET CASH AFTER OPERATIONS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH AFTER OPERATIONS")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>CPLTD</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["BS20"]
                        ? taxFormData[item]["BS20"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("BS20") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS20") || "$      0"}</td>
              </tr>
              <tr>
                <td>Interest Expense</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS18"]
                        ? taxFormData[item]["IS18"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS18") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS18") || "$      0"}</td>
              </tr>
              <tr>
                <td>Dividends Paid</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS26"]
                        ? taxFormData[item]["IS26"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS26") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> DEBT AMORTIZATION & FIN. COSTS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["DEBT AMORTIZATION & FIN. COSTS"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("DEBT AMORTIZATION & FIN. COSTS") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("DEBT AMORTIZATION & FIN. COSTS")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> NET CASH INCOME AFTER FIN. COST</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET CASH INCOME AFTER FIN. COST"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET CASH INCOME AFTER FIN. COST") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH INCOME AFTER FIN. COST")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Capital Expenditures</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Capital Expenditures"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Capital Expenditures") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Capital Expenditures") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change in L/T Investments</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in L/T Investments"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in L/T Investments") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in L/T Investments")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td> FINANCING SURPLUS/</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["FINANCING SURPLUS/"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("FINANCING SURPLUS/") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("FINANCING SURPLUS/") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td> REQUIREMENTS</td>
              </tr>
              <br></br>
              <tr>
                <td>Change In Overdraft</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Overdraft"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Change In Overdraft") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change In Overdraft") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change In Short-term Debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Short-term Debt"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change In Short-term Debt") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change In Short-term Debt")}
                </td>
              </tr>
              <tr>
                <td>Change in Long-Term debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in Long-Term debt"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in Long-Term debt") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in Long-Term debt") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Change In Equity</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change In Equity"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Change In Equity") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Change In Equity") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> TOTAL EXTERNAL FINANCING</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL EXTERNAL FINANCING"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TOTAL EXTERNAL FINANCING") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL EXTERNAL FINANCING") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td> CASH AFTER FINANCING</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH AFTER FINANCING"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CASH AFTER FINANCING") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CASH AFTER FINANCING") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td> ACTUAL CHANGE IN CASH</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ACTUAL CHANGE IN CASH"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("ACTUAL CHANGE IN CASH") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("ACTUAL CHANGE IN CASH") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>TRADITIONAL CASH FLOW</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TRADITIONAL CASH FLOW"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TRADITIONAL CASH FLOW") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TRADITIONAL CASH FLOW") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>STATEMENT DATE</td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>R A T I O S</td>
              </tr>
              <br></br>
              <tr>
                <td>ANNUALIZING FACTOR</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ANNUALIZING FACTOR"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("ANNUALIZING FACTOR") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("ANNUALIZING FACTOR") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>ANNUALIZED SALES GROWTH</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ANNUALIZED SALES GROWTH"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("ANNUALIZED SALES GROWTH") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("ANNUALIZED SALES GROWTH") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>LIQUIDITY:============================</b>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>WORKING CAPITAL</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["WORKING CAPITAL"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("WORKING CAPITAL") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("WORKING CAPITAL") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>CURRENT RATIO</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["CURRENT RATIO"] || "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(getElementAvrage("CURRENT RATIO") || "$      0").replace(
                    "$",
                    ""
                  )}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("CURRENT RATIO") || "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>QUICK RATIO</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(taxFormData[item]["QUICK RATIO"] || "$      0").replace(
                        "$",
                        ""
                      )}
                    </td>
                  ))}
                <td>
                  {(getElementAvrage("QUICK RATIO") || "$      0").replace(
                    "$",
                    ""
                  )}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("QUICK RATIO") || "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>AGE OF RECEIVABLES - DAYS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["AGE OF RECEIVABLES - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("AGE OF RECEIVABLES - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("AGE OF RECEIVABLES - DAYS")}
                </td>
              </tr>
              <tr>
                <td>INVENTORY TURNOVER - DAYS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INVENTORY TURNOVER - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("INVENTORY TURNOVER - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("INVENTORY TURNOVER - DAYS")}
                </td>
              </tr>
              <tr>
                <td>AGE OF PAYABLES - DAYS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["AGE OF PAYABLES - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("AGE OF PAYABLES - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("AGE OF PAYABLES - DAYS") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>COVERAGE:===========================</b>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>INTEREST COVERAGE</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INTEREST COVERAGE"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("INTEREST COVERAGE") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("INTEREST COVERAGE") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>NET CASH AFTER OPER./CPLTD</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET CASH AFTER OPER./CPLTD"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET CASH AFTER OPER./CPLTD") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH AFTER OPER./CPLTD")}
                </td>
              </tr>
              <tr>
                <td>TRADITIONAL CASH FLOW/CPLTD</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TRADITIONAL CASH FLOW/CPLTD"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TRADITIONAL CASH FLOW/CPLTD") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TRADITIONAL CASH FLOW/CPLTD")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>LEVERAGE:=============================</b>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>DEBT/TNW</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["DEBT/TNW"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("DEBT/TNW") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("DEBT/TNW") || "$      0"}</td>
              </tr>
              <tr>
                <td>CREDITORS EQUITY/TOTAL ASSETS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CREDITORS EQUITY/TOTAL ASSETS"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CREDITORS EQUITY/TOTAL ASSETS") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CREDITORS EQUITY/TOTAL ASSETS")}
                </td>
              </tr>
              <tr>
                <td>OWNERS EQUITY/TOTAL ASSETS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["OWNERS EQUITY/TOTAL ASSETS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("OWNERS EQUITY/TOTAL ASSETS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("OWNERS EQUITY/TOTAL ASSETS")}
                </td>
              </tr>
              <tr>
                <td>NET FIXED ASSETS/LTD</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET FIXED ASSETS/LTD"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET FIXED ASSETS/LTD") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET FIXED ASSETS/LTD") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>PROFITABILITY:=======================</b>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>GROSS PROFIT MARGIN %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["GROSS PROFIT MARGIN %"] || "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("GROSS PROFIT MARGIN %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("GROSS PROFIT MARGIN %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>PRE-TAX PROFIT MARGIN %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["PRE-TAX PROFIT MARGIN %"] ||
                        "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("PRE-TAX PROFIT MARGIN %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("PRE-TAX PROFIT MARGIN %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>NET PROFIT AFTER TAXES %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["NET PROFIT AFTER TAXES %"] ||
                        "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("NET PROFIT AFTER TAXES %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("NET PROFIT AFTER TAXES %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>RETURN ON EQUITY (ROE) %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["RETURN ON EQUITY (ROE) %"] ||
                        "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("RETURN ON EQUITY (ROE) %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("RETURN ON EQUITY (ROE) %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>RETURN ON ASSETS (ROA) %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["RETURN ON ASSETS (ROA) %"] ||
                        "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("RETURN ON ASSETS (ROA) %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("RETURN ON ASSETS (ROA) %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>BANKRUPTCY RATIO: Z SCORE</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BANKRUPTCY RATIO: Z SCORE"]}</td>
                  ))}
                <td>
                  {getElementAvrage("BANKRUPTCY RATIO: Z SCORE") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("BANKRUPTCY RATIO: Z SCORE")}
                </td>
              </tr>
              <tr>
                <td>Z {"<"} 1.80 WEAK COMPANY</td>
              </tr>
              <tr>
                <td>Z {">"} 2.70 STRONG COMPANY</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>OPERATING CAPITAL REQUIREMENTS=======</b>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CASH / AVG DAYS SALES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH / AVG DAYS SALES"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("CASH / AVG DAYS SALES") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("CASH / AVG DAYS SALES") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>RECEIVABLES / AVG DAYS SALES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["RECEIVABLES / AVG DAYS SALES"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("RECEIVABLES / AVG DAYS SALES") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("RECEIVABLES / AVG DAYS SALES")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>INVENTORY / AVG DAYS SALES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INVENTORY / AVG DAYS SALES"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("INVENTORY / AVG DAYS SALES") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("INVENTORY / AVG DAYS SALES")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> TOTAL TRADING CYCLE</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL TRADING CYCLE"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("TOTAL TRADING CYCLE") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL TRADING CYCLE") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>PAYABLES / AVG DAYS SALES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["PAYABLES / AVG DAYS SALES"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("PAYABLES / AVG DAYS SALES") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("PAYABLES / AVG DAYS SALES")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> NET CASH CYCLE</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["NET CASH CYCLE"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("NET CASH CYCLE") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH CYCLE") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>OPERATING CAPITAL REQUIRED </b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["OPERATING CAPITAL REQUIRED"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("OPERATING CAPITAL REQUIRED") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("OPERATING CAPITAL REQUIRED")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>FINANCIAL STATEMENTS ON:</td>
              </tr>
              <br></br>
              <tr>
                <td>TYPE OF REPORT</td>
                {taxYear &&
                  taxYear.map((item, index) => <td>{"Form Year"}</td>)}
              </tr>
              <tr>
                <td>STATEMENT DATE</td>
                {taxYear && taxYear.map((item, index) => <td>{"YEAR"}</td>)}
              </tr>
              <tr>
                <td># OF MONTHS</td>
                {taxYear &&
                  taxYear.map((item, index) => <td>{"Manual MO"}</td>)}
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>H I G H L I G H T S </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>INCOME STATEMENT:</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>ANNUALIZED SALES GROWTH</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ANNUALIZED SALES GROWTH _743"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("ANNUALIZED SALES GROWTH _743") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("ANNUALIZED SALES GROWTH _743") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>Gross Revenues</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["IS 1"]
                        ? taxFormData[item]["IS 1"]
                        : "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("IS 1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS 1") || "$      0"}</td>
              </tr>
              <tr>
                <td>GROSS PROFIT</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["GROSS PROFIT"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("GROSS PROFIT") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("GROSS PROFIT") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Total Operating Expenses</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Operating Expenses"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Operating Expenses") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Operating Expenses") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>INCOME BEFORE TAXES</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INCOME BEFORE TAXES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("INCOME BEFORE TAXES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("INCOME BEFORE TAXES") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>NET INCOME</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["NET INCOME"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("NET INCOME") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("NET INCOME") || "$      0"}</td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>CASH DIVIDENDS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["IS26"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("IS26") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("IS26") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>GROSS PROFIT MARGIN %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["GROSS PROFIT MARGIN %"] || "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("GROSS PROFIT MARGIN %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("GROSS PROFIT MARGIN %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>RETURN ON EQUITY (ROE) %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["RETURN ON EQUITY (ROE) %"]
                        ? taxFormData[item]["RETURN ON EQUITY (ROE) %"].replace(
                            "$",
                            ""
                          )
                        : "$      0"}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("RETURN ON EQUITY (ROE) %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("RETURN ON EQUITY (ROE) %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>RETURN ON ASSETS (ROA) %</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["RETURN ON ASSETS (ROA) %"]
                        ? taxFormData[item]["RETURN ON ASSETS (ROA) %"].replace(
                            "$",
                            ""
                          )
                        : "$      0"}
                    </td>
                  ))}
                <td>
                  {(
                    getElementAvrage("RETURN ON ASSETS (ROA) %") || "$      0"
                  ).replace("$", "")}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("RETURN ON ASSETS (ROA) %") ||
                    "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>BALANCE SHEET:</td>
              </tr>
              <br></br>
              <tr>
                <td>Cash</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["BS1"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("BS1") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("BS1") || "$      0"}</td>
              </tr>
              <tr>
                <td>Current Assets</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["Current Assets"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("Current Assets") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Current Assets") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>Net Fixed Assets</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Net Fixed Assets"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Net Fixed Assets") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Net Fixed Assets") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>TOTAL ASSETS</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["TOTAL ASSETS"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("TOTAL ASSETS") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL ASSETS") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>Short-term Debt</td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Short-term Debt"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("Short-term Debt") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("Short-term Debt") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Total Current Liabilities</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Current Liabilities"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Current Liabilities") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Current Liabilities")}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Total Long-term Debt</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Total Long-term Debt"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Total Long-term Debt") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Total Long-term Debt") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>TOTAL LIABILITIES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL LIABILITIES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("TOTAL LIABILITIES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL LIABILITIES") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>NET WORTH</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["NET WORTH"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("NET WORTH") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("NET WORTH") || "$      0"}</td>
              </tr>
              <br></br>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td>RATIOS:</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CURRENT RATIO</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(
                        taxFormData[item]["CURRENT RATIO"] || "$      0"
                      ).replace("$", "")}
                    </td>
                  ))}
                <td>
                  {(getElementAvrage("CURRENT RATIO") || "$      0").replace(
                    "$",
                    ""
                  )}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("CURRENT RATIO") || "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>
                  <b>QUICK RATIO</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {(taxFormData[item]["QUICK RATIO"] || "$      0").replace(
                        "$",
                        ""
                      )}
                    </td>
                  ))}
                <td>
                  {(getElementAvrage("QUICK RATIO") || "$      0").replace(
                    "$",
                    ""
                  )}
                </td>
                <td>
                  {(
                    getCurrentAndPriorChanges("QUICK RATIO") || "$      0"
                  ).replace("$", "")}
                </td>
              </tr>
              <tr>
                <td>
                  <b>WORKING CAPITAL</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["WORKING CAPITAL"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("WORKING CAPITAL") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("WORKING CAPITAL") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>AGE OF RECEIVABLES - DAYS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["AGE OF RECEIVABLES - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("AGE OF RECEIVABLES - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("AGE OF RECEIVABLES - DAYS")}
                </td>
              </tr>
              <tr>
                <td>
                  <b>INVENTORY TURNOVER - DAYS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["INVENTORY TURNOVER - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("INVENTORY TURNOVER - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("INVENTORY TURNOVER - DAYS")}
                </td>
              </tr>
              <tr>
                <td>
                  <b>AGE OF PAYABLES - DAYS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["AGE OF PAYABLES - DAYS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("AGE OF PAYABLES - DAYS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("AGE OF PAYABLES - DAYS") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>DEBT/TNW</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>{taxFormData[item]["DEBT/TNW"] || "$      0"}</td>
                  ))}
                <td>{getElementAvrage("DEBT/TNW") || "$      0"}</td>
                <td>{getCurrentAndPriorChanges("DEBT/TNW") || "$      0"}</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>CASH FLOW: Incr. (Decr.) in Cash</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b> CASH FROM SALES</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["CASH FROM SALES"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("CASH FROM SALES") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("CASH FROM SALES") || "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b> GROSS CASH MARGIN</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["GROSS CASH MARGIN"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("GROSS CASH MARGIN") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("GROSS CASH MARGIN") || "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> NET CASH AFTER OPERATIONS</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET CASH AFTER OPERATIONS"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET CASH AFTER OPERATIONS") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH AFTER OPERATIONS")}
                </td>
              </tr>
              <tr>
                <td>
                  <b> NET CASH INCOME AFTER FIN. COST</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["NET CASH INCOME AFTER FIN. COST"]}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("NET CASH INCOME AFTER FIN. COST") ||
                    "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("NET CASH INCOME AFTER FIN. COST")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Capital Expenditures</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Capital Expenditures"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Capital Expenditures") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Capital Expenditures") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Change in L/T Investments</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["Change in L/T Investments"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("Change in L/T Investments") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("Change in L/T Investments")}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b> FINANCING SURPLUS/</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["FINANCING SURPLUS/"] || "$      0"}
                    </td>
                  ))}
                <td>{getElementAvrage("FINANCING SURPLUS/") || "$      0"}</td>
                <td>
                  {getCurrentAndPriorChanges("FINANCING SURPLUS/") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b> TOTAL EXTERNAL FINANCING</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TOTAL EXTERNAL FINANCING"] ||
                        "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TOTAL EXTERNAL FINANCING") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TOTAL EXTERNAL FINANCING") ||
                    "$      0"}
                </td>
              </tr>
              <tr>
                <td>
                  <b> ACTUAL CHANGE IN CASH</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["ACTUAL CHANGE IN CASH"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("ACTUAL CHANGE IN CASH") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("ACTUAL CHANGE IN CASH") ||
                    "$      0"}
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>TRADITIONAL CASH FLOW</b>
                </td>
                {taxYear &&
                  taxYear.map((item, index) => (
                    <td>
                      {taxFormData[item]["TRADITIONAL CASH FLOW"] || "$      0"}
                    </td>
                  ))}
                <td>
                  {getElementAvrage("TRADITIONAL CASH FLOW") || "$      0"}
                </td>
                <td>
                  {getCurrentAndPriorChanges("TRADITIONAL CASH FLOW") ||
                    "$      0"}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TaxAnaysis;
