import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import YearApi from "../../services/API/YearApi";
import DataProcess from "../../common/Helper";
import { IoClose } from "react-icons/io5";
import Cookies from "js-cookie";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import { TablePagination } from "@material-ui/core";
import AuthService from "../../services/auth.service";
import SpreadApi from "../../services/API/SpreadApi";
import SpreadDataApi from "../../services/API/SpreadData";
import SpreadConfigurationApi from "../../services/API/spreadConfigurationApi";
import axios from "axios";
import { eventWrapper } from "@testing-library/user-event/dist/utils";

const SpreadData = () => {
  const [spreads, setSpreads] = useState([]);
  const [spreadsData, setSpreadsData] = useState([]);
  const [configs, setConfig] = useState([]);
  const [config, setConfigData] = useState("");
  const [show, setShow] = useState(false);
  const [inputValue, setinputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [dataid, setDataId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [spread, setSpread] = useState("");

  useEffect(() => {
    searchSpreads();
    searchconfig()
    getAllSpreads()
  }, [page, rowsPerPage]);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);

 

  const handleCancelClick = () => {
    handleClose();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleInputChange = (e) => {
    setinputValue(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllSpreads = async () => {
    const response = await SpreadDataApi.GetAllSpreadData(page, rowsPerPage, sortBy, sortOrder);
    let result = JSON.parse(DataProcess.decryptData(response.data.result))
    setSpreadsData(result.data);
    setTotalItems(result.count);
  };


  const searchSpreads = async () => {
    const response = await SpreadApi.searchSpread(page, rowsPerPage, sortBy, sortOrder);
    let result = JSON.parse(DataProcess.decryptData(response.data.result))
    setSpreads(result.data);
  };

  const searchconfig = async () => {
    const response = await SpreadConfigurationApi.SearchSpreadConfig();
    let result = JSON.parse(DataProcess.decryptData(response.data.result))
    setConfig(result.data);
  };

  const addNewYear = async (e) => {
    e.preventDefault();

    let reqJson = {
      spread_id: spread,
      config_id: config,
      value: inputValue
    }

    reqJson = DataProcess.encryptData(JSON.stringify(reqJson))
  
    if (inputValue.trim() !== "") {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_ENV_API +"/spread_data/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({ reqJson: reqJson }),
        });

        if (response.ok) {
          const newYear = await response.json();
          setSpreads([...spreads, newYear]);
          setinputValue("");
          handleClose();
          toasterSuccess("Year added successfully");
        } else {
          response.json().then((error) => {
            let errorData = JSON.parse(DataProcess.decryptData(error.resError));
            let errorMessage = errorData.message;
            toasterError(errorMessage);

          })
        }
      } catch (error) {
        toasterError(error);
      }
      getAllSpreads()
    }
  };


  const handleConfirmDelete = async (id) => {
    await SpreadDataApi.DeleteSpreadData(id).then((res) => {


      if (res && res.data && res.data.result) {
        handleOff();
        setDataId(id);
        toasterSuccess("Spread Data deleted successfully")
        getAllSpreads()
      } else {

        res.json().then((error) => {
          let errorData = JSON.parse(DataProcess.decryptData(error.resError));
          let errorMessage = errorData.message;
          toasterError(errorMessage);

          handleClose();
          getAllSpreads()
        });

      };
    })

  };

  const handleDelete = (id) => {
    handleOpen();
    setDataId(id);

  };

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');

    }
    getAllSpreads()
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setSpread(selectedOption)
  };

  const handleConfigOptionChange = (event) => {
    const selectedOption = event.target.value;
    setConfigData(selectedOption)
  };

  return (
    <>
      <div className="container mb-4">
        <div className="container mb-9 d-flex">
          <h3>SpreadData Management</h3>
          <span className="btnclass">
            <Button
              style={{
                backgroundColor: "#c00000",
                color: "white",
                width: "200px",
              }}
              onClick={handleShow}
            >
              Add SpreadData
            </Button>
          </span>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Add SpreadData</Modal.Title>
            <IoClose
              size={24}
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleCancelClick}
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={addNewYear}>
              <Form.Group controlId="formFName">
                <Form.Label>Spread</Form.Label>

                <Form.Control as="select" onChange={handleOptionChange}>
                  <option disabled selected>
                    select
                  </option>
                  {spreads.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>

                <Form.Label>Spread Configuration</Form.Label>

                <Form.Control as="select" onChange={handleConfigOptionChange}>
                  <option disabled selected>
                    select
                  </option>
                  {configs.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.fieldname}
                    </option>
                  ))}
                </Form.Control>
                <Form.Label>value</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter New value"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                style={{ marginTop: "15px", backgroundColor: "#c00000" }}
                type="submit"
              >
                Create SpreadData
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <div className="container mt-3">
          <Table
            striped
            bordered
            hover
            style={{ width: "100%", alignItems: "center" }}
          >
            <thead>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <td onClick={() => handleSort('configname')}>Spread 
                  {sortBy === 'configname' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}</td>
                <td onClick={() => handleSort('fieldname')}>Spread configuration
                  {sortBy === 'fieldname' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}</td>
                <td onClick={() => handleSort('value')}>value
                  {sortBy === 'value' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}</td>
                <td>Action </td>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {spreadsData.map((i) => (
                <tr key={i.id}>
                  <td>{i.configname}</td>
                  <td>{i.fieldname}</td>
                  <td>{i.value}</td>
                  <td>
                    <Link to={`/editspreaddata/${i.id}`}>
                      <FaEdit />
                    </Link>{" "}
                    /
                    <Link onClick={(id) => handleDelete(i.id)}>
                      {" "}
                      <FaTrash />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </div>
      </div>
      <Modal show={open} onHide={handleOff}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
          <IoClose
            size={24}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={handleCancelDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="primary"
            style={{
              marginTop: "15px",
              marginRight: "5px",
              backgroundColor: "#c00000",
            }}
            type="submit"
            onClick={() => handleConfirmDelete(dataid)}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            style={{ marginTop: "15px" }}
            type="submit"
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default SpreadData;
