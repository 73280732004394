import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import AuthService from "../auth.service";

const API_URL = process.env.REACT_APP_SERVER_ENV_API + "/spread_configuration/";
const API_URL_MASTER = process.env.REACT_APP_SERVER_ENV_API + "/spread_configration_master/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const CreateSpreadConfig = () => {
  return axios.post(API_URL + "create", {});
};

const GetSpreadConfig = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  let Data = DataProcess.encryptData(JSON.stringify({}));
  return axios.get(API_URL + "get/" + id, { headers: headers });
};

const DeleteSpreadConfig = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  return axios.delete(API_URL + "delete/" + id, {
    headers: headers
  });
};

const GetAllSpreadConfig = (skip, limit, sortBy, sortOrder, forms, year) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  let URL = API_URL + `get?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`

  if (year) {
    URL += `&year=${year}`;
  }
  if (forms) {
    URL += `&form_type='${forms}'`;
  }
  return axios.get(URL, {
    headers: headers
  });
};

const SearchSpreadConfig = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  return axios.get(API_URL + `search`, {
    headers: headers
  });
};

const UpdateSpreadConfig = async (updatedData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  const response = await fetch(API_URL + "update/" + updatedData.id, {
    method: "PUT",
    headers: headers,
    body: updatedData,
  });
};

const SpreadConfigYear = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  return axios.get(API_URL + "year", {
    headers: headers
  })
}

const SpreadConfigFormType = async (year) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  let url = API_URL + "form_type";

  if (year) {
    url += `?year=${year}`;
  }

  return axios.get(url, {
    headers: headers
  })
}

const SpreadConfigForm = async (year) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  let url = API_URL + "form_type";

  if (year) {
    url += `?year=${year}`;
  }

  return axios.get(url, {
    headers: headers
  })

}


const getSpreadConfigData = async (year, form_type) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  let url = API_URL + `get-configration-form-data?form_type=${form_type}&year=${year}`;

  return axios.get(url, {
    headers: headers
  })
}

const DeleteMultipleSpreadConfig = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  // return axios.post(API_URL + "delete-multiple-spread-configration/" + id, {
  //   headers: headers
  // });

  await fetch(API_URL + "delete-multiple-spread-configration/" + id, {
    method: "PUT",
    headers: headers,
  });

};

const getSearchConfigration = async (form_type, q) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  let url = API_URL_MASTER + `search?form_type=${form_type}&q=${q}`

  return axios.get(url, {
    headers: headers
  })
}

const exportSpreadConfigData = async (year, form_type) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  }

  let url;

  if (form_type && year) {
    url = API_URL + `export?form_type=${form_type}&year=${year}`;
  } else if (year) {
    url = API_URL + `export?year=${year}`;
  } else if (form_type) {
    url = API_URL + `export?form_type=${form_type}`;
  } else {
    url = API_URL + `export`
  }

  return axios.get(url, {
    headers: headers
  })
}

const SpreadConfigurationApi = {
  CreateSpreadConfig,
  GetSpreadConfig,
  DeleteSpreadConfig,
  GetAllSpreadConfig,
  UpdateSpreadConfig,
  SearchSpreadConfig,
  SpreadConfigYear,
  SpreadConfigForm,
  SpreadConfigFormType,
  getSpreadConfigData,
  DeleteMultipleSpreadConfig,
  getSearchConfigration,
  exportSpreadConfigData
};

export default SpreadConfigurationApi;
