import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import AuthService from "../auth.service";

const API_URL = process.env.REACT_APP_SERVER_ENV_API + "/sendgrid/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const GetSendGrid = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  let Data = DataProcess.encryptData(JSON.stringify({}));
  return axios.get(API_URL + "get", { headers: headers });
};

const UpdateSendGridData = async (updatedData) => {
  const stringifiedReqJson = JSON.stringify(updatedData, (key, value) => {
    // Exclude React-specific properties or elements
    if (key.startsWith("__react") || key === "stateNode") {
      return undefined;
    }
    return value;
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  await fetch(API_URL + "update", {
    method: "PUT",
    headers: headers,
    body: JSON.stringify({
      reqJson: DataProcess.encryptData(stringifiedReqJson),
    }),
  });
};

const SendGridApi = {
  GetSendGrid,
  UpdateSendGridData,
};

export default SendGridApi;
