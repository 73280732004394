import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DataProcess from "../../common/Helper";
import {
  toasterError,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";
import { useEffect } from "react";
import SendGridApi from "../../services/API/sendGridApi";
import Cookies from "js-cookie";

const SendGridKey = () => {
  const [loading, setLoading] = useState(false);
  const [key, setkey] = useState("");
  const [api_key, setApiKey] = useState("");
  const [mailuser, setMailUser] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const onChangeKey = (e) => {
    const updatedKey = e.target.value;
    setkey(updatedKey);
  };

  const onChangeApiKey = (e) => {
    const updatedKey = e.target.value;
    setApiKey(updatedKey);
  };

  const onChangeMailUser = (e) => {
    const updatedMailUser = e.target.value;
    setMailUser(updatedMailUser);
  };

  useEffect(() => {
    SendGridApi.GetSendGrid().then(
      (response) => {
        response =  response && response.data && response.data.result ? JSON.parse(DataProcess.decryptData(response.data.result)) : ""
        console.log("response",response);
        if (response && response.data) {
          setkey(response.data.key)
          setApiKey(response.data.api_key)
          setMailUser(response.data.mailuser)
        }
      },
      (error) => {
        console.log("error.response", error.response);
        const _content =
          error.response &&
            error.response.data &&
            error.response.data.resError ? error.response.data.resError : "";
        let message = JSON.parse(DataProcess.decryptData(_content)).error.message
        console.log("_content", (JSON.parse(DataProcess.decryptData(error.response.data.resError))).error.message);
        toasterError(message)
      }
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e) {
      try {
        let response = await SendGridApi.UpdateSendGridData({ key: key, api_key: api_key, mailUser: mailuser })

        toasterSuccess("Sendgrid Key updated successfully!");


      } catch (error) {
        console.log("error", error);

        toasterError("Only admin can access this page!")
        // let msgError = DataProcess.decryptData(error);
        // msgError = JSON.parse(msgError);
        // msgError = msgError && msgError.error && msgError.error.message ? msgError.error.message : msgError && msgError.message ? msgError.message : "";
        // console.log("msgError", msgError);
        // toasterError(msgError);
      }

      // toasterSuccess("Sendgrid Key updated successfully")
    }
  };
  let isAdmin = Cookies.get("isadmin");
  return (
    <>
      {(isAdmin === "true" || isAdmin === true) && (

        <div className="com-md-12">
          <div style={{ textAlign: "center" }}>
            <h1>Change SendGrid key</h1>
          </div>
          <div className="card card-container">
            <Form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">SENDGRID API KEY</label>
                <input
                  type="text"
                  className="form-control"
                  name="api_key"
                  value={api_key}
                  onChange={onChangeApiKey}
                />
              </div>

              {/* <div className="form-group">
              <label htmlFor="username">SECRET_KEY</label>
              <input
                type="text"
                className="form-control"
                name="key"
                value={key}
                onChange={onChangeKey}
              />
            </div> */}

              <div className="form-group">
                <label htmlFor="username">SENDGRID MAILUSER</label>
                <input
                  type="email"
                  className="form-control"
                  name="mailuser"
                  value={mailuser}
                  onChange={onChangeMailUser}
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={
                    !api_key || !mailuser
                  }
                  style={{
                    backgroundColor:
                      !api_key || !mailuser
                        ? "gray"
                        : "blue",
                  }}
                >
                  {loading && <span className="spinner-border-sm"></span>}
                  <span>Change key</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      )}

    </>
  );
};

export default SendGridKey;
