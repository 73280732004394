import React, { useState } from "react";
import "../StartNewSpread/SpreadPDF.css";
import { FaTrash } from "react-icons/fa";
import Iframe from "react-iframe";
import Loader from "../../../components/Loader/Loader";
let newValue = [];

const SpreadPDF = ({ fileData, loading }) => {
  const [updateValue, setUpdateValue] = useState([]);

  const handleInputValueChange = (event, id) => {
    // console.log("NEW", id);
    // const { value } = event.target;
    // console.log("NEW", id, "==", value);
    // newValue.push({ id: id, value: value });
    // setResult((prevResult) => {
    //   return prevResult.map((group) => {
    //     return group.map((item) => {
    //       if (item.id === id) {
    //         newValue.push({ id: id, value: value });
    //         return { ...item, value: value };
    //       }
    //       return item;
    //     });
    //   });
    // });
    // console.log("NEWVALUE", newValue);
    // console.log("updateValue", updateValue);
    // const filteredArray = Array.from(
    //   new Map(newValue.map((item) => [item.id, item])).values()
    // );
    // console.log("filteredArray", filteredArray);
    // setUpdateValue(filteredArray);
  };
  return (
    <>
      {loading &&(
        <Loader />
      )} 
        <>
          {fileData &&
            fileData.map((item, key) => {
              let resultData = item.result;
              // let resultData = result[key];
              return (
                <div className="col-lg-12 wrap-section mb-4">
                  <div className="top-bar text-center">
                    <p>
                      Upload #{item.fileData.upload_id} for Year{" "}
                      {item.fileData.year} from {item.fileData.form}{" "}
                      <span className="float-end px-4">
                        <FaTrash />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-12 left-wrap">
                    <div className="row">
                      <div className="col-lg-8 left-panel">
                        <div className="left-side-top d-flex flex-row bd-highlight mb-3 btn-section">
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary btn-11b btn-12b"
                            >
                              NOTES
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary adjest"
                            >
                              Adjustments
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary debt"
                            >
                              Debt Payments
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary addit"
                            >
                              Additions
                            </button>
                          </div>
                        </div>
                        <div className=" d-flex flex-row bd-highlight mb-3 btn-section">
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary btn-10b btn-13b"
                            >
                              1040
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button type="button" className="btn btn-primary">
                              1040 Schedule 1
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button type="button" className="btn btn-primary">
                              1040 Schedule 2
                            </button>
                          </div>
                          <div className="p-0 bd-highlight">
                            <button
                              type="button"
                              className="btn btn-primary lst-c"
                            >
                              1050 Schedule c
                            </button>
                          </div>
                        </div>
                        <div className="left-image-content">
                          <Iframe
                            className="iframes"
                            url={item.data}
                            width="100%"
                            type="application/pdf"
                            id="myId"
                            height="500px"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 right-part">
                        <div className="right-side-content">
                          <div className="right-side-content d-flex flex-row bd-highlight mb-3">
                            <div class="bg-color text-center desc-sec dsc">
                              <p
                                className="mb-1 pt-2"
                                style={{ color: "black" }}
                              >
                                Name of Individual or
                                <br />
                                Entity
                                <br />
                                Line Description
                              </p>
                              {/* <p className="desc-content mb-0">
                            {item.result[0].fieldname}
                          </p> */}

                              {resultData &&
                                resultData.map((item, key) => {
                                  return (
                                    <p
                                      className="desc-content mb-0 mt-0"
                                      key={key}
                                    >
                                      {item.fieldname}
                                    </p>
                                  );
                                })}
                            </div>
                            <div className=" line-content">
                              <p
                                className="pt-1 pb-0 lin-t"
                                style={{ color: "black" }}
                              >
                                Line#
                              </p>
                              {/* <p className="line-list mb-0">
                            {item.result[0].field_label}
                          </p> */}
                              {resultData &&
                                resultData.map((item, key) => {
                                  return (
                                    <p
                                      className="desc-content mb-0 mt-0"
                                      key={key}
                                    >
                                      {item.field_label}
                                    </p>
                                  );
                                })}
                            </div>
                            <div className=" name-content">
                              <p className="mb-0 set-colum pt-4">Enter Name</p>
                              <p className="mb-0 set-colum amt-content">
                                Entry Amount
                              </p>
                              {/* <p className="mb-0 set-colum text-right px-1">
                            {item.result[0].value}
                          </p> */}

                              {resultData &&
                                resultData.map((item, key) => {
                                  const { id, value } = item;
                                  return (
                                    <input
                                      key={id}
                                      className="desc-content mb-0 int-desc intc"
                                      type="text"
                                      value={value}
                                      onChange={(e) =>
                                        handleInputValueChange(e, id)
                                      }
                                    />
                                  );
                                })}
                            </div>
                          </div>
                          {/* <Modal show={open} onHide={handleOff}>
                        <Modal.Header>
                          <Modal.Title>
                            Are you sure you want to delete?
                          </Modal.Title>
                          <IoClose
                            size={24}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={handleCancelDelete}
                          />
                        </Modal.Header>
                        <Modal.Body>
                          <Button
                            variant="primary"
                            style={{
                              marginTop: "15px",
                              marginRight: "5px",
                              backgroundColor: "#c00000",
                            }}
                            type="submit"
                            onClick={() => handleConfirmDelete()}
                          >
                            Delete
                          </Button>
                          <Button
                            variant="primary"
                            style={{ marginTop: "15px" }}
                            type="submit"
                            onClick={handleCancelDelete}
                          >
                            Cancel
                          </Button>
                        </Modal.Body>
                      </Modal> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
    
    </>
  );
};

export default SpreadPDF;
