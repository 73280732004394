import React, { useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import DataProcess from "../../common/Helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  toasterError,
  toasterInfo,
  toasterSuccess,
} from "../../components/ToasterMessages/toastify.helper";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e) {
      AuthService.forgetPassword(email).then((res) => {
        if (res.status) {
          toasterSuccess("Password send successfully!");
          toasterInfo(
            "Password in your mail is available for 24 hours only,so you have to change your password after login."
          );

          navigate("/login");
        } else {
          let errorMessage = JSON.parse(DataProcess.decryptData(res.error));
          toasterError(errorMessage.message);
        }
      });
    }
  };

  return (
    <>
      <div
        className="col-12-md"
        style={{
          backgroundColor: "#343a40",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="card card-container"
          style={{ border: "1px solid black", borderRadius: "15px" }}
        >
          <img
            src="Robospreads-logo.png"
            alt="profile-img"
            style={{ marginBottom: "30px" }}
          />
          <Form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Username</label>
              <Input
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-block" disabled={!email} style={{backgroundColor: !email ? "gray" : "blue"}}>
                {loading && (
                  <span className=" spinner-border-sm"></span>
                )}
                <span>Forgot Password</span>
              </button>
            </div>
            <div>
              <p>
                <Link to="/login">Back To Login</Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
