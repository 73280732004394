import axios from "axios";
import DataProcess from "../../common/Helper";
import Cookies from "js-cookie";
import AuthService from "../auth.service";

const API_URL = process.env.REACT_APP_SERVER_ENV_API +"/spread/";

const validToken = () => {
  let token = Cookies.get("token");
  token = DataProcess.decryptData(token);
  token = JSON.parse(token);
  return token.token;
};

const CreateNewSpread = async (spreadData) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  let data = DataProcess.encryptData(
    JSON.stringify(spreadData)
  );

  await axios.post(API_URL + "create", {
    headers: headers,
    reqJson: data,
  })
    .then((response) => {
      if (response.data.result) {
      }

      return {
        status: true,
        result: response.data.result,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: false,
          error: error.response.data.resError,
        };
      }
    });
};

const GetSpread = (id) => {
  let Data = DataProcess.encryptData(JSON.stringify({}));

  return axios.get(API_URL + "get/" + id, {});
};

const GetAllSpread = (skip, limit, sortBy, sortOrder) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };
  return axios.get(API_URL + `get?page=${skip}&limit=${limit}&sortBy=${sortBy}&orderBy=${sortOrder}`);
};

const DeleteSpread = (id) => {
  return axios.delete(API_URL + "delete" + id, {});
};

const UpdateSpread = (id) => {
  return axios.put(API_URL + "update" + id, {});
};

const searchSpread =()=>{
  return axios.get(API_URL + "search" , {});
}

const DeleteSpreadImage = (id, upload_Id) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AuthService.validToken(),
  };

  return axios.delete(API_URL + "delete-image/" + id + "?upload_Id="+ upload_Id,{
    headers: headers,
  
  })
}
const SpreadApi = {
  CreateNewSpread,
  GetSpread,
  GetAllSpread,
  DeleteSpread,
  UpdateSpread,
  searchSpread,
  DeleteSpreadImage
};

export default SpreadApi;
