import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import DataProcess from "../../../common/Helper";
import {
  toasterError,
  toasterSuccess,
} from "../../../components/ToasterMessages/toastify.helper";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import ReactSwitch from "react-switch";
import YearApi from "../../../services/API/YearApi";
import "../MySpread/EditSpread.css";
import myImage from "../../../assets/image/cloud-computing.png";
import Iframe from "react-iframe";
import { FaTrash } from "react-icons/fa";
import SpreadApi from "../../../services/API/SpreadApi";
import MySpreadApi from "../../../services/API/myspreadApi";
import Cookies from "js-cookie";
import { IoClose } from "react-icons/io5";
import SpreadConfigurationApi from "../../../services/API/spreadConfigurationApi";
import { getValue } from "@testing-library/user-event/dist/utils";
import Loader from "../../../components/Loader/Loader";
import { AutoLogout } from "../../Logout/Logout";
let newValue = [];
let image = "";
let imageNotesArray = [];
let keyId;
let keyObject = {};
let buttonKey = 0;
let tabOpen;
let apiResultData = [];
const EditSpread = (props) => {
  const { id } = useParams();
  const [updateValue, setUpdateValue] = useState([]);
  const [name, setName] = useState("");
  const [data, setData] = useState({
    name: "",
    notes: "",
    year: null,
    corporateForm: "",
    paths: [],
    updatedValues: updateValue,
    percentageValueCash: [],
  });
  const [result, setResult] = useState([]);
  const [results, setResults] = useState([]);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState("");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [switchValueUpload, setSwitchValueUpload] = useState(false);
  const [dataid, setDataId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [dataPath, setDataPath] = useState("");
  const [form, setForm] = useState("");
  const [configYear, setConfigYear] = useState([]);
  const [formType, setFormType] = useState([]);
  const [notes, setNotes] = useState("");
  const [openNotes, setOpenNotes] = useState({});
  const [openAdjust, setOpenAdjust] = useState({});
  const [openDebt, setOpenDebt] = useState({});
  const [openAdditions, setOpenAdditions] = useState({});
  const [openBalanceSheet, setOpenBalanceSheet] = useState({});
  const [openCashFlow, setOpenCashFlow] = useState({});
  const [openIncome, setOpenIncome] = useState({});
  const [loading, setLoading] = useState(false);
  const [open1040, setOpen1040] = useState({});
  const [uploadOpen, setUploadOpen] = useState(false);
  const [tabValue, setTabValue] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [debtPayment, setDebtPayment] = useState([]);
  const [adjustment, setAdjustment] = useState([]);
  const [adjustments, setAdjustments] = useState([]);
  const [debtPayments, setDebtPayments] = useState([]);
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [cashFlow, setCashFlow] = useState([]);
  const [incomeStatement, setIncomeStatement] = useState([]);
  const [uploadId, setUploadId] = useState(null);
  const [clickInProgress, setClickInProgress] = useState(false);
  const [highlightButton, setHighlightButton] = useState(false);
  const [imageshown, setImageShown] = useState({});
  const [isCash, setIsCash] = useState(false);
  const [isCashMap, setIsCashMap] = useState({});
  const [percentagevalue, setPercentagevalue] = useState({});
  const [switchOn, setSwitchOn] = useState([]);

  useEffect(() => {
    const data = [];
    results &&
      results.map((item, key) => {
        const isCashOpen =
          isCashMap[item.upload_id] ||
          item.is_cash_1065c ||
          item.is_cash_1120 ||
          item.is_cash_1120s ||
          false;
        data[key] = isCashOpen;
      });

    setSwitchOn(data);
  }, [results]);

  const handleCashSwitchChange = (checked, uploadId, key) => {
    setIsCashMap((prevState) => ({
      ...prevState,
      [uploadId]: checked,
    }));
    const data = switchOn;
    data[key] = checked;
    setSwitchOn([...data]);
  };
  const formTypesToShow = ["1065C", "1120", "1120S"];

  // const handleInputPercentChange = (e, uploadId) => {
  //   setPercentagevalue(e.target.value,uploadId);

  // };
  const handleInputPercentChange = (e, uploadId) => {
    setPercentagevalue((prevValues) => ({
      ...prevValues,
      [uploadId]: e.target.value,
    }));
  };

  const handleChange = (event, additionalData) => {
    imageNotesArray.push({
      image_notes: event.target.value,
      upload_id: additionalData,
    });

    const updatedResults = results.map((result) => {
      if (result.upload_id === additionalData) {
        return {
          ...result,
          image_notes: event.target.value,
        };
      }
      return result;
    });

    setResults(updatedResults);
    const { value } = event.target;

    setSelectedItemData((prevData) => ({
      ...prevData,
      image_notes: value,
    }));
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOff = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  const firstData = (result) => {
    let count = 0;
    count++;

    // Process and update values in the result array
    const updatedResult = result.map((subArray) => {
      return subArray.map((item) => {
        let {
          id,
          value,
          percentage_1065c,
          percentage_1120,
          percentage_1120s,
          iscash,
        } = item;
        if (iscash) {
          let isCashInt = parseInt(
            percentage_1065c || percentage_1120 || percentage_1120s
          );
          if (!isNaN(isCashInt) && isCashInt !== 0) {
            item.value = (value * 100) / isCashInt;
          }
        }
        return item;
      });
    });
    return updatedResult;
  };

  useEffect(() => {
    let user;
    setLoading(true);
    MySpreadApi.getSpread(id).then(
      (response) => {
        user = response.data.result;

        let spreadData = DataProcess.decryptData(user);
        spreadData = JSON.parse(spreadData);

        firstData(spreadData.result);

        // const processedData = firstData(spreadData.result); // Call firstData here
        // spreadData.result = processedData;

        setImageShown(spreadData);
        setData(spreadData.data);
        const newpv = spreadData.resultMainData.map((e) => {
          const temppv = {};

          if (e.percentage_1065c || e.percentage_1120s || e.percentage_1120) {
            temppv[e.upload_id] =
              e.percentage_1065c || e.percentage_1120s || e.percentage_1120;
          }
          return temppv;
        });

        setPercentagevalue(newpv);
        console.log(percentagevalue, "percentagevalue");
        setResult(spreadData.result);
        setResults(spreadData.resultMainData);
        apiResultData = spreadData.result;

        setTabValue(spreadData.resultMainData);
        setSwitchValue(spreadData.data.corporateform);
        setDataId(id);
        setLoading(false);
      },
      (error) => {
        if (error.response.status == "401") {
          AutoLogout();
        } else {
          let msgError = DataProcess.decryptData(error.response.data.resError);
          msgError = JSON.parse(msgError);
          toasterError(msgError.message);
        }
      }
    );
  }, []);

  const handleSectionToggle = (uploadId, type) => {
    setOpenNotes((prev) => ({
      ...prev,
      [uploadId]: type === "notes" && !prev[uploadId],
    }));

    setOpenAdjust((prev) => ({
      ...prev,
      [uploadId]: type === "adjustment" && !prev[uploadId],
    }));

    setOpenDebt((prev) => ({
      ...prev,
      [uploadId]: type === "debt" && !prev[uploadId],
    }));

    setOpenAdditions((prev) => ({
      ...prev,
      [uploadId]: type === "additions" && !prev[uploadId],
    }));

    setOpenBalanceSheet((prev) => ({
      ...prev,
      [uploadId]: type === "Balancesheet" && !prev[uploadId],
    }));

    setOpenCashFlow((prev) => ({
      ...prev,
      [uploadId]: type === "1084_cash_flow" && !prev[uploadId],
    }));

    setOpenIncome((prev) => ({
      ...prev,
      [uploadId]: type === "incomeStatement" && !prev[uploadId],
    }));

    setSelectedItem(uploadId);
    const selectedItemData = results.find(
      (item) => item.upload_id === uploadId
    );
    setSelectedItemData(selectedItemData);
  };

  const handelOpenNotes = (uploadId, formType) => {
    setOpenNotes(true);
    handleSectionToggle(uploadId, "notes");
  };

  const handleOpenAdjustment = (uploadId) => {
    setOpenAdjust(true);
    handleSectionToggle(uploadId, "adjustment");
    getSpreadFormData(id, "Adjustments", uploadId);
  };

  const handleOpenDebt = async (uploadId) => {
    setOpenDebt(true);
    handleSectionToggle(uploadId, "debt");
    getSpreadFormData(id, "Debt-payment", uploadId);
  };

  const handleOpenAdditions = (uploadId, year) => {
    setOpenAdditions(true);
    handleSectionToggle(uploadId, "additions");
    spreadConfigForm(year);
  };

  const handleOpenSection = async (uploadId, form_type, key) => {
    tabOpen = form_type;
    buttonKey = key;
    if (form_type == "Balancesheet") {
      handleSectionToggle(uploadId, "Balancesheet");
      await getSpreadFormData(id, "Balancesheet", uploadId, key);
    } else if (form_type == "1084_cash_flow") {
      handleSectionToggle(uploadId, "1084_cash_flow");
      await getSpreadFormData(id, "1084_cash_flow", uploadId, key);
    } else if (form_type == "incomeStatement") {
      handleSectionToggle(uploadId, "incomeStatement");
      await getSpreadFormData(id, "incomeStatement", uploadId, key);
    } else if (form_type == "additions") {
      handleSectionToggle(uploadId);
    } else {
      handleSectionToggle(uploadId);
      await getSpreadFromDataForm(id, form_type, uploadId, key);
    }
  };

  const handleOpen1040 = (uploadId) => {
    setOpen1040((prev1040) => ({
      ...prev1040,
      [uploadId]: true,
    }));
  };

  const handelCloseNotes = (uploadId) => {
    setOpenNotes((prevNotes) => ({
      ...prevNotes,
      [uploadId]: false,
    }));
  };

  function findById(array, id) {
    return array.find((el) => el.id === id);
  }

  const handleUpdate = async (spreadData) => {
    const uniqueUploadIds = [
      ...new Set(imageNotesArray.map((item) => item.upload_id)),
    ];

    const resultNotes = uniqueUploadIds.map((uploadId) => {
      const filteredData = imageNotesArray.filter(
        (item) => item.upload_id === uploadId
      );
      const latestData = filteredData[filteredData.length - 1];
      return {
        image_notes: latestData.image_notes,
        upload_id: latestData.upload_id,
      };
    });

    spreadData.imageNotesArray = resultNotes;

    if (spreadData && spreadData.id) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.validToken(),
      };

      let newUpdatedValue = [...updateValue];
      newUpdatedValue.forEach((el) => {
        // Iterate over each array in the result array
        let foundObjects = [];

        result.forEach((resArray) => {
          let foundObject = findById(resArray, el.id);
          if (foundObject) {
            foundObjects.push(foundObject);
          }
        });

        // Check if any found object is cash and update the value
        if (foundObjects.length > 0) {
          foundObjects.forEach((foundObject) => {
            if (foundObject.iscash) {
              const percentage = percentagevalue[foundObject.upload_id];
              if (percentage) {
                el.value = ((percentage / 100) * Number(el.value)).toString();
              }
            } else {
              console.warn(`Object with id ${el.id} is not cash`);
            }
          });
        } else {
          console.warn(`Object with id ${el.id} not found in any result array`);
        }
      });

      const cashPercentageValues1 = apiResultData.forEach((rs, inx) => {});
      const filterApiDataResult = apiResultData.filter((rd, inx) => {
        return rd.iscash === true;
      });

      let cashPercentageValues = apiResultData.map((res) => {
        let rs = res[0];
        let cash_1065 = false;
        let cash_1120S = false;
        let cash_1120 = false;
        const percentage = percentagevalue[rs.upload_id];
        if (rs.form_type === "1065C") {
          cash_1065 = switchOn[parseInt(rs.upload_id) - 1];
        }
        if (rs.form_type === "1120S") {
          cash_1120S = switchOn[parseInt(rs.upload_id) - 1];
        }
        if (rs.form_type === "1120") {
          cash_1120 = switchOn[parseInt(rs.upload_id) - 1];
        }
        const newItems = {
          percentage_1120: rs.percentage_1120,
          percentage_1120s: rs.percentage_1120s,
          percentage_1065c: rs.percentage_1065c,
          is_cash_1120: cash_1120,
          is_cash_1120s: cash_1120S,
          is_cash_1065c: cash_1065,
          upload_id: rs.upload_id,
        };

        if (percentage) {
          if (rs.form_type === "1065C") {
            newItems.percentage_1065c = cash_1065 ? Number(percentage) : 0;
          } else if (rs.form_type === "1120") {
            newItems.percentage_1120 = cash_1120 ? Number(percentage) : 0;
          } else if (rs.form_type === "1120S") {
            newItems.percentage_1120s = cash_1120S ? Number(percentage) : 0;
          }
        }

        return newItems;
      });

      spreadData.updatedValues = newUpdatedValue;
      spreadData.percentageValueCash = cashPercentageValues;

      // return;
      const response = await fetch(
        process.env.REACT_APP_SERVER_ENV_API +
          "/spread/update/" +
          spreadData.id,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            reqJson: DataProcess.encryptData(JSON.stringify(spreadData)),
          }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();

        let error = DataProcess.decryptData(JSON.parse(errorResponse.resError));
        toasterError(error);
      } else {
        toasterSuccess("Update spread successfully!");

        window.location.reload();
      }
    }
  };
  const handleFileUpload = async (event, item) => {
    setLoading(true);
    let dataPath = [];
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let spreadData = DataProcess.encryptData(
        JSON.stringify({
          created_by: Cookies.get("id"),
          name: data.name,
          corporateForm: data.switchValue,
          year: item && item.yearid ? item.yearid : data.year ? data.year : "",
          notes: data.notes,
          spreadId: id,
          upload_id: item && item.upload_id ? item.upload_id : "",
        })
      );

      const formData = new FormData();
      formData.append("upload", file);
      formData.append("reqJson", spreadData);

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ENV_API + "/spread/upload",
          {
            method: "POST",

            body: formData,
          }
        );

        if (!response.ok) {
          const errorData = await response.json();

          if (errorData && errorData.resError) {
            let errorMessage = DataProcess.decryptData(errorData.resError);

            errorMessage = JSON.parse(errorMessage);

            setLoading(false);
            let message =
              errorMessage && errorMessage.error && errorMessage.error.message
                ? errorMessage.error.message
                : errorMessage && errorMessage.error
                ? errorMessage && errorMessage.error
                : errorMessage && errorMessage.message
                ? errorMessage.message
                : "";
            toasterError(message);
          }
        }

        let data = await response.json();
        data = JSON.parse(DataProcess.decryptData(data.result));
        dataPath.push(data.data);
        setLoading(false);
        window.location.reload();
      } catch (error) {
        setLoading(false);
      }
    }
    setData(data);
  };

  const handleUpload = async (event, item, key) => {
    setLoading(true);
    let dataPath = [];
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const formData = new FormData();
      formData.append("upload", file);

      let upload_id = item;

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ENV_API +
            `/spread/upload-image/${id}?upload_id=${upload_id}&key=${key}`,
          {
            method: "POST",

            body: formData,
          }
        );

        if (!response.ok) {
          const errorData = await response.json();

          if (errorData && errorData.resError) {
            let errorMessage = DataProcess.decryptData(errorData.resError);
            errorMessage = JSON.parse(errorMessage);
            setLoading(false);
            toasterError("File upload failed");
          }
        }

        let data = await response.json();
        data = JSON.parse(DataProcess.decryptData(data.result));
        dataPath.push(data.data);
        setLoading(false);
        setUploadId(null);
      } catch (error) {
        setUploadId(null);
        setLoading(false);
      }
    }
    setData(data);
    window.location.reload();
  };

  const handleFileUploadBoxClick = (uploadIdValue, event) => {
    if (!clickInProgress) {
      setClickInProgress(true);
      setUploadId(uploadIdValue);
      event.stopPropagation();
      setTimeout(() => {
        setClickInProgress(false);
      }, 100);
    }
  };
  const getSpreadFormData = async (id, form_type, upload_id, key) => {
    if (key >= 0 || form_type == "Debt-payment" || form_type == "Adjustments") {
      setLoading(true);
      MySpreadApi.getSpreadForm(id, form_type, upload_id, key).then(
        (response) => {
          let spreadFormData = DataProcess.decryptData(response.data.result);
          spreadFormData = JSON.parse(spreadFormData);

          if (form_type == "Debt-payment") {
            setDebtPayment(spreadFormData.result[0]);
            setDebtPayments((prevDebtPayment) => [
              ...prevDebtPayment,
              spreadFormData.result[0],
            ]);
          } else if (form_type == "Adjustments") {
            setAdjustment(spreadFormData.result[0]);
            setAdjustments((prevAdjustments) => [
              ...prevAdjustments,
              spreadFormData.result[0],
            ]);
          } else if (form_type == "Balancesheet") {
            setBalanceSheet((prevBalanceSheet) => [
              ...prevBalanceSheet,
              spreadFormData.result[0],
            ]);
          } else if (form_type == "1084_cash_flow") {
            setCashFlow((prevCashFlow) => [
              ...prevCashFlow,
              spreadFormData.result[0],
            ]);
          } else if (form_type == "incomeStatement") {
            setIncomeStatement((prevIncomeStatement) => [
              ...prevIncomeStatement,
              spreadFormData.result[0],
            ]);
          }

          const newData = result.map((innerArray) =>
            innerArray.filter((item) => item.upload_id !== upload_id)
          );

          let uploadData = spreadFormData.result[0];
          const emptyArrayIndex = newData.findIndex((arr) => arr.length === 0);

          newData[emptyArrayIndex] = uploadData;

          setResult([]);
          setResult(newData);

          setLoading(false);
          return spreadFormData.result[0];
        }
      );
    }
  };

  const getSpreadFromDataForm = async (id, form_type, upload_id, key) => {
    keyId = key;
    keyObject[upload_id] = key;

    if (key >= 0) {
      setLoading(true);
      MySpreadApi.getSpreadForm(id, form_type, upload_id, key).then(
        (response) => {
          let spreadFromDataForm = DataProcess.decryptData(
            response.data.result
          );
          spreadFromDataForm = JSON.parse(spreadFromDataForm);

          const newData = result.map((innerArray) =>
            innerArray.filter((item) => item.upload_id !== upload_id)
          );

          let uploadData = spreadFromDataForm.result[0];

          const emptyArrayIndex = newData.findIndex((arr) => arr.length === 0);

          newData[emptyArrayIndex] = uploadData;

          setResult([]);
          setResult(newData);

          setSelectedItem(spreadFromDataForm.result[0]);
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    allYear();
    spreadConfigYear();
    spreadConfigForm();
  }, [""]);

  const allYear = async () => {
    const response = await YearApi.SearchYear();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setYears(result.data);
  };

  const spreadConfigYear = async () => {
    const response = await SpreadConfigurationApi.SpreadConfigYear();
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setConfigYear(result.data);
  };

  const spreadConfigForm = async (configYear) => {
    let response;
    if (configYear) {
      response = await SpreadConfigurationApi.SpreadConfigForm(configYear);
    } else {
      response = await SpreadConfigurationApi.SpreadConfigForm();
    }
    let result = JSON.parse(DataProcess.decryptData(response.data.result));
    setFormType(result.data);
  };

  const handleOptionFormChange = (event) => {
    const selectedOption = event.target.value;
    setForm(selectedOption);
  };

  const handleOptionFormChanges = async (event) => {
    const selectedOption = event.target.value;
    setForm(selectedOption);
  };

  const handleSubmitForm = async (item) => {
    let SpreadData = DataProcess.encryptData(
      JSON.stringify({
        year_id: item.yearid,
        form_type: form,
        spreadId: id,
        upload_id: item.upload_id,
      })
    );

    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_ENV_API + "/spread/add-mannual-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({ reqJson: SpreadData }),
        }
      );
      if (!response.ok) {
        let data = await response.json();
        data = JSON.parse(DataProcess.decryptData(data.resError));

        toasterError(data.error.message);
        throw new Error("Network response was not ok");
      }

      handleClose();
      let data = await response.json();
      data = JSON.parse(DataProcess.decryptData(data.result));

      setLoading(true);
      MySpreadApi.getSpread(data.spreadId).then((response) => {
        let user = response.data.result;

        let spreadData = DataProcess.decryptData(user);
        spreadData = JSON.parse(spreadData);
        setData(spreadData.data);
        setResult(spreadData.result);
        setResults(spreadData.resultMainData);
        setSwitchValue(spreadData.data.corporateform);
        setDataId(data.spreadId);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setYear(selectedOption);
    spreadConfigForm(selectedOption);
  };

  const handleSwitchChange = (newValue) => {
    setSwitchValue(newValue);
    setData((prevData) => ({
      ...prevData,
      corporateform: newValue,
    }));
  };

  const handleSwitchUpload = () => {
    if (switchValueUpload) {
      setUploadOpen(false);
      setSwitchValueUpload(false);
    } else {
      setUploadOpen(true);
      setSwitchValueUpload(true);
    }
  };

  const convertFrequency = (input) => {
    if (input.endsWith("-Monthly")) {
      return input.replace("-Monthly", "-Annual");
    } else if (input.endsWith("-Annual")) {
      return input.replace("-Annual", "-Monthly");
    }
    return input;
  };

  const handleInputValueChange = (event, id, form_type, name) => {
    const { value } = event.target;
    let newData = [];

    newValue.push({ id: id, value: value });
    setResult((prevResult) => {
      return prevResult.map((group) => {
        return group.map((item) => {
          if (item.id === id) {
            newValue.push({ id: id, value: value });
            return { ...item, value: value };
          }
          return item;
        });
      });
    });

    if (form_type && form_type == "debtPayment") {
      let newDebtPayment;
      if (name) {
        const convertedData = convertFrequency(name);

        newDebtPayment = debtPayments.map((subArray) => {
          return subArray.map((item) => {
            if (item.id === id) {
              return { ...item, value: value };
            }

            if (item.fieldname == convertedData) {
              let newValue = 0;
              if (name.endsWith("-Monthly")) {
                newValue = value * 12;
                newValue = newValue.toFixed(2);
              } else if (name.endsWith("-Annual")) {
                newValue = value / 12;
                newValue = newValue.toFixed(2);
              }
              newData.push({ id: item.id, value: newValue });
              return { ...item, value: newValue };
            }
            return item;
          });
        });
      }

      const mergedArray = [...newValue, ...newData];

      newValue = mergedArray;
      setDebtPayments([]);
      setDebtPayments(newDebtPayment);
      setDebtPayment((prevResult) => {
        return prevResult.map((group) => {
          if (group.id === id) {
            newValue.push({ id: id, value: value });
            return { ...group, value: value };
          }
          return group;
        });
      });
    }

    if (form_type && form_type == "adjustment") {
      const newAdjustments = adjustments.map((subArray) => {
        return subArray.map((item) => {
          if (item.id === id) {
            return { ...item, value: value };
          }
          return item;
        });
      });

      setAdjustments([]);
      setAdjustments(newAdjustments);

      setAdjustment((prevResult) => {
        return prevResult.map((group) => {
          if (group.id === id) {
            newValue.push({ id: id, value: value });
            return { ...group, value: value };
          }
          return group;
        });
      });
    }
    if (form_type && form_type == "incomeStatement") {
      const newIncomeStatement = incomeStatement.map((subArray) => {
        return subArray.map((item) => {
          if (item.id === id) {
            return { ...item, value: value };
          }
          return item;
        });
      });

      setIncomeStatement([]);
      setIncomeStatement(newIncomeStatement);
    }

    if (form_type && form_type == "balanceSheet") {
      const newBalanceSheet = balanceSheet.map((subArray) => {
        return subArray.map((item) => {
          if (item.id === id) {
            return { ...item, value: value };
          }
          return item;
        });
      });

      setBalanceSheet([]);
      setBalanceSheet(newBalanceSheet);
    }
    if (form_type && form_type == "cashFlow") {
      const newCashFlow = cashFlow.map((subArray) => {
        return subArray.map((item) => {
          if (item.id === id) {
            return { ...item, value: value };
          }
          return item;
        });
      });

      setCashFlow([]);
      setCashFlow(newCashFlow);
    }

    const filteredArray = Array.from(
      new Map(newValue.map((item) => [item.id, item])).values()
    );

    setUpdateValue(filteredArray);
  };

  const handleConfirmDelete = async (id, path) => {
    await SpreadApi.DeleteSpreadImage(id, path.upload_id).then(() => {});

    handleOff();

    setDeleteId(id);
    setDataPath(path);

    window.location.reload();
  };

  const handleCancelDelete = () => {
    handleOff();
  };

  const handleDelete = (id, item) => {
    handleOpen();

    setDeleteId(id);
    setDataPath(item);
  };

  const handleOpenForms = () => {
    handleShow();
  };

  const handleCloseForm = () => {
    handleClose();
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    let SpreadData = DataProcess.encryptData(
      JSON.stringify({
        year_id: year,
        form_type: form,
        name: name,
        corporateForm: switchValue,
        year: year,
        notes: notes,
        spreadId: id,
      })
    );
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_ENV_API + "/spread/mannual-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.validToken(),
          },
          body: JSON.stringify({ reqJson: SpreadData }),
        }
      );
      handleClose();
      let data = await response.json();
      data = JSON.parse(DataProcess.decryptData(data.result));

      setLoading(true);
      MySpreadApi.getSpread(data.spreadId).then((response) => {
        let user = response.data.result;

        let spreadData = DataProcess.decryptData(user);
        spreadData = JSON.parse(spreadData);
        setData(spreadData.data);
        setResult(spreadData.result);
        setResults(spreadData.resultMainData);
        setSwitchValue(spreadData.data.corporateform);
        setDataId(data.spreadId);
        apiResultData = spreadData.result;
        setLoading(false);
      });
      window.location.reload();
    } catch (error) {
      setLoading(false);
    }
  };

  const getElementValue = (data, dataArray, upload_id, allDataArray) => {
    if (dataArray && dataArray.length > 0) {
      for (let i = 0; i < allDataArray.length; i++) {
        const element = allDataArray[i];
        let resultV = element.find(
          (obj) => obj.fieldname === data && obj.upload_id === upload_id
        );

        if (resultV && resultV.value) {
          return resultV.value;
        }
      }
    } else {
      return "";
    }
  };

  const getElementId = (data, dataArray, upload_id, allDataArray) => {
    if (dataArray && dataArray.length > 0) {
      for (let i = 0; i < allDataArray.length; i++) {
        const element = allDataArray[i];
        let resultV = element.find(
          (obj) => obj.fieldname === data && obj.upload_id === upload_id
        );

        if (resultV && resultV.id) {
          return resultV.id;
        }
      }
    } else {
      return "";
    }
  };
  const calculateRows = (buttons) => {
    const numRows = Math.ceil(buttons.length / 4);
    const rows = [];
    let start = 0;
    for (let i = 0; i < numRows; i++) {
      const rowButtons = buttons.slice(start, start + 4);
      if (rowButtons.length > 0) {
        rows.push(rowButtons);
      }
      start += 4;
    }
    return rows;
  };

  return (
    <div className="col-md-12">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Manual Form</Modal.Title>
          <IoClose
            size={24}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={handleCloseForm}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRegister}>
            <label>Select Year</label>

            <select
              defaultValue={"select"}
              onChange={handleOptionChange}
              className="form-control"
            >
              <option disabled>select</option>
              {configYear.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.year}
                </option>
              ))}
            </select>

            <label>Select Form Type</label>
            <select
              defaultValue={"select"}
              onChange={handleOptionFormChange}
              className="form-control"
            >
              <option disabled>select</option>
              {formType.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.form_type}
                </option>
              ))}
            </select>

            <Button
              variant="primary"
              style={{
                marginTop: "15px",
                backgroundColor: "#c00000",
              }}
              type="submit"
            >
              Create Manual Form
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div>
        <h3>Edit Spread</h3>
      </div>

      <div className="mb-3 d-flex top-row">
        <div className="form-inline">
          <div className="form-group">
            <h6 htmlFor="" className="d-inline">
              Spread Name:
            </h6>
            <input
              type="text"
              className="form-control input int"
              id=""
              name="name"
              value={data.name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <span className="btnclass" style={{ marginLeft: "auto" }}>
          <button
            className="btn btn-danger"
            onClick={() => handleUpdate(data, percentagevalue)}
          >
            Update Spread
          </button>
        </span>
      </div>

      <div className="row top-spread">
        <div className="col-md-8">
          <Table striped bordered hover>
            <thead>
              <tr style={{ backgroundColor: "#c00000", color: "white" }}>
                <th>Year</th>
                <th style={{ backgroundColor: "white", width: "30%" }}>
                  <Dropdown>
                    <select
                      defaultValue={"select"}
                      className="form-control"
                      name="year"
                      value={data.year}
                      onChange={handleInputChange}
                    >
                      <option disabled>select</option>
                      {years.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.year}
                        </option>
                      ))}
                    </select>
                  </Dropdown>
                </th>
                <th className="corp-text" style={{ width: "25%" }}>
                  Corporate Tax Return
                </th>
                <th
                  style={{ backgroundColor: "white" }}
                  className="text-center"
                >
                  <ReactSwitch
                    checked={switchValue}
                    onChange={handleSwitchChange}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Spread Detail</b>
                </td>
                <td colspan="2">
                  <input
                    type="text"
                    className="form-control input"
                    id=""
                    name="notes"
                    value={data.notes}
                    onChange={handleInputChange}
                  />
                </td>

                <td>Select yes to Add 1065, 1120, 1120S, K-1's. </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="col-md-4">
          <div className="file-upload-box">
            <img src={myImage} alt="" />
            <p>Select your Document Here</p>
            <label className="upload-btn" htmlFor="upload">
              Select a File
              <input
                type="file"
                id="upload"
                className=""
                accept="application/pdf"
                onChange={handleFileUpload}
                multiple
              />
            </label>
          </div>
        </div>
      </div>
      <div className="contain my-3">
        <p>
          <b>For Best Results:</b> For Best Results: Upload OCR PDF files
          directly from the tax return software. Convert the PDF to OCR by using
          CTRL Search before uploading the document. For optimal results, scan
          documents using high-quality DPI settings, ensuring pages are upright
          and in order. For suboptimal results, avoid uploading low-quality
          copies or unconverted fill-in PDFs. Note that large PDF files may take
          5-7 minutes to process.
        </p>
      </div>
      <div className="buttonEntry">
        <button className="btn btn-primary" onClick={handleOpenForms}>
          Manual Entry Form
        </button>
        &nbsp;
        <Link to={`/taxanalysis/${id}`}>
          <button
            className="btn ml-2 btn-danger"
            disabled={imageshown.imageCount === 0}
          >
            Get Tax Analysis
          </button>
        </Link>
      </div>
      {loading && <Loader />}
      {results &&
        results.map((item, key) => {
          const isNotesOpen = openNotes[item.upload_id] || false;
          const isAdjustmentOpen = openAdjust[item.upload_id] || false;
          const isDebtOpen = openDebt[item.upload_id] || false;
          const isAdditionOpen = openAdditions[item.upload_id] || false;
          const isCashOpen = isCashMap[item.upload_id] || false;
          let newKeyData = keyObject[item.upload_id];

          let isBalanceSheetOpen = openBalanceSheet[item.upload_id]
            ? openBalanceSheet[item.upload_id]
            : item.forms[0] === "Balancesheet" &&
              (!newKeyData || newKeyData == 0)
            ? item.forms[0] === "Balancesheet"
            : false;
          let isCashFlowOpen = openCashFlow[item.upload_id]
            ? openCashFlow[item.upload_id]
            : item.forms[0] === "1084_cash_flow" &&
              (!newKeyData || newKeyData == 0)
            ? item.forms[0] === "1084_cash_flow"
            : false;
          let isIncomeStatementOpen = openIncome[item.upload_id]
            ? openIncome[item.upload_id]
            : item.forms[0] === "incomeStatement" &&
              (!newKeyData || newKeyData == 0)
            ? item.forms[0] === "incomeStatement"
            : false;
          // if (isBalanceSheetOpen && balanceSheet.length === 0) {
          //   getSpreadFormData(id, "Balancesheet", item.upload_id, key);
          // }

          // if (isCashFlowOpen && cashFlow.length === 0) {
          //   getSpreadFormData(id, "1084_cash_flow", item.upload_id, key);
          // }
          // if (isIncomeStatementOpen && incomeStatement.length === 0) {
          //   getSpreadFormData(id, "incomeStatement", item.upload_id, key);
          // }

          if (isIncomeStatementOpen && tabOpen == "incomeStatement") {
            isCashFlowOpen = false;
            isBalanceSheetOpen = false;
            isIncomeStatementOpen = true;
          }

          if (isCashFlowOpen && tabOpen == "1084_cash_flow") {
            isIncomeStatementOpen = false;
            isBalanceSheetOpen = false;
            isCashFlowOpen = true;
          }

          if (isBalanceSheetOpen && tabOpen == "Balancesheet") {
            isCashFlowOpen = false;
            isIncomeStatementOpen = false;
            isBalanceSheetOpen = true;
          }

          let resultItems = result[key];

          const shouldShowSwitch =
            resultItems &&
            resultItems.some((item) =>
              formTypesToShow.includes(item.form_type)
            );
          let pageno =
            resultItems && resultItems[0].pageno ? resultItems[0].pageno : 1;

          // if(pageno == 1){
          //   pageno = 1
          // }

          let image =
            resultItems && resultItems.length > 0 && resultItems[0].images
              ? resultItems[0].images
              : "";
          let imagedata =
            resultItems && resultItems.length > 0 && resultItems[0].imagedata
              ? resultItems[0].imagedata
              : "";

          return (
            <div className="col-lg-12 wrap-section mb-4" key={key}>
              <div className="top-bar text-center">
                <p>
                  Upload #{item.upload_id} for Year {item.year} from{" "}
                  {item.form_type} {item.formtypedata}
                  <span className="trans">
                    <FaTrash onClick={() => handleDelete(dataid, item)} />
                  </span>
                </p>
              </div>
              <div className="col-lg-12 left-wrap">
                <div className="row">
                  <div className="col-lg-8 left-panel">
                    <div className="left-side-top d-flex flex-row bd-highlight btn-section">
                      <div className="p-0 bd-highlight">
                        <button
                          type="button"
                          className={`btn btn-primary btn-10b ${
                            highlightButton === "NOTES"
                              ? "btn-primary-dark"
                              : ""
                          }`}
                          onClick={() => {
                            setHighlightButton("NOTES");
                            handelOpenNotes(item.upload_id, "1040");
                          }}
                        >
                          NOTES
                        </button>
                      </div>
                      <div className="p-0 bd-highlight">
                        <button
                          type="button"
                          className={`btn btn-primary adjest ${
                            highlightButton === "Adjustments"
                              ? "btn-primary-dark"
                              : ""
                          }`}
                          onClick={() => {
                            setHighlightButton("Adjustments");
                            handleOpenAdjustment(item.upload_id);
                          }}
                        >
                          Adjustments
                        </button>
                      </div>
                      <div className="p-0 bd-highlight">
                        <button
                          type="button"
                          className={`btn btn-primary debt ${
                            highlightButton === "Debt Payments"
                              ? "btn-primary-dark"
                              : ""
                          }`}
                          onClick={() => {
                            setHighlightButton("Debt Payments");
                            handleOpenDebt(item.upload_id);
                          }}
                        >
                          Debt Payments
                        </button>
                      </div>
                      <div className="p-0 bd-highlight">
                        <button
                          type="button"
                          className={`btn btn-primary debt ${
                            highlightButton === "Additions"
                              ? "btn-primary-dark"
                              : ""
                          }`}
                          onClick={() => {
                            setHighlightButton("Additions");
                            handleOpenAdditions(item.upload_id, item.yearid);
                          }}
                        >
                          Additions
                        </button>
                      </div>
                    </div>

                    <div className="mb-3 btn-section spread_button" key={key}>
                      {calculateRows(item.forms).map((rowButtons, rowIndex) => (
                        <div className="d-flex flex-row" key={rowIndex}>
                          {rowButtons.map((iteminner, key) => (
                            <div className="p-0 bd-highlight" key={key}>
                              <button
                                type="button"
                                className={`btn btn-primary btn-10b ${
                                  highlightButton === iteminner
                                    ? "btn-primary-dark"
                                    : ""
                                }`}
                                onClick={() => {
                                  setHighlightButton(iteminner);
                                  handleOpenSection(
                                    item.upload_id,
                                    iteminner,
                                    rowIndex * 4 + key
                                  );
                                }}
                              >
                                {iteminner}
                              </button>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    <div className="left-image-content">
                      {imagedata ? (
                        <Iframe
                          className="iframes"
                          src={`${imagedata}#page=${
                            buttonKey === 0 ? 1 : pageno
                          }`}
                          width="100%"
                          type="application/pdf"
                          id="myId"
                          height="500px"
                          display="initial"
                          position="relative"
                        />
                      ) : (
                        <div
                          className="file-upload-box"
                          onClick={(event) =>
                            handleFileUploadBoxClick(item.upload_id, event)
                          }
                        >
                          <img src={myImage} alt="" />
                          <p>Select your Document Here</p>
                          <label className="upload-btn" htmlFor="upload2">
                            Select a File
                            <input
                              type="file"
                              id="upload2"
                              className=""
                              accept=""
                              onChange={(event) =>
                                handleUpload(event, uploadId, buttonKey)
                              }
                              multiple
                            />
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 right-part">
                    <div className="right-side-content">
                      <div className="right-side-content d-flex flex-row bd-highlight mb-3">
                        {isNotesOpen ? (
                          <div className="mb-3 w-100">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Add Notes
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              value={selectedItemData.image_notes}
                              onChange={(e) =>
                                handleChange(e, selectedItemData.upload_id)
                              }
                            ></textarea>
                          </div>
                        ) : isAdjustmentOpen ? (
                          <div className="right-side-contents d-flex flex-row bd-highlight mb-3">
                            <div
                              className="bg-color text-center desc-sec"
                              style={{ width: "280px" }}
                            >
                              <p
                                className="mb-1 pt-2 name-p"
                                style={{ color: "black" }}
                              >
                                Field Name <br />
                                <br /> <br />
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Auto Expenses Adjustments
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Refunded{" "}
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Owed
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Amortization
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Adjustments
                              </p>
                            </div>
                            <div className="name-content new-name-content">
                              <p className="mb-0 set-colum pt-4">Enter Name</p>
                              <p className="mb-0 set-colum amt-content">
                                Entry value
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Auto Expenses Adjustments",
                                    adjustment,
                                    item.upload_id,
                                    adjustments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Auto Expenses Adjustments",
                                        adjustment,
                                        item.upload_id,
                                        adjustments
                                      ),
                                      "adjustment"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Refunded",
                                    adjustment,
                                    item.upload_id,
                                    adjustments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Refunded",
                                        adjustment,
                                        item.upload_id,
                                        adjustments
                                      ),
                                      "adjustment"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Owed",
                                    adjustment,
                                    item.upload_id,
                                    adjustments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Owed",
                                        adjustment,
                                        item.upload_id,
                                        adjustments
                                      ),
                                      "adjustment"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Amortization",
                                    adjustment,
                                    item.upload_id,
                                    adjustments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Amortization",
                                        adjustment,
                                        item.upload_id,
                                        adjustments
                                      ),
                                      "adjustment"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Adjustments",
                                    adjustment,
                                    item.upload_id,
                                    adjustments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Adjustments",
                                        adjustment,
                                        item.upload_id,
                                        adjustments
                                      ),
                                      "adjustment"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : isDebtOpen ? (
                          <div className="debt-annual-pay right-side-contents d-flex flex-row bd-highlight mb-3">
                            <div
                              className="bg-color text-center desc-sec"
                              style={{ width: "278px" }}
                            >
                              <p
                                className="mb-1 pt-2 name-p"
                                style={{ color: "black" }}
                              >
                                Annual Debt Payments <br />
                                <br /> <br />
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Projected Payment on Loan Request
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Home Mortgage/Rent
                              </p>

                              <p className="desc-content mb-0 desc-item">
                                Revolving Totals
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Installment Totals
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Rental Totals
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Personal Living Expenses-est.
                              </p>
                            </div>
                            <div className="name-content new-name-content debt-annual">
                              <p className="mb-0 set-colum pt-4 name-topbox"></p>
                              <p className="mb-0 set-colum amt-content">
                                Monthly
                              </p>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Projected Payment on Loan Request-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Projected Payment on Loan Request-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Projected Payment on Loan Request-Monthly"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Home Mortgage/Rent-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Home Mortgage/Rent-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Home Mortgage/Rent-Monthly"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Revolving Totals-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Revolving Totals-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Revolving Totals-Monthly"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>

                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Installment Totals-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Installment Totals-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Installment Totals-Monthly"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Rental Totals-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Rental Totals-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Rental Totals-Monthly"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Other-Monthly"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Personal Living Expenses-est.-Monthly",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Personal Living Expenses-est.-Monthly",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Personal Living Expenses-est.-Monthly"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="name-content new-name-content debt-annual">
                              <p className="mb-0 set-colum pt-4 name-topbox"></p>
                              <p className="mb-0 set-colum amt-content">
                                Annual
                              </p>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Projected Payment on Loan Request-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Projected Payment on Loan Request-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Projected Payment on Loan Request-Annual"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Home Mortgage/Rent-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Home Mortgage/Rent-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Home Mortgage/Rent-Annual"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Revolving Totals-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Revolving Totals-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Revolving Totals-Annual"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Installment Totals-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Installment Totals-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Installment Totals-Annual"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Rental Totals-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Rental Totals-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Rental Totals-Annual"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Other-Annual"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>

                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Personal Living Expenses-est.-Annual",
                                    debtPayment,
                                    item.upload_id,
                                    debtPayments
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Personal Living Expenses-est.-Annual",
                                        debtPayment,
                                        item.upload_id,
                                        debtPayments
                                      ),
                                      "debtPayment",
                                      "Personal Living Expenses-est.-Annual"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : isAdditionOpen ? (
                          <div>
                            <div className="d-flex">
                              <p
                                style={{
                                  margin: "20px",
                                  fontWeight: "700",
                                  fontSize: "large",
                                }}
                              >
                                Add Form:
                              </p>
                              <p
                                style={{
                                  marginLeft: "50px",
                                  marginTop: "20px",
                                  fontWeight: "700",
                                  fontSize: "large",
                                }}
                              >
                                Is Upload:
                              </p>
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "10px",
                                }}
                              >
                                <ReactSwitch
                                  checked={switchValueUpload}
                                  onChange={handleSwitchUpload}
                                />
                              </span>
                            </div>
                            {uploadOpen ? (
                              <div className="file-upload-box">
                                <img src={myImage} alt="" />
                                <p>Select your Document Here</p>
                                <label className="upload-btn" htmlFor="upload1">
                                  Select a File
                                  <input
                                    type="file"
                                    id="upload1"
                                    className=""
                                    accept="application/pdf"
                                    onChange={(event) =>
                                      handleFileUpload(event, item)
                                    }
                                    multiple
                                  />
                                </label>
                              </div>
                            ) : (
                              <div style={{ margin: "4px" }}>
                                <select
                                  defaultValue={"select"}
                                  onChange={handleOptionFormChanges}
                                  className="form-control"
                                  style={{ width: "300px" }}
                                >
                                  <option disabled>select</option>
                                  {formType.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.form_type}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  style={{
                                    height: "fit-content",
                                    marginTop: "7px",
                                    backgroundColor: "#c00000",
                                    color: "white",
                                  }}
                                  onClick={() => handleSubmitForm(item)}
                                >
                                  Submit
                                </button>
                              </div>
                            )}
                          </div>
                        ) : isBalanceSheetOpen ? (
                          <div className="balance-sheet-form right-side-contents d-flex flex-row bd-highlight mb-3">
                            <div
                              className="bg-color text-center desc-sec"
                              style={{ width: "280px" }}
                            >
                              <p
                                className="mb-1 pt-2 name-p"
                                style={{ color: "black" }}
                              >
                                Balance Sheet <br />
                                <br />
                                <p className="desc-content mb-0 desc-item">
                                  Ownership %
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Form Name
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Year of Financials
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Enter Number of Whole Months for Financials
                                </p>
                                <br />
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Cash
                              </p>
                              <p className="desc-content mb-0 desc-item">COD</p>
                              <p className="desc-content mb-0 desc-item">
                                A/R trade
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Inv.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Cur Assets
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Land & Build.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Leasehold Imp.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Equipment
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Fixt & Furn.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Acc. DPR
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Prepaids
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                N/R - Noncurrent
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                L/T Invest.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Due from Insider
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Assets
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Intangibles
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Overdrafts
                              </p>
                              <p className="desc-content mb-0 desc-item">AP</p>
                              <p className="desc-content mb-0 desc-item">
                                Notes Payable
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                CPLTD
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Accrued Exp.
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Taxes Payable
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Income Taxes
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Long Term Debt
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Debt to Stockholders
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Deferred Taxes
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Liab
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Preferred Stock
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Common Stock
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Paid In Capital
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Treasury Stock
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Retained Earnings
                              </p>
                            </div>
                            <div className="name-content new-name-contents spread-form">
                              <p className="mb-0 set-colum pt-4">Enter Name</p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Ownership %",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Ownership %",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Form Name",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Form Name",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Year of Financials",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Year of Financials",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Enter Number of Whole Months for Financials",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Enter Number of Whole Months for Financials",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Current Assets
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Cash",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Cash",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "COD",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "COD",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "A/R trade",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "A/R trade",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Inv.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Inv.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Cur Assets",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Cur Assets",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Net Fixed Assets
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Land & Build.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Land & Build.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Leasehold Imp.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Leasehold Imp.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Equipment",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Equipment",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Fixt & Furn.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Fixt & Furn.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Acc. DPR",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Acc. DPR",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Other Assets
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Prepaids",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Prepaids",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "N/R - Noncurrent",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "N/R - Noncurrent",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "L/T Invest.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "L/T Invest.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Due from Insider",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Due from Insider",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Assets",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Assets",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Intangibles",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Intangibles",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Long Term Debt
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Overdrafts",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Overdrafts",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "AP",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "AP",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Notes Payable",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Notes Payable",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "CPLTD",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "CPLTD",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Accrued Exp.",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Accrued Exp.",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Taxes Payable",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Taxes Payable",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Income Taxes",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Income Taxes",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Long Term Liabilities
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Long Term Debt",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Long Term Debt",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Debt to Stockholders",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Debt to Stockholders",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Deferred Taxes",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Deferred Taxes",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Liab",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Liab",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Shareholder Equity
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Preferred Stock",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Preferred Stock",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Common Stock",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Common Stock",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Paid In Capital",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Paid In Capital",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Treasury Stock",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Treasury Stock",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Retained Earnings",
                                    balanceSheet,
                                    item.upload_id,
                                    balanceSheet
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Retained Earnings",
                                        balanceSheet,
                                        item.upload_id,
                                        balanceSheet
                                      ),
                                      "balanceSheet"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : isCashFlowOpen ? (
                          <div className="spread-cashflow">
                            <p>Form 1084 Cash Flow Forms</p>
                            <div className="right-side-contents d-flex flex-row bd-highlight mb-3 ">
                              <div
                                className="bg-color text-center desc-sec"
                                style={{ width: "280px" }}
                              >
                                <p
                                  className="mb-1 pt-2 name-p"
                                  style={{ color: "black" }}
                                >
                                  Field Name <br />
                                  <br />
                                  <h6>Schedule C Items</h6>
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Auto Expenses Adjustments
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Refunded
                                </p>
                                <p
                                  className="desc-content mb-0 desc-item"
                                  style={{ backgroundColor: "white" }}
                                ></p>
                                <h6 style={{ color: "black" }}>
                                  Schedule D Items
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Schedule D-Recurring Gains
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Recurring Capital Gains
                                </p>

                                <br />
                                <h6 style={{ color: "black" }}>
                                  Schedule E Items
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Total Expenses
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depletion
                                </p>

                                <br />
                                <h6 style={{ color: "black" }}>
                                  Schedule F Items
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Nonrecurring Other Income Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Business Use of Home
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Amortization/Casualty Loss
                                </p>

                                <br />
                                <h6 style={{ color: "black" }}>
                                  1065 Business Cash Flows
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Ordinary Income Loss From Other Partnerships
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Non Recurring Other
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depreciation
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depletion
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Amortization/Casualty Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Mortgages Or Notes Payable Less than 1 Year
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Non-Deductible Travel and and Entertainment
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Total % Percentage Owned
                                </p>

                                <br />
                                <h6 style={{ color: "black" }}>
                                  1120S Cash Flows
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Form 1120S - Adjustments to Business Cash Flow
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Nonrecurring Other (Income) Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depreciation
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depletion
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Amortization/Casualty Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Mortgages or Notes Payable in Less than 1 Year
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Non-deductible Travel and Entertainment
                                  Expenses
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Non-Deductible Travel and and Entertainment
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Total % Percentage Owned
                                </p>

                                <br />
                                <h6 style={{ color: "black" }}>
                                  1120 Corporation
                                </h6>
                                <p className="desc-content mb-0 desc-item">
                                  Taxable Income
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Total Tax
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Nonrecurring (Gains) Losses
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Nonrecurring Other (Income) Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depreciation
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Depletion
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Amortization/Casualty Loss
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Net Operating Loss and Special Deductions
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Mortgages or Notes Payable in Less than 1 Year
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Non-deductible Travel and Entertainment
                                  Expenses
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Less: Dividends Paid to Borrower
                                </p>
                              </div>
                              <div className="name-content new-name-content ">
                                <div className="d-flex flex-row">
                                  <div>
                                    <p className="mb-0 set-colum pt-4">
                                      Enter Name
                                    </p>
                                    <p className="mb-0 set-colum amt-content">
                                      Entry value
                                    </p>

                                    <div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Nonrecurring Other Inc/LossExp.",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Nonrecurring Other Inc/LossExp.",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Amortization/Casualty Loss",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Amortization/Casualty Loss",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* Schedule D Items */}
                                    <br />
                                    <br />
                                    <br />
                                    <div style={{ marginTop: "-7px" }}>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Schedule D-Recurring Gains",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Schedule D-Recurring Gains",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Recurring Capital Gains",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Recurring Capital Gains",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* Schedule E Items */}
                                    <br />
                                    <br />
                                    <div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Total Expenses",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Total Expenses",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depletion",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depletion",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* Schedule F Items */}
                                    <br />
                                    <br />

                                    <div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Nonrecurring Other Income Loss",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Nonrecurring Other Income Loss",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Business Use of Home",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Business Use of Home",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Amortization/Casualty Loss_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Amortization/Casualty Loss_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* 1065 Business Cash Flows */}
                                    <br />
                                    <br />
                                    <div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Ordinary Income Loss From Other Partnerships",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Ordinary Income Loss From Other Partnerships",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Non Recurring Other",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Non Recurring Other",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depreciation",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depreciation",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depletion_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depletion_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Amortization/Casualty Loss_2",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Amortization/Casualty Loss_2",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Mortgages Or Notes Payable Less than 1 Year",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Mortgages Or Notes Payable Less than 1 Year",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Non-Deductible Travel and and Entertainment",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Non-Deductible Travel and and Entertainment",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Total % Percentage Owned",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Total % Percentage Owned",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* 1120S Cash Flows */}
                                    <br />
                                    <br />
                                    <div style={{ marginTop: "5px" }}>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Form 1120S - Adjustments to Business Cash Flow",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Form 1120S - Adjustments to Business Cash Flow",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Nonrecurring Other (Income) Loss",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Nonrecurring Other (Income) Loss",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depreciation_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depreciation_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depletion_2",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depletion_2",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Amortization/Casualty Loss_3",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Amortization/Casualty Loss_3",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Mortgages or Notes Payable in Less than 1 Year",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Mortgages or Notes Payable in Less than 1 Year",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Non-deductible Travel and Entertainment Expenses",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Non-deductible Travel and Entertainment Expenses",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Non-Deductible Travel and and Entertainment_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Non-Deductible Travel and and Entertainment_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Total % Percentage Owned_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Total % Percentage Owned_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* 1120 Corporation*/}
                                    <br />
                                    <br />
                                    <br />
                                    <div style={{ marginTop: "-19px" }}>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Taxable Income",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Taxable Income",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Total Tax",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Total Tax",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Nonrecurring (Gains) Losses",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Nonrecurring (Gains) Losses",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Nonrecurring Other (Income) Loss_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Nonrecurring Other (Income) Loss_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depreciation_2",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depreciation_2",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Depletion_3",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Depletion_3",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Amortization/Casualty Loss_4",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Amortization/Casualty Loss_4",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Net Operating Loss and Special Deductions",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Net Operating Loss and Special Deductions",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Mortgages or Notes Payable in Less than 1 Year_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Mortgages or Notes Payable in Less than 1 Year_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>

                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Non-deductible Travel and Entertainment Expenses_1",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Non-deductible Travel and Entertainment Expenses_1",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="spread-custom-input "
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "5px",
                                            borderTop: "0px solid black",
                                            borderBottom: "0px solid black",
                                          }}
                                        >
                                          $
                                        </span>
                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          value={getElementValue(
                                            "Less: Dividends Paid to Borrower",
                                            cashFlow,
                                            item.upload_id,
                                            cashFlow
                                          )}
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              e,
                                              getElementId(
                                                "Less: Dividends Paid to Borrower",
                                                cashFlow,
                                                item.upload_id,
                                                cashFlow
                                              ),
                                              "cashFlow"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : isIncomeStatementOpen ? (
                          <div className="right-side-contents d-flex flex-row bd-highlight mb-3">
                            <div
                              className="bg-color text-center desc-sec"
                              style={{ width: "280px" }}
                            >
                              <p
                                className="mb-1 pt-2 name-p"
                                style={{ color: "black" }}
                              >
                                Income Statement <br />
                                <br />
                                <p className="desc-content mb-0 desc-item">
                                  Ownership %
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Form Name
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Year of Financials
                                </p>
                                <p className="desc-content mb-0 desc-item">
                                  Enter Number of Whole Months
                                </p>
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Gross Revenues
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Income
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Direct
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Materials
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                COGS - Depreciation
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                COGS - Other
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Depreciation Expense
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Rent
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Salaries
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Officer's Comp
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Repairs
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Taxes
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Employee benefits
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Advertisin
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Insurance Expense
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Utilities
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Operating Expenses
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Interest Expense
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Income
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other Expense
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Unconsolidated Subsidiary
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Current Taxes
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Deferred Taxes
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Cumu. Effect of Chng in Acct. Prin.
                              </p>
                              <p
                                className="desc-content mb-0 desc-item"
                                style={{ backgroundColor: "white" }}
                              ></p>
                              <p className="desc-content mb-0 desc-item">
                                Beginning Net Worth
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Dividends (disbursements)
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Other
                              </p>
                              <p className="desc-content mb-0 desc-item">
                                Adjustment Required
                              </p>
                            </div>
                            <div className="name-content new-name-contents">
                              <p className="mb-0 set-colum pt-4">Enter Name</p>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Ownership %",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Ownership %",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Form Name",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Form Name",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Year",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Year",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Enter Number of Whole Months for Financials",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Enter Number of Whole Months for Financials",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Revenues
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Gross Revenues",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Gross Revenues",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Income",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Income",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Direct Costs
                              </p>

                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Direct",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Direct",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Materials",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Materials",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "COGS - Depreciation",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "COGS - Depreciation",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "COGS - Other",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "COGS - Other",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Operating Expenses
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Depreciation Expense",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Depreciation Expense",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Rent",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Rent",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Salaries",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Salaries",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Officer's Comp",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Officer's Comp",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Repairs",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Repairs",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Taxes",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Taxes",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Employee benefits",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Employee benefits",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Advertisin",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Advertisin",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Insurance Expense",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Insurance Expense",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Utilities",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Utilities",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Operating Expenses",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Operating Expenses",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Other Expenses
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Interest Expense",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Interest Expense",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Income",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Income",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other Expense",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other Expense",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Unconsolidated Subsidiary",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Unconsolidated Subsidiary",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Taxes
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Current Taxes",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Current Taxes",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Deferred Taxes",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Deferred Taxes",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Cumu. Effect of Chng in Acct. Prin.",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Cumu. Effect of Chng in Acct. Prin.",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <p className="mb-0 set-colum amt-content">
                                Net Worth
                              </p>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Beginning Net Worth",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Beginning Net Worth",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Dividends (disbursements)",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Dividends (disbursements)",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Other",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Other",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                              <div className="spread-custom-input">
                                <span>$</span>
                                <input
                                  className="desc-content mb-0 int-desc"
                                  type="text"
                                  value={getElementValue(
                                    "Adjustment Required                      ss179",
                                    incomeStatement,
                                    item.upload_id,
                                    incomeStatement
                                  )}
                                  onChange={(e) =>
                                    handleInputValueChange(
                                      e,
                                      getElementId(
                                        "Adjustment Required                      ss179",
                                        incomeStatement,
                                        item.upload_id,
                                        incomeStatement
                                      ),
                                      "incomeStatement"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="right-side-content d-flex flex-row bd-highlight mb-3 edit-manualform newform-wrap">
                            <div className="bg-color text-center desc-sec">
                              <p
                                className="mb-1 pt-2 name-p"
                                style={{ color: "black" }}
                              >
                                Name of Individual or
                                <br />
                                Entity
                                <br />
                                Line Description
                              </p>
                              {resultItems &&
                                resultItems
                                  .filter(
                                    (item) => item.iscash === switchOn[key]
                                  )
                                  .map((item, key) => {
                                    return (
                                      <p
                                        className="desc-content mb-0 desc-item"
                                        key={key}
                                      >
                                        {item.fieldname}
                                      </p>
                                    );
                                  })}
                            </div>
                            <div className="line-content">
                              {shouldShowSwitch ? (
                                <span className="line-switch">
                                  <label htmlFor="isCash">IsCash</label>
                                  <ReactSwitch
                                    id={`isCash-${item.upload_id}`}
                                    onChange={(checked) =>
                                      handleCashSwitchChange(
                                        checked,
                                        item.upload_id,
                                        key
                                      )
                                    }
                                    checked={switchOn[key]}
                                  />
                                </span>
                              ) : (
                                <span className="line-switch">
                                  <label htmlFor="isCash"></label>
                                </span>
                              )}
                              <p
                                className="pt-1 pb-0 lin-t"
                                style={{ color: "black" }}
                              >
                                Line#
                              </p>
                              {resultItems &&
                                resultItems
                                  .filter(
                                    (item) => item.iscash === switchOn[key]
                                  )
                                  .map((item, key) => {
                                    return (
                                      <p
                                        className="desc-content mb-0 mt-0"
                                        key={key}
                                      >
                                        {item.field_label}
                                      </p>
                                    );
                                  })}
                            </div>
                            <div className="name-content new-name-content amount-percentage">
                              {/* {
                                switchOn[key] && formTypesToShow.includes(item.form_type) &&
                                <input
                                  type="text"
                                  defaultValue={item.form_type === "1065C"
                                    ? item.percentage_1065c
                                    : item.form_type === "1120S"
                                      ? item.percentage_1120s
                                      : item.form_type === "1120"
                                        ? item.percentage_1120
                                        : ""}
                                  onChange={(e) => handleInputPercentChange(e, item.upload_id)}
                                  placeholder="EnterValue"
                                />
                              } */}
                              {switchOn[key] &&
                                formTypesToShow.includes(item.form_type) && (
                                  <input
                                    type="text"
                                    defaultValue={(() => {
                                      switch (item.form_type) {
                                        case "1065C":
                                          return item.percentage_1065c;
                                        case "1120S":
                                          return item.percentage_1120s;
                                        case "1120":
                                          return item.percentage_1120;
                                        default:
                                          return "";
                                      }
                                    })()}
                                    onChange={(e) =>
                                      handleInputPercentChange(
                                        e,
                                        item.upload_id
                                      )
                                    }
                                    placeholder="Enter Value"
                                  />
                                )}
                              <p
                                className={`mb-0 set-colum ${
                                  switchOn[key] ? "" : "pt-4"
                                }`}
                              >
                                Enter Name
                              </p>
                              <p className="mb-0 set-colum amt-content">
                                Entry Amount
                              </p>
                              {resultItems &&
                                resultItems
                                  .filter(
                                    (item) => item.iscash === switchOn[key]
                                  )
                                  .map((item, key) => {
                                    let { id, value } = item;
                                    // {
                                    //   if (item.iscash && isCashOpen) {
                                    //     let valueInt = parseInt(item.percentage_1065c);
                                    //     let isCashInt = parseInt(item.percentage_1065c || item.percentage_1120 || item.percentage_1120s);
                                    //     // value=55
                                    //     value = (value * 100) / isCashInt || item.value;

                                    //   }
                                    // }
                                    return (
                                      <div
                                        className="spread-custom-input"
                                        key={id}
                                      >
                                        <span>$</span>
                                        <input
                                          className="desc-content mb-0 int-desc"
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          value={value}
                                          onChange={(e) =>
                                            handleInputValueChange(e, id)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <Modal show={open} onHide={handleOff}>
                  <Modal.Header>
                    <Modal.Title>Are you sure you want to delete?</Modal.Title>
                    <IoClose
                      size={24}
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={handleCancelDelete}
                    />
                  </Modal.Header>
                  <Modal.Body>
                    <Button
                      variant="primary"
                      style={{
                        marginTop: "15px",
                        marginRight: "5px",
                        backgroundColor: "#c00000",
                      }}
                      type="submit"
                      onClick={() => handleConfirmDelete(deleteId, dataPath)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="primary"
                      style={{ marginTop: "15px" }}
                      type="submit"
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EditSpread;

// Old Code
// import React, { useEffect, useState } from "react";
// import Form from "react-validation/build/form";
// import CheckButton from "react-validation/build/button";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import AuthService from "../../../services/auth.service";
// import DataProcess from "../../../common/Helper";
// import {
//   toasterError,
//   toasterSuccess,
// } from "../../../components/ToasterMessages/toastify.helper";
// import { Button, Dropdown, Modal, Table } from "react-bootstrap";
// import ReactSwitch from "react-switch";
// import YearApi from "../../../services/API/YearApi";
// import "../MySpread/EditSpread.css";
// import myImage from "../../../assets/image/cloud-computing.png";
// import Iframe from "react-iframe";
// import { FaTrash } from "react-icons/fa";
// import SpreadApi from "../../../services/API/SpreadApi";
// import MySpreadApi from "../../../services/API/myspreadApi";
// import Cookies from "js-cookie";
// import { IoClose } from "react-icons/io5";
// import SpreadConfigurationApi from "../../../services/API/spreadConfigurationApi";
// import { getValue } from "@testing-library/user-event/dist/utils";
// import Loader from "../../../components/Loader/Loader";
// import { AutoLogout } from "../../Logout/Logout";
// let newValue = [];
// let image = "";
// let imageNotesArray = [];
// let keyId;
// let keyObject = {};
// let buttonKey = 0;
// let tabOpen;

// const EditSpread = (props) => {
//   const { id } = useParams();
//   const [updateValue, setUpdateValue] = useState([]);
//   const [name, setName] = useState("");
//   const [data, setData] = useState({
//     name: "",
//     notes: "",
//     year: null,
//     corporateForm: "",
//     paths: [],
//     updatedValues: updateValue,
//   });
//   const [result, setResult] = useState([]);
//   const [results, setResults] = useState([]);
//   const [years, setYears] = useState([]);
//   const [year, setYear] = useState("");
//   const [show, setShow] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [switchValue, setSwitchValue] = useState(false);
//   const [switchValueUpload, setSwitchValueUpload] = useState(false);
//   const [dataid, setDataId] = useState("");
//   const [deleteId, setDeleteId] = useState("");
//   const [dataPath, setDataPath] = useState("");
//   const [form, setForm] = useState("");
//   const [configYear, setConfigYear] = useState([]);
//   const [formType, setFormType] = useState([]);
//   const [notes, setNotes] = useState("");
//   const [openNotes, setOpenNotes] = useState({});
//   const [openAdjust, setOpenAdjust] = useState({});
//   const [openDebt, setOpenDebt] = useState({});
//   const [openAdditions, setOpenAdditions] = useState({});
//   const [openBalanceSheet, setOpenBalanceSheet] = useState({});
//   const [openCashFlow, setOpenCashFlow] = useState({});
//   const [openIncome, setOpenIncome] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [open1040, setOpen1040] = useState({});
//   const [uploadOpen, setUploadOpen] = useState(false);
//   const [tabValue, setTabValue] = useState([]);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [selectedItemData, setSelectedItemData] = useState(null);
//   const [debtPayment, setDebtPayment] = useState([]);
//   const [adjustment, setAdjustment] = useState([]);
//   const [adjustments, setAdjustments] = useState([]);
//   const [debtPayments, setDebtPayments] = useState([]);
//   const [balanceSheet, setBalanceSheet] = useState([]);
//   const [cashFlow, setCashFlow] = useState([]);
//   const [incomeStatement, setIncomeStatement] = useState([]);
//   const [uploadId, setUploadId] = useState(null);
//   const [clickInProgress, setClickInProgress] = useState(false);
//   const [highlightButton, setHighlightButton] = useState(false);
//   const [imageshown, setImageShown] = useState({});
//   const [isCash, setIsCash] = useState(false);

//   const handleCashSwitchChange = (checked, uploadId) => {
//     setIsCash(checked, uploadId);
//     {
//       console.log(uploadId, "uploadId");
//     }
//   };
//   const formTypesToShow = ["1065C", "1120", "1120S"];

//   const handleChange = (event, additionalData) => {
//     imageNotesArray.push({
//       image_notes: event.target.value,
//       upload_id: additionalData,
//     });

//     const updatedResults = results.map((result) => {
//       if (result.upload_id === additionalData) {
//         return {
//           ...result,
//           image_notes: event.target.value,
//         };
//       }
//       return result;
//     });

//     setResults(updatedResults);
//     const { value } = event.target;

//     setSelectedItemData((prevData) => ({
//       ...prevData,
//       image_notes: value,
//     }));
//   };
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const handleOff = () => setOpen(false);
//   const handleOpen = () => setOpen(true);

//   const handleInputChange = (e) => {
//     setData({ ...data, [e.target.name]: e.target.value });
//   };
//   const navigate = useNavigate();

//   useEffect(() => {
//     let user;
//     setLoading(true);
//     MySpreadApi.getSpread(id).then(
//       (response) => {
//         user = response.data.result;

//         let spreadData = DataProcess.decryptData(user);
//         spreadData = JSON.parse(spreadData);
//         setImageShown(spreadData);
//         setData(spreadData.data);
//         setResult(spreadData.result);
//         setResults(spreadData.resultMainData);
//         setTabValue(spreadData.resultMainData);
//         setSwitchValue(spreadData.data.corporateform);
//         setDataId(id);
//         setLoading(false);
//       },
//       (error) => {
//         if (error.response.status == "401") {
//           AutoLogout();
//         } else {
//           let msgError = DataProcess.decryptData(error.response.data.resError);
//           msgError = JSON.parse(msgError);
//           toasterError(msgError.message);
//         }
//       }
//     );
//   }, []);

//   const handleSectionToggle = (uploadId, type) => {
//     setOpenNotes((prev) => ({
//       ...prev,
//       [uploadId]: type === "notes" && !prev[uploadId],
//     }));

//     setOpenAdjust((prev) => ({
//       ...prev,
//       [uploadId]: type === "adjustment" && !prev[uploadId],
//     }));

//     setOpenDebt((prev) => ({
//       ...prev,
//       [uploadId]: type === "debt" && !prev[uploadId],
//     }));

//     setOpenAdditions((prev) => ({
//       ...prev,
//       [uploadId]: type === "additions" && !prev[uploadId],
//     }));

//     setOpenBalanceSheet((prev) => ({
//       ...prev,
//       [uploadId]: type === "Balancesheet" && !prev[uploadId],
//     }));

//     setOpenCashFlow((prev) => ({
//       ...prev,
//       [uploadId]: type === "1084_cash_flow" && !prev[uploadId],
//     }));

//     setOpenIncome((prev) => ({
//       ...prev,
//       [uploadId]: type === "incomeStatement" && !prev[uploadId],
//     }));

//     setSelectedItem(uploadId);
//     const selectedItemData = results.find(
//       (item) => item.upload_id === uploadId
//     );
//     setSelectedItemData(selectedItemData);
//     console.log("selectedItemData", selectedItemData);
//   };

//   const handelOpenNotes = (uploadId, formType) => {
//     setOpenNotes(true);
//     handleSectionToggle(uploadId, "notes");
//   };

//   const handleOpenAdjustment = (uploadId) => {
//     setOpenAdjust(true);
//     handleSectionToggle(uploadId, "adjustment");
//     getSpreadFormData(id, "Adjustments", uploadId);
//   };

//   const handleOpenDebt = async (uploadId) => {
//     setOpenDebt(true);
//     handleSectionToggle(uploadId, "debt");
//     getSpreadFormData(id, "Debt-payment", uploadId);
//   };

//   const handleOpenAdditions = (uploadId, year) => {
//     setOpenAdditions(true);
//     handleSectionToggle(uploadId, "additions");
//     spreadConfigForm(year);
//   };

//   const handleOpenSection = async (uploadId, form_type, key) => {
//     tabOpen = form_type;
//     buttonKey = key;
//     if (form_type == "Balancesheet") {
//       handleSectionToggle(uploadId, "Balancesheet");
//       await getSpreadFormData(id, "Balancesheet", uploadId, key);
//     } else if (form_type == "1084_cash_flow") {
//       handleSectionToggle(uploadId, "1084_cash_flow");
//       await getSpreadFormData(id, "1084_cash_flow", uploadId, key);
//     } else if (form_type == "incomeStatement") {
//       handleSectionToggle(uploadId, "incomeStatement");
//       await getSpreadFormData(id, "incomeStatement", uploadId, key);
//     } else if (form_type == "additions") {
//       handleSectionToggle(uploadId);
//     } else {
//       handleSectionToggle(uploadId);
//       await getSpreadFromDataForm(id, form_type, uploadId, key);
//     }
//   };

//   const handleOpen1040 = (uploadId) => {
//     setOpen1040((prev1040) => ({
//       ...prev1040,
//       [uploadId]: true,
//     }));
//   };

//   const handelCloseNotes = (uploadId) => {
//     setOpenNotes((prevNotes) => ({
//       ...prevNotes,
//       [uploadId]: false,
//     }));
//   };

//   const handleUpdate = async (spreadData) => {
//     const uniqueUploadIds = [
//       ...new Set(imageNotesArray.map((item) => item.upload_id)),
//     ];

//     const result = uniqueUploadIds.map((uploadId) => {
//       const filteredData = imageNotesArray.filter(
//         (item) => item.upload_id === uploadId
//       );
//       const latestData = filteredData[filteredData.length - 1];
//       return {
//         image_notes: latestData.image_notes,
//         upload_id: latestData.upload_id,
//       };
//     });

//     spreadData.imageNotesArray = result;
//     if (spreadData && spreadData.id) {
//       const headers = {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + AuthService.validToken(),
//       };

//       spreadData.updatedValues = updateValue;
//       const response = await fetch(
//         process.env.REACT_APP_SERVER_ENV_API +
//           "/spread/update/" +
//           spreadData.id,
//         {
//           method: "PUT",
//           headers: headers,
//           body: JSON.stringify({
//             reqJson: DataProcess.encryptData(JSON.stringify(spreadData)),
//           }),
//         }
//       );

//       if (!response.ok) {
//         const errorResponse = await response.json();

//         let error = DataProcess.decryptData(JSON.parse(errorResponse.resError));
//         toasterError(error);
//       } else {
//         toasterSuccess("Update spread successfully!");

//         window.location.reload();
//       }
//     }
//   };
//   const handleFileUpload = async (event, item) => {
//     setLoading(true);
//     let dataPath = [];
//     const files = event.target.files;

//     for (let i = 0; i < files.length; i++) {
//       const file = files[i];
//       let spreadData = DataProcess.encryptData(
//         JSON.stringify({
//           created_by: Cookies.get("id"),
//           name: data.name,
//           corporateForm: data.switchValue,
//           year: item && item.yearid ? item.yearid : data.year ? data.year : "",
//           notes: data.notes,
//           spreadId: id,
//           upload_id: item && item.upload_id ? item.upload_id : "",
//         })
//       );

//       const formData = new FormData();
//       formData.append("upload", file);
//       formData.append("reqJson", spreadData);

//       try {
//         const response = await fetch(
//           process.env.REACT_APP_SERVER_ENV_API + "/spread/upload",
//           {
//             method: "POST",

//             body: formData,
//           }
//         );

//         if (!response.ok) {
//           const errorData = await response.json();

//           if (errorData && errorData.resError) {
//             let errorMessage = DataProcess.decryptData(errorData.resError);

//             errorMessage = JSON.parse(errorMessage);

//             setLoading(false);
//             let message =
//               errorMessage && errorMessage.error && errorMessage.error.message
//                 ? errorMessage.error.message
//                 : errorMessage && errorMessage.error
//                 ? errorMessage && errorMessage.error
//                 : errorMessage && errorMessage.message
//                 ? errorMessage.message
//                 : "";
//             toasterError(message);
//           }
//         }

//         let data = await response.json();
//         data = JSON.parse(DataProcess.decryptData(data.result));
//         dataPath.push(data.data);
//         setLoading(false);
//         window.location.reload();
//       } catch (error) {
//         setLoading(false);
//       }
//     }
//     setData(data);
//   };

//   const handleUpload = async (event, item, key) => {
//     setLoading(true);
//     let dataPath = [];
//     const files = event.target.files;

//     for (let i = 0; i < files.length; i++) {
//       const file = files[i];

//       const formData = new FormData();
//       formData.append("upload", file);

//       let upload_id = item;

//       try {
//         const response = await fetch(
//           process.env.REACT_APP_SERVER_ENV_API +
//             `/spread/upload-image/${id}?upload_id=${upload_id}&key=${key}`,
//           {
//             method: "POST",

//             body: formData,
//           }
//         );

//         if (!response.ok) {
//           const errorData = await response.json();

//           if (errorData && errorData.resError) {
//             let errorMessage = DataProcess.decryptData(errorData.resError);
//             errorMessage = JSON.parse(errorMessage);
//             setLoading(false);
//             toasterError("File upload failed");
//           }
//         }

//         let data = await response.json();
//         data = JSON.parse(DataProcess.decryptData(data.result));
//         dataPath.push(data.data);
//         setLoading(false);
//         setUploadId(null);
//       } catch (error) {
//         setUploadId(null);
//         setLoading(false);
//       }
//     }
//     setData(data);
//     window.location.reload();
//   };

//   const handleFileUploadBoxClick = (uploadIdValue, event) => {
//     if (!clickInProgress) {
//       setClickInProgress(true);
//       setUploadId(uploadIdValue);
//       event.stopPropagation();
//       setTimeout(() => {
//         setClickInProgress(false);
//       }, 100);
//     }
//   };
//   const getSpreadFormData = async (id, form_type, upload_id, key) => {
//     if (key >= 0 || form_type == "Debt-payment" || form_type == "Adjustments") {
//       setLoading(true);
//       MySpreadApi.getSpreadForm(id, form_type, upload_id, key).then(
//         (response) => {
//           let spreadFormData = DataProcess.decryptData(response.data.result);
//           spreadFormData = JSON.parse(spreadFormData);

//           if (form_type == "Debt-payment") {
//             setDebtPayment(spreadFormData.result[0]);
//             setDebtPayments((prevDebtPayment) => [
//               ...prevDebtPayment,
//               spreadFormData.result[0],
//             ]);
//           } else if (form_type == "Adjustments") {
//             setAdjustment(spreadFormData.result[0]);
//             setAdjustments((prevAdjustments) => [
//               ...prevAdjustments,
//               spreadFormData.result[0],
//             ]);
//           } else if (form_type == "Balancesheet") {
//             setBalanceSheet((prevBalanceSheet) => [
//               ...prevBalanceSheet,
//               spreadFormData.result[0],
//             ]);
//           } else if (form_type == "1084_cash_flow") {
//             setCashFlow((prevCashFlow) => [
//               ...prevCashFlow,
//               spreadFormData.result[0],
//             ]);
//           } else if (form_type == "incomeStatement") {
//             setIncomeStatement((prevIncomeStatement) => [
//               ...prevIncomeStatement,
//               spreadFormData.result[0],
//             ]);
//           }

//           const newData = result.map((innerArray) =>
//             innerArray.filter((item) => item.upload_id !== upload_id)
//           );

//           let uploadData = spreadFormData.result[0];
//           const emptyArrayIndex = newData.findIndex((arr) => arr.length === 0);

//           newData[emptyArrayIndex] = uploadData;

//           setResult([]);
//           setResult(newData);

//           setLoading(false);
//           return spreadFormData.result[0];
//         }
//       );
//     }
//   };

//   const getSpreadFromDataForm = async (id, form_type, upload_id, key) => {
//     keyId = key;
//     keyObject[upload_id] = key;

//     if (key >= 0) {
//       setLoading(true);
//       MySpreadApi.getSpreadForm(id, form_type, upload_id, key).then(
//         (response) => {
//           let spreadFromDataForm = DataProcess.decryptData(
//             response.data.result
//           );
//           spreadFromDataForm = JSON.parse(spreadFromDataForm);

//           const newData = result.map((innerArray) =>
//             innerArray.filter((item) => item.upload_id !== upload_id)
//           );

//           let uploadData = spreadFromDataForm.result[0];

//           const emptyArrayIndex = newData.findIndex((arr) => arr.length === 0);

//           newData[emptyArrayIndex] = uploadData;

//           setResult([]);
//           setResult(newData);

//           setSelectedItem(spreadFromDataForm.result[0]);
//           setLoading(false);
//         }
//       );
//     }
//   };

//   useEffect(() => {
//     allYear();
//     spreadConfigYear();
//     spreadConfigForm();
//   }, [""]);

//   const allYear = async () => {
//     const response = await YearApi.SearchYear();
//     let result = JSON.parse(DataProcess.decryptData(response.data.result));
//     setYears(result.data);
//   };

//   const spreadConfigYear = async () => {
//     const response = await SpreadConfigurationApi.SpreadConfigYear();
//     let result = JSON.parse(DataProcess.decryptData(response.data.result));
//     setConfigYear(result.data);
//   };

//   const spreadConfigForm = async (configYear) => {
//     let response;
//     if (configYear) {
//       response = await SpreadConfigurationApi.SpreadConfigForm(configYear);
//     } else {
//       response = await SpreadConfigurationApi.SpreadConfigForm();
//     }
//     let result = JSON.parse(DataProcess.decryptData(response.data.result));
//     setFormType(result.data);
//   };

//   const handleOptionFormChange = (event) => {
//     const selectedOption = event.target.value;
//     setForm(selectedOption);
//   };

//   const handleOptionFormChanges = async (event) => {
//     const selectedOption = event.target.value;
//     setForm(selectedOption);
//   };

//   const handleSubmitForm = async (item) => {
//     let SpreadData = DataProcess.encryptData(
//       JSON.stringify({
//         year_id: item.yearid,
//         form_type: form,
//         spreadId: id,
//         upload_id: item.upload_id,
//       })
//     );

//     try {
//       const response = await fetch(
//         process.env.REACT_APP_SERVER_ENV_API + "/spread/add-mannual-form",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + AuthService.validToken(),
//           },
//           body: JSON.stringify({ reqJson: SpreadData }),
//         }
//       );
//       if (!response.ok) {
//         let data = await response.json();
//         data = JSON.parse(DataProcess.decryptData(data.resError));

//         toasterError(data.error.message);
//         throw new Error("Network response was not ok");
//       }

//       handleClose();
//       let data = await response.json();
//       data = JSON.parse(DataProcess.decryptData(data.result));

//       setLoading(true);
//       MySpreadApi.getSpread(data.spreadId).then((response) => {
//         let user = response.data.result;

//         let spreadData = DataProcess.decryptData(user);
//         spreadData = JSON.parse(spreadData);
//         setData(spreadData.data);
//         setResult(spreadData.result);
//         setResults(spreadData.resultMainData);
//         setSwitchValue(spreadData.data.corporateform);
//         setDataId(data.spreadId);
//         setLoading(false);
//       });
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   const handleOptionChange = (event) => {
//     const selectedOption = event.target.value;
//     setYear(selectedOption);
//     spreadConfigForm(selectedOption);
//   };

//   const handleSwitchChange = (newValue) => {
//     setSwitchValue(newValue);
//     setData((prevData) => ({
//       ...prevData,
//       corporateform: newValue,
//     }));
//   };

//   const handleSwitchUpload = () => {
//     if (switchValueUpload) {
//       setUploadOpen(false);
//       setSwitchValueUpload(false);
//     } else {
//       setUploadOpen(true);
//       setSwitchValueUpload(true);
//     }
//   };

//   const convertFrequency = (input) => {
//     if (input.endsWith("-Monthly")) {
//       return input.replace("-Monthly", "-Annual");
//     } else if (input.endsWith("-Annual")) {
//       return input.replace("-Annual", "-Monthly");
//     }
//     return input;
//   };

//   const handleInputValueChange = (event, id, form_type, name) => {
//     const { value } = event.target;
//     let newData = [];

//     newValue.push({ id: id, value: value });
//     setResult((prevResult) => {
//       return prevResult.map((group) => {
//         return group.map((item) => {
//           if (item.id === id) {
//             newValue.push({ id: id, value: value });
//             return { ...item, value: value };
//           }
//           return item;
//         });
//       });
//     });

//     if (form_type && form_type == "debtPayment") {
//       let newDebtPayment;
//       if (name) {
//         const convertedData = convertFrequency(name);

//         newDebtPayment = debtPayments.map((subArray) => {
//           return subArray.map((item) => {
//             if (item.id === id) {
//               return { ...item, value: value };
//             }

//             if (item.fieldname == convertedData) {
//               let newValue = 0;
//               if (name.endsWith("-Monthly")) {
//                 newValue = value * 12;
//                 newValue = newValue.toFixed(2);
//               } else if (name.endsWith("-Annual")) {
//                 newValue = value / 12;
//                 newValue = newValue.toFixed(2);
//               }
//               newData.push({ id: item.id, value: newValue });
//               return { ...item, value: newValue };
//             }
//             return item;
//           });
//         });
//       }

//       const mergedArray = [...newValue, ...newData];

//       newValue = mergedArray;
//       setDebtPayments([]);
//       setDebtPayments(newDebtPayment);
//       setDebtPayment((prevResult) => {
//         return prevResult.map((group) => {
//           if (group.id === id) {
//             newValue.push({ id: id, value: value });
//             return { ...group, value: value };
//           }
//           return group;
//         });
//       });
//     }

//     if (form_type && form_type == "adjustment") {
//       const newAdjustments = adjustments.map((subArray) => {
//         return subArray.map((item) => {
//           if (item.id === id) {
//             return { ...item, value: value };
//           }
//           return item;
//         });
//       });

//       setAdjustments([]);
//       setAdjustments(newAdjustments);

//       setAdjustment((prevResult) => {
//         return prevResult.map((group) => {
//           if (group.id === id) {
//             newValue.push({ id: id, value: value });
//             return { ...group, value: value };
//           }
//           return group;
//         });
//       });
//     }
//     if (form_type && form_type == "incomeStatement") {
//       const newIncomeStatement = incomeStatement.map((subArray) => {
//         return subArray.map((item) => {
//           if (item.id === id) {
//             return { ...item, value: value };
//           }
//           return item;
//         });
//       });

//       setIncomeStatement([]);
//       setIncomeStatement(newIncomeStatement);
//     }

//     if (form_type && form_type == "balanceSheet") {
//       const newBalanceSheet = balanceSheet.map((subArray) => {
//         return subArray.map((item) => {
//           if (item.id === id) {
//             return { ...item, value: value };
//           }
//           return item;
//         });
//       });

//       setBalanceSheet([]);
//       setBalanceSheet(newBalanceSheet);
//     }
//     if (form_type && form_type == "cashFlow") {
//       const newCashFlow = cashFlow.map((subArray) => {
//         return subArray.map((item) => {
//           if (item.id === id) {
//             return { ...item, value: value };
//           }
//           return item;
//         });
//       });

//       setCashFlow([]);
//       setCashFlow(newCashFlow);
//     }

//     const filteredArray = Array.from(
//       new Map(newValue.map((item) => [item.id, item])).values()
//     );

//     setUpdateValue(filteredArray);
//   };

//   const handleConfirmDelete = async (id, path) => {
//     await SpreadApi.DeleteSpreadImage(id, path.upload_id).then(() => {});

//     handleOff();

//     setDeleteId(id);
//     setDataPath(path);

//     window.location.reload();
//   };

//   const handleCancelDelete = () => {
//     handleOff();
//   };

//   const handleDelete = (id, item) => {
//     handleOpen();

//     setDeleteId(id);
//     setDataPath(item);
//   };

//   const handleOpenForms = () => {
//     handleShow();
//   };

//   const handleCloseForm = () => {
//     handleClose();
//   };

//   const handleRegister = async (e) => {
//     e.preventDefault();

//     let SpreadData = DataProcess.encryptData(
//       JSON.stringify({
//         year_id: year,
//         form_type: form,
//         name: name,
//         corporateForm: switchValue,
//         year: year,
//         notes: notes,
//         spreadId: id,
//       })
//     );
//     try {
//       const response = await fetch(
//         process.env.REACT_APP_SERVER_ENV_API + "/spread/mannual-form",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + AuthService.validToken(),
//           },
//           body: JSON.stringify({ reqJson: SpreadData }),
//         }
//       );
//       handleClose();
//       let data = await response.json();
//       data = JSON.parse(DataProcess.decryptData(data.result));

//       setLoading(true);
//       MySpreadApi.getSpread(data.spreadId).then((response) => {
//         let user = response.data.result;

//         let spreadData = DataProcess.decryptData(user);
//         spreadData = JSON.parse(spreadData);
//         setData(spreadData.data);
//         setResult(spreadData.result);
//         setResults(spreadData.resultMainData);
//         setSwitchValue(spreadData.data.corporateform);
//         setDataId(data.spreadId);
//         setLoading(false);
//       });
//       window.location.reload();
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   const getElementValue = (data, dataArray, upload_id, allDataArray) => {
//     if (dataArray && dataArray.length > 0) {
//       for (let i = 0; i < allDataArray.length; i++) {
//         const element = allDataArray[i];
//         let resultV = element.find(
//           (obj) => obj.fieldname === data && obj.upload_id === upload_id
//         );

//         if (resultV && resultV.value) {
//           return resultV.value;
//         }
//       }
//     } else {
//       return "";
//     }
//   };

//   const getElementId = (data, dataArray, upload_id, allDataArray) => {
//     if (dataArray && dataArray.length > 0) {
//       for (let i = 0; i < allDataArray.length; i++) {
//         const element = allDataArray[i];
//         let resultV = element.find(
//           (obj) => obj.fieldname === data && obj.upload_id === upload_id
//         );

//         if (resultV && resultV.id) {
//           return resultV.id;
//         }
//       }
//     } else {
//       return "";
//     }
//   };
//   const calculateRows = (buttons) => {
//     const numRows = Math.ceil(buttons.length / 4);
//     const rows = [];
//     let start = 0;
//     for (let i = 0; i < numRows; i++) {
//       const rowButtons = buttons.slice(start, start + 4);
//       if (rowButtons.length > 0) {
//         rows.push(rowButtons);
//       }
//       start += 4;
//     }
//     return rows;
//   };

//   return (
//     <div className="col-md-12">
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header>
//           <Modal.Title>Add Manual Form</Modal.Title>
//           <IoClose
//             size={24}
//             color="red"
//             style={{ cursor: "pointer" }}
//             onClick={handleCloseForm}
//           />
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleRegister}>
//             <label>Select Year</label>

//             <select
//               defaultValue={"select"}
//               onChange={handleOptionChange}
//               className="form-control"
//             >
//               <option disabled>select</option>
//               {configYear.map((option) => (
//                 <option key={option.id} value={option.id}>
//                   {option.year}
//                 </option>
//               ))}
//             </select>

//             <label>Select Form Type</label>
//             <select
//               defaultValue={"select"}
//               onChange={handleOptionFormChange}
//               className="form-control"
//             >
//               <option disabled>select</option>
//               {formType.map((option) => (
//                 <option key={option.id} value={option.id}>
//                   {option.form_type}
//                 </option>
//               ))}
//             </select>

//             <Button
//               variant="primary"
//               style={{
//                 marginTop: "15px",
//                 backgroundColor: "#c00000",
//               }}
//               type="submit"
//             >
//               Create Manual Form
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//       <div>
//         <h3>Edit Spread</h3>
//       </div>

//       <div className="mb-3 d-flex top-row">
//         <div className="form-inline">
//           <div className="form-group">
//             <h6 htmlFor="" className="d-inline">
//               Spread Name:
//             </h6>
//             <input
//               type="text"
//               className="form-control input int"
//               id=""
//               name="name"
//               value={data.name}
//               onChange={handleInputChange}
//             />
//           </div>
//         </div>
//         <span className="btnclass" style={{ marginLeft: "auto" }}>
//           <button className="btn btn-danger" onClick={() => handleUpdate(data)}>
//             Update Spread
//           </button>
//         </span>
//       </div>

//       <div className="row top-spread">
//         <div className="col-md-8">
//           <Table striped bordered hover>
//             <thead>
//               <tr style={{ backgroundColor: "#c00000", color: "white" }}>
//                 <th>Year</th>
//                 <th style={{ backgroundColor: "white", width: "30%" }}>
//                   <Dropdown>
//                     <select
//                       defaultValue={"select"}
//                       className="form-control"
//                       name="year"
//                       value={data.year}
//                       onChange={handleInputChange}
//                     >
//                       <option disabled>select</option>
//                       {years.map((option) => (
//                         <option key={option.id} value={option.id}>
//                           {option.year}
//                         </option>
//                       ))}
//                     </select>
//                   </Dropdown>
//                 </th>
//                 <th className="corp-text" style={{ width: "25%" }}>
//                   Corporate Tax Return
//                 </th>
//                 <th
//                   style={{ backgroundColor: "white" }}
//                   className="text-center"
//                 >
//                   <ReactSwitch
//                     checked={switchValue}
//                     onChange={handleSwitchChange}
//                   />
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>
//                   <b>Spread Detail</b>
//                 </td>
//                 <td colspan="2">
//                   <input
//                     type="text"
//                     className="form-control input"
//                     id=""
//                     name="notes"
//                     value={data.notes}
//                     onChange={handleInputChange}
//                   />
//                 </td>

//                 <td>Select yes to Add 1065, 1120, 1120S, K-1's. </td>
//               </tr>
//             </tbody>
//           </Table>
//         </div>
//         <div className="col-md-4">
//           <div className="file-upload-box">
//             <img src={myImage} alt="" />
//             <p>Select your Document Here</p>
//             <label className="upload-btn" htmlFor="upload">
//               Select a File
//               <input
//                 type="file"
//                 id="upload"
//                 className=""
//                 accept="application/pdf"
//                 onChange={handleFileUpload}
//                 multiple
//               />
//             </label>
//           </div>
//         </div>
//       </div>
//       <div className="contain my-3">
//         <p>
//           <b>For Best Results:</b> Upload OCR PDF files directly from tax return
//           software. Convert PDF to OCR by CTRL Search and then convert document
//           prior to uploads. For Next Level Results scan in using high quality
//           DPI and ensure pages scan upright and in order. Lowest Level Results:
//           low quality copies of items uploaded or fill in PDF's not converted to
//           a print to PDF OCR version
//         </p>
//       </div>
//       <div className="buttonEntry">
//         <button className="btn btn-primary" onClick={handleOpenForms}>
//           Manual Entry Form
//         </button>
//         &nbsp;
//         <Link to={`/taxanalysis/${id}`}>
//           <button
//             className="btn ml-2 btn-danger"
//             disabled={imageshown.imageCount === 0}
//           >
//             Get Tax Analysis
//           </button>
//         </Link>
//       </div>
//       {loading && <Loader />}
//       {results &&
//         results.map((item, key) => {
//           const isNotesOpen = openNotes[item.upload_id] || false;
//           const isAdjustmentOpen = openAdjust[item.upload_id] || false;
//           const isDebtOpen = openDebt[item.upload_id] || false;
//           const isAdditionOpen = openAdditions[item.upload_id] || false;
//           let newKeyData = keyObject[item.upload_id];

//           let isBalanceSheetOpen = openBalanceSheet[item.upload_id]
//             ? openBalanceSheet[item.upload_id]
//             : item.forms[0] === "Balancesheet" &&
//               (!newKeyData || newKeyData == 0)
//             ? item.forms[0] === "Balancesheet"
//             : false;
//           let isCashFlowOpen = openCashFlow[item.upload_id]
//             ? openCashFlow[item.upload_id]
//             : item.forms[0] === "1084_cash_flow" &&
//               (!newKeyData || newKeyData == 0)
//             ? item.forms[0] === "1084_cash_flow"
//             : false;
//           let isIncomeStatementOpen = openIncome[item.upload_id]
//             ? openIncome[item.upload_id]
//             : item.forms[0] === "incomeStatement" &&
//               (!newKeyData || newKeyData == 0)
//             ? item.forms[0] === "incomeStatement"
//             : false;
//           // if (isBalanceSheetOpen && balanceSheet.length === 0) {
//           //   getSpreadFormData(id, "Balancesheet", item.upload_id, key);
//           // }

//           // if (isCashFlowOpen && cashFlow.length === 0) {
//           //   getSpreadFormData(id, "1084_cash_flow", item.upload_id, key);
//           // }
//           // if (isIncomeStatementOpen && incomeStatement.length === 0) {
//           //   getSpreadFormData(id, "incomeStatement", item.upload_id, key);
//           // }

//           if (isIncomeStatementOpen && tabOpen == "incomeStatement") {
//             isCashFlowOpen = false;
//             isBalanceSheetOpen = false;
//             isIncomeStatementOpen = true;
//           }

//           if (isCashFlowOpen && tabOpen == "1084_cash_flow") {
//             isIncomeStatementOpen = false;
//             isBalanceSheetOpen = false;
//             isCashFlowOpen = true;
//           }

//           if (isBalanceSheetOpen && tabOpen == "Balancesheet") {
//             isCashFlowOpen = false;
//             isIncomeStatementOpen = false;
//             isBalanceSheetOpen = true;
//           }

//           let resultItems = result[key];
//           const shouldShowSwitch =
//             resultItems &&
//             resultItems.some((item) =>
//               formTypesToShow.includes(item.form_type)
//             );

//           let pageno =
//             resultItems && resultItems[0].pageno ? resultItems[0].pageno : 1;

//           // if(pageno == 1){
//           //   pageno = 1
//           // }

//           console.log("pageno 1152", pageno);

//           console.log("buttonkey", buttonKey);
//           console.log("resultItems 1152", resultItems);

//           let imagedata =
//             resultItems && resultItems.length > 0 && resultItems[0].imagedata
//               ? resultItems[0].imagedata
//               : "";

//           return (
//             <div className="col-lg-12 wrap-section mb-4" key={key}>
//               <div className="top-bar text-center">
//                 <p>
//                   Upload #{item.upload_id} for Year {item.year} from{" "}
//                   {item.form_type} {item.formtypedata}
//                   <span className="trans">
//                     <FaTrash onClick={() => handleDelete(dataid, item)} />
//                   </span>
//                 </p>
//               </div>
//               <div className="col-lg-12 left-wrap">
//                 <div className="row">
//                   <div className="col-lg-8 left-panel">
//                     <div className="left-side-top d-flex flex-row bd-highlight btn-section">
//                       <div className="p-0 bd-highlight">
//                         <button
//                           type="button"
//                           className={`btn btn-primary btn-10b ${
//                             highlightButton === "NOTES"
//                               ? "btn-primary-dark"
//                               : ""
//                           }`}
//                           onClick={() => {
//                             setHighlightButton("NOTES");
//                             handelOpenNotes(item.upload_id, "1040");
//                           }}
//                         >
//                           NOTES
//                         </button>
//                       </div>
//                       <div className="p-0 bd-highlight">
//                         <button
//                           type="button"
//                           className={`btn btn-primary adjest ${
//                             highlightButton === "Adjustments"
//                               ? "btn-primary-dark"
//                               : ""
//                           }`}
//                           onClick={() => {
//                             setHighlightButton("Adjustments");
//                             handleOpenAdjustment(item.upload_id);
//                           }}
//                         >
//                           Adjustments
//                         </button>
//                       </div>
//                       <div className="p-0 bd-highlight">
//                         <button
//                           type="button"
//                           className={`btn btn-primary debt ${
//                             highlightButton === "Debt Payments"
//                               ? "btn-primary-dark"
//                               : ""
//                           }`}
//                           onClick={() => {
//                             setHighlightButton("Debt Payments");
//                             handleOpenDebt(item.upload_id);
//                           }}
//                         >
//                           Debt Payments
//                         </button>
//                       </div>
//                       <div className="p-0 bd-highlight">
//                         <button
//                           type="button"
//                           className={`btn btn-primary debt ${
//                             highlightButton === "Additions"
//                               ? "btn-primary-dark"
//                               : ""
//                           }`}
//                           onClick={() => {
//                             setHighlightButton("Additions");
//                             handleOpenAdditions(item.upload_id, item.yearid);
//                           }}
//                         >
//                           Additions
//                         </button>
//                       </div>
//                     </div>

//                     <div className="mb-3 btn-section spread_button" key={key}>
//                       {calculateRows(item.forms).map((rowButtons, rowIndex) => (
//                         <div className="d-flex flex-row" key={rowIndex}>
//                           {rowButtons.map((iteminner, key) => (
//                             <div className="p-0 bd-highlight" key={key}>
//                               <button
//                                 type="button"
//                                 className={`btn btn-primary btn-10b ${
//                                   highlightButton === iteminner
//                                     ? "btn-primary-dark"
//                                     : ""
//                                 }`}
//                                 onClick={() => {
//                                   setHighlightButton(iteminner);
//                                   handleOpenSection(
//                                     item.upload_id,
//                                     iteminner,
//                                     rowIndex * 4 + key
//                                   );
//                                 }}
//                               >
//                                 {iteminner}
//                               </button>
//                             </div>
//                           ))}
//                         </div>
//                       ))}
//                     </div>

//                     <div className="left-image-content">
//                       {imagedata ? (
//                         <Iframe
//                           className="iframes"
//                           src={`${imagedata}#page=${
//                             buttonKey === 0 ? 1 : pageno
//                           }`}
//                           width="100%"
//                           type="application/pdf"
//                           id="myId"
//                           height="500px"
//                           display="initial"
//                           position="relative"
//                         />
//                       ) : (
//                         <div
//                           className="file-upload-box"
//                           onClick={(event) =>
//                             handleFileUploadBoxClick(item.upload_id, event)
//                           }
//                         >
//                           <img src={myImage} alt="" />
//                           <p>Select your Document Here</p>
//                           <label className="upload-btn" htmlFor="upload2">
//                             Select a File
//                             <input
//                               type="file"
//                               id="upload2"
//                               className=""
//                               accept=""
//                               onChange={(event) =>
//                                 handleUpload(event, uploadId, buttonKey)
//                               }
//                               multiple
//                             />
//                           </label>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-lg-4 right-part">
//                     <div className="right-side-content">
//                       <div className="right-side-content d-flex flex-row bd-highlight mb-3">
//                         {isNotesOpen ? (
//                           <div className="mb-3 w-100">
//                             <label
//                               htmlFor="exampleFormControlTextarea1"
//                               className="form-label"
//                             >
//                               Add Notes
//                             </label>
//                             <textarea
//                               className="form-control"
//                               id="exampleFormControlTextarea1"
//                               rows="3"
//                               value={selectedItemData.image_notes}
//                               onChange={(e) =>
//                                 handleChange(e, selectedItemData.upload_id)
//                               }
//                             ></textarea>
//                           </div>
//                         ) : isAdjustmentOpen ? (
//                           <div className="right-side-contents d-flex flex-row bd-highlight mb-3">
//                             <div
//                               className="bg-color text-center desc-sec"
//                               style={{ width: "280px" }}
//                             >
//                               <p
//                                 className="mb-1 pt-2 name-p"
//                                 style={{ color: "black" }}
//                               >
//                                 Field Name <br />
//                                 <br /> <br />
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Auto Expenses Adjustments
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Refunded{" "}
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Owed
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Amortization
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Adjustments
//                               </p>
//                             </div>
//                             <div className="name-content new-name-content">
//                               <p className="mb-0 set-colum pt-4">Enter Name</p>
//                               <p className="mb-0 set-colum amt-content">
//                                 Entry value
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Auto Expenses Adjustments",
//                                     adjustment,
//                                     item.upload_id,
//                                     adjustments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Auto Expenses Adjustments",
//                                         adjustment,
//                                         item.upload_id,
//                                         adjustments
//                                       ),
//                                       "adjustment"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Refunded",
//                                     adjustment,
//                                     item.upload_id,
//                                     adjustments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Refunded",
//                                         adjustment,
//                                         item.upload_id,
//                                         adjustments
//                                       ),
//                                       "adjustment"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Owed",
//                                     adjustment,
//                                     item.upload_id,
//                                     adjustments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Owed",
//                                         adjustment,
//                                         item.upload_id,
//                                         adjustments
//                                       ),
//                                       "adjustment"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Amortization",
//                                     adjustment,
//                                     item.upload_id,
//                                     adjustments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Amortization",
//                                         adjustment,
//                                         item.upload_id,
//                                         adjustments
//                                       ),
//                                       "adjustment"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Adjustments",
//                                     adjustment,
//                                     item.upload_id,
//                                     adjustments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Adjustments",
//                                         adjustment,
//                                         item.upload_id,
//                                         adjustments
//                                       ),
//                                       "adjustment"
//                                     )
//                                   }
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         ) : isDebtOpen ? (
//                           <div className="debt-annual-pay right-side-contents d-flex flex-row bd-highlight mb-3">
//                             <div
//                               className="bg-color text-center desc-sec"
//                               style={{ width: "278px" }}
//                             >
//                               <p
//                                 className="mb-1 pt-2 name-p"
//                                 style={{ color: "black" }}
//                               >
//                                 Annual Debt Payments <br />
//                                 <br /> <br />
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Projected Payment on Loan Request
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Home Mortgage/Rent
//                               </p>

//                               <p className="desc-content mb-0 desc-item">
//                                 Revolving Totals
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Installment Totals
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Rental Totals
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Personal Living Expenses-est.
//                               </p>
//                             </div>
//                             <div className="name-content new-name-content debt-annual">
//                               <p className="mb-0 set-colum pt-4 name-topbox"></p>
//                               <p className="mb-0 set-colum amt-content">
//                                 Monthly
//                               </p>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Projected Payment on Loan Request-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Projected Payment on Loan Request-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Projected Payment on Loan Request-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Home Mortgage/Rent-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Home Mortgage/Rent-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Home Mortgage/Rent-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Revolving Totals-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Revolving Totals-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Revolving Totals-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>

//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Installment Totals-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Installment Totals-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Installment Totals-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Rental Totals-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Rental Totals-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Rental Totals-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Other-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Personal Living Expenses-est.-Monthly",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Personal Living Expenses-est.-Monthly",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Personal Living Expenses-est.-Monthly"
//                                     )
//                                   }
//                                 />
//                               </div>
//                             </div>
//                             <div className="name-content new-name-content debt-annual">
//                               <p className="mb-0 set-colum pt-4 name-topbox"></p>
//                               <p className="mb-0 set-colum amt-content">
//                                 Annual
//                               </p>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Projected Payment on Loan Request-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Projected Payment on Loan Request-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Projected Payment on Loan Request-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Home Mortgage/Rent-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Home Mortgage/Rent-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Home Mortgage/Rent-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Revolving Totals-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Revolving Totals-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Revolving Totals-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Installment Totals-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Installment Totals-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Installment Totals-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Rental Totals-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Rental Totals-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Rental Totals-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Other-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>

//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Personal Living Expenses-est.-Annual",
//                                     debtPayment,
//                                     item.upload_id,
//                                     debtPayments
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Personal Living Expenses-est.-Annual",
//                                         debtPayment,
//                                         item.upload_id,
//                                         debtPayments
//                                       ),
//                                       "debtPayment",
//                                       "Personal Living Expenses-est.-Annual"
//                                     )
//                                   }
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         ) : isAdditionOpen ? (
//                           <div>
//                             <div className="d-flex">
//                               <p
//                                 style={{
//                                   margin: "20px",
//                                   fontWeight: "700",
//                                   fontSize: "large",
//                                 }}
//                               >
//                                 Add Form:
//                               </p>
//                               <p
//                                 style={{
//                                   marginLeft: "50px",
//                                   marginTop: "20px",
//                                   fontWeight: "700",
//                                   fontSize: "large",
//                                 }}
//                               >
//                                 Is Upload:
//                               </p>
//                               <span
//                                 style={{
//                                   marginTop: "20px",
//                                   marginLeft: "10px",
//                                 }}
//                               >
//                                 <ReactSwitch
//                                   checked={switchValueUpload}
//                                   onChange={handleSwitchUpload}
//                                 />
//                               </span>
//                             </div>
//                             {uploadOpen ? (
//                               <div className="file-upload-box">
//                                 <img src={myImage} alt="" />
//                                 <p>Select your Document Here</p>
//                                 <label className="upload-btn" htmlFor="upload1">
//                                   Select a File
//                                   <input
//                                     type="file"
//                                     id="upload1"
//                                     className=""
//                                     accept="application/pdf"
//                                     onChange={(event) =>
//                                       handleFileUpload(event, item)
//                                     }
//                                     multiple
//                                   />
//                                 </label>
//                               </div>
//                             ) : (
//                               <div style={{ margin: "4px" }}>
//                                 <select
//                                   defaultValue={"select"}
//                                   onChange={handleOptionFormChanges}
//                                   className="form-control"
//                                   style={{ width: "300px" }}
//                                 >
//                                   <option disabled>select</option>
//                                   {formType.map((option) => (
//                                     <option key={option.id} value={option.id}>
//                                       {option.form_type}
//                                     </option>
//                                   ))}
//                                 </select>
//                                 <button
//                                   style={{
//                                     height: "fit-content",
//                                     marginTop: "7px",
//                                     backgroundColor: "#c00000",
//                                     color: "white",
//                                   }}
//                                   onClick={() => handleSubmitForm(item)}
//                                 >
//                                   Submit
//                                 </button>
//                               </div>
//                             )}
//                           </div>
//                         ) : isBalanceSheetOpen ? (
//                           <div className="balance-sheet-form right-side-contents d-flex flex-row bd-highlight mb-3">
//                             <div
//                               className="bg-color text-center desc-sec"
//                               style={{ width: "280px" }}
//                             >
//                               <p
//                                 className="mb-1 pt-2 name-p"
//                                 style={{ color: "black" }}
//                               >
//                                 Balance Sheet <br />
//                                 <br />
//                                 <p className="desc-content mb-0 desc-item">
//                                   Ownership %
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Form Name
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Year of Financials
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Enter Number of Whole Months for Financials
//                                 </p>
//                                 <br />
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Cash
//                               </p>
//                               <p className="desc-content mb-0 desc-item">COD</p>
//                               <p className="desc-content mb-0 desc-item">
//                                 A/R trade
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Inv.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Cur Assets
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Land & Build.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Leasehold Imp.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Equipment
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Fixt & Furn.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Acc. DPR
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Prepaids
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 N/R - Noncurrent
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 L/T Invest.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Due from Insider
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Assets
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Intangibles
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Overdrafts
//                               </p>
//                               <p className="desc-content mb-0 desc-item">AP</p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Notes Payable
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 CPLTD
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Accrued Exp.
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Taxes Payable
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Income Taxes
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Long Term Debt
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Debt to Stockholders
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Deferred Taxes
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Liab
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Preferred Stock
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Common Stock
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Paid In Capital
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Treasury Stock
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Retained Earnings
//                               </p>
//                             </div>
//                             <div className="name-content new-name-contents spread-form">
//                               <p className="mb-0 set-colum pt-4">Enter Name</p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Ownership %",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Ownership %",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Form Name",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Form Name",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Year of Financials",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Year of Financials",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Enter Number of Whole Months for Financials",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Enter Number of Whole Months for Financials",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Current Assets
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Cash",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Cash",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "COD",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "COD",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "A/R trade",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "A/R trade",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Inv.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Inv.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Cur Assets",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Cur Assets",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Net Fixed Assets
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Land & Build.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Land & Build.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Leasehold Imp.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Leasehold Imp.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Equipment",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Equipment",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Fixt & Furn.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Fixt & Furn.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Acc. DPR",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Acc. DPR",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Other Assets
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Prepaids",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Prepaids",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "N/R - Noncurrent",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "N/R - Noncurrent",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "L/T Invest.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "L/T Invest.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Due from Insider",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Due from Insider",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Assets",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Assets",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Intangibles",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Intangibles",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Long Term Debt
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Overdrafts",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Overdrafts",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "AP",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "AP",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Notes Payable",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Notes Payable",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "CPLTD",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "CPLTD",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Accrued Exp.",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Accrued Exp.",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Taxes Payable",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Taxes Payable",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Income Taxes",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Income Taxes",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Long Term Liabilities
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Long Term Debt",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Long Term Debt",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Debt to Stockholders",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Debt to Stockholders",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Deferred Taxes",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Deferred Taxes",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Liab",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Liab",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Shareholder Equity
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Preferred Stock",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Preferred Stock",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Common Stock",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Common Stock",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Paid In Capital",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Paid In Capital",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Treasury Stock",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Treasury Stock",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Retained Earnings",
//                                     balanceSheet,
//                                     item.upload_id,
//                                     balanceSheet
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Retained Earnings",
//                                         balanceSheet,
//                                         item.upload_id,
//                                         balanceSheet
//                                       ),
//                                       "balanceSheet"
//                                     )
//                                   }
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         ) : isCashFlowOpen ? (
//                           <div className="spread-cashflow">
//                             <p>Form 1084 Cash Flow Forms</p>
//                             <div className="right-side-contents d-flex flex-row bd-highlight mb-3 ">
//                               <div
//                                 className="bg-color text-center desc-sec"
//                                 style={{ width: "280px" }}
//                               >
//                                 <p
//                                   className="mb-1 pt-2 name-p"
//                                   style={{ color: "black" }}
//                                 >
//                                   Field Name <br />
//                                   <br />
//                                   <h6>Schedule C Items</h6>
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Auto Expenses Adjustments
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Refunded
//                                 </p>
//                                 <p
//                                   className="desc-content mb-0 desc-item"
//                                   style={{ backgroundColor: "white" }}
//                                 ></p>
//                                 <h6 style={{ color: "black" }}>
//                                   Schedule D Items
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Schedule D-Recurring Gains
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Recurring Capital Gains
//                                 </p>

//                                 <br />
//                                 <h6 style={{ color: "black" }}>
//                                   Schedule E Items
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Total Expenses
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depletion
//                                 </p>

//                                 <br />
//                                 <h6 style={{ color: "black" }}>
//                                   Schedule F Items
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Nonrecurring Other Income Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Business Use of Home
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Amortization/Casualty Loss
//                                 </p>

//                                 <br />
//                                 <h6 style={{ color: "black" }}>
//                                   1065 Business Cash Flows
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Ordinary Income Loss From Other Partnerships
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Non Recurring Other
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depreciation
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depletion
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Amortization/Casualty Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Mortgages Or Notes Payable Less than 1 Year
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Non-Deductible Travel and and Entertainment
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Total % Percentage Owned
//                                 </p>

//                                 <br />
//                                 <h6 style={{ color: "black" }}>
//                                   1120S Cash Flows
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Form 1120S - Adjustments to Business Cash Flow
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Nonrecurring Other (Income) Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depreciation
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depletion
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Amortization/Casualty Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Mortgages or Notes Payable in Less than 1 Year
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Non-deductible Travel and Entertainment
//                                   Expenses
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Non-Deductible Travel and and Entertainment
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Total % Percentage Owned
//                                 </p>

//                                 <br />
//                                 <h6 style={{ color: "black" }}>
//                                   1120 Corporation
//                                 </h6>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Taxable Income
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Total Tax
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Nonrecurring (Gains) Losses
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Nonrecurring Other (Income) Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depreciation
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Depletion
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Amortization/Casualty Loss
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Net Operating Loss and Special Deductions
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Mortgages or Notes Payable in Less than 1 Year
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Non-deductible Travel and Entertainment
//                                   Expenses
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Less: Dividends Paid to Borrower
//                                 </p>
//                               </div>
//                               <div className="name-content new-name-content ">
//                                 <div className="d-flex flex-row">
//                                   <div>
//                                     <p className="mb-0 set-colum pt-4">
//                                       Enter Name
//                                     </p>
//                                     <p className="mb-0 set-colum amt-content">
//                                       Entry value
//                                     </p>

//                                     <div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Nonrecurring Other Inc/LossExp.",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Nonrecurring Other Inc/LossExp.",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Amortization/Casualty Loss",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Amortization/Casualty Loss",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                     {/* Schedule D Items */}
//                                     <br />
//                                     <br />
//                                     <br />
//                                     <div style={{ marginTop: "-7px" }}>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Schedule D-Recurring Gains",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Schedule D-Recurring Gains",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Recurring Capital Gains",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Recurring Capital Gains",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                     {/* Schedule E Items */}
//                                     <br />
//                                     <br />
//                                     <div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Total Expenses",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Total Expenses",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depletion",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depletion",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                     {/* Schedule F Items */}
//                                     <br />
//                                     <br />

//                                     <div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Nonrecurring Other Income Loss",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Nonrecurring Other Income Loss",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Business Use of Home",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Business Use of Home",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Amortization/Casualty Loss_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Amortization/Casualty Loss_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                     {/* 1065 Business Cash Flows */}
//                                     <br />
//                                     <br />
//                                     <div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Ordinary Income Loss From Other Partnerships",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Ordinary Income Loss From Other Partnerships",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Non Recurring Other",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Non Recurring Other",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depreciation",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depreciation",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depletion_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depletion_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Amortization/Casualty Loss_2",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Amortization/Casualty Loss_2",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Mortgages Or Notes Payable Less than 1 Year",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Mortgages Or Notes Payable Less than 1 Year",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Non-Deductible Travel and and Entertainment",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Non-Deductible Travel and and Entertainment",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Total % Percentage Owned",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Total % Percentage Owned",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>

//                                     {/* 1120S Cash Flows */}
//                                     <br />
//                                     <br />
//                                     <div style={{ marginTop: "5px" }}>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Form 1120S - Adjustments to Business Cash Flow",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Form 1120S - Adjustments to Business Cash Flow",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Nonrecurring Other (Income) Loss",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Nonrecurring Other (Income) Loss",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depreciation_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depreciation_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depletion_2",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depletion_2",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Amortization/Casualty Loss_3",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Amortization/Casualty Loss_3",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Mortgages or Notes Payable in Less than 1 Year",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Mortgages or Notes Payable in Less than 1 Year",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Non-deductible Travel and Entertainment Expenses",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Non-deductible Travel and Entertainment Expenses",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Non-Deductible Travel and and Entertainment_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Non-Deductible Travel and and Entertainment_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Total % Percentage Owned_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Total % Percentage Owned_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>

//                                     {/* 1120 Corporation*/}
//                                     <br />
//                                     <br />
//                                     <br />
//                                     <div style={{ marginTop: "-19px" }}>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Taxable Income",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Taxable Income",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Total Tax",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Total Tax",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Nonrecurring (Gains) Losses",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Nonrecurring (Gains) Losses",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Nonrecurring Other (Income) Loss_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Nonrecurring Other (Income) Loss_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depreciation_2",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depreciation_2",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Depletion_3",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Depletion_3",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Amortization/Casualty Loss_4",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Amortization/Casualty Loss_4",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Net Operating Loss and Special Deductions",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Net Operating Loss and Special Deductions",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Mortgages or Notes Payable in Less than 1 Year_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Mortgages or Notes Payable in Less than 1 Year_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>

//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Non-deductible Travel and Entertainment Expenses_1",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Non-deductible Travel and Entertainment Expenses_1",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                       <div
//                                         className="spread-custom-input "
//                                         style={{
//                                           display: "flex",
//                                           alignItems: "center",
//                                         }}
//                                       >
//                                         <span
//                                           style={{
//                                             marginRight: "5px",
//                                             borderTop: "0px solid black",
//                                             borderBottom: "0px solid black",
//                                           }}
//                                         >
//                                           $
//                                         </span>
//                                         <input
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           value={getElementValue(
//                                             "Less: Dividends Paid to Borrower",
//                                             cashFlow,
//                                             item.upload_id,
//                                             cashFlow
//                                           )}
//                                           onChange={(e) =>
//                                             handleInputValueChange(
//                                               e,
//                                               getElementId(
//                                                 "Less: Dividends Paid to Borrower",
//                                                 cashFlow,
//                                                 item.upload_id,
//                                                 cashFlow
//                                               ),
//                                               "cashFlow"
//                                             )
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         ) : isIncomeStatementOpen ? (
//                           <div className="right-side-contents d-flex flex-row bd-highlight mb-3">
//                             <div
//                               className="bg-color text-center desc-sec"
//                               style={{ width: "280px" }}
//                             >
//                               <p
//                                 className="mb-1 pt-2 name-p"
//                                 style={{ color: "black" }}
//                               >
//                                 Income Statement <br />
//                                 <br />
//                                 <p className="desc-content mb-0 desc-item">
//                                   Ownership %
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Form Name
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Year of Financials
//                                 </p>
//                                 <p className="desc-content mb-0 desc-item">
//                                   Enter Number of Whole Months
//                                 </p>
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Gross Revenues
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Income
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Direct
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Materials
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 COGS - Depreciation
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 COGS - Other
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Depreciation Expense
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Rent
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Salaries
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Officer's Comp
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Repairs
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Taxes
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Employee benefits
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Advertisin
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Insurance Expense
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Utilities
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Operating Expenses
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Interest Expense
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Income
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other Expense
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Unconsolidated Subsidiary
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Current Taxes
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Deferred Taxes
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Cumu. Effect of Chng in Acct. Prin.
//                               </p>
//                               <p
//                                 className="desc-content mb-0 desc-item"
//                                 style={{ backgroundColor: "white" }}
//                               ></p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Beginning Net Worth
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Dividends (disbursements)
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Other
//                               </p>
//                               <p className="desc-content mb-0 desc-item">
//                                 Adjustment Required
//                               </p>
//                             </div>
//                             <div className="name-content new-name-contents">
//                               <p className="mb-0 set-colum pt-4">Enter Name</p>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Ownership %",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Ownership %",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Form Name",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Form Name",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Year",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Year",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Enter Number of Whole Months for Financials",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Enter Number of Whole Months for Financials",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Revenues
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Gross Revenues",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Gross Revenues",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Income",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Income",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Direct Costs
//                               </p>

//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Direct",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Direct",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Materials",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Materials",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "COGS - Depreciation",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "COGS - Depreciation",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "COGS - Other",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "COGS - Other",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Operating Expenses
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Depreciation Expense",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Depreciation Expense",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Rent",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Rent",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Salaries",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Salaries",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Officer's Comp",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Officer's Comp",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Repairs",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Repairs",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Taxes",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Taxes",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Employee benefits",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Employee benefits",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Advertisin",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Advertisin",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Insurance Expense",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Insurance Expense",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Utilities",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Utilities",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Operating Expenses",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Operating Expenses",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Other Expenses
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Interest Expense",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Interest Expense",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Income",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Income",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other Expense",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other Expense",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Unconsolidated Subsidiary",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Unconsolidated Subsidiary",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Taxes
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Current Taxes",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Current Taxes",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Deferred Taxes",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Deferred Taxes",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Cumu. Effect of Chng in Acct. Prin.",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Cumu. Effect of Chng in Acct. Prin.",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <p className="mb-0 set-colum amt-content">
//                                 Net Worth
//                               </p>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Beginning Net Worth",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Beginning Net Worth",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Dividends (disbursements)",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Dividends (disbursements)",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Other",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Other",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                               <div className="spread-custom-input">
//                                 <span>$</span>
//                                 <input
//                                   className="desc-content mb-0 int-desc"
//                                   type="text"
//                                   value={getElementValue(
//                                     "Adjustment Required                      ss179",
//                                     incomeStatement,
//                                     item.upload_id,
//                                     incomeStatement
//                                   )}
//                                   onChange={(e) =>
//                                     handleInputValueChange(
//                                       e,
//                                       getElementId(
//                                         "Adjustment Required                      ss179",
//                                         incomeStatement,
//                                         item.upload_id,
//                                         incomeStatement
//                                       ),
//                                       "incomeStatement"
//                                     )
//                                   }
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         ) : (
//                           <div className="right-side-content d-flex flex-row bd-highlight mb-3 edit-manualform newform-wrap">
//                             <div className="bg-color text-center desc-sec">
//                               <p
//                                 className="mb-1 pt-2 name-p"
//                                 style={{ color: "black" }}
//                               >
//                                 Name of Individual or
//                                 <br />
//                                 Entity
//                                 <br />
//                                 Line Description
//                               </p>
//                               {resultItems &&
//                                 resultItems
//                                   .filter((item) => item.iscash === isCash)
//                                   .map((item, key) => {
//                                     return (
//                                       <p
//                                         className="desc-content mb-0 desc-item"
//                                         key={key}
//                                       >
//                                         {item.fieldname}
//                                       </p>
//                                     );
//                                   })}
//                             </div>
//                             <div className="line-content">
//                               {shouldShowSwitch ? (
//                                 <span className="line-switch">
//                                   <label htmlFor="isCash">IsCash</label>
//                                   <ReactSwitch
//                                     onChange={(e) =>
//                                       handleCashSwitchChange(e, item.upload_id)
//                                     }
//                                     checked={isCash}
//                                   />
//                                 </span>
//                               ) : (
//                                 <span className="line-switch">
//                                   <label htmlFor="isCash"></label>
//                                 </span>
//                               )}
//                               <p
//                                 className="pt-1 pb-0 lin-t"
//                                 style={{ color: "black" }}
//                               >
//                                 Line#
//                               </p>
//                               {resultItems &&
//                                 resultItems
//                                   .filter((item) => item.iscash === isCash)
//                                   .map((item, key) => {
//                                     return (
//                                       <p
//                                         className="desc-content mb-0 mt-0"
//                                         key={key}
//                                       >
//                                         {item.field_label}
//                                       </p>
//                                     );
//                                   })}
//                             </div>
//                             <div className="name-content new-name-content">
//                               <p className="mb-0 set-colum pt-4">Enter Name</p>
//                               <p className="mb-0 set-colum amt-content">
//                                 Entry Amount
//                               </p>
//                               {resultItems &&
//                                 resultItems
//                                   .filter((item) => item.iscash === isCash)
//                                   .map((item, key) => {
//                                     const { id, value } = item;
//                                     const formattedValue =
//                                       new Intl.NumberFormat("en-US", {
//                                         style: "decimal",
//                                         // minimumFractionDigits: 0,
//                                       }).format(value);
//                                     return (
//                                       <div className="spread-custom-input">
//                                         <span>$</span>
//                                         <input
//                                           key={id}
//                                           className="desc-content mb-0 int-desc"
//                                           type="text"
//                                           style={{ borderLeft: "none" }}
//                                           lue={value}
//                                           value={formattedValue}
//                                           onChange={(e) => {
//                                             const realPrice =
//                                               e.target.value.replace(/,/g, "");

//                                             const modifiedEvent = {
//                                               ...e,
//                                               target: {
//                                                 ...e.target,
//                                                 value: realPrice,
//                                               },
//                                             };
//                                             handleInputValueChange(
//                                               modifiedEvent,
//                                               id
//                                             );
//                                           }}
//                                         />
//                                       </div>
//                                     );
//                                   })}
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                       {/* )} */}
//                     </div>
//                   </div>
//                 </div>
//                 <Modal show={open} onHide={handleOff}>
//                   <Modal.Header>
//                     <Modal.Title>Are you sure you want to delete?</Modal.Title>
//                     <IoClose
//                       size={24}
//                       color="red"
//                       style={{ cursor: "pointer" }}
//                       onClick={handleCancelDelete}
//                     />
//                   </Modal.Header>
//                   <Modal.Body>
//                     <Button
//                       variant="primary"
//                       style={{
//                         marginTop: "15px",
//                         marginRight: "5px",
//                         backgroundColor: "#c00000",
//                       }}
//                       type="submit"
//                       onClick={() => handleConfirmDelete(deleteId, dataPath)}
//                     >
//                       Delete
//                     </Button>
//                     <Button
//                       variant="primary"
//                       style={{ marginTop: "15px" }}
//                       type="submit"
//                       onClick={handleCancelDelete}
//                     >
//                       Cancel
//                     </Button>
//                   </Modal.Body>
//                 </Modal>
//               </div>
//             </div>
//           );
//         })}
//     </div>
//   );
// };

// export default EditSpread;
