import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import Logo from "../../../src/assets/image/Robospreads-logo.png"
import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import { Logout } from "../../Pages/Logout/Logout";
import UserApi from "../../services/API/UserApi";
import Avatar from "react-avatar";
import Cookies from "js-cookie";

const Navbar = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  let isAdmin = Cookies.get("isadmin");


  useEffect(() => {
    const user = UserApi.getCurrentUser();

    if (user) {
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const profile = () => {

  }

  const logOut = () => {



    Logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };


  useEffect(async () => {
    let userData = await AuthService.profile()
    console.log("userDatauserDatauserData", userData);
    Cookies.set("id", userData.id)
    setName(userData.name)
  }, []);


  return (
    <>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <div className="left-head-logo" style={{ textAlign: "center" }}>
            <img
              style={{ width: "206px", height: "auto", margin: "10px 20px " }}
              src={Logo}
            ></img>
          </div>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/myspread"} className="nav-link"></Link>
            </li>

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              {isAdmin === "true" && (
                <li className="nav-item">
                  <Link to={"/sendgrid"} className="nav-link">
                    SendGrid key
                  </Link>
                </li>
              )}
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              {isAdmin === "true" && (
                <li className="nav-item">
                  <Link to={"/sendgrid"} className="nav-link">
                    SendGrid key
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link to={"/changepassword"} className="nav-link">
                  Change Password
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
              <li className="nav-item">
                <Avatar
                  name={name}
                  size="40"
                  round={true}
                  src="path_to_avatar_image.jpg"
                  style={{ cursor: "pointer" }}
                />
              </li>
            </div>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
